










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
import TestReportContent from '@/components/test_report/TestReportContent.vue';

@Component({
  components: {
    Header,
    TestReportContent,
    Footer,
  },
})
export default class MyTestReport extends Vue {}
