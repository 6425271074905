







































import router from '@/router'

import { Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"

import LoginService from '@/services/login_service';
import ServiceCallHelper from '@/mixins/service_call_helper'

import { Messages, Code } from '@/const'
import { LoginData } from '@/types'
import { OverlayLoader, ErrorMessage, LogoImage } from '@/components/parts'
import { PwdResetSendValidator } from '@/validators'

@Component({
  components: {
    OverlayLoader,
    ErrorMessage,
    LogoImage,
  },
  validations: {
    fields: new PwdResetSendValidator()
  },
})
export default class PwdResetSendForm extends ServiceCallHelper {

  fields = new LoginData();

  /**
   * 送信するボタン
   */
  clickSendButton() {
    // 入力チェック
    if (this.$v.$invalid) {
      this.$swal(Messages.INPUT_VALIDATION_ERROR, '', 'error');
      return;
    }

    // 送信処理
    this.showLoading();
    new LoginService().pwdResetSend(this.fields.loginId).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      Vue.$log.debug(data);
      // 成功時
      if(data.code == Code.SUCCESS) {
        router.push('/pwd_reset_send/complete');
      }
      // アカウントが存在しない
      else if(data.code == Code.ACCOUNT_NOT_FOUND) {
        this.$swal('指定されたユーザーは存在しません', '', 'error');
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }
}
