import { NotifyService } from '@/services/notify/notify_service'
import { AxiosResponse } from '@/types';

export default class ReactionRequestNotifyService extends NotifyService {
  /**
   * リアクション依頼通知
   *
   * @aparam params
   */
  public notifyTarget(userIds: number[]): Promise<AxiosResponse> {
    return this.getReq('/api/v1/reaction_request_notify', {"userIds": userIds}) as Promise<AxiosResponse>;
  }
}