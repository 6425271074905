




































































































import { Component } from "vue-mixin-decorator";
import ServiceCallHelper from "@/mixins/service_call_helper";
import router from "@/router";
import ActionReportSearchService from "@/services/action_report_search";

@Component({
  components: {},
})
export default class ActionReportSearchModal extends ServiceCallHelper {
  /** 入力フィールド */
  keyword = "";

  /** 各種ボタン */
  isActiveMemoButton = false;
  isActiveCommentButton = false;
  isActiveUserButton = false;

  /** 検索タイプ */
  searchType: "" | "memo" | "comment" | "user" = "memo";

  /** 検索履歴 */
  searchHistories: string[] = [];

  /**
   * 検索タイプ変更
   */
  changeSearchType(type: "memo" | "comment" | "user") {
    this.searchType = type;
  }

  clearSearchCondition()  {
    this.keyword = "";
    this.searchType = "memo";
    this.$store.commit("setActionReportSearchConditions", {
      termFrom: "",
      termTo: "",
      text: this.keyword,
      st: this.searchType,
    });
  }

  mounted() {
    // 状態の初期化
    this.keyword = this.$store.getters.actionReportSearchConditions.text;
    this.searchType = this.$store.getters.actionReportSearchConditions.st;
    const searchHistories = localStorage.getItem("search_term");
    if (searchHistories) {
      const searchsearchHistoriesArray = JSON.parse(searchHistories);
      this.searchHistories = searchsearchHistoriesArray;
    }
  }

  /**
   * 検索
   */
  search(keyword: string) {
    this.$modal.hide("action-report-search-modal");

    // ユーザ検索の場合はアクションレポート画面に飛ばす
    if (this.searchType === "user") {
      // 検索結果画面にいない時のみ
      this.$store.commit("setActionReportSearchConditions", {
        termFrom: "",
        termTo: "",
        text: keyword,
        st: this.searchType,
      });

      // パラメータの変更
      if (this.$router.currentRoute.path !== "/action_report") {
        router.push(`/action_report?username=${this.keyword}`);
      } else {
        router.push({
          path: "/action_report",
          query: { username: this.keyword },
        });
      }

      return;
    }

    // MEMO/コメント検索
    new ActionReportSearchService()
      .getActionReportSearch({
        termFrom: "",
        termTo: "",
        text: keyword,
        st: this.searchType,
        page: 1,
        perPage: 10,
        filters: {},
      })
      .then(async (response) => {
        if (this.commonApiErrorHandler(response)) {
          await this.$store.commit("setActionReportSearchConditions", {
            termFrom: "",
            termTo: "",
            text: keyword,
            st: this.searchType,
          });
          this.$store.commit(
            "setActionReportSearchResult",
            response.data.result
          );

          // 検索結果をlocalStorageに保存
          const searchTerm = localStorage.getItem("search_term");
          if (searchTerm) {
            const searchTermArray = JSON.parse(searchTerm);
            let newSearchTermArray = searchTermArray;
            // 検索キーワードがlocalStorageに含まれていない場合
            if (!searchTerm.includes(keyword)) {
              searchTermArray.unshift(keyword);

              if (searchTermArray.length > 5) {
                newSearchTermArray = searchTermArray.slice(0, 5);
              }
            } else {
              // 検索キーワードがlocalStorageに含まれていた場合
              const tempArray: string[] = searchTermArray.filter(
                (term: string) => term !== keyword
              );
              tempArray.unshift(keyword);
              newSearchTermArray = tempArray;
            }
            localStorage.setItem(
              "search_term",
              JSON.stringify(newSearchTermArray)
            );
            this.searchHistories = newSearchTermArray;
          } else {
            localStorage.setItem("search_term", JSON.stringify([keyword]));
            this.searchHistories = [keyword];
          }

          // 検索結果画面以外の時のみ遷移
          if (this.$router.currentRoute.path !== "/action_report_search") {
            router.push("/action_report_search");
          }
        }
      });
  }

  /**
   * 検索実行
   */
  searchAction(keyword?: string) {
    // 検索ボタン
    if (!keyword) {
      this.search(this.keyword);
    } else {
      // 履歴から
      this.search(keyword);
    }
  }
}
