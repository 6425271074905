/**
 * ログインフォーム
 */
export class LoginData {
  loginId = '';
  loginPassword = '';
}

/**
 * パスワード再設定
 */
export class PwdResetData {
  loginPassword = '';
  loginPassword2 = '';
}
