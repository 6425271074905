import Vue from 'vue';
import { Mixin } from "vue-mixin-decorator"
import { SearchParams, SortOption, SortParam, ColumnOption, PaginationOption, PageChangeParam } from '@/types/good_table_types'

/**
 * good table用パラメータ
 */
@Mixin
// eslint-disable-next-line
export default class GoodTableHeader<T = any> extends Vue {

  paginationOptions = new PaginationOption(
    true,
    'pages', this.$device.mobile == Boolean(true));
  isLoading = false;
  totalRows = 0;
  columns: Array<ColumnOption>;
  serverParams = new SearchParams();
  pageName = '';
  
  rows: Array<T>;

  sortOptions: SortOption;

  created() {
    const pageInfo = this.$store.getters.searchParams || {};
    Vue.$log.debug('created');
    Vue.$log.debug(pageInfo);
    if(this.pageName && pageInfo && pageInfo[this.pageName]) {
      const info = pageInfo[this.pageName];
      this.serverParams = info;
      if(info.perPage) {
        this.paginationOptions.perPage = info.perPage;
      }
      if(info.page) {
        this.paginationOptions.setCurrentPage = info.page;
      }
      Vue.$log.debug('created server params');
      Vue.$log.debug(this.serverParams);
    }
  }

  // eslint-disable-next-line
  updateParams(newProps: any) {
    Vue.$log.debug('updateParams');
    Vue.$log.debug(newProps);
    this.serverParams = Object.assign({}, this.serverParams, newProps);
    Vue.$log.debug(this.serverParams);
    const pageInfo = this.$store.getters.searchParams || {};
    if(this.pageName) {
      pageInfo[this.pageName] = this.serverParams;
      this.$store.commit('setSearchParams', pageInfo);
    }
  }

  onPageChange(params: PageChangeParam) {
    Vue.$log.debug('onPageChange');
    Vue.$log.debug(params);
    this.updateParams({page: params.currentPage});
    this.loadItems();
  }

  onPerPageChange(params: PageChangeParam) {
    Vue.$log.debug('onPerPageChange');
    Vue.$log.debug(params);
    this.updateParams({perPage: params.currentPerPage});
    this.loadItems();
  }

  onSortChange(params: Array<SortParam>) {
    Vue.$log.debug('onSortChange');
    Vue.$log.debug(params.map(function(p){return new SortParam(p.field, p.type)}));
    this.updateParams({sort: params.map(function(p){return new SortParam(p.field, p.type)})});
    this.loadItems();
  }

  clearParams(keys: Array<string>) {
    Vue.$log.debug('super clearSearchFilter');
    Vue.$log.debug(keys);
    if('filters' in this.serverParams && this.serverParams.filters) {
      keys.forEach(key => {
        delete this.serverParams['filters'][key];
      });
    }
    Vue.$log.debug(keys);
  }

  loadItems() {
    alert('not implemented');
  }

  // eslint-disable-next-line
  getSelectedRows(table: any) {
    return table.selectedRows
  }
}

