


























































import { Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"

import LoginService from '@/services/login_service';
import { OverlayLoader, ErrorMessage, LogoImage } from '@/components/parts'

import router from '@/router'
import { Messages, Code } from '@/const';
import { validationMixin } from 'vuelidate'
import { LoginValidator } from '@/validators'
import { LoginData, ActionReportSearchConditionsData } from '@/types'
import Cookies from '@/store/cookies'

@Component({
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
    LogoImage,
  },
  validations: {
    fields: new LoginValidator()
  },
})
export default class LoginForm extends Vue {

  loginError = '';

  fields = new LoginData();

  forwardUrl = '';

  /** ログイン情報を保存する */
  savingLoginInfo = true;

  /**
   * mount時にログイン情報保存チェックの有無を変更
   */
  mounted() {
    Vue.$log.info('updated');
    Vue.$log.debug(`Cookies.getNoSavingLoginInfo() = ${Cookies.getNoSavingLoginInfo()}`);
    if(Cookies.getNoSavingLoginInfo() === '1') {
      this.savingLoginInfo = false;
    }
    this.forwardUrl = this.$store.getters.forwardUrl;
    Vue.$log.info(this.forwardUrl);
    this.$store.commit('setForwardUrl', '');
  }

  /**
   * ログイン処理
   */
  clickLoginButton() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$swal(Messages.INVALID_INPUT, '', 'error');
      return;
    }
    (this.$refs.overlayLoader as OverlayLoader).isLoading = true;
    new LoginService().login(this.fields.loginId, this.fields.loginPassword).then( (response) => {
      Vue.$log.debug(response);
      const data = response.data;
      // response取得失敗時
      if(response.status == 403) {
        router.push('/unauthorized');
      }
      // ログイン失敗時
      else if(data && data.code === Code.LOGIN_FAILURE) {
        this.loginError = Messages.LOGIN_FAILURE;
      }
      // 休止モード
      else if(data && data.code == Code.USER_SUSPENSION) {
        Vue.$log.debug('USER_SUSPENSION');
        this.loginError = '指定されたユーザーは休止中です。管理者に問い合わせてください。';
      }
      // データ不正
      else if(!data || !data.result) {
        Vue.$log.debug('no data or no data.result');
        Vue.$log.debug(data);
        this.loginError = Messages.NETWORK_ERROR;
      }
      // 成功時
      else {
        if(data.result.accessToken) {
          Vue.$log.debug('login success');
          this.$store.commit("setActionReportSearchConditions", new ActionReportSearchConditionsData())
          const accessToken: string = data.result.accessToken;
          // ログイン情報保持チェックあり : 30日保存
          if(this.savingLoginInfo) {
            Cookies.setAccessToken(accessToken);
            Cookies.setNoSavingLoginInfo('0');
          }
          // ログイン情報保持チェックなし : ブラウザのみ
          else {
            Cookies.setAccessTokenNoCache(accessToken);
            Cookies.setNoSavingLoginInfo('0');
          }
          this.$store.commit('setUserInfo', data.result.userInfo);
          if(this.forwardUrl) {
            router.push(this.forwardUrl);
            console.log(this.forwardUrl, "loginService");
          }
          else {
            router.push('/');
          }
        }
      }
    }).catch( (error) => {
      Vue.$log.error(error);
      this.loginError = Messages.NETWORK_ERROR;
    }).finally(() => {
      if(this.$refs.overlayLoader) (this.$refs.overlayLoader as OverlayLoader).isLoading = false;
    });
  }
}
