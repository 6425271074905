






















import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import { LogoImage } from '@/components/parts'

@Component({
  components: {
    Footer,
    LogoImage,
  },
})
export default class PwdResetSend extends Vue {}

