import NotFound from '@/views/control/NotFound.vue'
import Forbidden from '@/views/control/Forbidden.vue'

export default [
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: '/unauthorized',
    name: 'Forbidden',
    component: Forbidden,
    meta: {
      requireLogin: false,
    },
  },
]