










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
import ActionLinkContent from '@/components/action_report/ActionLinkContent.vue';

@Component({
  components: {
    Header,
    ActionLinkContent,
    Footer,
  },
})
export default class ActionLink extends Vue {}
