





import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import { Code } from '@/const'
import router from '@/router'

@Component({
  components: {
    Footer,
  },
})
export default class RootContent extends Vue{
  created() {
    // メンバーの場合は
    const userInfo = this.$store.getters.userInfo;
    if(userInfo.userRole == -1) {
      router.push("/login")
    }
    else if(userInfo.userRole == Code.USER_ROLE_MEMBER) {
      router.push('/my_action_report')
    }
    else {
      router.push('/dashboard')
    }
  }
}

