






















































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from "vue-mixin-decorator"
import { Messages, Code } from '@/const';
import { ChecktestReportData, ChecktestReportUserTypeDist } from '@/types';
import { OverlayLoader, StrongPoint, WeakPoint, UserSearchModal } from '@/components/parts'
import { ChecktestRadar } from '@/components/chart'
import TestReportUserType from '@/components/parts/TestReportUserType.vue'
import TestReportTypeDist from '@/components/test_report/TestReportTypeDist.vue'

import { ServiceCallHelper, UserSearchHelper, UserSearchOptionsHelper } from '@/mixins'

import { default as ChecktestReportService } from '@/services/checktest_report_service'
import TestReportScoreDist from '@/components/test_report/TestReportScoreDist.vue'

interface MixinInterface extends ServiceCallHelper, UserSearchHelper, UserSearchOptionsHelper {}

@Component({
  components: {
    OverlayLoader,
    ChecktestRadar,
    StrongPoint,
    WeakPoint,
    TestReportUserType,
    TestReportTypeDist,
    TestReportScoreDist,
    UserSearchModal,
  },
})
export default class GroupTestReportContent extends Mixins<MixinInterface>(ServiceCallHelper, UserSearchHelper, UserSearchOptionsHelper) {

  // 選択レコード数
  totalRows = 0;

  /** 表示情報 */
  report: ChecktestReportData;

  /**  */
  scoreDistReport: ChecktestReportData;

  /** ユーザータイプ人数集計 */
  userTypeCounts: ChecktestReportUserTypeDist;

  // レーダーチャートのCSS
  radarCss = "mx-auto w-100 checktestRadarChartBox checktestRadarChartBoxImage";

  /**
   * mounted時、データ取得とCSSのの設定
   */
  mounted() {
    this.loadStats();

    // mobileの場合は背景を省略
    if(this.$device.mobile) {
      this.radarCss = "mx-auto w-100 checktestRadarChartBox";
    }
  }

  /**
   * データ取得
   */
  loadStats() {
    this.showLoading();

    new ChecktestReportService().getGroupReport(this.searchParams).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      Vue.$log.debug(data);
      // SUCCESSの場合は値を設定
      if(data.code == Code.SUCCESS) {
        this.report = data.result.report;
        this.totalRows = data.result.report.scoreDataAvg.userCount;
        this.pageLoaded = true;
        this.userTypeCounts = data.result.userTypeCounts;
        if(this.$refs.checktestRadar) {
          (this.$refs.checktestRadar as ChecktestRadar).updateChart(this.report);
        }
        if(this.$refs.testReportTypeDist) {
          (this.$refs.testReportTypeDist as TestReportTypeDist).updateReport(data.result.userTypeCounts);
        }
      }
      // チェックテスト受験者ゼロ時
      else if(data.code == Code.CHECKTEST_NOT_FOUND) {
        this.totalRows = 0;
        this.$swal(Messages.CHECKTEST_NOT_FOUND, '', 'warning');
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
      this.updateFilterOptionsText();
    });
  }

  /**
   * 検索クリアボタン
   */
  groupTestClearSearchFilter() {
    this.clearSearchFilter();
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    userSearchModal.clearSearchOptionsFilter();
  }
}

