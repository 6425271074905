








import { Component, Vue } from 'vue-property-decorator';
import ChecktestHeader from '@/components/checktest/ChecktestHeader.vue';
import Footer from '@/components/common/Footer.vue';
import ChecktestInputForm from '@/components/checktest/ChecktestInputForm.vue';

@Component({
  components: {
    ChecktestHeader,
    ChecktestInputForm,
    Footer,
  },
})
export default class ChecktestInput extends Vue {}
