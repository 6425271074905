






































import { Vue, Prop } from "vue-property-decorator";
import { Component } from "vue-mixin-decorator";

import ServiceCallHelper from "@/mixins/service_call_helper";
import { UserService } from "@/services";

@Component
export default class UserEditFormSubmit extends ServiceCallHelper {
  @Prop()
  loginId: string;

  @Prop()
  userName: string;

  challenge = "";

  fields: any = {};

  /**
   * 設定を保存ボタン押下時、データ更新
   */
  clickWebAuthn() {
    this.$modal.show('passkey-modal');
  }

  submitRegister(platform: AuthenticatorAttachment) {
    Vue.$log.debug(`loginId=${this.loginId}`)
    new UserService().postWebAuthnChallenge(this.loginId, this.fields.password).then((res) => {
      Vue.$log.debug(res.data.result)
      this.challenge = res.data.result.challengeID;
      Vue.$log.debug(`this.challenge=${this.challenge}`);
      this.createCredential(this.challenge, platform).then((credential) => {
        const anyCredential = credential as any;
        Vue.$log.debug(credential);
        alert(credential);
        if(credential) {
          new UserService().postWebAuthnRegister(anyCredential.response.clientDataJSON).then((res) => {
            Vue.$log.debug(res.data.result)
          })
        }
      })
    })
  }

  async createCredential(challenge: string, platform: AuthenticatorAttachment) {
    Vue.$log.debug(`challenge=${challenge}, rpId="skillbox-staging.com"`);
    try {
      const credential = await navigator.credentials.create(
        {
          publicKey: {
            rp: {
                name: "skillbox",
                id: "skillbox-staging.com",
            },    
            challenge: Uint8Array.from(
              challenge,
              c => c.toString().charCodeAt(0)
            ),
            user: {
              id: Uint8Array.from(
                  this.loginId,
                  c => c.charCodeAt(0)
              ),
              name: this.loginId,
              displayName: this.userName,
            },
            pubKeyCredParams: [{alg: -7, type: "public-key"}],
            authenticatorSelection: {
              authenticatorAttachment: platform,
            },
            timeout: 30000,
            attestation: "direct"
        }
      });
      return credential;
    }
    catch (e) {
      Vue.$log.error(e);
    }
  }
}
