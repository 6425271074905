



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HourMinuteSelectBox extends Vue {

  @Prop({default: ''})
  sendTime: string;

  @Prop({default: ''})
  prefix: string;

  @Prop({default: 3})
  col: number;

  sendHour = '';
  sendMinute = '';

  created() {
    Vue.$log.debug('this.sendTime');
    Vue.$log.debug(this.sendTime);
    if(this.sendTime && this.sendTime.indexOf(':') >= 0) {
      const splitted = this.sendTime.split(':');
      this.sendHour = splitted[0];
      this.sendMinute = splitted[1];
    }
  }

  change() {
    let st = '';
    if(this.sendHour && this.sendMinute) {
      st = this.sendHour + ':' + this.sendMinute;
    }
    this.$emit('input', st);
  }
}
