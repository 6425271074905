



































































































































































































































import moment from "moment"
import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from "vue-mixin-decorator"
import { OverlayLoader } from '@/components/parts'
import { Messages, Code } from '@/const';
import { ActionListObject, ActionAnswerResponseData, ActionshowHiddenCommentUsersData, NoAnswerUser, AlertInfo, ApproveAlertInfo } from '@/types';

import ServiceCallHelper from '@/mixins/service_call_helper'

import { ActionAnswerService } from '@/services'
import { UserSearchOptionsHelper } from '@/mixins'

import ActionAnswerFormSubmit from "@/components/action_list/ActionAnswerFormSubmit.vue"
import UserSearchOptionsModal from '@/components/parts/UserSearchOptionsModal.vue'

/** 役職、職種、組織のセレクトボックス値設定 */
interface MixinInterface extends ServiceCallHelper, UserSearchOptionsHelper {}

@Component({
  components: {
    ActionAnswerFormSubmit,
    OverlayLoader,
    UserSearchOptionsModal,
  },
})
export default class ActionListApproveForm extends Mixins<MixinInterface>(ServiceCallHelper, UserSearchOptionsHelper) {

  // form fields
  fields: ActionListObject = {};

  // response data
  data: ActionAnswerResponseData;

  // 日付検索フィールド
  date: string = moment(new Date()).format('YYYY-MM-DD');

  // アクション数
  actionCount = 0;

  // アクションリスト状態フラグ
  statusCode = 4;

  /** 編集可能か */
  isEditable = true;

  constMessages = Messages

  showHiddenCommentUsers = '';

  expireDiff: number | null = null;

  /** テキストエリアのデフォルトの高さ */
  defaultTextareaHeight = 180;

  optionList: any[] = [];

  /** テキストエリアの高さ */
  textareaHeightUserComment = this.defaultTextareaHeight;
  textareaHeightHiddenComment = this.defaultTextareaHeight

    /** アクション状況 */
  noAnswerDates: Array<NoAnswerUser> = [];

  mounted() {
    this.setActions();
    // アクション状況取得
    new ActionAnswerService()
      .getMyNoAnswer()
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          const data = response.data;
          if (data.code == Code.SUCCESS) {
            this.noAnswerLoaded(data.result.noAnswerDates);
          }
        }
      });
    if(this.$route.query.date != null) {
      this.date = this.$route.query.date as string;
    }
  }

  /** 入力時、テキストエリアの高さを動的に変更 */
  inputTextareaUserComment(e: Event) {
    this.textareaHeightUserComment =(e.target as HTMLInputElement).scrollHeight
  }

  inputTextareaHiddenComment(e: Event) {
    this.textareaHeightHiddenComment =(e.target as HTMLInputElement).scrollHeight
  }

  /**
   * アクションの設定処理
   */
  setActions() {
    this.showLoading();
    new ActionAnswerService().getActions(this.date).then((response) => { if(this.commonApiErrorHandler(response, false)) {
      const data = response.data;
      // アクションリスト設定チェック
      if(data.code == Code.ACTION_LIST_NOT_FOUND) {
        this.statusCode = 1;
      }
      // アクションリスト承認チェック
      else if(data.code == Code.ACTION_LIST_NOT_APPROVED) {
        this.statusCode = 2;
      }
      // VALIDATION_ERRORはエラー表示
      else if(data.code == Code.VALIDATION_ERROR) {
        let msg = ((data.errors as any).date as string);
        if(!msg){
          msg = Messages.INPUT_VALIDATION_ERROR; 
        }
        this.$swal(msg, '', 'warning');
        return false;
      }
      // SUCCESSの場合は値を設定
      else if(data.code == Code.SUCCESS) {
        Vue.$log.debug(data.result.action);
        // field情報の設定とaction数の取得
        const fields: ActionListObject = {};
        const result = data.result;
        let actionCount = 0;
        for(const ability of result.action) {
          if(ability.actions) {
            actionCount += ability.actions.length
            for(const action of ability.actions) {
              if(result && result.userAnswer[action.id]) {
                fields[`act_${action.id}`] = result.userAnswer[action.id];
              }
              else {
                fields[`act_${action.id}`] = '';
              }
            }
          }
        }
        fields['userComment'] = result.userAnswer.userComment;
        if(!fields['userComment'] && this.$store.getters.userInfo.memoInitialText) {
          fields['userComment'] = this.$store.getters.userInfo.memoInitialText;
        }
        fields['hiddenComment'] = result.userAnswer.hiddenComment;
        // ナイショコメントを閲覧できるユーザの取得
        this.optionList = result.showHiddenCommentUsers.map((user) => {
          return `${user.departmentName} ${user.userName}`;
        });
        this.selectedList['superUsers'] = this.optionList;
        this.showHiddenCommentUsers = this.hiddenCommentUsersText(result.showHiddenCommentUsers);
        this.data = data.result;
        this.fields = fields;
        Vue.$log.debug('fields');
        Vue.$log.debug(fields);
        this.actionCount = actionCount;
        this.pageLoaded = true;
        const dateDiff = moment().diff(moment(this.date), 'days');
        if(dateDiff > 7 && data.result.userAnswer && Object.keys(data.result.userAnswer).length > 0) {
          this.isEditable = false;
        }
        else {
          this.isEditable = true;
        }
        const expireDateDiff = data.result.expireDateDiff;
        if(typeof(expireDateDiff) == "number" && expireDateDiff <= 0) {
          this.statusCode = 3;
        }
        else {
          this.statusCode = 4;
        }
      }

      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'warning');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  // ナイショ話表示切替
  changeShowHiddenComment(e: Event) {
    this.$store.commit('setIsShowHiddenComment', (e.target as HTMLInputElement).value);
  }

   // アクション状況取得
  noAnswerLoaded(noAnswerData: NoAnswerUser[]) {
    const noAnswerDates: Array<NoAnswerUser> = [];
    const deleteDate = moment().subtract(7, "days");
    Vue.$log.debug(deleteDate);
    const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
    noAnswerData.forEach(elem => {
      if(! closedAlert.find(alert => {
        return elem.userId == alert.userId && alert.alertType == 'noAnswer' && elem.answerDate == (alert as AlertInfo).answerDate && moment(elem.answerDate) > deleteDate
      })) {
        noAnswerDates.push(elem);
      }
    });
    this.noAnswerDates = noAnswerDates;
  }

  closeAlert(answerDate: string, userId: number, alertType: string) {
    // storeされている無視リストの更新
    const deleteDate = moment().subtract(30, "days");
    Vue.$log.debug(deleteDate);
    const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
    const newClosedAlert: Array<AlertInfo | ApproveAlertInfo> = [];
    const thisAlert = {answerDate: answerDate, userId: userId, alertType: alertType};
    closedAlert.forEach(alert => {
      if(moment((alert as AlertInfo).answerDate) > deleteDate) {
        newClosedAlert.push((alert as AlertInfo));
      }
      newClosedAlert.push((alert as ApproveAlertInfo));
    });
    newClosedAlert.push(thisAlert);
    this.$store.commit('setClosedAlert', [...new Set(newClosedAlert)]);
    Vue.$log.debug('this.$store.getters.closedAlert');
    Vue.$log.debug(this.$store.getters.closedAlert);

    // 無回答アラート管理
    const noAnswerDates: Array<NoAnswerUser> = [];
    this.noAnswerDates.forEach(elem => {
      if(answerDate != elem.answerDate || userId != elem.userId) {
        noAnswerDates.push(elem);
      }
    })
    this.noAnswerDates = noAnswerDates;
  }

  /**
   * 対象日変更
   */
  changeDate() {
    // 日付未設定の場合は処理スキップ
    if(!this.date) {
      return;
    }
    // 未来日チェック
    if(moment(this.date).isAfter()) {
      this.$swal('未来日は指定できません。', '', 'warning');
      this.date = moment(new Date()).format('YYYY-MM-DD');
    }

    this.setActions();
  }

  showSendTargetOption() {
    this.$modal.show('user-search-options-modal');
  }

  /**
   * SuperUserOptionsの変更
   */
  changeOptions(data: string) {
    if (this.selectedList["superUsers"].includes(data)) {
      this.selectedList["superUsers"] = this.selectedList["superUsers"].filter(v => v !== data);
    } else {
      this.selectedList["superUsers"].push(data);
    }
    this.showHiddenCommentUsers = this.hiddenCommentUsersText(this.data.showHiddenCommentUsers);
  }

  /**
   * モーダル終了
   */
  closeUserSearchOptionsModal() {
    this.$modal.hide('user-search-options-modal');
  }

  /**
   * ナイショ話の対象ユーザー取得
   */
  hiddenCommentUsers() {
    return this.data.showHiddenCommentUsers.filter((user) => {
      return this.selectedList['superUsers'].includes(`${user.departmentName} ${user.userName}`);
    });
  }

  /**
   * ナイショ話のユーザ表示テキスト
   */
  hiddenCommentUsersText(showHiddenCommentUsers: Array<ActionshowHiddenCommentUsersData> | undefined | null) {
    if(!showHiddenCommentUsers || showHiddenCommentUsers.length == 0) {
      return '';
    }
    return showHiddenCommentUsers.reduce((accumulator, currentValue, currentIndex) => {
      let userInfo = `${currentValue.departmentName} ${currentValue.userName}`
      if(this.selectedList['superUsers'].includes(userInfo)) {
        if (currentIndex === 0) {
          userInfo = `記入内容を見れる人<br>${userInfo}`
        }
        accumulator += userInfo + '<br>';
      }
      return accumulator
    }, '');
  }

}

