import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, SkillBoxResponse, CompanyEditData } from '@/types'

import RequestMixin from '@/mixins/request_mixin'

/** 会社ID検索用type指定 */
export interface CompanyIdSearchData {
  company: CompanyEditData;
  businessDays: string[];
}
export class CompanyIdSearchResponseData extends SkillBoxResponse<CompanyIdSearchData>{}
export class CompanyIdSearchResponse extends AxiosResponse<CompanyIdSearchResponseData>{}

export default class CompanyService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * ID検索
   */
  public getCompany(): Promise<CompanyIdSearchResponse> {
    return this.getReq('/api/v1/company', {}) as Promise<CompanyIdSearchResponse>;
  }

  /*
   * 更新
   *
   * @param param
   */
  public putCompany(param: CompanyEditData, days: string[]): Promise<AxiosResponse> { 
    return this.putReq('/api/v1/company', {'company': param, 'businessDays': days}) as Promise<AxiosResponse>;
  }

  /**
   * Line Works登録
   */
  public uploadLineWorks(formData: FormData): Promise<AxiosResponse> {
    return this.uploadReq('/api/v1/company_line_works', formData);
  }

}