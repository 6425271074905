




























































import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class ChecktestQuestionMessage extends Vue {
  @Prop()
  pageNumber: number;

  sectionClass() {
    if(this.pageNumber == 1) {
      return 'check-test__answering-hint';
    }
    if([5, 9, 11].includes(this.pageNumber)) {
      return 'check-test__comment mt-4';
    }
    return '';
  }
}
