










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
import DepartmentTable from '@/components/settings/department/DepartmentTable.vue';

@Component({
  components: {
    Header,
    DepartmentTable,
    Footer,
  },
})
export default class DepartmentList extends Vue {}
