import { NotifyService } from '@/services/notify/notify_service'
import { AxiosResponse } from '@/types';
import { SearchParams } from '@/types/good_table_types'

export default class ActionSettingsNotifyService extends NotifyService {

  /**
   * アクション回答依頼（全ユーザー）
   *
   * @aparam params
   */
  public notifyAll(param: SearchParams): Promise<AxiosResponse> {
    return this.getReq('/api/v1/action_answer_notify_all', param) as Promise<AxiosResponse>;
  }

  /**
   * アクション回答依頼（指定ユーザー）
   *
   * @aparam params
   */
  public notifyTarget(userIds: number[]): Promise<AxiosResponse> {
    return this.getReq('/api/v1/action_answer_notify', {'userIds': userIds}) as Promise<AxiosResponse>;
  }

  /**
   * アクション回答依頼（指定ユーザー）
   *
   * @aparam params
   */
  public notifyTargetWithAnswerDate(userIds: number[], answerDate: string): Promise<AxiosResponse> {
    const param = {"userIds": userIds, "answerDate": answerDate};
    return this.getReq('/api/v1/action_answer_notify', param) as Promise<AxiosResponse>;
  }

}

