import moment from "moment"
import { EncourageComments } from '@/const/encourage';
import { ActionAnswerStatsData, ActionEncourageData } from '@/types';
import { String } from 'typescript-string-operations';

export default {

  getComment(stats: Array<ActionAnswerStatsData>, encourage: ActionEncourageData, showNoCheckMessage: boolean) {
    const targetComments: Array<number> = []
    // 1〜3. 平均達成率
    if(encourage.avgAchieveRate < 0.395)
      targetComments.push(1)
    else if(encourage.avgAchieveRate < 0.795)
      targetComments.push(2)
    else
      targetComments.push(3)
    // 4. 最低達成率
    let lowest = 1.;
    let highest = 0.;
    stats.forEach(stat => {
      if(stat.achieveRate < lowest)
        lowest = stat.achieveRate;
      if(stat.achieveRate > highest)
        highest = stat.achieveRate;
    })
    if(lowest < 0.395)
        targetComments.push(4)
    const lowestActions = stats.filter(stat => stat.achieveRate == lowest);
    const lowestAction = lowestActions[Math.floor(Math.random() * lowestActions.length)]
    // 5. 最高達成率
    if(highest == 1.0)
        targetComments.push(5)
    const highestActions = stats.filter(stat => stat.achieveRate == highest);
    const highestAction = highestActions[Math.floor(Math.random() * highestActions.length)]
    // 6〜7. メモ率
    if(encourage.memoRate >= 0.7)
      targetComments.push(6);
    else if(encourage.memoRate <= 0.2)
      targetComments.push(7);
    // 8. メモ文字数 
    if(encourage.memoLength >= 100)
      targetComments.push(8);
    // 9. 管理者コメント率
    if(encourage.adminCommentRate >= 0.6)
      targetComments.push(9);
    // 10. 管理者コメント文字数
    if(encourage.adminCommentLength >= 100)
      targetComments.push(10);
    // 11. 未記録日数
    if(showNoCheckMessage && encourage.noCheckDays >= 3)
      targetComments.push(11);
    // 12〜14. リスト設定日数 
    const dateDiff = moment().diff(moment(encourage.approvalDate), 'days');
    if(dateDiff >= 30 && dateDiff < 60)
        targetComments.push(12)
    else if(dateDiff >= 60 && dateDiff < 90)
        targetComments.push(13)
    else if(dateDiff >= 90)
        targetComments.push(14)
    // 15〜16. 期間内の記録数
    if(encourage.answerCount >= 1)
        targetComments.push(15)
    if(encourage.answerCount >= 2)
        targetComments.push(16)

    // 0件時は空データを返す
    if(targetComments.length == 0) {
      return {'comment': [] as Array<string>, 'image': '', 'name': ''};
    }

    // パーセンテージに変換
    const encourageP = Object.assign({}, encourage) as any;
    encourageP.avgAchieveRateP = (encourage.avgAchieveRate * 100).toFixed(1);
    encourageP.memoRateP = (encourage.memoRate * 100).toFixed(1);
    encourageP.commentRateP = (encourage.adminCommentRate * 100).toFixed(1);

    // 最大/最小アクションの番号設定
    encourageP.lowestAct = lowestAction.indexChar;
    encourageP.highestAct = highestAction.indexChar;

    // アクション設定経過日数設定
    encourageP.listDays = dateDiff;

    // ランダムにコメントを取得
    const comments = EncourageComments.COMMENTS as any;
    const weightSum = targetComments.map(i => comments[i].weight).reduce((x: number, y: number) => x + y);
    const rnd = Math.random() * weightSum;

    let value = 0.0;
    function _getRandomComment() {
      let resultComment: any;
      for(const target of targetComments) {
        resultComment = comments[target];
        value += resultComment.weight;
        if(value > rnd)  {
          return resultComment;
        }
      }
      return resultComment;
    }

    // string format
    const selectedComments = _getRandomComment().comments;
    const selectedComment = selectedComments[Math.floor(Math.random() * selectedComments.length)];
    const comment = selectedComment.comment.map((c: string) => String.Format(c, encourageP));

    return {
      'comment': comment,
      'image': selectedComment.image,
      'name': selectedComment.name,
    };
  }

}

