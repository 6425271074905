import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types'
import { OccupationData } from '@/types/settings/position_occupation_types'

import RequestMixin from '@/mixins/request_mixin'

/** 役職・職種検索用type指定 */
export interface PositionOccupationSearchSkillBoxData {
  positions: Array<string>;
  occupations: Array<OccupationData>;
}
export class PositionOccupationSearchResponseData extends SkillBoxResponse<PositionOccupationSearchSkillBoxData>{}
export class PositionOccupationSearchResponse extends AxiosResponse<PositionOccupationSearchResponseData>{}

export default class PositionService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * 役職・職種検索
   */
  public getPositionOccupations(): Promise<PositionOccupationSearchResponse> {
    return this.getReq('/api/v1/position_occupation', {}) as Promise<PositionOccupationSearchResponse>;
  }

  /**
   * 役職CSVダウンロード
   *
   * @param params
   */
  public downloadCsv() {
    this.downloadReq('/api/v1/position_csv', 'position.csv', {});
  }

  /**
   * 役職CSVアップロード
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public uploadFile(formData: FormData): Promise<AxiosResponse> {
    return this.uploadReq('/api/v1/position_csv', formData);
  }

}
