import { Mixins } from "vue-mixin-decorator";
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types';
import { DashboardReport, UserSearchData } from '@/types';

import RequestMixin from '@/mixins/request_mixin'; 

/** 能力別チャートデータAPI（自身のみ、アクションごと）(Action別) */
export interface DailyAbilityAchieveRatesPerActionResponseData {
  labels: Array<string>;
  data: Array<number>;
}
export class DailyAbilityAchieveRatesActionSkillBoxResponse extends SkillBoxResponse<DailyAbilityAchieveRatesPerActionResponseData>{}
export class DailyAbilityAchieveRatesPerActionResponse extends AxiosResponse<DailyAbilityAchieveRatesActionSkillBoxResponse>{}

/** アクションリスト表示一覧 */
export class DashboardReportSkillboxResponse extends SkillBoxResponse<DashboardReport>{}
export class DashboardReportResponse extends AxiosResponse<DashboardReportSkillboxResponse>{}

/**
 * アクションリストservice
 */
export default class DashboardService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * ダッシュボードの情報取得
   */
  public getReport(params: UserSearchData): Promise<DashboardReportResponse> {
    return this.getReq(`/api/v1/dashboard`, {'filters': params}) as Promise<DashboardReportResponse>;
  }

  /**
   * 能力別チャートデータAPI（自身のみ、アクションごと）
   */
  public getAbilityAchieveRate(params: UserSearchData): Promise<DailyAbilityAchieveRatesPerActionResponse> {
    return this.getReq(`/api/v1/action_ability_achieve_rate`, {'filters': params}) as Promise<AxiosResponse>;
  }
}

