










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
import CompanyLineWorksForm from '@/components/settings/company_line_works/CompanyLineWorksForm.vue';

@Component({
  components: {
    Header,
    CompanyLineWorksForm,
    Footer,
  },
})
export default class CompanyLineWorks extends Vue {}
