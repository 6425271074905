/**
 * 会社更新データ
 */
export class CompanyEditData {
  checktestEndDate = '';
  alSettingEndDate = '';
  ctRedirect = '';
  ctActionNotify = '';
  showCompanyAvg = '';
  showAdminComment = '';
  showAddAction = '';
  memoTitle = '';
  memoDescription = '';
  memoExample = '';
}


export class CompanyActionRecommendData {
  id = -1;
  recommendName = "";
  departmentId = "";
  departmentName = "";
  position = "";
  occupation = "";
  childOccupation = "";
  hireYearBelow = "";
}

export class CompanyActionRecommendFlg {
  department: boolean | string | number = false;
  position: boolean | string | number = false;
  occupation: boolean | string | number = false;
  childOccupation: boolean | string | number = false;
  hireYear: boolean | string | number = false;
}

export class CompanyLineWorksAuthData {
  clientId = "";
  clientSecret = "";
  serviceAccount = "";
  botId = "";
}