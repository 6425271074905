import HelpMenuPage from '@/views/help/HelpMenuPage.vue'
import HelpPage from '@/views/help/HelpPage.vue'
import ContactUs from '@/views/help/ContactUs.vue'

export default [
  {
    path: '/help',
    name: 'HelpMenuPage',
    component: HelpMenuPage,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/help/:pageId',
    name: 'HelpPage',
    component: HelpPage,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      requireLogin: true,
    },
  },
]
