






















































































































































import moment from 'moment'
import router from '@/router'

import { Vue, Mixins } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { OverlayLoader, DateSearchModal } from '@/components/parts'
import { AchieveLineChart, AchieveHorizontalBarChart } from '@/components/chart'
import ActionReportDetails from '@/components/action_report/ActionReportDetails.vue'
import ActionAnswerStats from '@/components/action_report/ActionAnswerStats.vue'

import Encourage from '@/utils/encourage'
import Filters from '@/utils/filters'

import { Code, Messages } from '@/const'

import { ActionAnswerDetailResponseRowData, DateSearchData, UserSearchData, ActionAnswerStatsData, ActionEncourageData, NoAnswerUser, AlertInfo, ApproveAlertInfo, CommentData, ActionNoAnswer } from '@/types'
import ServiceCallHelper from '@/mixins/service_call_helper'

import { ACTION_REPORT_CHART_COLOR, ACHIEVE_REPORT_CHART_COLOR } from "@/const/action_report_chart"

import GoodTableHeader from '@/mixins/good_table_mixin'
import ActionReportServiceHelper from '@/mixins/action_report_service_helper'
import { default as ActionReportService, DailyAchieveRatesPerActionResponseData, DailyAbilityRatesAbilitySeries, DailyAbilityRatesResponse } from "@/services/action_report_service";
import { default as ActionAnswerService, ActionAnswerDetailResponse } from '@/services/action_answer_service'
import { SearchParams } from '@/types/good_table_types';

interface MixinInterface extends ActionReportServiceHelper, GoodTableHeader<ActionAnswerDetailResponseRowData> {}

@Component({
  components: {
    OverlayLoader,
    AchieveLineChart,
    AchieveHorizontalBarChart,
    ActionReportDetails,
    ActionAnswerStats,
    DateSearchModal,
  },
  filters: Filters
})

export default class MyActionReportContent extends Mixins<MixinInterface>(ServiceCallHelper, GoodTableHeader) {

  /** 検索条件テキスト */
  filterText = '';

  /** アクションリスト状態フラグ */
  statusCode = 3;

  /** 励ましコメント */
  encourage = {
    'comment': [] as Array<string>,
    'image': '',
    'name': '',
  };

  /** 達成率ラインチャート平均 */
  achieveLineChartAvg = 0;

  chartLabelData: {actionLabel: string; actionText: string}[] = [];

  /** カテゴリごとラインチャート平均 */
  abilityLineChartAvg = 0;

  abilityChartLabelData: {actionLabel: string; actionText: string}[] = [];

  /** カテゴリのチャートデータ */
  abilityHorizontalChartData = {}

  /** カテゴリのチャート高さ */
  abilityHorizontalChartHeight = 0;

  /** アクションの達成率チャートの表示・非表示切り替え */
  isShowActionLineChart = true;

  /** アクション設定日 */
  latestChangeDate = '';

  /** 回答率 */
  answerRate = 0;

  /** リアクション獲得率 */
  reactionRate = 0;

  /** 有効回答率 */
  achieveRate = 0;

    /** アクションリストの直近の更新日 */
  latestListChangeDate = '';

  /** アクションリスト変更通知メッセージ表示日 */
  listChangeAnnotationDate = '';

  /** コメント一覧表示用データ */
  listComments: Array<CommentData> = [];

  // /** ユーザーID */
  userId = "";

  /** ユーザー名 */
  userName = "";

  /** action id */
  aid: string | null = null;

  /** 入力フィールド初期値 */
  getDefaultFields() {
    return new DateSearchData(
      moment().subtract(1, 'months').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'));
  }

  /** 入力フィールド */
  fields = this.getDefaultFields();

  /** 初期値（変更時は励ましコメント非表示） */
  defaultFields = this.getDefaultFields();

  /** アクション状況 */
  noAnswerDates: Array<NoAnswerUser> = [];

  mounted() {
    this.updateFilter();
    // カテゴリ関連のデータを取得・グラフのレンダリング
    this.abilityLineChartMounted();
    // アクション状況取得
    new ActionAnswerService()
    .getMyNoAnswer()
    .then((response) => {
      if (this.commonApiErrorHandler(response)) {
        const data = response.data;
        if (data.code == Code.SUCCESS) {
          this.noAnswerLoaded(data.result.noAnswerDates);
        }
      }
    });
  }

  created() {
    // aid設定
    this.aid = (this.$route.query.aid as string);
  }

  getActionReport(serverParams: SearchParams, isCommentList = false, targetDate = "", targetUserId = 0) {
    this.isLoading = true;
    this.serverParams = serverParams;
    const actionReportDetails = this.$refs.actionReportDetails as ActionReportDetails;
    Vue.$log.debug(this.serverParams);

    // service戻り値処理
    const thenFunc = (response: ActionAnswerDetailResponse) => {
      const data = response.data;
      if(this.commonApiErrorHandler(response)) {
        // SUCCESSの場合は値を設定
        if(data.code == Code.SUCCESS) {
          Vue.$log.debug(response.data);
          actionReportDetails.totalRows = response.data.result.totalRows;
          this.userId = response.data.result.userId;
          this.userName = response.data.result.userName;
          this.latestListChangeDate = response.data.result.latestListChangeDate;
          // コメント一覧からの投稿の場合はコメントを更新
          if (isCommentList) {
            const currentAction = response.data.result.records.filter(
              (v) =>
                v.answerDate === targetDate && v.userId === Number(targetUserId)
            );
            if (currentAction) {
              actionReportDetails.listComments = currentAction[0].comments;
            }
          }
          if(actionReportDetails.totalRows > 0) {
            actionReportDetails.rows = response.data.result.records;
            // リスト変更メッセージ表示日を設定
            for(const row of actionReportDetails.rows) {
              if(row.answerDate < this.latestListChangeDate) {
                break;
              }
              this.listChangeAnnotationDate = row.answerDate;
            }
            if(actionReportDetails.rows[actionReportDetails.rows.length - 1].answerDate == this.listChangeAnnotationDate) {
              this.listChangeAnnotationDate = ''
            }
          }
          else {
            actionReportDetails.rows = [];
          }
          actionReportDetails.pageLoaded = true;
        }
        else if(data.code == Code.ACTION_LIST_NOT_FOUND) {
          this.setActionListNotFound();
        }
        else if(data.code == Code.ACTION_LIST_NOT_APPROVED) {
          this.setActionListNotApproved();
        }
      }
    }

    // ログインユーザーのレポート取得
    new ActionAnswerService().searchMyActionDetails(this.serverParams, this.aid)
      .then(thenFunc)
      .finally(()=> {
        this.isLoading = false;
        this.reportDetailLoaded(this.userName);
    });
  }

  // アクション状況取得
  noAnswerLoaded(noAnswerData: NoAnswerUser[]) {
    const noAnswerDates: Array<NoAnswerUser> = [];
    const deleteDate = moment().subtract(7, "days");
    Vue.$log.debug(deleteDate);
    const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
    noAnswerData.forEach(elem => {
      if(! closedAlert.find(alert => {
        return elem.userId == alert.userId && alert.alertType == 'noAnswer' && elem.answerDate == (alert as AlertInfo).answerDate && moment(elem.answerDate) > deleteDate
      })) {
        noAnswerDates.push(elem);
      }
    });
    this.noAnswerDates = noAnswerDates;
  }

  closeAlert(answerDate: string, userId: number, alertType: string) {
    // storeされている無視リストの更新
    const deleteDate = moment().subtract(30, "days");
    Vue.$log.debug(deleteDate);
    const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
    const newClosedAlert: Array<AlertInfo | ApproveAlertInfo> = [];
    const thisAlert = {answerDate: answerDate, userId: userId, alertType: alertType};
    closedAlert.forEach(alert => {
      if(moment((alert as AlertInfo).answerDate) > deleteDate) {
        newClosedAlert.push((alert as AlertInfo));
      }
      newClosedAlert.push((alert as ApproveAlertInfo));
    });
    newClosedAlert.push(thisAlert);
    this.$store.commit('setClosedAlert', [...new Set(newClosedAlert)]);
    Vue.$log.debug('this.$store.getters.closedAlert');
    Vue.$log.debug(this.$store.getters.closedAlert);

    // 無回答アラート管理
    const noAnswerDates: Array<NoAnswerUser> = [];
    this.noAnswerDates.forEach(elem => {
      if(answerDate != elem.answerDate || userId != elem.userId) {
        noAnswerDates.push(elem);
      }
    })
    this.noAnswerDates = noAnswerDates;
  }

  /** 日付検索モーダルを閉じる */
  closeDateSearchModal() {
    Vue.$log.debug('closeDateSearchModal');
    this.updateFilter();
    this.$modal.hide('date-search-modal');
  }

  /** 絞り込み検索テキストの更新 */
  updateFilter() {
    Vue.$log.debug('updateFilter');

    // 日付検索モーダルから値の取得
    if(this.$refs.dateSearchModal) {
      const dateSearchModal = this.$refs.dateSearchModal as DateSearchModal;
      this.filterText = dateSearchModal.searchText();
      // 子コンポーネントで参照されている値の設定
      this.fields = new DateSearchData(
          dateSearchModal.fields.dateFrom,
          dateSearchModal.fields.dateTo)
    }

    // 達成率グラフコンポーネントの更新
    if(this.$refs.achieveLineChart) {
      this.achieveLineChartMounted();
    }

    // カテゴリ達成率グラフコンポーネントの更新
    if(this.$refs.abilityLineChart) {
      this.abilityLineChartMounted();
    }

    // アクションごと集計更新
    if(this.$refs.actionAnswerStats) {
      const isDefault = (this.defaultFields.dateFrom == this.fields.dateFrom && this.defaultFields.dateTo == this.fields.dateTo);
      const actionAnswerStats = this.$refs.actionAnswerStats as ActionAnswerStats;
      actionAnswerStats.loadStats(this.fields.dateFrom, this.fields.dateTo, isDefault);
    }
  }

  /** 達成率チャート描画 */
  achieveLineChartMounted() {
    const achieveLineChart = this.$refs.achieveLineChart as AchieveLineChart;
    const userSearchData = { termFrom: this.fields.dateFrom, termTo: this.fields.dateTo };
    achieveLineChart.search(userSearchData as UserSearchData);
  }

  /** カテゴリ達成率チャート描画 */
  abilityLineChartMounted() {
    const abilityLineChart = this.$refs.abilityLineChart as AchieveLineChart;
    if(abilityLineChart) {
      const userSearchData = { termFrom: this.fields.dateFrom, termTo: this.fields.dateTo };
      const thisSearchParams = Object.assign({}, userSearchData);

      // カテゴリごとのチャートは複数箇所のデータを取得するので、ここでまとめて行う
      const renderChart = (response: DailyAbilityRatesResponse) => {
        abilityLineChart.getMyAbilityAchiveRateAction(response);
        this.abilityHorizontalBarChartMounted(response);
        this.renderTotal(response);
      }
      new ActionReportService().getMyAbilityAchiveRate(
        this.fields.dateFrom, this.fields.dateTo
      ).then(renderChart);
    }
  }

  /**
   * トータルの要素を描画
   */
  renderTotal(response: DailyAbilityRatesResponse) {
    const data = response.data;
    this.latestChangeDate = data.result.latestChangeDate;
    this.answerRate = data.result.total.answerRate;
    this.reactionRate = data.result.total.reactionRate;
    this.achieveRate = data.result.total.achieveRate;
  }

  /**
   * カテゴリごとのチャート描画
   */
  abilityHorizontalBarChartMounted(response: DailyAbilityRatesResponse) {
    if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESSの場合は値を設定
      if(data.code == Code.SUCCESS) {
        const abilityBar = data.result.abilityBar;
        // グラフの高さを動的に生成
        if (abilityBar.labels.length === 1) {
          this.abilityHorizontalChartHeight = 80;
        } else if (abilityBar.labels.length === 2) {
          this.abilityHorizontalChartHeight = 100;
        } else {
          this.abilityHorizontalChartHeight = abilityBar.labels.length * 40;
        }
        this.abilityHorizontalChartData = {
          labels: abilityBar.labels,
          data: abilityBar.data.map((v: number) => Math.floor(v * 100)),
        }

        const abilityHorizontalBarChart = this.$refs.abilityHorizontalBarChart as AchieveHorizontalBarChart;
        abilityHorizontalBarChart.search(abilityBar);
      // その他のケースは接続エラーに
      } else {
        this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      }
    }
  }

  /**
   * 達成率ラインチャートの描画終了時、平均再描画実行
   */
  achieveLineChartUpdated(data: DailyAchieveRatesPerActionResponseData) {
    Vue.$log.debug('achieveLineChartUpdated');
    Vue.$log.debug(data);
    const info = {
      date: data.date,
      chartData: data.chartData,
    }
    this.achieveLineChartAvg = data.chartDataAvg;
    this.chartLabelData = info.chartData.map(data => ({
      actionLabel: data.actionLabel,
      actionText: data.actionText
    }))
  }

  /**
   * カテゴリごとの達成率ラインチャートの描画終了時、平均再描画実行
   */
  abilityLineChartUpdated(data: DailyAbilityRatesAbilitySeries) {
    Vue.$log.debug('abilityLineChartUpdated');
    Vue.$log.debug(data);
    const info = {
      date: data.date,
      chartData: data.chartData,
    }
    this.abilityLineChartAvg = data.chartDataAvg;
    this.abilityChartLabelData = info.chartData.map((data) => ({
      actionLabel: data.abilityName,
      actionText: data.abilityName
    }))
  }

  /** 条件を絞り込み検索ボタン */
  clickFilterButton() {
    this.$modal.show('date-search-modal');
  }

  /** アクションリスト設定へ遷移 */
  moveActionListSettings() {
    router.push('/action_list/settings')
  }

  /** アクション回答へ遷移 */
  moveActionAnswer() {
    router.push('/action_answer')
  }

  /** ActionReportDetailからユーザー名の通知 */
  reportDetailLoaded(userName: string) {
    Vue.$log.debug('reportDetailLoaded');
    this.userName = userName;
    this.statusCode = 3;
  }

  /** アクションリスト未設定フラグ設定 */
  setActionListNotFound() {
    Vue.$log.debug('setActionListNotFound');
    this.statusCode = 1;
  }

  /** 未承認フラグ設定 */
  setActionListNotApproved() {
    Vue.$log.debug('setActionListNotApproved');
    this.statusCode = 2;
  }

  /** statsデータ取得成功 */
  statsSuccess(stats: Array<ActionAnswerStatsData>, encourage: ActionEncourageData) {
    if(Object.keys(encourage).length > 0) {
      const noCheckMessage = this.$cookies.get('noCheckMessage');
      this.encourage = Encourage.getComment(stats, encourage, noCheckMessage != '1');
      if(this.encourage.name == 'noCheckMessage') {
        this.$cookies.set('noCheckMessage', '1', {'expires': '30d'});
      }
    }
    else {
      this.encourage.comment = [];
    }
  }

  /**
   * グラフの表示・非表示
   */
  toggleChartLine(value: number, actionLabel: string) {
    const achieveLineChart = this.$refs.achieveLineChart as AchieveLineChart;
    achieveLineChart.hideChartLine(value, actionLabel);
  }

  /**
   * 能力別グラフの表示・非表示
   */
  toggleAbilityChartLine(value: number, actionLabel: string) {
    const achieveLineChart = this.$refs.abilityLineChart as AchieveLineChart;
    achieveLineChart.hideChartLine(value, actionLabel);
  }

  /**
   * 凡例の色取得
   */
  getChartLegendColor(actionLabel: string) {
    return ACTION_REPORT_CHART_COLOR.find(color => color.actionLabel === actionLabel)?.color || "#999999"
  }

  /**
   * 凡例の色取得（カテゴリごと）
   */
  getAbilityChartLegendColor(actionLabel: string, index: number) {
    if (actionLabel === '平均') {
      return ACTION_REPORT_CHART_COLOR[0].color;
    } else {
      return ACHIEVE_REPORT_CHART_COLOR[index];
    }
  }
}

