import Vue from 'vue';
import { Mixin } from "vue-mixin-decorator"

import { DepartmentService } from '@/services'
import { DepartmentSelectBoxData } from '@/types';

/**
 * 職種役職選択
 */
@Mixin
export class DepartmentSelect extends Vue {

  /** 組織セレクトボックス */
  departments: Array<DepartmentSelectBoxData> = [];

  /** 選択中セレクトボックス */
  selectedDepartmentText = '';

  /** root組織を表示するか */
  showRootDepartment = true;

  mounted() {
    this.updateDepartments();
  }

  updateDepartments() {
    Vue.$log.debug('update departments')
    Vue.$log.debug(this.showRootDepartment)
    new DepartmentService().getDepartmentIdNames(this.showRootDepartment).then((response) => {
      const data = response.data;
      if(data && data.result) {
        this.departments = data.result.departments;
      }
    });
    Vue.$log.debug(this.departments)
  }

  changeDepartment(event: Event) {
    Vue.$log.debug('department changed');
    const options = (event.target as HTMLSelectElement).options;
    this.selectedDepartmentText = options[options.selectedIndex].text;
    Vue.$log.debug(this.selectedDepartmentText);
  }

}

