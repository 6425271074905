import { isDate, zeroOrOne, oneOrTwo } from '@/validators/utils'
import { required } from 'vuelidate/lib/validators'


/**
 * チェックテスト初期入力Validator
 */
export class ChecktestInputValidator {
  birthday = { required, isDate };
  gender = { required, oneOrTwo };
  newRecruit = { required, zeroOrOne };
  hireYear = { required };
  hireMonth = { required };
  occupation = { required };
  childOccupation = { required };
  position = { required };
}

