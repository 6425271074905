
























































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class ActionListSettingsComplete extends Vue {
  pageMode = 0;

  created() {
    Vue.$log.debug(this.$store.getters.alRequireApproval);
    if(this.$store.getters.alRequireApproval == '0') {
      this.pageMode = 1;
    }
  }
}
