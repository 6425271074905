import Login from '@/views/auth/Login.vue'
import Logout from '@/views/auth/Logout.vue'
import PwdResetSend from '@/views/auth/PwdResetSend.vue'
import PwdResetSendComplete from '@/views/auth/PwdResetSendComplete.vue'
import PwdReset from '@/views/auth/PwdReset.vue'
import PwdResetComplete from '@/views/auth/PwdResetComplete.vue'

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: '/pwd_reset_send',
    name: 'PwdResetSend',
    component: PwdResetSend,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: '/pwd_reset_send/complete',
    name: 'PwdResetSendComplete',
    component: PwdResetSendComplete,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: '/pwd_reset',
    name: 'PwdReset',
    component: PwdReset,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: '/pwd_reset/complete',
    name: 'PwdResetComplete',
    component: PwdResetComplete,
    meta: {
      requireLogin: false,
    },
  },
]
