import Chart from "chart.js";

export { default as AchieveHorizontalBarChart } from '@/components/chart/AchieveHorizontalBarChart.vue'
export { default as AchieveLineChart } from '@/components/chart/AchieveLineChart.vue'
export { default as AchievePieChart } from '@/components/chart/AchievePieChart.vue'
export { default as AchieveStackBar } from '@/components/chart/AchieveStackBar.vue'
export { default as AchieveScatterChart } from '@/components/chart/AchieveScatterChart.vue'
export { default as ChecktestRadar } from '@/components/chart/ChecktestRadar.vue'

export class SkboxPlugins {

  /**
   * dougnnut chartの中央にパーセンテージを配置する
   */
  drawDoughnutPercenteageText = {
    afterDraw: (chart: Chart) => { // 円グラフの内側のラベル表示に関する設定
      if(chart.width && chart.height && chart.ctx && chart.canvas && chart.data.datasets) {
        const cx = chart.width / 2 - 5;
        const cy = chart.height / 2 + 17;
        for(const dataset of chart.data.datasets) {
          if(dataset.data) {
            const value = (dataset.data[0] || 0) as number;
            const text = `${Math.round(value)}`;
            chart.ctx.font = `${Math.floor(chart.width / 8)}px Helvetica Neue bold`;
            chart.ctx.textAlign = 'center';
            chart.ctx.textBaseline = 'middle';
            chart.ctx.fillText(text, cx, cy);

            const mesure = chart.ctx.measureText(`${Math.floor(value)}`);
            const cxPercentage = Math.round((cx + (mesure.width / 2)) + 7);
            const cyPercentage = Math.round((cy + ((mesure.actualBoundingBoxAscent + mesure.actualBoundingBoxDescent))) - 12);
            chart.ctx.font = `${Math.floor(chart.width / 15)}px Helvetica Neue bold`;
            chart.ctx.textAlign = 'center';
            chart.ctx.textBaseline = 'middle';
            chart.ctx.fillText("%", cxPercentage, cyPercentage);
          }
        }
      }
    }
  }

}
