











































































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import 'vue-good-table/dist/vue-good-table.css'
import { ChecktestReportService, TestReportService, TestNotifyService, TestReportNotifyService } from '@/services'
import { SortOption, ColumnOption  } from '@/types/good_table_types'
import { ChecktestReportRowData, UserSearchData } from '@/types'
import { Messages, Code } from '@/const'
import Filters from '@/utils/filters'

import GoodTableHeader from '@/mixins/good_table_mixin'
import ServiceCallHelper from '@/mixins/service_call_helper'
import UserSearchOptionsHelper from '@/mixins/user_search_options_helper'

import { OverlayLoader, UserSearchModal } from '@/components/parts'

interface MixinInterface extends ServiceCallHelper, GoodTableHeader<ChecktestReportRowData>, UserSearchOptionsHelper {}

@Component({
  components: {
    OverlayLoader,
    UserSearchModal,
    'vue-good-table': require('vue-good-table').VueGoodTable,
  },
  filters: Filters
})
export default class TestReportTable extends Mixins<MixinInterface>(ServiceCallHelper, GoodTableHeader, UserSearchOptionsHelper) {

  columns = [
    new ColumnOption('user_id', 'userId', {hidden: true}),
    new ColumnOption('名前', 'userName'),
    new ColumnOption('受験日', 'checktestDate'),
    new ColumnOption('タイプ', 'userType'),
    new ColumnOption('総合点', 'totalScore'),
    new ColumnOption('前に踏み出す力', 'stepForwardScore'),
    new ColumnOption('考え抜く力', 'thinkingScore'),
    new ColumnOption('チームで働く力', 'teamworkScore'),
    new ColumnOption('', 'showResult', {sortable: false, tdClass: 'text-center'}),
  ]

  pageName = 'userTablePage';

  rows: ChecktestReportRowData[] = [];

  sortOptions = new SortOption(true, []);

  /** 検索条件テキスト */
  filterTexts: string[] = [];

  /**
   * レポート一覧の取得
   */
  loadItems() {
    Vue.$log.debug('loadItems');
    new ChecktestReportService().getReportList(this.serverParams).then((response) => { if(this.commonApiErrorHandler(response)) {
      Vue.$log.debug(response.data);
      const data = response.data;
      // SUCCESS時はフォームに値を設定
      if(data.code == Code.SUCCESS) {
        this.totalRows = data.result.recordCount;
        this.rows = data.result.report;
        Vue.$log.debug(this.totalRows);
        Vue.$log.debug(this.rows);
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(()=> {
      this.isLoading = false;
      this.updateFilterOptionsText();
    });
  }

  /** 条件を絞り込み検索ボタン */
  clickFilterButton() {
    this.$modal.show('user-search-modal');
  }

  /** 検索ボタンでuserSearchModalをclose */
  userSearch(searchParams: UserSearchData) {
    const testReportTable = this.$refs.testReportTable as any;
    Vue.$log.debug('closeUserSearchModal');
    Vue.$log.debug(searchParams);
    this.$modal.hide('user-search-modal');
    this.updateParams({filters: searchParams, page: 1});
    testReportTable.initializePagination();
    this.updateFilterText();
    this.loadItems();
  }

  /** 検索条件のクリア */
  clearSearchFilter() {
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    const testReportTable = this.$refs.testReportTable as any;
    this.clearParams(Object.keys(userSearchModal.fields));
    this.filterTexts = [];
    userSearchModal.clearFields();
    userSearchModal.clearSearchOptionsFilter();
    this.updateParams({ page: 1});
    testReportTable.initializePagination();
    this.loadItems();
  }

  /** 絞り込み検索テキストの更新 */
  updateFilterText() {
    Vue.$log.debug('updateFilterText');
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    this.filterTexts = userSearchModal.searchTexts();
  }

  /** テストの受験依頼（全メンバー） */
  clickRequestTestAll() {
    this.sendMailAll(
      new TestNotifyService(),
      this.serverParams,
      '確認画面',
      '全メンバーへテストの受験依頼を依頼してよろしいですか？',
    );
  }

  /** テストの受験依頼（指定メンバー） */
  clickRequestTest() {
    const rows = this.getSelectedRows(this.$refs.testReportTable)
    const userIds = rows.map(function(p: ChecktestReportRowData){return p.userId});
    this.sendMailTargetUsers(
      new TestNotifyService(),
      userIds,
      '確認画面',
      '選択したメンバーへテストの受験依頼を依頼してよろしいですか？',
    );
  }

  /** テスト結果の閲覧（全メンバー） */
  clickRequestReportAll() {
    this.sendMailAll(
      new TestReportNotifyService(),
      this.serverParams,
      '確認画面',
      '全メンバーへテスト結果の閲覧を依頼してよろしいですか？',
    );
  }

  /** テスト結果の閲覧（指定メンバー） */
  clickRequestReport() {
    const rows = this.getSelectedRows(this.$refs.testReportTable)
    const userIds = rows.map(function(p: ChecktestReportRowData){return p.userId});
    this.sendMailTargetUsers(
      new TestReportNotifyService(),
      userIds,
      '確認画面',
      '選択したメンバーへテスト結果の閲覧を依頼してよろしいですか？',
    );
  }

  /** レポートCSVダウンロード（全メンバー） */
  clickDownloadCsvAll() {
    this.showLoading();
    new TestReportService().csvDownloadAll(this.serverParams).then((response) => { 
      this.commonApiErrorHandler(response)
    }).finally(() => {
      this.hideLoading();
    });
  }

  /** レポートCSVダウンロード（指定メンバー） */
  clickDownloadCsv() {
    const rows = this.getSelectedRows(this.$refs.testReportTable)
    Vue.$log.debug(rows);
    if(!rows || rows.length == 0) {
      this.$swal('メンバーが選択されていません。', '', 'warning');
    }
    else {
      const userIds = rows.map(function(p: ChecktestReportRowData){return p.userId});
      Vue.$log.debug(userIds);
      this.showLoading();
      new TestReportService().csvDownload(userIds).then((response) => { 
        this.commonApiErrorHandler(response)
      }).finally(() => {
        this.hideLoading();
      });
    }
  }

  /** PDFダウンロード */
  clicDownloadPdf() {
    const rows = this.getSelectedRows(this.$refs.testReportTable)
    Vue.$log.debug(rows);
    if(!rows || rows.length == 0) {
      this.$swal('メンバーが選択されていません。', '', 'warning');
    }
    else {
      const userIds = rows.map(function(p: ChecktestReportRowData){return p.userId});
      Vue.$log.debug(userIds);
      this.showLoading();
      new TestReportService().pdfDownload(userIds).then((response) => { 
        this.commonApiErrorHandler(response)
      }).finally(() => {
        this.hideLoading();
      });
    }
  }

}

