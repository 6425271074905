import RequestMixin from '@/mixins/request_mixin';

import Vue from 'vue';
import { AxiosResponse } from '@/types';
import { SearchParams } from '@/types/good_table_types'

export class NotifyService extends RequestMixin {

  /**
   * 全ユーザー通知
   */
  public notifyAll(param: SearchParams): Promise<AxiosResponse> {
    Vue.$log.debug(param);
    throw new Error('not implemented');
  }

  /**
   * 指定ユーザー通知
   */
  public notifyTarget(userIds: number[]): Promise<AxiosResponse> {
    Vue.$log.debug(userIds);
    throw new Error('not implemented');
  }

}
