/**
 * 励ましコメント
 */
export class EncourageComments {

  static readonly COMMENTS = {
    // 平均達成率が39％以下。
    1: {
      'weight': 10,
      'paramKeys': ['avgAchieveRateP'],
      'comments': [
        {
          'comment': ['達成率は平均すると{avgAchieveRateP}％です。ちょっと低めかもしれませんね。',
               'まだまだこれからですよ、伸びしろあります！',
               '私も精一杯応援しますので、頑張っていきましょう！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['達成率は平均すると{avgAchieveRateP}％です。平均よりはちょっと低いくらいです。',
               'これから達成率を上げていけば、それだけ成長できたということになりますね！',
               '毎朝チェック内容を見てから仕事を始めるといいかも、着実に習得していきましょう。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['達成率は平均すると{avgAchieveRateP}％です。平均よりはちょっと低いくらいです。',
               'これから達成率が上がり自分が成長すると考えるとワクワクしますね！',
               '難しいアクションは付箋で目につくところへ貼っておくと意識しやすいですよ。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['平均達成率は{avgAchieveRateP}％です。平均よりはちょっと低めです。',
               'もしかするとアクション設定の難易度が少し高い可能性もあります。',
               'このままでもいいですし、勝ちグセをつけるため変えてみるのもいいですね。'],
          'image': '3_sad.png',
        },
      ],
    },
    // 平均達成率が40％以上79％以下。
    2: {
      'weight': 10,
      'paramKeys': ['avgAchieveRateP'],
      'comments': [
        {
          'comment': ['達成率は平均すると{avgAchieveRateP}％です。',
               'まずまずですね、この調子で頑張りましょう！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['達成率の平均は{avgAchieveRateP}％です。',
               'これからこの数字がグイグイ上がっていくと思うとワクワクしますね！',
               '日々チェックを繰り返すことで確実に成長できます、着実にやっていきましょう。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['平均達成率は{avgAchieveRateP}％です。標準的な達成率です、流石ですね！',
               'アクションによって毎回達成できる習得済みのものがあれば、',
               'それだけ別のアクションに差し替えてみてもいいかもしれませんよ。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['達成率の平均は{avgAchieveRateP}％です。アクションによっては、',
               'なかなか達成が難しいものもあります。その場合は未達成に慣れてしまう前に、',
               '気分転換で一度アクションを変えてみるのもありですね。'],
          'image': '1_normal.png',
        },
      ],
    },
    // 平均達成率が80％以上。
    3: {
      'weight': 10,
      'paramKeys': ['avgAchieveRateP'],
      'comments': [
        {
          'comment': ['平均達成率は{avgAchieveRateP}％、流石の数字ですね！！',
               'これだけ高いと、いくつかのアクションはもう習得済みだと思いますので、',
               'それらはぜひ別のアクションへの変更を検討してみてください！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['達成率は平均すると{avgAchieveRateP}％です。いいですねー、とってもいいですねー！',
               'アクションの変更は、たくさん申請した方がきっと会社からも高評価ですよ。',
               'もちろん自身の成長にもなるので、ぜひ検討してみてくださいね！'],
          'image': '1_normal.png',
        },
        {
          'comment': ['達成率は平均すると{avgAchieveRateP}％です。素晴らしい数字ですね。',
               '前日にアクションリストを見なくてもいつでも達成できている状態になれば、',
               '完全な習得です。そこまで引き続きチェックを続けていきましょう。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['達成率の平均は{avgAchieveRateP}％です。非常に順調ですね。',
               'とは言え達成することが目的ではなく、自身の成長が目的ですので、',
               'できるようになったアクションは差し替えてどんどん成長していきましょう。'],
          'image': '1_normal.png',
        },
      ],
    },
    // 達成率の一番低いアクションが39％以下。複数ある場合ランダムで一つ。
    4: {
      'weight': 2,
      'comments': [
        {
          'comment': ['少し達成率が低いアクションがありますね。',
               'もしも難易度が高すぎるようでしたら一度別のアクションに変えてみて、',
               '少し時間が経ってから再度設定するというのもいいですよ。成長を実感できます！'],
          'image': '1_normal.png',
        },
        {
          'comment': ['{lowestAct}のアクションの達成率がちょっと低めですね。',
               'もしどうしても苦手ということであれば、能力習得に向けて、',
               '一度先輩や上司の方に良い方法がないか相談してみるのもいいと思います。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['{lowestAct}のアクションの達成率が少し低めです。',
               'その分これからその数字がどれだけ上がっていくか、楽しみですね！',
               '例えば普段目に付くところへアクションのメモをしておくと意識しやすいですよ。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['{lowestAct}のアクションの達成率がちょっと低めですね。',
               'もしかするとアクション設定の難易度が少し高すぎる可能性もあります。',
               '目標設定は高すぎても逆効果ですので、もしそうであれば再検討してみましょう。'],
          'image': '3_sad.png',
        },
      ],
    },
    // 達成率の一番高いアクションが100％。複数ある場合ランダムで一つ。
    5: {
      'weight': 1,
      'paramKeys': ['highestAct'],
      'comments': [
        {
          'comment': ['いいですね、達成率100％のアクションがあります！',
               'もし完全に習得済みであれば、設定画面からアクションを再設定してみましょう。',
               'この調子でどんどんスキルを習得して、たくさん成長しちゃいましょう！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['{highestAct}のアクション、達成率100％ですよ！流石です！',
               '前日にアクションリストを見なくてもいつでも達成できている状態になれば、',
               '完全な習得です。そうなったらもうアクションは再設定しちゃいましょう！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['達成率100％のアクションがありますね、素晴らしいです。',
               'この調子で一つ一つのアクションをしっかりと習得していき、',
               'より素敵な自分へとどんどん成長してきましょう。'],
          'image': '1_normal.png',
        },
      ],
    },
    // メモ率が70％以上。
    6: {
      'weight': 1,
      'paramKeys': ['memoRateP', 'name'],
      'comments': [
        {
          'comment': ['メモ率が{memoRateP}％…素晴らしいです！',
               'この調子でどんどん他と差をつけてしまいましょう！',
               '{name}さんは絶対に他の人より成長速度が速いですよ！'],
          'image': '4_surprise.png',
        },
        {
          'comment': ['流石、メモ率が{memoRateP}％もあります！',
               'いいですねー、すっごくいいですねー。',
               '努力の分だけ、間違いなく自身の成長として返ってきますよ！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['{name}さんはきちんとメモをつけられていて素晴らしいですね。',
               'メモをつけて、それを後日見返すことで、メモをつけなかった場合に比べて',
               '学びの頻度は3倍にもなります。ぜひこの調子で頑張ってみてください。'],
          'image': '1_normal.png',
        },
      ],
    },
    // メモ率が20％以下。
    7: {
      'weight': 1,
      'paramKeys': ['name'],
      'comments': [
        {
          'comment': ['良かったら、ぜひメモ機能も積極的に使ってみてくださいね。',
               'メモをつけて、それを後日見返すことで、',
               'メモをつけなかった場合に比べて学びの頻度はなんと3倍にもなりますよ。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['アクションを記録する際のメモ機能には上司への連絡という意味の他に、',
               '自身がこのレポートを見返す時の学び直し強化という意味もあります。',
               '業務効率が高まりますので、ぜひ積極的に使ってみてくださいね。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['メモ機能には上司の方とのコミュニケーションという側面もありますので、',
               '特にうまくいって嬉しかったことや、失敗してしまって悔しかったことなど、',
               '積極的にメモをつけてしっかり交流をはかってみてくださいね。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['メモ機能をたくさん使用している人は、達成率が上がっていく傾向にあり、',
               '2回目の社会人基礎力チェックテストでも得点伸び率が高い傾向にあります。',
               'よければぜひ{name}さんもたくさん活用しみてくださいね！'],
          'image': '1_normal.png',
        },
      ],
    },
    // メモの文字数平均が100字以上。
    8: {
      'weight': 100,
      'paramKeys': [],
      'comments': [
        {
          'comment': ['しっかりとメモをつけられていて、流石です！',
               'メモ機能には上司の方とのコミュニケーションという側面もありますので、',
               'きっと上司の方はあなたのことをよく理解していてくれますね！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['1回のメモあたりのボリュームがいいですね！',
               '読み返す時にもしっかりとした再度の学びがあると思います。',
               'この調子でどんどんメモをして、成長の速度を高めていきましょう！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['メモ機能をうまく使いこなせていますね！',
               '日報のようにしてもいいし、自分の学びを強化する備忘録としてもいいです。',
               '自分なりの使い方をして、ぜひ自身の成長をサポートしてあげてくださいね。'],
          'image': '1_normal.png',
        },
      ],
    },
    // 管理者からのコメント率が60％以上。
    9: {
      'weight': 100,
      'paramKeys': ['commentRateP'],
      'comments': [
        {
          'comment': ['おぉー、上司の方はよくコメントしてくれていますね…！',
               '普通はもっとコメント率低いですよ。',
               'これはやる気が出ますね、頑張ってください！'],
          'image': '4_surprise.png',
        },
        {
          'comment': ['上司の方のコメント率高いですね！',
               'チームとしての結束力の高さがよく分かります。',
               '最高のチームの一員として引き続き頑張ってください！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['上司の方のコメント率が{commentRateP}％もありますね。',
               '管理する人の人数が多くなると普通はもっとコメント率は低くなってしまいます。',
               '期待に応えて、ぜひ引き続き頑張っていきましょう。'],
          'image': '1_normal.png',
        },
      ],
    },
    // 管理者からのコメントの文字数平均が100字以上
    10: {
      'weight': 1,
      'paramKeys': [],
      'comments': [
        {
          'comment': ['上司の方はきちんとコメントしてくれていますね…！',
               '管理する人の人数が多くなるとあまり長いコメントはできない場合が多いです。',
               '期待に応えて、ぜひ引き続き頑張っていきましょう！'],
          'image': '1_normal.png',
        },
        {
          'comment': ['上司の方、しっかりと長いコメントをしてくれていますね！',
               '期待の表れだと思います。素敵なチームだと思います。',
               '引き続きアクションを意識して、どんどん成長していきましょう！'],
          'image': '1_normal.png',
        },
        {
          'comment': ['上司の方もしっかりとコメントしてくれていますね。',
               'このシステムは双方向コミュニケーションのツールとしても役立ちますので、',
               '引き続き良好な関係のままアクションを続けていってもらえればと思います。'],
          'image': '1_normal.png',
        },
      ],
    },
    // アクセス時点で、配信日における未記録日数の数が3つ以上。（一定期間このコメントを表示させない）（30日間）
    11: {
      'weight': 1000,
      'paramKeys': [],
      'comments': [
        {
          'name': 'noCheckMessage',
          'comment': ['未記録の日数が3つ以上ありますね…。',
               '昨日、一昨日くらいのものであれば、なんとか思い出してつけてみてください。',
               '社会人基礎力の向上は、コツコツ地道です。'],
          'image': '3_sad.png',
        },
      ],
    },
    // 現在のリスト設定から30日以上60未満
    12: {
      'weight': 1,
      'paramKeys': ['listDays'],
      'comments': [
        {
          'comment': ['今日で現在のアクションリストを設定してから{listDays}日ですね！',
               'そろそろアクションを意識するのにも慣れてきましたか？',
               '習得してしまったアクションは別のものに変えてみるのもいいですよ！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['今日で現在のアクションリストを設定してから{listDays}日ですね。',
               'アクションの記録にも慣れてきて、その分中だるみもしやすい時期です。',
               '達成できなかったアクションにはなるべくメモをつけるようにしてみましょう。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['現在のアクションリスト設定から、今日で{listDays}日です。',
               'しっかりと続けられていて、とても素晴らしいですね。',
               '社会人基礎力は一日にしてならず、継続は力なりです。頑張りましょう。'],
          'image': '1_normal.png',
        },
      ],
    },
    // 現在のリスト設定から60日以上90日未満
    13: {
      'weight': 1,
      'paramKeys': ['listDays'],
      'comments': [
        {
          'comment': ['今日で現在のアクションリストを設定してから{listDays}日ですね。',
               'いくつかのアクションはもう習得が完了しているのではないでしょうか。',
               'それらだけ、別のアクションに変えてみるというのもいいと思いますよ。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['現在のアクションリスト設定から、今日で{listDays}日です。',
               'なかなか習得にてこずっているアクションがあるみたいですね。',
               '難しいアクションは付箋で目につくところへ貼っておくと意識しやすいですよ。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['現在のアクションリスト設定から{listDays}日、たくさんの活用ありがとうございます。',
               'アクションの変更は必ずしも一括でする必要はありませんので、',
               'よければ習得が完了したアクションがあれば一部変更をしてみてくださいね。'],
          'image': '1_normal.png',
        },
      ],
    },
    // 現在のリスト設定から90日以上
    14: {
      'weight': 100,
      'paramKeys': ['listDays'],
      'comments': [
        {
          'comment': ['今日で現在のアクションリスト設定から{listDays}日です！',
               'すごく長く使っていただいてますね、ありがとうございます。',
               '成長のために、そろそろ新しいアクションを検討してみてもいいかもです！'],
          'image': '4_surprise.png',
        },
        {
          'comment': ['現在のアクションリスト設定から、今日で{listDays}日です。',
               '習得して、別のアクションへと変えていった数だけレベルアップできます。',
               '良ければぜひ他のアクションの追加や、変更を検討してみてくださいね。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['現在のアクションリスト設定から{listDays}日、たくさんの活用ありがとうございます。',
               'アクションの変更は必ずしも一括でする必要はありませんので、',
               'よければ習得が完了したアクションがあれば一部変更をしてみてくださいね。'],
          'image': '1_normal.png',
        },
      ],
    },
    // 期間内の記録数が1つ以上
    15: {
      'weight': 1,
      'paramKeys': ['name'],
      'comments': [
        {
          'comment': ['{name}さんの、最近感動した出来事って何がありますか？',
               '日ごろの小さな幸せを意識すると、日々の生産性も上がりますよ。',
               'もしすぐに思い出せなければ…今日は美味しいものでも食べに行きましょう！'],
          'image': '2_joy.png',
        },
        {
          'comment': ['{name}さんが、最近成長を感じた出来事ってどんなことがありますか？',
               '小さなことでも、成長を感じられると自信になりますよね。',
               'いつも頑張っていて、お疲れ様です。'],
          'image': '1_normal.png',
        },
        {
          'comment': ['昨日、友達とゲームをしてたらコテンパンに負けてしまいました。。。',
               '私はボードゲームが好きなんですけど、頭使うゲームって難しいですね。',
               '悔しいなー、次回こそはリベンジしてみせます！'],
          'image': '3_sad.png',
        },
      ],
    },
    // 期間内の記録数が2つ以上
    16: {
      'weight': 0.1,
      'paramKeys': [],
      'comments': [
        {
          'comment': ['（これは表示確率5％以下のレアコメントです。レアは全4種類あります）',
               'この衣装どうです、可愛くないですか！？',
               '今日のあなたはラッキー、きっといいことありますよ！'],
          'image': '5_kosupure.png',
        },
        {
          'comment': ['（これは表示確率5％以下のレアコメントです。レアは全4種類あります）',
               'この舞子さん衣装、可愛くないですか！？',
               '今日のあなたはラッキーどす。いいことありますえ！'],
          'image': '6_maiko.png',
        },
        {
          'comment': ['（これは表示確率5％以下のレアコメントです。レアは全4種類あります）',
               '魔法使い秘書子です！この衣装、どうですか？',
               '今日のあなたはラッキー、いいことがありますよーに！'],
          'image': '7_majyo.png',
        },
        {
          'comment': ['（これは表示確率5％以下のレアコメントです。レアは全4種類あります）',
               'このドレス、似合ってますか？プリンセスです！',
               '今日のあなたはラッキー、絶対にいいことありますよ！'],
          'image': '8_hime.png',
        },
      ],
    },
  }
}

