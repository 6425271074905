import moment from 'moment';
import { SidebarItem } from 'vue-sidebar-menu';

export class SideMenuItems {

  public static getDashboardMenu() {
    return {
      href: '/dashboard',
      title: 'ダッシュボード',
      icon: 'fas fa-th',
      hiddenOnCollapse: true,
    };
  }

  /** タイムライン */
  public static getTimelineMenu() {
    return {
      href: '/action_timeline',
      title: 'タイムライン',
      icon: 'fas fa-clock',
      hiddenOnCollapse: true,
    };
  }

  /** チームボード */
  public static getTeamBoardMenu(count: number, lastUpdated: string | null, lastViewd: string | null) {
    const title = 'チームボード';
    // if(count) {
    //   title += `(${count})`;
    // }
    let cssCount = count;
    if(count > 9) {
      cssCount = 99;
    }
    const data = {
      href: '/action_team_board',
      title: title,
      icon: 'fas fa-sitemap',
      hiddenOnCollapse: true,
    };
    const bold = lastUpdated && lastViewd && moment(lastUpdated) > moment(lastViewd)
    if(bold) {
      data.icon = `fas fa-sitemap vsm--title--${cssCount}`;
    }
    else {
      data.icon = `fas fa-sitemap vsm--title--${cssCount}`;
    }
    return data;
  }

  /** チェックテストメニュー項目 */
  public static getChecktestMenu(userRole: number, dispExam: number, dispTestReport: number) {
    const params = {
      title: 'チェックテスト',
      icon: 'fas fa-file',
      href: '#checktest',
      hiddenOnCollapse: true,
      child: [] as SidebarItem[],
    };
    if(dispExam == 1) {
      params.child.push({
        href: '/checktest',
        title: '受験',
      });
    }
    params.child.push({
      href: '/group_test_report',
      title: '組織レポート',
    })
    if(userRole != 3 || dispTestReport == 1) {
      params.child.push({
        href: '/test_report',
        title: 'テストレポート一覧',
      });
    }
    params.child.push({
      href: '/my_test_report',
      title: 'Myテストレポート',
    })

    return params;
  }

  /** チェックテストメニュー項目 */
  public static getActionListMenu(userRole: number, dispReportList: number) {
    const params = {
      title: 'アクションリスト',
      icon: 'fas fa-list-ul',
      href: '#action_list',
      hiddenOnCollapse: true,
      child: [] as SidebarItem[],
    };
    if((userRole == 1 || userRole == 2 || dispReportList == 1) && userRole !== 3) {
      params.child.push({
        href: '/action_report',
        title: 'アクションレポート一覧',
      });
    }
    params.child.push({
      href: '/action_answer',
      title: '回答',
    });
    params.child.push({
      href: '/my_action_report',
      title: 'Myアクションレポート',
    });
    if(dispReportList == 1 && userRole == 3) {
      params.child.push({
        href: '/action_report',
        title: 'アクションレポート一覧',
      });
    }
    params.child.push({
      href: '/action_list/settings',
      title: '設定・変更',
    });

    return params;
  }

  /**
   * 各種設定メニュー
   */
  public static getSettingsMenu(userRole: number) {
    const params = {
      title: '各種設定',
      icon: 'fas fa-cog',
      href: '#settings',
      hiddenOnCollapse: true,
      child: [] as SidebarItem[],
    };
    if(userRole == 1) {
      params.child.push({
        href: '/company',
        title: '全社共通設定',
      });
    }
    if(userRole == 1 || userRole == 2) {
      params.child.push({
        href: '/user',
        title: 'ユーザー管理',
      });
      params.child.push({
        href: '/department',
        title: '組織管理',
      });
    }
    params.child.push({
      href: '/my_user',
      title: 'Myユーザー設定',
    });

    return params;
  }
} 
