import Vue from 'vue';
import { Mixin } from "vue-mixin-decorator"

import { UserSearchData } from '@/types' 
import { UserSearchModal } from '@/components/parts'

/**
 * ユーザー検索補助
 */
@Mixin
export default class UserSearchHelper extends Vue {

  /** 検索条件テキスト */
  filterTexts: string[] = [];

  /** 検索条件 */
  searchParams = new UserSearchData();

  /** データ取得 */
  loadStats() {
    alert('not implemented');
  }

  /** 条件を絞り込み検索ボタン */
  clickFilterButton() {
    this.$modal.show('user-search-modal');
  }

  /** 検索ボタンでuserSearchModalをclose */
  userSearch(searchParams: UserSearchData) {
    Vue.$log.debug('closeUserSearchModal');
    Vue.$log.debug(searchParams);
    this.$modal.hide('user-search-modal');
    this.searchParams = searchParams;
    this.updateFilterText();
    this.loadStats();
  }

  /** 検索条件のクリア */
  clearSearchFilter() {
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    this.filterTexts = [];
    this.searchParams = new UserSearchData();
    userSearchModal.clearFields();
    this.loadStats();
  }

  /** 絞り込み検索テキストの更新 */
  updateFilterText() {
    Vue.$log.debug('updateFilterText');
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    userSearchModal.fields = this.searchParams;
    this.filterTexts = userSearchModal.searchTexts();
  }

}
