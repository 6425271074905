import { required, maxLength } from 'vuelidate/lib/validators';
import { overOneMonth } from '@/validators/utils'

/**
 * コメントValidator
 */
export class ActionCommentValidator {
  text = {
    required,
    maxLength: maxLength(5000)
  };
}

export class TeamBoardSearchDateValidator {
  dateFrom = {}
  dateTo = { overOneMonth }
}
