






































import { Component, Vue, Prop } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class ActionAnswerComplete extends Vue {

  @Prop()
  remain: string | null = null;

  mounted() {
    const remain = this.$route.query.r;
    if(remain) {
      if(typeof remain == "string") {
        this.remain = remain;
      }
      else {
        this.remain = remain[0];
      }
    }
  }

  get message() {
    const messages = [
      '💡チャレンジャータイプは前に踏み出す力が高く、組織としての行動でも起点となり活躍します。',
      '💡アナリストタイプは考え抜く力が高く、改善点を発見し解決に向けた筋道を立てることが得意です。',
      '💡サポータータイプはチームで働く力が高く、チームプレーにおいて大きな力を発揮します。',
      '💡コンサルタントタイプは課題を発見し計画を立てて、メンバーを一致団結させることが得意です。',
      '💡リーダータイプはチームの結束力を高め、率先した行動でチームを引っ張っていくことが得意です。',
      '💡ストラテジストタイプは、自分一人でも課題を発見し、計画を立て、実現に向けて行動していきます。',
      '💡バランスタイプは、広く平均的な能力を持っている、手堅いタイプと言うことができます。',
      '💡アクションリストは、できるようになったものがあれば積極的に内容を変更していきましょう。',
      '💡アクションリストを変更した回数は、あなたが習得したスキルの数を意味します。',
      '💡回答依頼の通知時間は変更が可能です。業務開始前を指定してその日の意識付けに使うのもありです。',
      '💡回答頻度は変更が可能です。毎日や一日おきなど、希望があれば管理者に相談してみてください。',
      '💡メモ欄には管理者へのメッセージを入力するのもありです。相談事項などあれば書いてみましょう。',
      '💡毎日のルーティーン業務をアクションに設定して、リマインダーとして使用するのもありです。',
      '💡管理画面からアクションレポートを見ると、私が毎回コメントしてるのって知っていましたか？',
      '💡いつもご回答いただきありがとうございます。あなたに素敵な将来が待っていますように。',
    ];
    return messages[Math.floor(Math.random() * messages.length)];
  }
}
