























































import router from '@/router'

import { Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"

import { Messages, Code } from '@/const'
import { PwdResetData } from '@/types'
import { OverlayLoader, ErrorMessage, LogoImage } from '@/components/parts'

import LoginService from '@/services/login_service';
import ServiceCallHelper from '@/mixins/service_call_helper'

import { PwdResetValidator } from '@/validators'

@Component({
  components: {
    OverlayLoader,
    ErrorMessage,
    LogoImage,
  },
  validations: {
    fields: new PwdResetValidator()
  },
})
export default class PwdResetForm extends ServiceCallHelper {
  errorMessage = '';

  fields = new PwdResetData()

  mounted() {
    if(!this.$route.query.token) {
      this.$swal('指定されたURLが正しくありません。', '', 'error');
      router.push('/login');
    }
  }

  /**
   * 送信するボタン
   */
  clickSendButton() {
    // 入力チェック
    if (this.$v.$invalid || !this.$route.query.token) {
      this.$swal(Messages.INPUT_VALIDATION_ERROR, '', 'warning');
      return;
    }

    // 送信処理
    const token = String(this.$route.query.token)
    this.showLoading();
    new LoginService().pwdReset(this.fields.loginPassword, token).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      Vue.$log.debug(data);
      // 成功時
      if(data.code == Code.SUCCESS) {
        router.push('/pwd_reset/complete');
      }
      // 有効期限切れ
      else if(data.code == Code.SIGNATURE_EXPIRED) {
        this.$swal('ページの有効期限が切れています。', '', 'warning');
        router.push('/pwd_reset_send');
      }
      // signature不正
      else if(data.code == Code.BAD_SIGNATURE) {
        this.$swal('トークンが有効ではありません。', 'パスワード再設定画面から処理をやり直してください。', 'warning');
        router.push('/pwd_reset_send');
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }
}
