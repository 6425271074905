









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CycleFreqSelectBox extends Vue {
  @Prop()
  value: string;

  change(event: Event) {
    this.$emit('input', (event.target as HTMLInputElement).value);
  }
}
