





































import { Messages, Code } from '@/const';
import { Prop, Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { ActionListService } from '@/services'
import ServiceCallHelper from '@/mixins/service_call_helper'

import { OverlayLoader } from '@/components/parts'

@Component({
  components: {
    OverlayLoader,
  },
})
export default class ActionListRemandModal extends ServiceCallHelper {

  @Prop()
  userId: number;

  remandMessage = '';

    /** テキストエリアのデフォルトの高さ */
  defaultTextareaHeight = 180;

  /** テキストエリアの高さ */
  textareaHeight: number = this.defaultTextareaHeight;

  /** 入力時、テキストエリアの高さを動的に変更 */
  inputTextarea(e: Event) {
    this.textareaHeight = (e.target as HTMLInputElement).scrollHeight
  }

  /**
   * キャンセル
   */
  cancelButton() {
    this.$modal.hide('action-list-remand-modal');
  }

  /**
   * 差戻しボタン
   */
  clickRemandButton() {
    Vue.$log.debug('addButton');
    if (!this.remandMessage.trim()) {
      this.$swal('メッセージを入力してください。', '', 'warning');
      return;
    }

    this.showLoading();
    Vue.$log.debug(this.userId);
    Vue.$log.debug(this.remandMessage);
    new ActionListService().postRemand(this.userId, this.remandMessage).then((response) => { if(this.commonApiErrorHandler(response)) {
      Vue.$log.debug(response);
      const data = response.data;
      if(data.code == Code.SUCCESS) {
        Vue.$log.debug(data.code);
        this.$emit('success');
      }
      else if(data.code == Code.VALIDATION_ERROR) {
        Vue.$log.debug(data.code);
        this.$swal('メッセージを入力してください。', '', 'warning');
      }
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }
}

