
































import { Prop, Vue } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { DepartmentSelectBoxData } from '@/types';

@Component
export default class UserSearchOptionsModal extends Vue {

  @Prop()
  optionsModalType: "departments" | "childOccupations" | "positions" | "superUsers";

  @Prop({ default: [] })
  list: Array<DepartmentSelectBoxData> | Array<string>;

  @Prop()
  selectedList: string[];

  @Prop({ default: "" })
  descriptionText: string;

  // 役職等の変更
  changed(data: string) {
     this.$emit("changeOptions", data);
  }

  // 該当箇所がチェック済みか
  isChecked(data: string) {
    return this.selectedList.includes(data);
  }

  // 閉じる
  close() {
    this.$emit('close');
  }
}

