

















import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import HelpContent from '@/components/help/HelpContent.vue'
import { HelpMenu } from '@/const/help';

@Component({
  components: {
    HelpContent,
    Footer,
  },
})
export default class HelpPage extends Vue {

  page = '';

  mounted() {
    const pageId = this.$route.params.pageId;
    HelpMenu.MenuItems.forEach((menu) => {
      menu.subMenus.forEach((subMenu) => {
        if(subMenu.page == pageId) {
          this.page = subMenu.title;
        }
      });
    });
  }

}
