import { Mixins } from "vue-mixin-decorator";
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types';
import { ActionFollowData } from '@/types'

import RequestMixin from '@/mixins/request_mixin'; 

/**  */
export class ActionFollowSkillboxResponse extends SkillBoxResponse<ActionFollowData>{}
export class ActionFollowResponse extends AxiosResponse<ActionFollowSkillboxResponse>{}

/**
 * アクションフォローservice
 */
export default class ActionFollowService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * 指定ユーザーのフォロー情報を取得
   */
  public getUserFollowData(userId: string, params: object = {}): Promise<ActionFollowResponse> {
    return this.getReq(`/api/v1/action_follow/${userId}`, params);
  }

  /**
   * 自身のフォロー情報を取得
   */
  public getMyFollowData(params: object = {}): Promise<ActionFollowResponse> {
    return this.getReq(`/api/v1/my_action_follow`, params);
  }

  /**
   * フォロー
   */
  public postFollow(userId: string, params: object = {}): Promise<ActionFollowResponse> {
    return this.postReq(`/api/v1/my_action_follow`, {action: {userId: userId}, ...params});
  }

  /**
   * フォロー解除
   */
  public postUnFollow(userId: string, params: object = {}): Promise<ActionFollowResponse> {
    return this.deleteReq(`/api/v1/my_action_follow`, {userId: userId});
  }
}

