/**
 * ソート用パラメータ
 */
export class SortParam {
  field: string;
  type: string;

  constructor(field: string, type: string) {    
    this.field = field;
    this.type = type;
  }
}

/**
 * ソートオプション
 */
export class SortOption {
  enabled = true;
  initialSortBy = [] as Array<SortParam>;

  constructor(enabled: boolean, initialSortBy: Array<SortParam>) {
    this.enabled = enabled;
    this.initialSortBy = initialSortBy;
  }
}

/**
 * ページネーションオプション
 */
export class PaginationOption {
  enabled = true;
  mode = 'pages';
  perPageDropdown = [10, 50, 100];
  page = 1;
  perPage = 10;
  dropdownAllowAll = false;
  rowsPerPageLabel = 'ページ毎表示件数';
  nextLabel = '次へ';
  prevLabel = '前へ';
  pageLabel = 'ページ';
  ofLabel = '/';
  setCurrentPage = 1;

  constructor(enabled?: boolean, mode?: string, mobile?: boolean) {
    this.enabled = enabled !== false;
    this.mode = mode ? mode : 'pages';
    this.pageLabel = mobile ? '' : 'ページ';
  }
}

/**
 * onPageChangeパラメータ
 */
export interface PageChangeParam {
  currentPage: number;
  currentPerPage: number;
  total: number;
  prevPage: number;
}

/**
 * カラムオプション
 */
export class ColumnOption {
  label: string;
  field: string;
  hidden: boolean;
  sortable: boolean;
  tdClass: string;
  thClass: string;

  constructor(label: string, field: string, params?: { hidden? : boolean; sortable? : boolean; tdClass? : string; thClass? : string }) {
    this.label = label;
    this.field = field;
    if(params) {
      this.hidden = (params.hidden === true);
      this.sortable = (params.sortable === true);
      if(params.tdClass) {
        this.tdClass = params.tdClass;
      }
      if(params.thClass) {
        this.thClass = params.thClass;
      }
    }
  }
}

/**
 * 検索用共通パラメータ
 */
export class SearchParams {
  // eslint-disable-next-line
  filters: any;
  sort: Array<SortParam>;
  page: number;
  perPage: number;
}

