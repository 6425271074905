import { isDate, zeroOrOne, oneOrTwo } from '@/validators/utils'
import { required, maxLength } from 'vuelidate/lib/validators'

/**
 * 会社更新入力Validator
 */
export class CompanyValidator {
  checktestEndDate = { isDate };
  alSettingEndDate = { isDate };
  ctRedirect = { zeroOrOne };
  ctActionNotify = { zeroOrOne };
  showCompanyAvg = { zeroOrOne };
  showAdminComment = { required, zeroOrOne };
  showAddAction = { required, zeroOrOne };
  showMemberActionReportList = { zeroOrOne };
  showMemberTestReportList = { zeroOrOne };
  memoTitle = { required, maxLength: maxLength(30) };
  memoDescription = { maxLength: maxLength(100) };
  memoExample = { maxLength: maxLength(100) };
}

/**
 * 職種・役職選択
 */
export class PositionOccupationValidator {
  positionOccupation = { required, oneOrTwo };
}

/**
 * 会社アクションレコメンドValidator
 */
export class CompanyActionRecommendValidator {
  recommendName = { required };
  departmentId = { required };
  occupation = {};
  childOccupation = {};
  position = {};
  hireYearBelow = {};

}

/**
 * 会社更新入力Validator
 */
export class CompanyLineWorksValidator {
  clientId = { required };
  clientSecret = { required };
  serviceAccount = { required };
  botId = { required };
}