










import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import LoginForm from '@/components/auth/LoginForm.vue';

@Component({
  components: {
    LoginForm,
    Footer,
  },
})
export default class Login extends Vue {}

