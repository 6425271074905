









































































import { Prop } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { CollapseHintMessage } from '@/components/parts'

import ServiceCallHelper from '@/mixins/service_call_helper'

@Component({
  components: {
    CollapseHintMessage,
  }
})
export default class ActionListApproveExplains extends ServiceCallHelper {

  // 1:入力ページ, 2:確認ページ, 3:未申請
  @Prop()
  pageMode: number;

  @Prop()
  alLimitBelow: number;

  @Prop()
  alLimitAbove: number;

  @Prop()
  userName: string;
}

