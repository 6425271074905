

































import { Component, Prop, Vue } from 'vue-property-decorator';

import { DayOfWeek } from '@/types';

@Component
export default class DayOfWeekCheckbox extends Vue {

  @Prop()
  dayOfWeeks: string;

  dayOfWeekObj: DayOfWeek;

  created() {
    Vue.$log.debug('this.dayOfWeeks');
    Vue.$log.debug(this.dayOfWeeks);
    this.dayOfWeekObj = new DayOfWeek(this.dayOfWeeks);
  }

  change() {
    Vue.$log.debug(this.dayOfWeekObj.toString());
    this.$emit('input', this.dayOfWeekObj.toString());
  }
}
