import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AuthRoutes from '@/router/auth-routes';
import DashboardRoutes from '@/router/dashboard-routes';
import SettingsRoutes from '@/router/settings-routes';
import ControlRoutes from '@/router/control-routes';
import ChecktestRoutes from '@/router/checktest-routes';
import ActionRoutes from '@/router/action-routes';
import HelpRoutes from '@/router/help-routes';
import TestReportRoutes from '@/router/test-report-routes';
import RootContent from '@/views/control/RootContent.vue';
import ActionReportSearch from '@/router/action-report-search-routes';
import DesignContent from '@/views/control/DesignContent.vue';
import DesignContent1 from '@/views/control/DesignContent1.vue';
import DesignContent2 from '@/views/control/DesignContent2.vue';
import DesignContent3 from '@/views/control/DesignContent3.vue';
import DesignContent4 from '@/views/control/DesignContent4.vue';
import DesignContent5 from '@/views/control/DesignContent5.vue';
import NotFound from '@/views/control/NotFound.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...SettingsRoutes,
  ...ControlRoutes,
  ...ChecktestRoutes,
  ...ActionRoutes,
  ...TestReportRoutes,
  ...HelpRoutes,
  ...ActionReportSearch,
  { path: "/designtest983287925872958729", component: DesignContent },
  { path: "/designtest983287925872958721", component: DesignContent1 },
  { path: "/designtest983287925872958722", component: DesignContent2 },
  { path: "/designtest983287925872958723", component: DesignContent3 },
  { path: "/designtest983287925872958724", component: DesignContent4 },
  { path: "/designtest983287925872958725", component: DesignContent5 },
  { path: "/", component: RootContent},
  { path: "*", component: NotFound }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

// eslint-disable-next-line
function nextPage(next: any) {
  store.commit('setErrorMessage', '');
  store.commit('setInfoMessage', '');
  next();
}

router.beforeEach((to, frm, next) => {
  // requireLoginがtrueでなければ続行
  if(to.meta.requireLogin !== true) {
    nextPage(next);
  }
  // requireLogin===trueの場合は、access_tokenを持っていれば続行
  else if(Vue.cookies.get('accessToken')) {
    Vue.$log.debug(`userRole=${store.getters.userRole}`);
    // スーパーユーザー権限チェック
    if(to.meta.isSuper === true) {
      if(store.getters.userRole <= 1) {
        nextPage(next);
      }
      else {
        Vue.$log.debug(`notSuperUser=${store.getters.userRole}`);
        router.push('/404');
      }
    }
    // 管理者ユーザー権限チェック
    else if(to.meta.isAdmin === true) {
      if(store.getters.userRole <= 2) {
        nextPage(next);
      }
      else {
        Vue.$log.debug(`notAdminUser=${store.getters.userRole}`);
        router.push('/404');
      }
    }
    // チェックテスト有効
    else if(to.meta.enableChecktest === true) {
      if(store.getters.userInfo.enableChecktest == 1) {
        nextPage(next);
      }
      else {
        router.push('/404');
      }
    }
    // アクションリスト有効
    else if(to.meta.enableActionList === true) {
      if(store.getters.userInfo.enableActionList == 1) {
        nextPage(next);
      }
      else {
        router.push('/404');
      }
    }
    //
    else {
      nextPage(next);
    }
  }
  // access_tokenがなく現ページが/loginでなければ/loginに飛ばす
  else if(frm.path != '/login') {
    store.commit('setForwardUrl', to.path);
    router.push('/login');
  }
})

export default router

