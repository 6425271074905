










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
import TeamBoardContent from '@/components/action_report/TeamBoardContent.vue';

@Component({
  components: {
    Header,
    TeamBoardContent,
    Footer,
  },
})
export default class ActionTeamBoard extends Vue {}
