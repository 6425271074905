























import { Prop, Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { Messages, Code } from '@/const';
import { ActionAnswerStatsData } from '@/types';

import Filters from '@/utils/filters';

import ServiceCallHelper from '@/mixins/service_call_helper';

import { default as ActionAnswerService, ActionAnswerStatsResponse } from '@/services/action_answer_service';

@Component({
  filters: Filters
})
export default class ActionAnswerStats extends ServiceCallHelper {

  @Prop({default: ''})
  userId: string;

  /** マイか否か */
  @Prop({default: true})
  isMyAction: boolean;

  // 表示情報 
  dispStats: Array<ActionAnswerStatsData> = [];

  /** width表現 */
  widthExpression(rate: number) {
    return `width: ${Math.round(rate * 100)}%`;
  }

  /**  */
  loadStats(dateFrom: string, dateTo: string, isDefault?: boolean) {
    this.showLoading();

    // service戻り値処理
    const thenFunc = (response: ActionAnswerStatsResponse) => {
      if(this.commonApiErrorHandler(response, false)) {
        const data = response.data;
        // SUCCESSの場合は値を設定
        if(data.code == Code.SUCCESS) {
          this.dispStats = data.result.stats;
          this.$emit('success', data.result.stats, data.result.encourage);
        }
        // ValidationErrorをスキップ
        else if(data.code == Code.VALIDATION_ERROR) {
          Vue.$log.debug(data);
          this.$swal(data.message, '', 'warning');
        }
        // その他のケースは接続エラーに
        else {
          this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
        }
      }
    }

    // userIdが設定されている場合はsearchActionStats呼び出し
    if(this.userId) {
      new ActionAnswerService().searchActionStats(this.userId, dateFrom, dateTo)
        .then(thenFunc)
        .finally(() => {
          this.hideLoading();
        });
    }
    // userIdが設定されていない場合はsearchMyActionStats呼び出し
    else {
      new ActionAnswerService().searchMyActionStats(dateFrom, dateTo, isDefault)
        .then(thenFunc)
        .finally(() => {
          this.hideLoading();
        });
    }
  }

}

