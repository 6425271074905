











































































































































import { Vue, Prop } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { OverlayLoader, CollapseHintMessage } from '@/components/parts'
import ActionListRemandModal from '@/components/action_list/ActionListRemandModal.vue'
import ActionListApproveHeader from '@/components/action_list/ActionListApproveHeader.vue'
import ActionListSettingsHeader from '@/components/action_list/ActionListSettingsHeader.vue'
import { Messages, Code } from '@/const';
import { ActionListResponseData } from '@/types';
import router from '@/router'

import ActionListItems from '@/components/action_list/ActionListItems.vue'
import { ActionListService } from '@/services'
import ServiceCallHelper from '@/mixins/service_call_helper'

@Component({
  components: {
    OverlayLoader,
    CollapseHintMessage,
    ActionListRemandModal,
    ActionListItems,
    ActionListApproveHeader,
    ActionListSettingsHeader,
  },
})
export default class ActionListApproveForm extends ServiceCallHelper {

  /**
   * 承認画面フラグ
   * true: ユーザーが行った設定に対して承認を行う
   * false: ユーザーの申請なしでメンターがアクションを設定する
   */
  @Prop({default: true})
  isApprove: boolean;

  // 表示データ
  data: ActionListResponseData;

  // userId
  userId = -1;

  // 1:入力ページ, 2:確認ページ, 3:未申請
  pageMode = 0;


  /** テキストエリアのデフォルトの高さ */
  defaultTextareaHeight = 180;

  /** テキストエリアの高さ */
  userCommentHeight = this.defaultTextareaHeight;

  textareaHeight: number = this.defaultTextareaHeight;

  /** 入力時、テキストエリアの高さを動的に変更 */
  inputTextarea(e: Event) {
    this.textareaHeight = (e.target as HTMLInputElement).scrollHeight
  }

  /**
   * mount時、データ取得
   */
  mounted() {
    Vue.$log.debug(this.$route);
    this.userId = parseInt(this.$route.params.userId, 10);
    this.showLoading();
    new ActionListService().getUserActions(this.userId).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      Vue.$log.debug('loadImtes get response');
      Vue.$log.debug(data);
      if(data && data.result) {
        this.data = data.result;
        // 前回設定のmanager commentは表示しない
        this.data.managerComment = "";
      }
      // SUCCESSの場合は値を設定
      if(data.code == Code.SUCCESS) {
        this.pageLoaded = true;
        this.pageMode = 1;
      }
      // アクションリスト未申請時
      else if(data.code == Code.ACTION_LIST_NOT_REQUESTED) {
        this.pageMode = 3;
      }
      // その他のケースは接続エラーに
      else {
        this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      }
    } }).finally(() => {
      this.hideLoading();

      const targetCommentHeight: any = this.$refs.userCommentHeight;
      this.userCommentHeight = targetCommentHeight.scrollHeight;
    });
  }

  /** 戻るボタン **/
  clickBackButton() {
    Vue.$log.debug('clickBackButton');
    window.scrollTo(0,0);
    this.pageMode = 1;
  }

  /** 確認画面へボタン */
  clickConfirmButton() {
    const actionListItems = (this.$refs.actionListItems as ActionListItems);
    actionListItems.updateCheckedCount();
    // アクション設定数取得
    const checkCount = actionListItems.checkedActions().length;
    if(checkCount < this.data.alLimitBelow || checkCount > this.data.alLimitAbove) {
      this.$swal(`${this.data.alLimitBelow}件〜${this.data.alLimitAbove}件の間で選択してください。`, '', 'warning');
    } else {
      window.scrollTo(0,0);
      this.pageMode = 2;
    }
  }

  /**
   * 差戻しボタン
   */
  clickRemandButton() {
    const actionListRemandModal = this.$refs.actionListRemandModal as ActionListRemandModal;
    actionListRemandModal.remandMessage = this.data.managerComment;
    this.$modal.show('action-list-remand-modal');
    Vue.$log.debug('clickRemandButton');
  }

  /**
   * 差戻し成功時、action_reportへ遷移
   */
  remandSuccess() {
    this.$swal('差戻しが完了しました', '', 'success');
    router.push('/action_report');
  }

  /** 設定保存ボタン **/
  clickSaveButton() {
    const fields = (this.$refs.actionListItems as ActionListItems).fields;
    fields['userComment'] = this.data.userComment;
    fields['managerComment'] = this.data.managerComment;
    this.showLoading();
    new ActionListService().approveUserActions(this.userId, fields).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      Vue.$log.debug(data);
      // SUCCESSの場合はcomplete画面へ
      if(data.code == Code.SUCCESS) {
        router.push('/action_list/approve/complete')
        Vue.$log.debug(data.result.action);
      }
      // アクションリスト未申請時
      else if(data.code == Code.ACTION_LIST_NOT_REQUESTED) {
        this.pageMode = 3;
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }
}

