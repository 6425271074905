








































































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Messages, Code } from '@/const';
import { OverlayLoader, UserSearchModal, UploadModal } from '@/components/parts'

import 'vue-good-table/dist/vue-good-table.css'

import UserService from '@/services/user_service'
import { SortOption, ColumnOption  } from '@/types/good_table_types'

import UserAddModal from '@/components/settings/user/UserAddModal.vue';
import { UserSearchData } from '@/types/settings/user_types';
import Filters from '@/utils/filters'

import GoodTableHeader from '@/mixins/good_table_mixin'
import ServiceCallHelper from '@/mixins/service_call_helper'
import UserSearchOptionsHelper from '@/mixins/user_search_options_helper'

interface MixinInterface extends ServiceCallHelper, GoodTableHeader<UserSearchData>, UserSearchOptionsHelper {}

@Component({
  components: {
    OverlayLoader,
    UserAddModal,
    UserSearchModal,
    UploadModal,
    'vue-good-table': require('vue-good-table').VueGoodTable,
  },
  filters: Filters
})
export default class UserTable extends Mixins<MixinInterface>(ServiceCallHelper, GoodTableHeader, UserSearchOptionsHelper) {

  columns = [
    new ColumnOption('id', 'id', {hidden: true}),
    new ColumnOption('名前', 'userName', {thClass: 'long', tdClass: 'long', sortable: true}),
    new ColumnOption('通知先', 'notifyAddress'),
    new ColumnOption('所属組織', 'departmentName'),
    new ColumnOption('権限', 'userRole'),
    new ColumnOption('承認機能', 'requireApproval'),
    new ColumnOption('通知頻度', 'usePersonalSetting', {sortable: false}),
  ]

  pageName = 'userTablePage';

  /** 行データ */
  rows: UserSearchData[] = [];

  /** ソート設定 */
  sortOptions = new SortOption(true, []);

  /** 検索条件テキスト */
  filterTexts: string[] = [];

  /** upload modalに渡すサービスインスタンス */
  userService = new UserService();

  /**
   * mounted時にテーブル検索
   */
  mounted() {
    const searchParams = this.$store.getters.searchParams || {};
    Vue.$log.debug('mounted searchParams')
    Vue.$log.debug(searchParams)
    if(searchParams && searchParams[this.pageName]) {
      Vue.$log.debug('mounted searchParams param')
      Vue.$log.debug(searchParams[this.pageName])
      Vue.$log.debug({
        filters: searchParams[this.pageName].searchData,
        page: searchParams[this.pageName].page || 1,
        perPage: searchParams[this.pageName].perPage || 10,
      });
      this.updateParams({filters: searchParams[this.pageName].searchData});
      this.updateParams({page: searchParams[this.pageName].page || 1});
      this.updateParams({perPage: searchParams[this.pageName].perPage || 10});
      this.updateFilterText();
    }
    this.loadItems();
  }

  /**
   * ユーザー一覧の取得
   */
  loadItems() {
    Vue.$log.debug('loadItems');
    new UserService().getUsers(this.serverParams).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESS時はデータ設定
      if(data.code == Code.SUCCESS) {
        const result = response.data.result;
        this.totalRows = result.recordCount;
        this.rows = result.users;
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.isLoading = false;
      this.updateFilterOptionsText();
    });
  }

  /** ユーザーを追加ボタン */
  clickUserAddButton() {
    this.$modal.show('user-add-modal');
  }

  userAddSuccess() {
    this.loadItems();
  }

  /** CSVから追加ボタン */
  clickCsvUploadButton() {
    this.$modal.show('user-upload-modal');
  }

  /** アップロード成功 */
  uploadSuccess() {
    this.$modal.hide('user-upload-modal');
    this.loadItems();
  }

  /** CSVをダウンロードボタン */
  clickCsvDownloadButton() {
    Vue.$log.debug('csv download');
    this.showLoading();
    new UserService().downloadUsers(this.serverParams).then((response) => { 
      this.commonApiErrorHandler(response)
    }).finally(() => {
      this.hideLoading();
    });
  }

  /** 条件を絞り込み検索ボタン */
  clickFilterButton() {
    this.$modal.show('user-search-modal');
  }

  /** 検索ボタンでuserSearchModalをclose */
  userSearch(searchParams: UserSearchData) {
    const goodTable = this.$refs.goodTable as any;
    Vue.$log.debug('closeUserSearchModal');
    Vue.$log.debug(searchParams);
    this.$modal.hide('user-search-modal');
    this.updateParams({filters: searchParams, page: 1});
    goodTable.initializePagination();
    this.updateFilterText();
    this.loadItems();
  }

  /** 検索条件のクリア */
  clearSearchFilter() {
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    const goodTable = this.$refs.goodTable as any;
    this.clearParams(Object.keys(userSearchModal.fields));
    this.filterTexts = [];
    userSearchModal.clearFields();
    userSearchModal.clearSearchOptionsFilter();
    this.updateParams({ page: 1});
    goodTable.initializePagination();
    this.loadItems();
  }

  /** 絞り込み検索テキストの更新 */
  updateFilterText() {
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    this.filterTexts = userSearchModal.searchTexts();
    Vue.$log.debug(this.filterTexts);
    this.updateFilterOptionsText();
  }
}

