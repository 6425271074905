import Dashboard from '@/views/dashboard/Dashboard.vue'

export default [
  { 
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true,
      isAdmin: true,
    },
  },
]
