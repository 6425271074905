










import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import PwdResetForm from '@/components/auth/PwdResetForm.vue';

@Component({
  components: {
    PwdResetForm,
    Footer,
  },
})
export default class PwdReset extends Vue {}

