import { isDate, zeroOrOne, oneOrTwo, oneTwoThree, frequency } from '@/validators/utils'
import { required, integer, email, helpers } from 'vuelidate/lib/validators'

const setNotify = helpers.withParams(
  { type: 'setNotify' },
  // eslint-disable-next-line
  (value: string, vm: any) =>
      (vm.notifyAddress != undefined && vm.notifyAddress != '')
      || (vm.notifySlackId != undefined && vm.notifySlackId != '')
      || (vm.notifyLineId != undefined && vm.notifyLineId != '')
      || (vm.notifyLineWorksId != undefined && vm.notifyLineWorksId != '')
      || ('hasNotifyLineId' in vm && vm.hasNotifyLineId)
)

/**
 * ユーザー登録Validator
 */
export class UserAddValidator {
  // 必須設定
  userName = { required };
  userAccount = { required, email };
  departmentId = { required, integer };
  userRole = { required, oneTwoThree };
  alRequireApproval = { required, zeroOrOne };

  notifyAddress = { email, setNotify };
  notifySlackId = {};
  notifyLineId = {};
  notifyLineWorksId = {};

  // 配信設定
  usePersonalSetting = { required, zeroOrOne };
  alCycleFreq = { frequency };
  alDayOfWeeks = {};
  alMonthday = {};
  alSendTime = {};
  arCycleFreq = { frequency };
  arDayOfWeeks = {};
  arMonthday = {};
  arSendTime = {};

  // 詳細設定（任意）
  birthday = { isDate };
  gender = { oneOrTwo };
  newRecruit = { zeroOrOne };
  hireYear = { integer };
  hireMonth = { integer };
  occupation = {};
  childOccupation = {};
  position = {};
}

/**
 * ユーザー更新Validator
 */
export class UserEditValidator {
  // 必須設定
  userName = { required };
  userAccount = {};
  departmentId = { required, integer };
  userRole = { required, oneTwoThree };
  alRequireApproval = { required, zeroOrOne };

  notifyAddress = { email, setNotify };
  notifySlackId = {};
  notifyLineId = {};

  // 配信設定
  usePersonalSetting = { required, zeroOrOne };
  alCycleFreq = {};
  alSendTime = {};
  arCycleFreq = { frequency };
  arDayOfWeeks = {};
  arMonthday = {};

  // 詳細設定（任意）
  birthday = { isDate };
  gender = { oneOrTwo };
  newRecruit = { zeroOrOne };
  hireYear = { integer };
  hireMonth = { integer };
  occupation = {};
  childOccupation = {};
  position = {};

  // 褒めるAIコンサルタント設定
  toneType = { integer };
  callType = { integer };
}
