

















import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import { UserInfo } from '@/types/user_info'

@Component({
  components: {
    Footer,
  },
})
export default class LogoutContent extends Vue {

  timer: number;

  mounted() {
    this.$cookies.remove('accessToken');
    this.$store.commit('setErrorMessage', new UserInfo());
    this.timer = setTimeout( () => this.$router.push({ path: '/login'}), 5000);
  }

  destroyed() {
    Vue.$log.debug('clear timeout');
    clearTimeout(this.timer);
  }
}
