















import { Component, Vue } from 'vue-property-decorator';
import HelpMenu from '@/components/help/HelpMenu.vue'
import Footer from '@/components/common/Footer.vue';

@Component({
  components: {
    HelpMenu,
    Footer,
  },
})
export default class HelpMenuPage extends Vue {}
