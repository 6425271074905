





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CollapseHintMessage extends Vue {
  @Prop()
  title: string;

  @Prop()
  messages: string[];
}
