


























import router from '@/router'

import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class ActionListApproveComplete extends Vue {
  /** リダイレクト */
  mounted() {
    setTimeout(function(){ router.push('/action_report'); }, 5000);
  }
}
