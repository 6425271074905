















































































import moment from 'moment';
import store from '@/store';

import { Component, Watch, Vue } from 'vue-property-decorator';
import { SidebarMenu, SidebarItem } from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import '@/styles/sidebar-menu.scss'

import { SideMenuItems } from '@/const';
import ActionReportServiceHelper from '@/mixins/action_report_service_helper'
import { ActionTeamboardCount, ActionReportSearchConditionsData } from '@/types';
import { mapGetters } from 'vuex';

import { ActionReportSearchModal } from "@/components/parts";

@Component({
  components: {
    SidebarMenu,
    ActionReportSearchModal,
  },
  computed: mapGetters(['actionTeamboardCount'])
})
export default class Header extends ActionReportServiceHelper {
  menu: SidebarItem[] = this.getMenu();

  hasTeamBoard = false;

  get widthCollapsed() {
    if(this.$device.mobile) {
      return "0px";
    }
    return "50px";
  }

  @Watch('actionTeamboardCount')
  onActionTeamboardCountChanged(newValue: ActionTeamboardCount, oldValue: ActionTeamboardCount) {
    this.menu = this.getMenu();
  }

  created() {
    Vue.$log.debug("menu mounted");
    if(!store.getters.actionReportSearchConditions) {
      this.$store.commit('setActionReportSearchConditions', new ActionReportSearchConditionsData());
    }
    // チームボードカウント取得処理
    if(store.getters.userInfo.userRole != 3 && store.getters.userInfo.enableActionList) {
      // storeされていればそこから取得
      const atCount = this.$store.getters.actionTeamboardCount;
      Vue.$log.debug('atCount');
      Vue.$log.debug(atCount);
      // storeされていないか期限切れならserviceから再取得
      if(!atCount || !atCount.lastUpdated || moment().diff(moment(atCount.lastUpdated), 'minutes') > 0) {
        this.updateTeamboardCount();
      }
    }
    // deviceごとのcollapsedの切り替え
    this.$store.commit('setIsOpenSidemenu', !this.$device.mobile);
  }

  /**
   * 生成前にメニューのデータ構造を確認
   */
  getMenu() {
    Vue.$log.debug('update menu');

    const menu: SidebarItem[] = [];

    // アクションリスト利用時はダッシュボードを表示
    if(store.getters.userInfo.userRole != 3 && store.getters.userInfo.enableActionList) {
      menu.push(SideMenuItems.getDashboardMenu());
    }

    // アクションリスト利用時且つメンバーへの表示可能であればタイムラインを表示
    if(
      store.getters.userInfo.userRole != 3 ||
      (
        store.getters.userInfo.enableActionList && store.getters.userInfo.showMemberActionReportList)
      ) {
      menu.push(SideMenuItems.getTimelineMenu());
    }

    // アクションリスト利用時且つ管理者はチームボードを表示
    if(store.getters.userInfo.userRole != 3 && store.getters.userInfo.enableActionList) {
      const atCount = this.$store.getters.actionTeamboardCount;
      if(atCount) {
        const noAnswerCount = atCount.noAnswers.length;
        Vue.$log.debug('noAnswerCount + atCount.noReactionCount');
        Vue.$log.debug(noAnswerCount + (atCount.noReactionCount || 0));
        menu.push(SideMenuItems.getTeamBoardMenu(
          noAnswerCount + (atCount.noReactionCount || 0), atCount.lastUpdated, atCount.lastViewd));
      }
      else {
        menu.push(SideMenuItems.getTeamBoardMenu(0, null, null));
      }
    }

    // アクションリスト利用時はアクションリストを表示
    if(this.$store.getters.userInfo.enableActionList) {
      menu.push(SideMenuItems.getActionListMenu(
        store.getters.userInfo.userRole,
        store.getters.userInfo.showMemberActionReportList,
      ));
    }

    // チェックテスト利用時はチェックテストを表示
    if(store.getters.userInfo.enableChecktest) {
      menu.push(SideMenuItems.getChecktestMenu(
        store.getters.userInfo.userRole,
        store.getters.userInfo.enableUserChecktest,
        store.getters.userInfo.showMemberTestReportList,
      ));
    }

    // 各種設定を表示
    menu.push(SideMenuItems.getSettingsMenu(
      store.getters.userInfo.userRole,
    ));

    return menu;
  }

  /** サイドメニュー切り替え */
  sidebarItemClick() {
    this.$store.commit('setIsOpenSidemenu', !this.$store.getters.isOpenSidemenu);
  }

  logoImage() {
    const domain = process.env.VUE_APP_IMAGE_BASE_URL;
    return `${domain}/images/logo/logo.png`;
  }

  showSearchDialog() {
    this.$modal.show("action-report-search-modal");
  }

  get sidebarIsOpen() {
    return this.$store.getters.isOpenSidemenu ? 'sidebar-open' : '';
  }

  showSearchForm() {
    const userInfo = this.$store.getters.userInfo;
    Vue.$log.debug("userInfo");
    Vue.$log.debug(userInfo);
    if(
      userInfo.showMemberActionReportList == 1
      || userInfo.userRole == 1
      || userInfo.userRole == 2
    )
      return true;
    return false; 
  }

  clearSearchWords() {
    (this.$refs.actionReportSearchModal as any).clearSearchCondition();
  }
}
