export const ACTION_REPORT_CHART_COLOR: {
  actionLabel: string;
  color: string;
}[] = [
  {
    actionLabel: "平均",
    color: "#FF0000",
  },
  {
    actionLabel: "A",
    color: "#5EB0DE",
  },
  {
    actionLabel: "B",
    color: "#F3A3C0",
  },
  {
    actionLabel: "C",
    color: "#FFD87C",
  },
  {
    actionLabel: "D",
    color: "#ABE3C3",
  },
  {
    actionLabel: "E",
    color: "#C7C7FF",
  },
  {
    actionLabel: "F",
    color: "#9CE6E8",
  },
  {
    actionLabel: "G",
    color: "#FFB5B5",
  },
  {
    actionLabel: "H",
    color: "#FFB5B5",
  },
  {
    actionLabel: "I",
    color: "#FFB5B5",
  },
  {
    actionLabel: "J",
    color: "#FFB5B5",
  },
];

export const ACHIEVE_REPORT_CHART_COLOR = [
  "#5EB0DE",
  "#F3A3C0",
  "#FFD87C",
  "#ABE3C3",
  "#D0D0FF",
  "#9CE6E8",
  "#FFB5B6",
  "#ED7CFF",
  "#E3B8AB",
  "#D0EEFF"
];
