import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types'
import { ChecktestInputData, ChecktestQuestionData, ChecktestAnswerObject } from '@/types'

import RequestMixin from '@/mixins/request_mixin'

/** チェックテスト事前入力値 */
export class UserIdSearchSkillboxResponse extends SkillBoxResponse<ChecktestInputData>{}
export class UserIdSearchResponse extends AxiosResponse<UserIdSearchSkillboxResponse>{}

/** チェックテスト出題一覧 */
interface ChecktestQuestions {
  questions: Array<ChecktestQuestionData>;
}
export class ChecktestQuestionsSkillboxResponse extends SkillBoxResponse<ChecktestQuestions>{}
export class ChecktestQuestionsResponse extends AxiosResponse<ChecktestQuestionsSkillboxResponse>{}


/**
 * チェックテストservice
 */
export default class ChecktestService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * チェックテスト受験用画面ユーザーID検索
   */
  public getChecktestUser(): Promise<UserIdSearchResponse> {
      return this.getReq(`/api/v1/checktest`, {}) as Promise<UserIdSearchResponse>;
  }

  /*
   * 更新
   *
   * @param param
   */
  public postChecktestUser(param: ChecktestInputData): Promise<AxiosResponse> { 
    // API呼び出し
    return this.postReq('/api/v1/checktest', {'user': param}) as Promise<AxiosResponse>;
  }

  /*
   * 問題一覧の取得
   */
  public getQuestions(): Promise<ChecktestQuestionsResponse> {
    return this.getReq(`/api/v1/checktest/question`, {}) as Promise<ChecktestQuestionsResponse>;
  }

  /*
   * 回答
   *
   * @param param
   */
  public postAnswers(answers: ChecktestAnswerObject, questions: Array<ChecktestQuestionData>): Promise<AxiosResponse> { 
    // idとsortNumのみパラメータに加える
    const param = {
        'answer': answers,
        'question': questions.map(function(data) { return {'id': data.id, 'sortNum': data.sortNum} }),
    };
    // API呼び出し
    return this.postReq('/api/v1/checktest/answer', param) as Promise<AxiosResponse>;
  }

}

