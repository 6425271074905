import moment from "moment";
import { DateSearchData } from '@/types';

export const isDate = (value: string) => !value || moment(value, 'YYYY-MM-DD', true).isValid();

export const zeroOrOne = (value: string) => !value || value == '1' || value == '0';

export const oneOrTwo = (value: string) => !value || value == '1' || value == '2';

export const oneTwoThree = (value: string) => !value || value == '1' || value == '2' || value == '3';

export const frequency = (value: string) => !value || value == 'w1' ||  value == 'w2' || value == 'm1';

export const isPhoneNumber = (value: string) => {
  const re = /^0\d{2,3}-\d{1,4}-\d{4}$/;
  return re.test(value);
}

export const overOneMonth = (value: string, date: DateSearchData) => {
  const dateTo = moment(value, 'YYYY-MM-DD', true).isValid();
  const dateFrom = moment(date.dateFrom, 'YYYY-MM-DD', true).isValid();
  const maxDate = moment(date.dateFrom).add(1, "M").add(1, "days").format("YYYY-MM-DD") > moment(date.dateTo).format("YYYY-MM-DD");
  return dateTo && dateFrom && maxDate;
}
