


















import { Prop, Vue } from "vue-property-decorator";
import { Component } from "vue-mixin-decorator";

@Component
export default class AutoUrlLink extends Vue {
  @Prop()
  text: string

  @Prop({default: false})
  commentList: boolean

  splitAndLabelText(text: string) {
    const segments: [string, string][] = [];
    let currentSegment = "";
    const url = new RegExp(/https?:\/\/[a-zA-Z0-9-._~!'()[\]*;/?:@&=+$,%#]+/, "g");

    for (let i = 0; i < text.length; i++) {
      if (text[i] === '\n') {
        if (currentSegment) {
          segments.push([currentSegment, "TEXT"]);
          currentSegment = "";
        }
        segments.push(["\n", "LB"]);
      } else if (text[i] === 'h' && url.test(text.substring(i, i + 9))) {
        if (currentSegment) {
          segments.push([currentSegment, "TEXT"]);
          currentSegment = "";
        }
        const urlMatch = text.substring(i).match(url);
        if (urlMatch) {
          segments.push([urlMatch[0], "URL"]);
          i += urlMatch[0].length - 1;
        }
      } else {
        currentSegment += text[i];
      }
    }
    if (currentSegment) {
      segments.push([currentSegment, "TEXT"]);
    }
    return segments;
  }
}
