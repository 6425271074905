import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, UploadableService } from '@/types'

import RequestMixin from '@/mixins/request_mixin'

export default class OccupationService extends Mixins<RequestMixin>(RequestMixin) implements UploadableService {

  /**
   * 職種CSVダウンロード
   *
   * @param params
   */
  public downloadCsv() {
    this.downloadReq('/api/v1/occupation_csv', 'occupation.csv', {});
  }

  /**
   * 職種CSVアップロード
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public uploadFile(formData: FormData): Promise<AxiosResponse> {
    return this.uploadReq('/api/v1/occupation_csv', formData);
  }

}

