












































import { Mixins, Vue } from "vue-property-decorator";
import { Component } from "vue-mixin-decorator";
import { OverlayLoader, DateSearchModal } from "@/components/parts";
import { default as ActionAnswerService } from '@/services/action_answer_service'
import ActionReportServiceHelper from '@/mixins/action_report_service_helper'
import { ActionAnswerDetailResponseRowData } from "@/types"
import CommentConfirmDetails from "@/components/action_report/CommentConfirmDetails.vue"

interface MixinInterface extends ActionReportServiceHelper, GoodTableHeader<ActionAnswerDetailResponseRowData> {}

import { Code } from '@/const'

import GoodTableHeader from '@/mixins/good_table_mixin'

import ServiceCallHelper from "@/mixins/service_call_helper";

import Filters from '@/utils/filters'

@Component({
  components: {
    OverlayLoader,
    CommentConfirmDetails,
    DateSearchModal,
  },
  filters: Filters
})
export default class CommentConfirmContent extends Mixins<MixinInterface>(ServiceCallHelper, GoodTableHeader) {

  // アクションリスト状態フラグ
  statusCode = 1;

    /** userIdに紐づくユーザーの各種情報 */
  userInfo: any = {};

  /** action id */
  aid: string | null = null;

  created() {
    // aid設定
    this.aid = (this.$route.query.aid as string);
  }

  mounted() {
    this.loadItems();
  }

  // アクションレポートを取得
  loadItems() {
    this.isLoading = true;
    const CommentConfirmDetails = this.$refs.commentConfirmDetails as CommentConfirmDetails

    new ActionAnswerService()
    .searchActionPendingComment()
    .then((response) => {
      const data = response.data;
      if(this.commonApiErrorHandler(response)) {
        // SUCCESSの場合は値を設定
        if(data.code == Code.SUCCESS) {
          Vue.$log.debug(response.data);
          CommentConfirmDetails.getRow(response.data.result.totalRows, response.data.result.records);
          this.statusCode = 1;
          this.userInfo = data.result.users;
          Vue.$log.debug(this.userInfo);
        } else {
          this.statusCode = 0;
        }
      }
    }).finally(() => {
      this.isLoading = false;
    });
    
  }
}
