



























import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import router from '@/router'
import { LogoImage } from '@/components/parts'

@Component({
  components: {
    Footer,
    LogoImage,
  },
})
export default class PwdResetSend extends Vue {
  clickLoginButton() {
    router.push('/login');
  }
}

