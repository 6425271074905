





















































import { Vue, Prop } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { CollapseHintMessage } from '@/components/parts'


@Component({
  components: {
    CollapseHintMessage,
  },
})
export default class ActionListSettingsHeader extends Vue {

  @Prop({default: ""})
  userName: string;

  @Prop({default: false})
  checktestNotFound: boolean;

  @Prop()
  inputPage: boolean;

  @Prop()
  isReSetting: boolean;

  @Prop()
  alLimitAbove: number;
}

