import moment from 'moment';
import Vue from 'vue';
import { Mixin } from "vue-mixin-decorator"

import { ActionAnswerService } from '@/services' 
import ServiceCallHelper from "@/mixins/service_call_helper";

/**
 * アクションレポート補助
 */
@Mixin
export default class ActionReportServiceHelper extends ServiceCallHelper {

  /** 回答インデックス一覧 */
  answerIndices = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

  /**
   * チームボードの件数情報更新
   */
  updateTeamboardCount() {
    if(this.$store.getters.userInfo.userRole <= 2) {
      Vue.$log.debug('updateTeamboardCount');
      const atCount = this.$store.getters.actionTeamboardCount;
      new ActionAnswerService().searchActionTeamBoardCount().then((response) => { if(this.commonApiErrorHandler(response)) {
        Vue.$log.debug('response.data');
        Vue.$log.debug(response.data);
        const actionTeamboardCount = response.data.result;
        if(atCount) {
          actionTeamboardCount.lastViewd = atCount.lastViewd;
        }
        Vue.$log.debug('setActionTeamboardCount');
        Vue.$log.debug(actionTeamboardCount);
        this.$store.commit('setActionTeamboardCount', actionTeamboardCount);
      } });
    }
  }

  /**
   * アクション未回答の非表示
   */
  hideNoAction(answerDate: string, userId: number | undefined) {
    this.$swal({
      title: `未回答でも回答率100%になります。回答率の集計対象から外してよろしいですか？`,
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
      confirmButtonText : "OK",
      cancelButtonText : "戻る",
    }).then((isConfirm) => {
      if(isConfirm.isConfirmed) {
        this.sendHideNoAction(answerDate, userId);
      }
    });
  }

  /**
   * アクション未回答の非表示API呼び出し
   */
  sendHideNoAction(answerDate: string, userId: number | undefined) {
    Vue.$log.debug('sendHideNoAction');
    if(userId) {
      new ActionAnswerService()
        .postSkipNoAnswer(userId, answerDate)
        .then((response) => {
          this.commonApiErrorHandler(response);
          this.hideSkippedAnswer(answerDate, userId);
        });
    }
    else {
      new ActionAnswerService()
        .postSkipMyNoAnswer(answerDate)
        .then((response) => {
          this.commonApiErrorHandler(response);
          this.hideSkippedAnswer(answerDate, userId);
        });
    }
  }

  /**
   * スキップした未回答アクションを非表示にする
   */
  hideSkippedAnswer(answerDate: string, userId: number | undefined) {
    alert('not implemented');
  }

}
