
















































































































import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class StrongPoint extends Vue {

  @Prop()
  strongPoints: Array<string>;

}
