import { Mixins } from "vue-mixin-decorator";
import { AxiosResponse, SkillBoxResponse } from '@/types/axios_types';
import { PostCommentData, DeleteCommentData } from '@/types';

import RequestMixin from '@/mixins/request_mixin'; 

export interface ConsultantPhrase {
  text: string;
}
/** 褒めるAIコンサルタント文案 */
export class ConsultantPhraseSkillboxResponse extends SkillBoxResponse<ConsultantPhrase>{}
export class ConsultantPhraseResponse extends AxiosResponse<ConsultantPhraseSkillboxResponse>{}

/**
 * コメント投稿service
 */
export default class ActionCommentService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * コメント投稿
   */
  public postComment(commentData: PostCommentData): Promise<AxiosResponse> {
    return this.postReq(`/api/v1/action_comment`, {'comment': commentData}) as Promise<AxiosResponse>;
  }

  public deleteComment(commentData: DeleteCommentData): Promise<AxiosResponse> {
    return this.deleteReq("/api/v1/action_comment", commentData) as Promise<AxiosResponse>;
  }

  /**
   * 褒めるAIコンサルタント文案取得
   */
  public getConsultantPhrase(dailyAnswerId: string, userId: string): Promise<ConsultantPhraseResponse> {
    const param = {
      'dailyAnswerId': dailyAnswerId,
      'userId': userId,
    };
    return this.getReq(`/api/v1/praise_consultant_comment`, param) as Promise<AxiosResponse>;
  }
}

