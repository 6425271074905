






















































































import Vue from 'vue';
import { Component } from "vue-mixin-decorator"
import { OverlayLoader, ErrorMessage } from '@/components/parts'
import { Messages, Code } from '@/const';

import { CompanyService } from '@/services'
import { CompanyLineWorksAuthData } from '@/types';

import { validationMixin } from 'vuelidate'
import { CompanyLineWorksValidator } from '@/validators'

import ServiceCallHelper from '@/mixins/service_call_helper'

@Component({
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
  },
  validations: {
    fields: new CompanyLineWorksValidator()
  },
})
export default class CompanyLineWorksForm extends ServiceCallHelper {

  /** form fields */
  fields = new CompanyLineWorksAuthData();

  /** 表示エラーメッセージ */
  errorMessage = "";

  /** ファイル名 */
  fileLabel = '';

  /**
   * データ更新
   */
  clickSaveButton() {
    // form取得とファイルチェック
    const form = document.getElementById("form") as HTMLFormElement;
    const formData = new FormData(form);

    // リクエスト実行
    this.showLoading();
    new CompanyService().uploadLineWorks(formData).then((response) => {
      if(this.commonApiErrorHandler(response, false)) {
        const data = response.data;
        // 成功の場合は成功メッセージ
        if(data.code == Code.SUCCESS){
          this.$swal(Messages.UPDATE_SUCCESS, '', 'success');
        }
        // その他のケースは接続エラーに
        else {
          this.$swal(Messages.NETWORK_ERROR, '', 'error');
        }
      }
    }).finally(() => {
      this.hideLoading();
    });
  }

  /*
   * ファイル変更時
   */
  changeUploadFile(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (!files) {
      Vue.$log.debug('not files');
    } else if(!files.length) {
      this.errorMessage = Messages.FILE_REQUIRE_ERROR;
    } else {
      Vue.$log.debug(files[0]);
      this.fileLabel = files[0].name;
      this.errorMessage = '';
    }
  }

}
