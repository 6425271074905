























































































import { Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { OverlayLoader, CollapseHintMessage } from '@/components/parts'
import { Messages, Code } from '@/const';
import { ActionListResponseData } from '@/types';
import router from '@/router'

import ActionListItems from '@/components/action_list/ActionListItems.vue'
import ActionListSettingsHeader from '@/components/action_list/ActionListSettingsHeader.vue'
import { ActionListService } from '@/services'
import ServiceCallHelper from '@/mixins/service_call_helper'

@Component({
  components: {
    OverlayLoader,
    CollapseHintMessage,
    ActionListItems,
    ActionListSettingsHeader,
  },
})
export default class ActionListSettingsForm extends ServiceCallHelper {

  // 表示データ
  data: ActionListResponseData;

  // チェックテストなしフラグ
  checktestNotFound = false;

  // true:入力ページ, false:確認ページ
  inputPage = true;

  // 2回目以降フラグ
  isReSetting = false;

  // 強み/弱み
  strongWeakPoint: any;

  /** テキストエリアのデフォルトの高さ */
  defaultTextareaHeight = 180;

  /** テキストエリアの高さ */
  textareaHeight: number = this.defaultTextareaHeight;

  /**
   * mount時、データ取得
   */
  mounted() {
    // action一覧取得
    this.showLoading();
    new ActionListService().getActions().then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      Vue.$log.debug('loadImtes get response');
      Vue.$log.debug(data);
      // SUCCESSの場合は値を設定
      if(data.code == Code.SUCCESS) {
        data.result.userComment = '';
        this.data = data.result;
        // 2回目以降フラグ
        if(data.result.userAction && data.result.userAction.length > 0) {
          this.isReSetting = true;
        }
        this.pageLoaded = true;
      }
      // チェックテスト未受験時メッセージフラグ
      else if(data.code == Code.CHECKTEST_NOT_FOUND) {
        this.checktestNotFound = true;
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /** 戻るボタン **/
  clickBackButton() {
    Vue.$log.debug('clickBackButton');
    this.inputPage = true;
  }

  /** 確認画面へボタン */
  clickConfirmButton() {
    const actionListItems = (this.$refs.actionListItems as ActionListItems);
    actionListItems.updateCheckedCount();
    // アクション設定数取得
    const checkCount = actionListItems.checkedActions().length;
    if(checkCount < this.data.alLimitBelow || checkCount > this.data.alLimitAbove) {
      if(this.data.alLimitBelow != this.data.alLimitAbove) {
        this.$swal(`アクションリスト候補の中から${this.data.alLimitBelow}～${this.data.alLimitAbove}個選択してください。`, '', 'warning');
      }
      else {
        this.$swal(`アクションリスト候補の中から${this.data.alLimitBelow}個選択してください。`, '', 'warning');
      }
    // アクションに変更がないかチェック
    } else if(!actionListItems.isActionChanged()) {
      this.$swal(`アクションが変更されていません。`, '', 'warning');
    } else {
      const pageContent = (this.$refs.pageContent as any);
      pageContent.scrollIntoView();
      this.inputPage = false;
    }
  }

  /** 設定保存ボタン **/
  clickSaveButton() {
    Vue.$log.debug('clickSaveButton');
    const fields = (this.$refs.actionListItems as ActionListItems).fields;
    fields['userComment'] = this.data.userComment;
    this.showLoading();
    new ActionListService().postUserActions(fields).then((response) => { if(this.commonApiErrorHandler(response, false)) {
        const data = response.data;
        Vue.$log.debug(data);
        // SUCCESSの場合はcomplete画面へ
        if(data.code == Code.SUCCESS) {
          this.$store.commit('setAlRequireApproval', data.result.alRequireApproval);
          router.push('/action_list/settings/complete')
        }
        // レコードが存在しない場合は404へ
        else if(data.code == Code.CHECKTEST_NOT_FOUND) {
          this.checktestNotFound = true;
        }
        // 入力チェックエラーの場合はメッセージを通知
        else if(data.code == Code.VALIDATION_ERROR) {
          Vue.$log.debug(data.code);
          this.$swal(data.message, '', 'warning');
        }
        // その他のケースは接続エラーに
        else {
          this.$swal(Messages.NETWORK_ERROR, '', 'error');
        }
      }
    }).finally(() => {
      this.hideLoading();
    });
  }

  /** 入力時、テキストエリアの高さを動的に変更 */
  inputTextarea(e: Event) {
    this.textareaHeight = (e.target as HTMLInputElement).scrollHeight
  }

}

