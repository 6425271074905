import { isPhoneNumber } from '@/validators/utils'
import { required, integer, email } from 'vuelidate/lib/validators'

/**
 * お問い合わせ入力Validator
 */
export class ContactUsValidator {
  departmentId = { required, integer };
  userName = { required };
  loginId = { required, email };
  phoneNumber = { required, isPhoneNumber }
  text = { required }
}

