



































import router from '@/router'
import { Vue, Prop } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { OverlayLoader } from '@/components/parts';
import { Messages, Code } from '@/const';

import { CompanyActionService } from '@/services'

import { validationMixin } from 'vuelidate'

@Component({
  mixins: [validationMixin],
  components: {
    OverlayLoader,
  },
})
export default class CompanyEditForm extends Vue {

  @Prop()
  recommendId: number;

  /**
   * 戻る（一覧へ）
   */
  clickBackButton() {
    router.push('/company_action_recommends')
  }

  /**
   * 登録
   */
  clickSaveButton() {
    const parent = this.$parent as any;
    parent.showLoading();
    Vue.$log.debug("click save button");

    // チェックされたActionを配列に
    const checkedActions: string[] = [];
    for (const [key, value] of Object.entries(parent.checkedActions)) {
      if(value) {
        checkedActions.push(key);
      }
    }
    const checkedRequireActions: string[] = [];
    for (const [key, value] of Object.entries(parent.checkedRequireActions)) {
      if(value) {
        checkedRequireActions.push(key);
      }
    }

    // API呼び出し
    (parent.recommendId
      ? new CompanyActionService().putCompanyActionRecommend(
        parent.recommendId,
        parent.fields,
        checkedActions,
        checkedRequireActions,
      )
      : new CompanyActionService().postCompanyActionRecommend(
        parent.fields,
        checkedActions,
        checkedRequireActions,
      )
    ).then((response) => {
      if(parent.commonApiErrorHandler(response)) {
        const data = response.data;
        // SUCCESSの場合はメッセージを設定
        if(data.code == Code.SUCCESS) {
          if(data.code == Code.SUCCESS){
            this.$swal(Messages.UPDATE_SUCCESS, '', 'success');
            this.$emit("success", checkedActions.map(Number), checkedRequireActions.map(Number));
          }
        }
        // その他のケースは接続エラーに
        else {
          this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
        }
      }
    }).finally(() => {
      parent.hideLoading();
    });
  }

  /**
   * 削除
   */
  clickDeleteButton() {
    const parent = this.$parent as any;
    Vue.$log.debug("click delete button");

    // API呼び出し

    this.$swal({
      title: "レコメンド条件を削除します",
      text: "削除されたレコメンド条件は復元することができません。よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if(isConfirm.isConfirmed) {
        parent.showLoading();
        new CompanyActionService().deleteCompanyActionRecommend(
            parent.recommendId,
        ).then((response) => {
          if(parent.commonApiErrorHandler(response)) {
            const data = response.data;
            // SUCCESSの場合はメッセージを設定
            if(data.code == Code.SUCCESS) {
              if(data.code == Code.SUCCESS){
                this.$swal(Messages.DELETE_SUCCESS, '', 'success').then(() => {
                  router.push("/company_action_recommends");
                });
              }
            }
            // その他のケースは接続エラーに
            else {
              this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
            }
          }
        }).finally(() => {
          parent.hideLoading();
        });
      }
    })
  }
}
