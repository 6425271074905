



























































import { Prop, Component, Vue } from 'vue-property-decorator';
import Filters from '@/utils/filters'

@Component({
  filters: Filters
})
export default class DepartmentAchieveIcons extends Vue {

  @Prop({default: ''})
  titleText: string;

  @Prop({default: 0.0})
  rate: number;

}
