










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
import DashboardContent from '@/components/dashboard/DashboardContent.vue';

@Component({
  components: {
    Header,
    DashboardContent,
    Footer,
  },
})
export default class Dashboard extends Vue {}
