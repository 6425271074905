












































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { UserAddData } from '@/types/settings/user_types';
import { Messages, Code } from '@/const'

import UserService from '@/services/user_service'
import ServiceCallHelper from '@/mixins/service_call_helper'
import { ErrorMessage, OverlayLoader, DayOfWeekCheckbox, CycleFreqSelectBox, HourMinuteSelectBox } from '@/components/parts'

import { validationMixin } from 'vuelidate'
import { UserAddValidator } from '@/validators'

import { PositionOccupationSelect, DepartmentSelect } from '@/mixins'

/** 役職、職種、組織のセレクトボックス値設定 */
interface MixinInterface extends ServiceCallHelper, PositionOccupationSelect, DepartmentSelect {}

@Component( {
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
    DayOfWeekCheckbox,
    CycleFreqSelectBox,
    HourMinuteSelectBox,
  },
  validations: {
    fields: new UserAddValidator()
  },
} )
export default class UserAddModal extends Mixins<MixinInterface>(ServiceCallHelper, PositionOccupationSelect, DepartmentSelect) {

  fields = new UserAddData();

  /** セレクトボックスにroot組織を表示するか */
  showRootDepartment = false;

  // 入社年のselect box年一覧
  hireYearOptions = Array.from({length: new Date().getFullYear() - 1969}, (v, k) => k + 1970);

  // 個人設定を適用時のフォーム表示
  showPersonalSetting = false;

  /**
   * 初期設定で組織設定を適用を選択
   */
  mounted() {
    this.fields.usePersonalSetting = '0';
  }

  /**
   * キャンセル
   */
  clickCancelButton() {
    this.$modal.hide('user-add-modal');
  }

  /**
   * 追加ボタン
   */
  clickAddButton() {
    Vue.$log.debug(this.fields);
    this.$v.$touch();
    if (this.$v.$invalid) {
      Vue.$log.debug(this.$v);
      this.$swal(Messages.INVALID_INPUT, '', 'warning');
      return;
    }

    // 追加API呼び出し
    this.showLoading();
    new UserService().postUser(this.fields).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESS時は成功通知
      if(data.code == Code.SUCCESS) {
        this.$swal(Messages.INSERT_SUCCESS, '', 'success');
        this.$emit('success');
        this.$modal.hide('user-add-modal');
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /**
   * 組織設定を適用を選択時に配信頻度ののフォームの表示/非表示を切り替える
   */
  changeUsePersonalSetting(value: number) {
    this.showPersonalSetting = (value == 1);
  }

}

