









































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { ChecktestReportScoreData } from '@/types'

import Filters from '@/utils/filters';

@Component({
  filters: Filters
})
export default class TestReportScoreDist extends Vue{

  @Prop()
  scoreData: ChecktestReportScoreData;

  @Prop()
  scoreDataAvg: ChecktestReportScoreData;

  @Prop()
  isPersonalData: boolean;

  /** 総合点補足テキスト */
  get totalDescription() {
    const scoreData = this.scoreData;
    if(scoreData.stepForwardScore < 24 && scoreData.thinkingScore < 24 && scoreData.teamworkScore < 48) {
      return '３つの基礎力それぞれに関して、合格点には至っていません。知識を覚えればその分すぐに成長できますので、一緒に頑張りましょう。';
    }
    else if(scoreData.stepForwardScore >= 24 && scoreData.thinkingScore < 24 && scoreData.teamworkScore < 48) {
      return '「前に踏み出す力」が高いため、主体的で自立した人と言えます。そのため他の二つも、学べばすぐに身につけられるはずです。';
    }
    else if(scoreData.stepForwardScore < 24 && scoreData.thinkingScore >= 24 && scoreData.teamworkScore < 48) {
      return '「考え抜く力」が高いため、様々な物事の解決策を導く力があります。そのため他の二つも、学べばすぐに身につけられるはずです。';
    }
    else if(scoreData.stepForwardScore < 24 && scoreData.thinkingScore < 24 && scoreData.teamworkScore >= 48) {
      return '「チームで働く力」が高いため、他人から好かれる誠実な人と言えます。真面目なため、他の二つも学べばすぐに身につくはずです。';
    }
    else if(scoreData.stepForwardScore < 24 && scoreData.thinkingScore >= 24 && scoreData.teamworkScore >= 48) {
      return '「前に踏み出す力」だけ合格点には至りませんでしたが、平均と比べて非常に優れています。ぜひこの調子で学びを深めていきましょう。';
    }
    else if(scoreData.stepForwardScore >= 24 && scoreData.thinkingScore < 24 && scoreData.teamworkScore >= 48) {
      return '「考え抜く力」だけ合格点には至りませんでしたが、平均と比べて非常に優れています。ぜひこの調子で学びを深めていきましょう。';
    }
    else if(scoreData.stepForwardScore >= 24 && scoreData.thinkingScore >= 24 && scoreData.teamworkScore < 48) {
      return '「チームで働く力」だけ合格点には至りませんでしたが、平均と比べて非常に優れています。ぜひこの調子で学びを深めていきましょう。';
    }
    else {
      return 'すでにしっかりとした社会人基礎力が身についています。応用へと進んでまったく問題ありませんので、自主的な成長を心がけてください。';
    }
  }

  /** 3要素点数毎補足テキスト */
  pointDescription(point: number) {
    Vue.$log.debug(point);
    Vue.$log.debug(point >= 15);
    if(point >= 24) {
      return '基礎力が十分にあります。専門的な知識の学習へと進む中で、実際の業務において試行錯誤をしながら応用力を高めていきましょう。';
    }
    else if(point >= 15) {
      return '基礎力の確立まであと一歩です。特別な勉強をしていないのであれば素晴らしい感性です。学習により、さらなる飛躍が期待できます。';
    }
    else {
      return '平均的なレベルには至っていませんが、その分伸びしろが大きくあります。一歩ずつ着実に覚えるべき基礎を一緒に身につけていきましょう';
    }
  }
}

