


























import router from '@/router'

import { Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"

@Component
export default class ChecktestCompleteBox extends Vue {

  isRedirect = false; 

  /** リダイレクト設定チェック */
  mounted() {
    this.isRedirect = this.$store.getters.userInfo.ctRedirect == '1';
    Vue.$log.debug(this.isRedirect);
    if(this.isRedirect) {
      setTimeout(function(){ router.push('/my_test_report'); }, 5000);
    }
  }
}

