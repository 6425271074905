

















































































































import moment from 'moment'
import router from '@/router'

import { Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { OverlayLoader } from '@/components/parts'
import { Code, Messages } from "@/const";
import TeamBoardDetails from '@/components/action_report/TeamBoardDetails.vue'
import TeamBoardActionListWaitApproveMessage from '@/components/parts/TeamBoardActionListWaitApproveMessage.vue'

import { DateSearchData, NoAnswerUser, ActionTimelineData, WaitApproveUsers, AlertInfo, ApproveAlertInfo } from '@/types'
import { ActionAnswerNotifyService, ActionAnswerService } from '@/services'
import ActionReportServiceHelper from '@/mixins/action_report_service_helper'

@Component({
  components: {
    OverlayLoader,
    TeamBoardDetails,
    TeamBoardActionListWaitApproveMessage,
  },
})
export default class TeamBoardContent extends ActionReportServiceHelper {
  /** 検索条件テキスト */
  filterText = '';

  /** アクション状況 */
  noAnswerDates: Array<NoAnswerUser> = [];

  /** 低達成率ユーザー */
  lowAchieveUsers: any[] = [];

  /** 低有効回答率ユーザー */
  lowAnswerUsers: any[] = [];

  /** アクションリスト承認待ちユーザー */
  waitApproveUsers: Array<WaitApproveUsers> = [];

  /** リアクションのない回答データ */
  noReactionData: ActionTimelineData[] = [];

  /** userIdに紐づくユーザーの各種情報 */
  userInfo: any = {};

  /** アクション回答へ遷移 */
  moveActionAnswer() {
    router.push('/action_answer')
  }

  mounted() {
    // 最終閲覧時記録
    const atCount = this.$store.getters.actionTeamboardCount;
    if(atCount) {
      atCount.lastViewd = moment().format('YYYY-MM-DD HH:mm:ss');
      this.$store.commit('setActionTeamboardCount', atCount);
    }
    // アクション状況取得
    new ActionAnswerService()
      .getGroupNoAnswer()
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          const data = response.data;
          if (data.code == Code.SUCCESS) {
            this.noAnswerLoaded(data.result.noAnswerDates);
          }
        }
      });
  }

  /**
   * チームボードロード終了時。アラート表示。
   */
  teamBoardLoaded(
    timelineData: ActionTimelineData[], userInfo: any, waitApproveUsers: WaitApproveUsers[], dateSearchData: DateSearchData,
  ) {
    const lowAchieveRates: any[] = [];
    const lowAnswerRates: any[] = [];
    const noReactionRates: any[] = [];
    const latestAnswerCheckedUserId: any[] = [];
    // this.$store.commit('setClosedAlert', []);
    const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
    Vue.$log.debug(`loaded waitApproveUsers=${waitApproveUsers}`);
    const alertWaitApproveUsers: any[] = [];
    waitApproveUsers.forEach(elem => {
      if(! closedAlert.find(alert => {
        return elem.userId == alert.userId && alert.alertType == "approveAlert" && (alert as ApproveAlertInfo).requestDate == elem.requestDate
      })) {
        alertWaitApproveUsers.push(elem);
      }
    });
    this.waitApproveUsers = alertWaitApproveUsers;
    Vue.$log.debug('closedAlert');
    Vue.$log.debug(closedAlert);
    timelineData.forEach(elem => {
      if(moment(dateSearchData.dateTo).format("YYYY-MM-DD") >= moment().subtract(1, "d").format("YYYY-MM-DD") && !latestAnswerCheckedUserId.includes(elem.userId)) {
        if(elem.achieveRate <= 0.4) {
          const user = userInfo[elem.userId];
          if(! closedAlert.find(alert => {
            return elem.userId == alert.userId && alert.alertType == 'lowAchieve' && elem.answerDate >= (alert as AlertInfo).answerDate
          })) {
            if(! lowAchieveRates.find(e => e.userId == user.userId)) {
              user.answerDate = elem.answerDate;
              lowAchieveRates.push(user);
            }
          }
        }
        if(elem.answerRate <= 0.4) {
          const user = userInfo[elem.userId];
          if(! closedAlert.find(alert => {
            return elem.userId == alert.userId && alert.alertType == 'lowAnswer' && elem.answerDate >= (alert as AlertInfo).answerDate
          })) {
            if(! lowAnswerRates.find(e => e.userId == user.userId)) {
              user.answerDate = elem.answerDate;
              lowAnswerRates.push(user);
            }
          }
        }
        latestAnswerCheckedUserId.push(elem.userId);
      }
      if(elem.hasReaction == 0) {
        if(! closedAlert.find(alert => {
          return elem.userId == alert.userId && alert.alertType == 'noReaction' && elem.answerDate == (alert as AlertInfo).answerDate
        })) {
          noReactionRates.push(elem);
        }
      }
      this.lowAchieveUsers = lowAchieveRates;
      this.lowAnswerUsers = lowAnswerRates;
      this.userInfo = userInfo;
      this.noReactionData = noReactionRates;
    });
  }

  /**
   * 無回答アラート表示
   */
  noAnswerLoaded(noAnswerData: NoAnswerUser[]) {
    const noAnswerDates: Array<NoAnswerUser> = [];
    const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
    noAnswerData.forEach(elem => {
      if(! closedAlert.find(alert => {
        return elem.userId == alert.userId && alert.alertType == 'noAnswer' && elem.answerDate == (alert as AlertInfo).answerDate
      })) {
        noAnswerDates.push(elem);
      }
    });
    this.noAnswerDates = noAnswerDates;
  }

  clickOrTup() {
    if(this.$device.mobile) {
      return 'タップ';
    }
    else {
      return 'クリック';
    }
  }

  closeAlert(answerDate: string, userId: number, alertType: string) {
    // storeされている無視リストの更新
    const deleteDate = moment().subtract(30, "days");
    const yesterday = moment().subtract(1, "days");
    Vue.$log.debug(deleteDate);
    Vue.$log.debug(yesterday);
    const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
    const newClosedAlert: Array<AlertInfo | ApproveAlertInfo> = [];
    const thisAlert = {answerDate: answerDate, userId: userId, alertType: alertType};
    closedAlert.forEach(alert => {
      if(moment((alert as AlertInfo).answerDate) > deleteDate) {
        newClosedAlert.push((alert as AlertInfo));
      }
      newClosedAlert.push((alert as ApproveAlertInfo));
    });
    newClosedAlert.push(thisAlert);
    this.$store.commit('setClosedAlert', [...new Set(newClosedAlert)]);
    Vue.$log.debug('this.$store.getters.closedAlert');
    Vue.$log.debug(this.$store.getters.closedAlert);

    // 達成率アラート管理
    if(alertType == 'lowAchieve') {
      const lowAchieveUsers: Array<any> = [];
      this.lowAchieveUsers.forEach(elem => {
        if(answerDate != elem.answerDate || userId != elem.userId) {
          lowAchieveUsers.push(elem);
        }
      })
      this.lowAchieveUsers = lowAchieveUsers;
    }

    // 有効回答率アラート管理
    if(alertType == 'lowAnswer') {
      const lowAnswerUsers: Array<any> = [];
      this.lowAnswerUsers.forEach(elem => {
        if(answerDate != elem.answerDate || userId != elem.userId) {
          lowAnswerUsers.push(elem);
        }
      })
      this.lowAnswerUsers = lowAnswerUsers;
    }

    // 未リアクションアラート管理
    if(alertType == "noReaction") {
      const noReactionData: Array<any> = [];
      this.noReactionData.forEach(elem => {
        if(answerDate != elem.answerDate || userId != elem.userId) {
          noReactionData.push(elem);
        }
      })
      this.noReactionData = noReactionData;
    }

    // 無回答アラート管理
    if(alertType == "noAnswer") {
      this.hideSkippedAnswer(answerDate, userId);
    }
  }

  closeApproveAlert(requestDate: string, userId: number) {
    const deleteDate = moment().subtract(30, "days");
    const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
    const newClosedAlert: Array<AlertInfo | ApproveAlertInfo> = [];
    const thisAlert = {requestDate: requestDate, userId: userId, alertType: "approveAlert"};
    closedAlert.forEach(alert => {
      if(moment((alert as ApproveAlertInfo).requestDate) > deleteDate) {
          newClosedAlert.push((alert as ApproveAlertInfo));
        }
      newClosedAlert.push((alert as AlertInfo));
    });
    newClosedAlert.push(thisAlert);
    this.$store.commit('setClosedAlert', [...new Set(newClosedAlert)]);
    Vue.$log.debug('this.$store.getters.closedAlert');
    Vue.$log.debug(this.$store.getters.closedAlert);

    // 未承認アラートの管理
    const waitApproveUsers: Array<any> = [];
    this.waitApproveUsers.forEach(elem => {
      if(requestDate != elem.requestDate || userId != elem.userId) {
        waitApproveUsers.push(elem);
      }
    })
    this.waitApproveUsers = waitApproveUsers;
  }

  allAlertClose() {
    // 未回答アラートをclose
    this.noAnswerDates.forEach(elem => {
      this.closeAlert(elem.answerDate, elem.userId, "noAnswer");
    });

    // 達成率が低いアラートをclose
    this.lowAchieveUsers.forEach(elem => {
      this.closeAlert(elem.answerDate, elem.userId, "lowAchieve");
    });

    // 有効回答率が低いアラートをclose
    this.lowAnswerUsers.forEach(elem => {
      this.closeAlert(elem.answerDate, elem.userId, "lowAnswer");
    });

    // 未リアクションアラートをclose
    this.noReactionData.forEach(elem => {
      this.closeAlert(elem.answerDate, elem.userId, "noReaction");
    });

    // 未承認アラートをclose
    this.waitApproveUsers.forEach(elem => {
      this.closeApproveAlert(elem.requestDate, elem.userId);
    });
  }

  /**
   * 回答日に該当しない為、無視する
   */
  hideSkippedAnswer(answerDate: string, userId: number) {
    const noAnswerDates: Array<NoAnswerUser> = [];
    this.noAnswerDates.forEach(elem => {
      if(answerDate != elem.answerDate || userId != elem.userId) {
        noAnswerDates.push(elem);
      }
    })
    this.noAnswerDates = noAnswerDates;
  }

  /**
   * 回答依頼を通知する
   */
  sendAnswerRequest(answerDate: string, userId: number, userName: string) {
    this.$swal({
      title: `${userName}さんに回答依頼を送信しますか？`,
      html: '',
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if(isConfirm.isConfirmed) {
        new ActionAnswerNotifyService().notifyTargetWithAnswerDate([userId], answerDate).then((response) => {
          if (this.commonApiErrorHandler(response)) {
            const data = response.data;
            if (data.code == Code.SUCCESS) {
              this.$swal(Messages.NOTIFY_SUCCESS, '', 'success');
            }
            else {
              this.$swal(Messages.SERVER_ERROR, '', 'warning');
            }
          }
        });
      }
    });
  }
}

