



























































































import { Vue } from "vue-property-decorator";
import { Component } from "vue-mixin-decorator";
import { Code } from "@/const";

import ActionFollowService from "@/services/action_follow_service";
import ServiceCallHelper from "@/mixins/service_call_helper";
import { OverlayLoader, ErrorMessage } from "@/components/parts";

import Filters from '@/utils/filters'

import { ActionCommentValidator } from "@/validators";
import { ActionFollowData } from "@/types";
import router from "@/router";
@Component({
  components: {
    OverlayLoader,
    ErrorMessage,
  },
  validations: {
    fields: new ActionCommentValidator(),
  },
  filters: Filters,
})
export default class MemberListModal extends ServiceCallHelper {
  currentTabState: "all" | "near" | "followed" = "near";

  followData: ActionFollowData | null = null;

  hoverUser = -1;

  mounted() {
    // フォローデータ取得
    new ActionFollowService()
      .getMyFollowData({ au: 1, ru: 1 })
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          const data = response.data;
          if (data.code == Code.SUCCESS) {
            this.followData = data.result;
          }
        }
      });
  }

  /** モーダル内タブの変更 */
  tabChange(tabState: "all" | "near" | "followed") {
    this.currentTabState = tabState;
  }

  /** フォローボタン */
  clickFollowButton(userId: string) {
    if (userId) {
      new ActionFollowService()
        .postFollow(userId, { ru: 1, au: 1 })
        .then((response) => {
          if (this.commonApiErrorHandler(response)) {
            const data = response.data;
            Vue.$log.debug(userId);
            if (data.code == Code.SUCCESS) {
              this.followData = data.result;
            }
          }
        });
    }
  }

  /** フォロー解除ボタン */
  clickUnFollowButton(userId: string) {
    if (userId) {
      new ActionFollowService()
        .postUnFollow(userId, { ru: 1, au: 1 })
        .then((response) => {
          if (this.commonApiErrorHandler(response)) {
            const data = response.data;
            Vue.$log.debug(userId);
            if (data.code == Code.SUCCESS) {
              this.followData = data.result;
            }
          }
        });
    }
  }

  /**
   * 各条件のユーザリストを取得
   */
  get userList() {
    if (this.followData) {
      switch (this.currentTabState) {
        case "all":
          return this.followData.au;
        case "near":
          return this.followData.ru;
        case "followed":
          return (
            this.followData.au &&
            this.followData.au.filter((user) => user.followed === 1)
          );
        default:
          return this.followData;
      }
    }
    return [];
  }
}
