





























































































































































import { Prop, Vue } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";
import {
  CommentData,
  ActionTimelineData,
} from "@/types";
import StampButton from "@/components/action_report/StampButton.vue";
import StampModal from "@/components/action_report/StampModal.vue";
import AutoUrlLink from "./AutoUrlLink.vue";
import { default as ActionAnswerService } from '@/services/action_answer_service'
import ActionCommentService from "@/services/action_comment_service";
import { Code, Messages } from "@/const";
import ActionReportServiceHelper from "@/mixins/action_report_service_helper";

import Filters from '@/utils/filters'

import GoodTableHeader from "@/mixins/good_table_mixin";

interface MixinInterface
  extends ActionReportServiceHelper,
    GoodTableHeader<ActionTimelineData> {}

@Component({
  components: {
    StampButton,
    StampModal,
    AutoUrlLink,
  },
  filters: Filters,
})
export default class CommentConfirmCommentListModal extends Mixins<MixinInterface>(
  ActionReportServiceHelper
) {
  /** コメント投稿対象者のユーザーID */
  @Prop()
  comments: Array<CommentData>;

  @Prop()
  memo: { memo: string; date: string };

  /** マイか否か */
  @Prop({default: true})
  isMyAction: boolean;

  /** 閲覧ユーザーのコメントが存在するかの判定 */
  @Prop()
  inMyComment: boolean;

  /** コメント承認画面か否か */
  @Prop({default: false})
  isCommentConfirm: boolean;

  userId = "";

  answerDate = "";

  dailyAnswerId = "";

  /** モーダルクリック時のcommentId保存 */
  actionCommentId: number | string = "";

  scrollHeights: number[] = [];

  inputComment = "";

  /** テキストエリアのデフォルトの高さ */
  defaultTextareaHeight = 94;

  textAreaHeight: number = this.defaultTextareaHeight;

  /** 返信機能 */
  replyUser = "";
  replyUserId = 0;
  replyProfileImage = "";

  /** 編集機能 */
  currentEditCommentId = 0;
  editComment = "";
  editDate = "";

  /** スタンプ表示更新 */
  refreshStampDisp(dailyAnswerId: string, userId: string, answerDate: string) {
    Vue.$log.debug("refreshStampDisp");
    this.dailyAnswerId = dailyAnswerId;
    this.userId = userId;
    this.answerDate = answerDate;
  }

  /** 閉じるイベント */
  clickCloseButton() {
    this.$emit("close");
  }

  /** 返信 */
  clickReply(
    replyUser: string,
    replyUserId: number,
    replyProfileImage: string
  ) {
    this.replyUser = replyUser;
    this.replyUserId = replyUserId;
    this.replyProfileImage = replyProfileImage;
  }

  /** 返信クリア */
  clearReplyUser() {
    this.replyUser = "";
    this.replyUserId = 0;
    this.replyProfileImage = "";
  }

  /** コメント編集 */
  clickEdit(comment: string, date: string, commentId: number) {
    this.editComment = comment;
    this.editDate = date;
    this.currentEditCommentId = commentId;

    this.$modal.show("comment-update-modal");
  }

  /** 入力時、テキストエリアの高さを動的に変更 */
  inputTextarea(e: Event) {
    this.textAreaHeight = (e.target as HTMLInputElement).scrollHeight;
  }

  /** コメント送信 */
  clickCommentButton() {
    if (!this.inputComment) return;
    const postData: {
      // answerDate: string;
      // userId: number | string;
      dailyAnswerId: string;
      text: string;
      replyUserId?: number;
    } = {
      // answerDate: this.answerDate,
      // userId: this.userId,
      dailyAnswerId: this.dailyAnswerId,
      text: this.inputComment,
    };
    // 返信対象のユーザを指定している場合は追加
    if (this.replyUserId) {
      postData.replyUserId = this.replyUserId;
    }
    this.showLoading();
    new ActionCommentService()
      .postComment(postData)
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          const data = response.data;
          // SUCCESSの場合は成功通知
          if (data.code == Code.SUCCESS) {
            Vue.$log.debug(data.result.action);
            // if (this.$v.fields.text) {
            //   this.$v.fields.text.$reset();
            // }
            this.$emit("success", {
              date: this.memo.date,
              userId: this.userId,
            });
            this.inputComment = "";
          }
          // その他のケースはエラー通知
          else {
            this.$swal(Messages.NETWORK_ERROR, "", "error");
          }
        }
      })
      .finally(() => {
        this.textAreaHeight = this.defaultTextareaHeight;
        this.hideLoading();
      });
  }

  /** コメント削除 */
  clickDelete(commentId: number) {
    const postData: {
      commentId: number;
      dailyAnswerId: string;
    } = {
      commentId: commentId,
      dailyAnswerId: this.dailyAnswerId,
    }

    this.$swal({
      title: "コメントを削除します",
      text: "削除された回答は復元することができません。よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        new ActionCommentService()
        .deleteComment(postData)
        .then((response) => {
          if(this.commonApiErrorHandler(response)) {
            const data = response.data;
            Vue.$log.debug(response.data);
            // SUCCESSの場合は成功通知
            if(data.code == Code.SUCCESS) {
              this.$emit("deleteSuccess", {
                  date: this.memo.date,
                  userId: this.userId,
                })
              this.inputComment = "";
            }
            // その他のケースはエラー通知
            else {
              this.$swal(Messages.NETWORK_ERROR, "", "error");
            }
          }
        }).finally(() => {
          this.hideLoading();
        });
      }
    });
  }

// コメント承認
  clickCommentConfirm(commentId: number, text: string) {
    this.$swal({
      title: "コメントを承認します",
      text: "よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        new ActionAnswerService()
        .PostActionPendingComment(commentId, text)
        .then((response) => {
          if(this.commonApiErrorHandler(response)) {
            const data = response.data;
            Vue.$log.debug(response.data);
            // SUCCESSの場合は成功通知
            if(data.code == Code.SUCCESS) {
              this.$swal("コメントを承認しました", '', 'success').then(() => {
                this.$emit("commentConfirm");
              });
            }
            // その他のケースはエラー通知
            else {
              this.$swal(Messages.NETWORK_ERROR, "", "error");
            }
          }
        }).finally();
      }
    });
  }

  modalClosed() {
    this.scrollHeights = [];
  }

  commentUpdateSuccess(text: string, commentId: number) {
    this.$emit("commentUpdate", text, commentId);
    this.$modal.hide('comment-update-modal');
  }
}
