



























































































































































































































































































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { DepartmentAddData } from '@/types/settings/department_types';
import { Messages, Code } from '@/const'

import DepartmentService from '@/services/department_service'
import ServiceCallHelper from '@/mixins/service_call_helper'

import { validationMixin } from 'vuelidate'
import { DepartmentSelect } from '@/mixins'

import { ErrorMessage, OverlayLoader, DayOfWeekCheckbox, CycleFreqSelectBox, HourMinuteSelectBox } from '@/components/parts'
import { DepartmentAddValidator } from '@/validators'

/** 組織のセレクトボックス値設定 */
interface MixinInterface extends ServiceCallHelper, DepartmentSelect {}

@Component( {
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
    DayOfWeekCheckbox,
    CycleFreqSelectBox,
    HourMinuteSelectBox,
  },
  validations: {
    fields: new DepartmentAddValidator()
  },
} )
export default class DepartmentAddModal extends Mixins<MixinInterface>(ServiceCallHelper, DepartmentSelect) {

  fields = new DepartmentAddData();

  /**
   * キャンセル
   */
  clickCancelButton() {
    this.$modal.hide('department-add-modal');
  }

  /**
   * 追加ボタン
   */
  clickAddButton() {
    // validation
    Vue.$log.debug(this.fields);
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$swal(Messages.INVALID_INPUT, '', 'warning');
      return;
    }

    // 追加API呼び出し
    this.showLoading();
    new DepartmentService().postDepartment(this.fields).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESS時は成功通知
      if(data.code == Code.SUCCESS) {
        this.$swal(Messages.INSERT_SUCCESS, '', 'success');
        this.$emit('success');
        this.$modal.hide('department-add-modal');
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }
}

