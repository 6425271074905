import Vue from 'vue';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import VueCookies from "vue-cookies-ts";
Vue.use(VueCookies);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import Vuex from 'vuex';
Vue.use(Vuex);

//import '@fortawesome/fontawesome-free/css/all.css'
//import '@fortawesome/fontawesome-free/js/all.js'

import VueDeviceDetector from "vue-device-detector"
Vue.use(VueDeviceDetector);

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

const logLevel = process.env.VUE_APP_LOG_LEVEL;
import VueLoggerPlugin from 'vuejs-logger/dist/vue-logger';
const options = {
  isEnabled: true,
  logLevel: logLevel,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};
Vue.use(VueLoggerPlugin, options);

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css'
Vue.use(Vuetify)

import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

import VModal from 'vue-js-modal';
Vue.use(VModal);

declare module 'vue/types/vue' {
  export interface VueConstructor<V extends Vue = Vue> {
    $log: {
      // eslint-disable-next-line
      debug(message: any): void;
      // eslint-disable-next-line
      info(message: any): void;
      // eslint-disable-next-line
      warn(message: any): void;
      // eslint-disable-next-line
      error(message: any): void;
    };
  }
}

Vue.config.productionTip = false;

Vue.$log.info(process.env);

// custom style
import '@/styles/assets/sass/main.scss';
import '@/styles/assets/sass/dashboard/style.scss';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// vue2-touch-events
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents, {
  touchHoldTolerance: 400,
  namespace: 'custom-touch' // Vuetifyの名前空間と競合するので、カスタムディレクティブとして宣言
})
