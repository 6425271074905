













































































































import moment from "moment";
import router from "@/router";

import { Vue } from "vue-property-decorator";
import { Component } from "vue-mixin-decorator";
import { OverlayLoader } from "@/components/parts";
import TimelineDetails from "@/components/action_report/TimelineDetails.vue";
import { Code } from "@/const";
import Filters from '@/utils/filters'

import { DateSearchData, ActionFollowData, ActionNoAnswer, NoAnswerUser } from "@/types";
import ActionReportServiceHelper from '@/mixins/action_report_service_helper'
import ActionFollowService from "@/services/action_follow_service";
import ActionAnswerService from "@/services/action_answer_service";

import "@/styles/tooltips.scss";

@Component({
  components: {
    OverlayLoader,
    TimelineDetails,
  },
  filters: Filters
})
export default class TimeLineContent extends ActionReportServiceHelper {
  /** 検索条件テキスト */
  filterText = "";

  /** フォロー情報 */
  followData: ActionFollowData | null = null;

  /** アクション状況 */
  actionInfo: ActionNoAnswer | null = null;

  /** 入力フィールド */
  fields = new DateSearchData(
    moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  );

  mounted() {
    // フォローデータ取得
    new ActionFollowService()
      .getMyFollowData({ ru: 1, limit: 10 })
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          const data = response.data;
          if (data.code == Code.SUCCESS) {
            this.followData = data.result;
            if(this.followData && this.followData.ru && this.$device.mobile) {
              this.followData.ru = this.followData.ru.slice(0, 5);
            }
          }
        }
      });

    // アクション状況取得
    new ActionAnswerService()
      .getMyNoAnswer()
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          const data = response.data;
          if (data.code == Code.SUCCESS) {
            this.actionInfo = data.result;
          }
        }
      });
  }

  /** アクション回答へ遷移 */
  moveActionAnswer() {
    router.push("/action_answer");
  }

  /**
   * メンバーリストモーダル表示
   */
  showMemberListModal() {
    this.$modal.show("member-list-modal");
  }

  /**
   * 回答日に該当しない為、無視する
   */
  hideSkippedAnswer(answerDate: string, userId: number | undefined) {
    if(this.actionInfo) {
      const noAnswerDates: Array<NoAnswerUser> = [];
      this.actionInfo.noAnswerDates.forEach(elem => {
        if(answerDate != elem.answerDate) {
          noAnswerDates.push(elem);
        }
      })
      this.actionInfo.noAnswerDates = noAnswerDates;
    }
  }

  // allAlertClose() {
  //   if(this.actionInfo) {
  //     this.actionInfo.noAnswerDates.forEach(elem => {
  //       this.hideSkippedAnswer(elem.answerDate, elem.userId);
  //     });
  //   }
  //   return
  // }

}
