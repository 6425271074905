



































































































































































































import moment from "moment";
import router from '@/router';

import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { OverlayLoader, ErrorMessage } from '@/components/parts'
import { Messages, Code } from '@/const'
import ServiceCallHelper from '@/mixins/service_call_helper'

import { ChecktestService } from '@/services'
import { ChecktestInputData } from '@/types';

import { validationMixin } from 'vuelidate'
import { ChecktestInputValidator } from '@/validators'

import { PositionOccupationSelect } from '@/mixins'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

/** 役職、職種、組織のセレクトボックス値設定 */
interface MixinInterface extends ServiceCallHelper, PositionOccupationSelect {}

@Component({
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
    DatePicker,
  },
  validations: {
    fields: new ChecktestInputValidator()
  },
})
export default class ChecktestInputForm extends Mixins<MixinInterface>(ServiceCallHelper, PositionOccupationSelect) {

  // form fields
  fields = new ChecktestInputData();

  // 入社年のselect box年一覧
  hireYearOptions = Array.from({length: new Date().getFullYear() - 1969}, (v, k) => k + 1970);

  // 誕生年のselect box年一覧
  birthYearOptions1 = Array.from({length: 70}, (v, k) => k + 1900);
  birthYearOptions2 = Array.from({length: new Date().getFullYear() - 1969}, (v, k) => k + 1970);

  /**
   * mounted時、フォームデータ取得及び当日受験チェック
   */
  mounted() {
    this.showLoading();
    new ChecktestService().getChecktestUser().then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      Vue.$log.debug(data);
      // SUCCESS時はフォームに値を設定
      if(data.code == Code.SUCCESS) {
        this.fields = data.result;
        // birthdayのyear, month, date変換 
        if(!this.fields.birthday) {
          this.fields.birthYear = '';
          this.fields.birthMonth = '';
          this.fields.birthDate = '';
        }
        else {
          const birthday = moment(this.fields.birthday);
          this.fields.birthYear = birthday.format("YYYY");
          this.fields.birthMonth = birthday.format("M");
          this.fields.birthDate = birthday.format("D");
          Vue.$log.debug(this.fields.birthMonth);
          Vue.$log.debug(this.fields.birthDate);
        }
        // その他フィールドの空文字投入
        if(!this.fields.hireYear) {
          this.fields.hireYear = '';
          this.fields.hireMonth = '';
        }
        if(!this.fields.position) {
          this.fields.position = '';
        }
        if(!this.fields.occupation) {
          this.fields.occupation = '';
        }
        if(!this.fields.childOccupation) {
          this.fields.childOccupation = '';
        }
        this.changeOccupation();
      }
      // 回答済みの場合はエラー表示
      else if(data.code == Code.CHECKTEST_ALREADY_EXIST) {
        this.$swal('チェックテストは終了しています', 'テストレポートページへ移動します。', 'error');
        this.$store.commit('setEnableUserChecktest', 0);
        router.push('/my_test_report');
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /** 回答開始ボタン **/
  clickStartButton() {
    this.fields.birthday = `${this.fields.birthYear}-${('0' + this.fields.birthMonth).slice(-2)}-${('0' + this.fields.birthDate).slice(-2)}`
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$swal(Messages.INVALID_INPUT, '', 'warning');
      return;
    }
    this.showLoading();
    new ChecktestService().postChecktestUser(this.fields).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      if(data.code == Code.VALIDATION_ERROR) {
        Vue.$log.debug('validation error')
        this.$store.commit('setErrorMessage', data.message);
      }
      else if(data.code == Code.SUCCESS) {
        router.push('/checktest/question');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /**
   * 誕生日更新
   */
  updateBirthday() {
    this.fields.birthday = `${this.fields.birthYear}-${('0' + this.fields.birthMonth).slice(-2)}-${('0' + this.fields.birthDate).slice(-2)}`;
  }

}

