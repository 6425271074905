































import { Prop, Vue } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';

@Component
export default class DateSearchModal extends Vue {
}

