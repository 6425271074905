import { Mixins } from "vue-mixin-decorator";
import { AxiosResponse, SkillBoxResponse } from "@/types/axios_types";
import { ActionReportSearchResultData } from "@/types";

import RequestMixin from "@/mixins/request_mixin";

/**  */
export class ActionReportSearchSkillboxResponse extends SkillBoxResponse<
  ActionReportSearchResultData
> {}
export class ActionReportSearchResponse extends AxiosResponse<
  ActionReportSearchSkillboxResponse
> {}

export interface ActionReportSearchRequest {
  termFrom: string;
  termTo: string;
  text: string;
  st: string;
  page: number;
  perPage: number;
  filters: object;
}

/**
 * 検索service
 */
export default class ActionReportSearchService extends Mixins<RequestMixin>(
  RequestMixin
) {
  /**
   * 全体検索
   */
  public getActionReportSearch(
    params: ActionReportSearchRequest
  ): Promise<ActionReportSearchResponse> {
    return this.getReq(`/api/v1/action_report_search`, { filters: params });
  }
}
