import Vue from 'vue';
import { Mixin } from "vue-mixin-decorator"

import { PositionService } from '@/services'

import { OccupationData } from '@/types';

/**
 * 職種役職選択
 */
@Mixin
export class PositionOccupationSelect extends Vue {

  // 役職選択一覧
  positions: Array<string> = [];

  // 職種1選択一覧
  occupations: Array<OccupationData> = [];

  // 職種2選択一覧
  childOccupations: Array<string> = [];

  // フィールドデータ
  // eslint-disable-next-line
  fields: any;

  mounted() {
    new PositionService().getPositionOccupations().then((response) => {
      const data = response.data;
      Vue.$log.debug(data);
      if(data && data.result) {
        this.positions = data.result.positions;
        this.occupations = data.result.occupations;
      }
    }).finally(() => {
      this.changeOccupation();
    });
  }

  /** 職種1change時の職種2option変更処理 */
  changeOccupation() {
    Vue.$log.debug('changeOccupation');
    this.childOccupations = [];
    Vue.$log.info(this.occupations);
    Vue.$log.info(this.fields.occupation);
    for(const occ of this.occupations) {
      if(occ.name == this.fields.occupation) {
        this.childOccupations = occ.childs;
        break;
      }
    }
  }

}

