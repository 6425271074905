




























import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import { HelpMenu, MenuItem } from '@/const/help';

@Component({
  components: {
  },
})
export default class HelpNav extends Vue {

  @Prop({default: ''})
  pageId: string;

  /** メニュー設定 */
  menus = HelpMenu.MenuItems;

  /** 初期表示メニュー */
  defaultMenus: MenuItem[] = HelpMenu.MenuItems;

  /** 検索テキスト */
  searchText = '';

  /** ナビメニュー */
  navMenus: MenuItem[] = [];

  /**
   * mounted時、ページ描画情報設定
   */
  mounted() {
    Vue.$log.debug('menus');
    // メニューページの場合はルートメニュー表示
    if(!this.pageId) {
      this.navMenus = this.menus;
    }
    // コンテンツの場合は同一階層のメニュー表示
    else {
      this.navMenus = [];
      this.menus.forEach((menu) => {
        menu.subMenus.forEach((subMenu) => {
          if(subMenu.page == this.pageId) {
            this.defaultMenus = menu.subMenus;
            this.navMenus = [];
            subMenu.subMenus.forEach((content: MenuItem) => {
              if(content.title.includes(this.searchText)) {
                content['page'] = subMenu.page;
                this.navMenus.push(content);
              }
            });
          }
        });
      });
    }
    Vue.$log.debug(this.navMenus);
  }

  /**
   * リンク生成
   */
  getLink(item: MenuItem) {
    if(item.anchor.endsWith('_l')) {
      return `/help#${item.anchor}`;
    }
    return `/help/${item.page}#${item.anchor}`;
  }

  /**
   * 検索
   */
  @Watch("searchText")
  @Debounce(500)
  changeSearchText() {
    if(!this.searchText) {
      this.navMenus = this.defaultMenus;
    }
    else {
      this.navMenus = [];
      this.menus.forEach((menu) => {
        menu.subMenus.forEach((subMenu: MenuItem) => {
          if(subMenu.title.includes(this.searchText)) {
            this.navMenus.push(subMenu);
          }
          if(subMenu.subMenus) {
            subMenu.subMenus.forEach((content) => {
              if(content.title.includes(this.searchText)) {
                content['page'] = subMenu.page;
                this.navMenus.push(content);
              }
            });
          }
        });
      });
    }
  }
}
