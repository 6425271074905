import { Mixins } from "vue-mixin-decorator";
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types';
import { SearchParams, SortParam } from '@/types/good_table_types'
import { ActionListObject, ActionAnswerResponseData, ActionAnswerDetailResponseRowData,
  ActionAnswerStatsData, ActionEncourageData, ActionTimelineData, ActionTeamboardCount,
  ActionNoAnswer, WaitApproveUsers} from '@/types';

import RequestMixin from '@/mixins/request_mixin'; 

/** アクションリスト表示一覧 */
export class ActionAnswerSkillboxResponse extends SkillBoxResponse<ActionAnswerResponseData>{}
export class ActionAnswerResponse extends AxiosResponse<ActionAnswerSkillboxResponse>{}

/** アクション回答詳細一覧 */
export interface ActionAnswerDetailResponseData{
  totalRows: number;
  userId: string;
  userName: string;
  latestListChangeDate: string;
  records: Array<ActionAnswerDetailResponseRowData>;
  alertType: string;
}
export class ActionAnswerDetailSkillboxResponse extends SkillBoxResponse<ActionAnswerDetailResponseData>{}
export class ActionAnswerDetailResponse extends AxiosResponse<ActionAnswerDetailSkillboxResponse>{}

/** タイムライン */
export interface ActionAnswerTimelineResponseData {
  totalRows: number;
  action: Array<ActionTimelineData>;
  actionTexts: any;  // { actionAlpha -> actionText }
  users: any;  // { userId -> ActionTimelineUserData }
  waitApproveUsers: Array<WaitApproveUsers>;
}
export class ActionTimelineSkillboxResponse extends SkillBoxResponse<ActionAnswerTimelineResponseData>{}
export class ActionTimelineResponse extends AxiosResponse<ActionTimelineSkillboxResponse>{}

/** チームボード回答 */
export class ActionTeamboadCountSkillboxRepsonse extends SkillBoxResponse<ActionTeamboardCount>{}
export class ActionTeamboardCountResponse extends AxiosResponse<ActionTeamboadCountSkillboxRepsonse>{}

/** アクション達成率集計結果一覧 */
export interface ActionAnswerStatsResponseData{
  stats: Array<ActionAnswerStatsData>;
  encourage: ActionEncourageData;
}
export class ActionAnswerStatsSkillboxResponse extends SkillBoxResponse<ActionAnswerStatsResponseData>{}
export class ActionAnswerStatsResponse extends AxiosResponse<ActionAnswerStatsSkillboxResponse>{}

/** アクション未回答API */
export class ActionNoAnswerSkillboxResponse extends SkillBoxResponse<ActionNoAnswer>{}
export class ActionNoAnswerResponse extends AxiosResponse<ActionNoAnswerSkillboxResponse>{}

export interface ActionPendingCommentResponseData {
  totalRows: number;
  userId: string;
  userName: string;
  latestListChangeDate: string;
  records: Array<ActionAnswerDetailResponseRowData>;
  users: any;
}
/** コメント承認 */
export class ActionPendingCommentSkillboxResponse extends SkillBoxResponse<ActionPendingCommentResponseData>{}
export class ActionPendingCommentResponse extends AxiosResponse<ActionPendingCommentSkillboxResponse>{}

/**
 * アクションリストservice
 */
export default class ActionAnswerService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * アクション回答日付検索
   */
  public getActions(date: string): Promise<ActionAnswerResponse> {
    return this.getReq(`/api/v1/action_answer?date=${date}`, {}) as Promise<ActionAnswerResponse>;
  }

  /**
   * ログインユーザーのアクション詳細回答日期間検索
   */
  public searchMyActionDetails(params: SearchParams, aid: string | null): Promise<ActionAnswerDetailResponse> {
    (params as any).aid = aid;
    return this.getReq(`/api/v1/action_answer_detail`, params) as Promise<ActionAnswerDetailResponse>;
  }

  /**
   * 指定ユーザーのアクション詳細回答日期間検索
   */
  public searchActionDetails(userId: string, params: SearchParams, aid: string | null): Promise<ActionAnswerDetailResponse> { //userId: string, dateFrom: string, dateTo: string): Promise<ActionAnswerDetailResponse> {
    (params as any).aid = aid;
    return this.getReq(`/api/v1/action_answer_detail/${userId}`, params) as Promise<ActionAnswerDetailResponse>;
  }

  /**
   * タイムラインの取得
   */
  public searchActionTimeline(params: SearchParams): Promise<ActionTimelineResponse> {
    return this.getReq(`/api/v1/action_timeline`, params) as Promise<ActionTimelineResponse>;
  }

  /**
   * チームボードの取得
   */
  public searchActionTeamBoard(params: SearchParams): Promise<ActionTimelineResponse> {
    return this.getReq(`/api/v1/action_team_board`, params) as Promise<ActionTimelineResponse>;
  }

  /**
   * チームボードメニュー件数取得
   */
  public searchActionTeamBoardCount(): Promise<ActionTeamboardCountResponse> {
    return this.getReq(`/api/v1/action_team_board_count`, {}) as Promise<ActionTeamboardCountResponse>;
  }

  /**
   * ログインユーザーの各アクション達成率集計期間検索
   */
  public searchMyActionStats(dateFrom: string, dateTo: string, enc?: boolean): Promise<ActionAnswerStatsResponse> {
    const encPram = (enc ? 1 : 0);
    return this.getReq(`/api/v1/action_answer_stats?dateFrom=${dateFrom}&dateTo=${dateTo}&enc=${encPram}`, {}) as Promise<ActionAnswerStatsResponse>;
  }

  /**
   * 指定ユーザーの各アクション達成率集計期間検索
   */
  public searchActionStats(userId: string, dateFrom: string, dateTo: string): Promise<ActionAnswerStatsResponse> {
    return this.getReq(`/api/v1/action_answer_stats/${userId}?dateFrom=${dateFrom}&dateTo=${dateTo}`, {}) as Promise<ActionAnswerStatsResponse>;
  }

  /**
   * アクション回答
   */
  public postAnswer(date: string, action: ActionListObject, userIds: number[]): Promise<AxiosResponse> {
    const anyAction = action as any;
    anyAction.hiddenCommentUsers = userIds;
    const params ={
      'date': date,
      'action': anyAction,
    }
    return this.postReq(`/api/v1/action_answer`, params) as Promise<AxiosResponse>;
  }

  /*
   * アクション回答削除
   */
  public deleteAnswer(answerDate: string, answerId: number): Promise<AxiosResponse> { 
    const params = {date: answerDate, id: answerId}
    return this.deleteReq(`/api/v1/action_answer`, params) as Promise<AxiosResponse>;
  }

  /**
   * 未回答日取得
   */
  public getNoAnswer(userId: number | string): Promise<ActionNoAnswerResponse> {
    return this.getReq(`/api/v1/action_no_answer/${userId}`, {}) as Promise<ActionNoAnswerResponse>;
  }

  /**
   * 未回答日取得
   */
  public getMyNoAnswer(): Promise<ActionNoAnswerResponse> {
    return this.getReq('/api/v1/my_action_no_answer', {}) as Promise<ActionNoAnswerResponse>;
  }

  /**
   * 未回答日取得
   */
  public getGroupNoAnswer(): Promise<ActionNoAnswerResponse> {
    return this.getReq('/api/v1/group_action_no_answer', {}) as Promise<ActionNoAnswerResponse>;
  }

  /**
   * 未回答日スキップ
   */
  public postSkipNoAnswer(userId: number | string, answerDate: string) {
    const params = {
      action: {userId: userId, answerDate: answerDate}
    };
    return this.postReq('/api/v1/action_answer_skip', params) as Promise<AxiosResponse>;
  }

  /**
   * 未回答日スキップ
   */
  public postSkipMyNoAnswer(answerDate: string) {
    const params = {
      action: {answerDate: answerDate}
    };
    return this.postReq('/api/v1/my_action_answer_skip', params) as Promise<AxiosResponse>;
  }

  /**
   * CSVダウンロード
   */
  public downloadCsv(userIds: Array<number>, dateFrom: string, dateTo: string) {
    const params = {
      userIds: userIds,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    return this.downloadReq('/api/v1/action_answer_csv', "action_answer.csv", params) as Promise<AxiosResponse>;
  }

  /**
   * コメント承認一覧取得
   */
  public searchActionPendingComment(): Promise<ActionPendingCommentResponse> {
    return this.getReq("/api/v1/action_pending_comment", {}) as Promise<AxiosResponse>
  }

  /**
   * コメント承認
   */
  public PostActionPendingComment(commentId: number, text: string ) {
    const params = {
      "comment": {
        "commentId": commentId,
        "text": text,
      }
    }
    return this.postReq("/api/v1/action_pending_comment", params) as Promise<AxiosResponse>
  }
}
