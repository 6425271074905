/**
 * チェックテスト開始登録
 */
export class ChecktestInputData {
  name = '';
  birthday = '';
  birthYear: number | string = '';
  birthMonth: number | string = '';
  birthDate: number | string = '';
  gender = '';
  newRecruit = '';
  hireYear: number | string = '';
  hireMonth: number | string = '';
  occupation = '';
  childOccupation = '';
  position = '';
}

/**
 * チェックテスト設問
 */
export class ChecktestQuestionData {
  id = '';
  sortNum = '';
  text = '';
  answer = '';
}

/**
 * チェックテスト回答データ
 */
export interface ChecktestAnswerObject {
  [key: string]: string;
}


/**
 * チェックテストレポート一覧行データ
 */
export interface ChecktestReportRowData {
  userId: number;
  userName: string;
  checktestDate: string;
  userType: string;
  totalScore: number;
  stepForwardScore: number;
  thinkingScore: number;
  teamworkScore: number;
}

/**
 * チェックテストスコアデータ
 */
export interface ChecktestReportScoreData {
  totalScore: number;

  stepForwardScore: number;
  thinkingScore: number;
  teamworkScore: number;

  subjectivityScore: number;
  approachScore: number;
  executionScore: number;

  problemDiscoveryScore: number;
  planningScore: number;
  creativityScore: number;

  transmissionScore: number;
  listeningScore: number;
  flexibilityScore: number;
  graspSituationScore: number;
  disciplineScore: number;
  stressControlScore: number;

  userCount: number;
}

/**
 * チェックテストレポートデータ
 */
export interface ChecktestReportData {
  userId: number;
  userName: string;
  userType: string;
  checktestDate: string;
  gender: string;
  showCompanyAvg: boolean;

  strongPoints: Array<string>;
  weakPoints: Array<string>;

  scoreData: ChecktestReportScoreData;
  scoreDataAvg: ChecktestReportScoreData;
  scoreDataNationalAvg: ChecktestReportScoreData;
}

/**
 * チェックユーザータイプ割合データ
 */
export interface ChecktestReportUserTypeDist {
  balance: number;
  strategist: number;
  leader: number;
  consultant: number;
  challenger: number;
  analyst: number;
  supporter: number;
  allCount: number;
  updateFlag: number;
}

export type UserTypeKeys = 'balance' | 'strategist' | 'leader' | 'consultant' | 'challenger' | 'analyst' | 'supporter';

