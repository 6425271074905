import Vue from 'vue';
import { Mixin } from "vue-mixin-decorator"
import { AxiosResponse } from '@/types/axios_types'
import { OverlayLoader } from '@/components/parts'
import { FieldErrors } from '@/types'
import { SearchParams } from '@/types/good_table_types'
import { NotifyService } from '@/services/notify/notify_service'

import router from '@/router'

import { Messages, Code } from '@/const'


/**
 * service呼び出し補助
 */
@Mixin
export default class ServiceCallHelper extends Vue {

  /** ページ読み込み終了フラグ */
  pageLoaded = false;

  /** 入力エラー（サーバーサイド） */
  serverSideFieldErrors: FieldErrors = {};

  /**
   * loading表示
   */
  showLoading() {
    if(this.$refs.overlayLoader) {
      (this.$refs.overlayLoader as OverlayLoader).isLoading = true;
    }
  }

  /**
   * loading解除
   */
  hideLoading() {
    if(this.$refs.overlayLoader) {
      (this.$refs.overlayLoader as OverlayLoader).isLoading = false;
    }
  }

  /**
   * API呼び出し時の共通エラー処理
   */
  commonApiErrorHandler(response: AxiosResponse, validation = true) {
    Vue.$log.debug(response);
    if(response) {
        Vue.$log.debug(response);
      // 422時はログアウト
      if(response.status == 422) {
        this.$swal(Messages.INVALID_AUTH, '', 'warning').then(() => {
          router.push('/logout');
        });
        return false;
      }
      // 403時はブランクページ
      if(response.status == 403) {
        router.push('/unauthorized');
        return false;
      }
      // 404時はNot Found
      else if(response.status == 404) {
        router.push('/404');
        return false;
      }
      // 413時は警告メッセージの表示
      else if(response.status == 413) {
        this.$swal('ファイルサイズが大きすぎます', '5MB以上のファイルは送信できません。', 'warning');
        return false;
      }
      // 500時はエラーメッセージの表示
      else if(response.status == 500) {
        this.$swal('システムでエラーが発生しました', '管理者にお問い合わせください。', 'warning');
        return false;
      }
      const data = response.data;
      if(data && data.result) {
        // RECORD_NOT_FOUND時は404ページへ遷移
        if(data.code == Code.RECORD_NOT_FOUND) {
          router.push('/404');
          return false;
        }
        // INVALID_AUTH時は再ログイン
        else if(data.code == Code.INVALID_AUTH) {
          this.$swal(Messages.INVALID_AUTH, '', 'warning').then(() => {
            router.push('/login');
          });
          return false;
        }
        // SERVER_ERROR時はエラー通知
        else if(data.code == Code.SERVER_ERROR) {
          this.$swal(Messages.SERVER_ERROR, '', 'error');
          return false;
        }
        // USER_SUSPENSION時は強制ログアウト
        else if(data.code == Code.USER_SUSPENSION) {
          this.$swal(Messages.USER_SUSPENSION, '', 'warning').then(() => {
            router.push('/login');
          });
          return false;
        }
        // VALIDATION_ERRORはエラー表示
        else if(validation && data.code == Code.VALIDATION_ERROR) {
          this.serverSideFieldErrors = data.errors as FieldErrors;
          if(data.message) {
            this.$swal(data.message, '', 'warning');
          }
          else {
            this.$swal(Messages.INPUT_VALIDATION_ERROR, '', 'warning');
          }
          return false;
        }
        // SUCCESS時の共通処理
        else if(data.code == Code.SUCCESS) {
          // エラーメッセージのリセット
          this.$store.commit('setErrorMessage', '');
          //  accessTokenが振られていたら再設定
          if(data.result.accessToken) {
            if(this.$cookies.get('noSavingLoginInfo') == '1') {
              this.$cookies.set('accessToken', data.result.accessToken);
            }
            else {
              this.$cookies.set('accessToken', data.result.accessToken, {'expires': '30d'});
            }
          }
          //  userInfoが振られていたら再設定
          if(data.result.userInfo) {
            this.$store.commit('setUserInfo', data.result.userInfo);
          }
          // エラー文の除去
          this.serverSideFieldErrors = {}
        }
      }
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
        return false;
      }
    }
    return true;
  }

  /**
   * 全ユーザーへのメール送信
   */
  sendMailAll(service: NotifyService, serverParams: SearchParams, swalTitle: string, swalText: string) {
    this.$swal({
      title: swalTitle,
      text: swalText,
      confirmButtonColor: '#5EB0DE',
      cancelButtonColor: '#FC7067',
      showCancelButton: true,
    }).then( isConfirm => {
      if(isConfirm.isConfirmed) {
        this.showLoading();
        service.notifyAll(serverParams).then((response) => { if(this.commonApiErrorHandler(response)) {
          if(this.commonApiErrorHandler(response)) {
            this.$swal(Messages.NOTIFY_SUCCESS, '', 'success');
          }
        } }).finally(() => {
          this.hideLoading();
        });
      }
    }); 
  }

  /**
   * 指定ユーザーへのメール送信
   */
  sendMailTargetUsers(service: NotifyService, userIds: number[], swalTitle: string, swalText: string) {
    if(!userIds || userIds.length == 0) {
      this.$swal('ユーザーが選択されていません。', '', 'warning');
    }
    else {
      this.$swal({
        title: swalTitle,
        text: swalText,
        confirmButtonColor: '#5EB0DE',
        cancelButtonColor: '#FC7067',
        showCancelButton: true,
      }).then( isConfirm => {
        if(isConfirm.isConfirmed) {
          this.showLoading();
          service.notifyTarget(userIds).then((response) => { if(this.commonApiErrorHandler(response)) {
            if(this.commonApiErrorHandler(response)) {
              this.$swal(Messages.NOTIFY_SUCCESS, '', 'success');
            }
          } }).finally(() => {
            this.hideLoading();
          });
        }
      }); 
    }
  }
}

