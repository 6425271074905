import { DayOfWeek } from '@/types'

/**
 * 組織検索
 */
export class DepartmentSearchData {
  departmentName = '';
  parentDepartmentId = '';
}

/**
 * 組織追加データ
 */
export class DepartmentAddData {
  departmentName = '';
  parentId = '';
  alLimitBelow = '';
  alLimitAbove = '';
  alCycleFreq = '';
  alDayOfWeeks = '';
  alDayOfWeeksObj = new DayOfWeek();
  al_monthday = '';
  arCycleFreq = '';
  arDayOfWeeks = '';
  arDayOfWeeksObj = new DayOfWeek();
  arMonthday = '';
  checkCycleFreq = '';
  checkDayOfWeeks = '';
  checkDayOfWeeksObj = new DayOfWeek();
  checkMonthday = '';
  actionAnswerNotify = '';
}

/**
 * 組織更新データ
 */
export class DepartmentEditData {
  departmentName = '';
  parentId = '';
  alLimitBelow = '';
  alLimitAbove = '';
  alCycleFreq = '';
  alDayOfWeeks = '';
  alMonthday = '';
  alSendTime = '';
  arSendTime = '';
  arCycleFreq = '';
  arDayOfWeeks = '';
  arMonthday = '';
  checkCycleFreq = '';
  checkDayOfWeeks = '';
  checkSendTime = '';
  checkMonthday = '';
  actionAnswerNotify = '';
  showMemberActionReportList = '';
  showChildDeparmentAction = '';
  alSettingEndDate = '';
  alSettingEndDateSendTime = '';
  memoExample = '';
  memoDescription = '';
  memoInitialText = '';
}

/**
 * 組織セレクトボックスデータ
 */
export class DepartmentSelectBoxData {
  id = '';
  name = '';
}


