import { render, staticRenderFns } from "./DayOfWeekCheckbox.vue?vue&type=template&id=4ffebf0d&"
import script from "./DayOfWeekCheckbox.vue?vue&type=script&lang=ts&"
export * from "./DayOfWeekCheckbox.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports