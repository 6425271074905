

import Chart from "chart.js";

import { Prop, Watch, Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Doughnut } from "vue-chartjs";
import ServiceCallHelper from '@/mixins/service_call_helper'

interface MixinInterface extends ServiceCallHelper, Doughnut {}

@Component
export default class AchievePieChart extends Mixins<MixinInterface>(ServiceCallHelper, Doughnut) {

  @Prop()
  percent: number;

  @Prop()
  faceColor: string;

  @Prop()
  title: string;

  @Watch('percent')
  changePercent(newValue: number, oldValue: number){
    Vue.$log.debug(`${newValue} - ${oldValue}`);
    this.updateChart(newValue);
  }

  /** グラフ描画用データ検索 */
  mounted() {
    this.updateChart(this.percent);
  }

  updateChart(percent: number) {
    if(!percent) {
      percent = 0;
    }
    const chartOptions: Chart.ChartOptions = {
      legend: {
        display: false,
      },
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
      },
      title: {
        display: true,
        text: this.title,
        fontSize: 14,
        fontStyle: 'bold',
      },
      tooltips: {
        enabled: false,
      }
    };

    const datasets: Chart.ChartDataSets[] = [{
      backgroundColor: [this.faceColor, "#f3f3f3"], // データセットの円弧の塗りつぶし色
      borderColor: "#ffffff", // データセットの円弧の境界線の色データセットの円弧の塗りつぶし色
      borderWidth: 1, // データセットの円弧の境界線の太さ
      data: [percent, 100 - percent] // データ値
    }];

    const chartData: Chart.ChartData = {
      datasets: datasets, 
    }

    this.renderChart(chartData, chartOptions);
    Vue.$log.debug('draw pie chart');
  }

}
