import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types'
import { SearchParams } from '@/types/good_table_types'
import { DepartmentAddData, DepartmentEditData } from '@/types/settings/department_types'

import RequestMixin from '@/mixins/request_mixin'


/** 検索結果用type指定 */
export class DepartmentSearchSkillBoxData {
  departments = [];
  recordCount = 0;
}
export class DepartmentSearchResponseData extends SkillBoxResponse<DepartmentSearchSkillBoxData>{}
export class DepartmentSearchResponse extends AxiosResponse<DepartmentSearchResponseData>{}

/** 検索結果用type指定（セレクトボックス用） */
export class DepartmentSelectBoxSkillBoxData {
  departments = [];
}
export class DepartmentSelectBoxResponseData extends SkillBoxResponse<DepartmentSelectBoxSkillBoxData>{}
export class DepartmentSelectBoxResponse extends AxiosResponse<DepartmentSelectBoxResponseData>{}

/** 組織ID検索用type指定 */
export class DepartmentEditSkillBoxData {
  department = new DepartmentEditData();
}
export class DepartmentIdSearchResponseData extends SkillBoxResponse<DepartmentEditSkillBoxData>{}
export class DepartmentIdSearchResponse extends AxiosResponse<DepartmentIdSearchResponseData>{}


export default class DepartmentService extends Mixins<RequestMixin>(RequestMixin) {

  /*
   * 検索
   *
   * @param params
   */
  public getDepartments(params: SearchParams): Promise<DepartmentSearchResponse> { 
    return this.getReq('/api/v1/department', params) as Promise<DepartmentSearchResponse>;
  }

  /*
   * 検索（セレクトボックス用）
   *
   * @param params
   */
  public getDepartmentIdNames(showRootDepartment?: boolean): Promise<DepartmentSelectBoxResponse> { 
    const params = showRootDepartment ? {} : {parent: 1};
    return this.getReq('/api/v1/department_select', params) as Promise<DepartmentSelectBoxResponse>;
  }

  /**
   * ID検索
   */
  public getDepartment(departmentId: number): Promise<DepartmentIdSearchResponse> {
    // const params = {
    //   filters: {
    //     text: "検索",
    //     st: "comment",
    //   }
    // }
    // return this.getReq(`/api/v1/action_report_search`, params) as Promise<AxiosResponse>;
    return this.getReq(`/api/v1/department/${departmentId}`, {}) as Promise<DepartmentIdSearchResponse>;
  }

  /*
   * 登録
   *
   * @param param
   */
  public postDepartment(param: DepartmentAddData): Promise<AxiosResponse> { 
    return this.postReq('/api/v1/department', {'department': param}) as Promise<AxiosResponse>;
  }

  /*
   * 更新
   *
   * @param param
   */
  public putDepartment(departmentId: number, param: DepartmentEditData): Promise<AxiosResponse> { 
    return this.putReq(`/api/v1/department/${departmentId}`, {'department': param}) as Promise<AxiosResponse>;
  }

  /*
   * 削除
   *
   * @param param
   */
  public deleteDepartment(departmentId: number): Promise<AxiosResponse> { 
    return this.deleteReq(`/api/v1/department/${departmentId}`, {}) as Promise<AxiosResponse>;
  }

  /**
   * CSVダウンロード
   *
   * @param params
   */
  public downloadCsv(params: SearchParams) {
    this.downloadReq('/api/v1/department_csv', 'department.csv', params);
  }

  /**
   * CSVアップロード
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public uploadFile(formData: FormData): Promise<AxiosResponse> {
    return this.uploadReq('/api/v1/department_csv', formData);
  }
}

