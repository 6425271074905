











import { Component, Prop, Vue } from 'vue-property-decorator';
import { Messages } from '@/const'
import { FieldErrors }  from '@/types'

@Component
export default class ErrorMessage extends Vue {
  @Prop()
  serverErrors: FieldErrors;

  @Prop()
  // eslint-disable-next-line
  fieldV: any;

  @Prop({ default: '' })
  fieldName: string;

  @Prop({ default: '' })
  dispName: string;

  @Prop({ default: '' })
  value: string;

  check() {
    if(this.fieldV.$error)  {
      for(const k of Object.keys(this.fieldV.$params)) {
        Vue.$log.debug(k);
        Vue.$log.debug(this.fieldV[k]);
      }
    }
  }

  get errors() {
    Vue.$log.debug(this.fieldV);
    if(! this.fieldV.$error) return [];
    const errors: string[] = [];
    for(const k of Object.keys(this.fieldV.$params)) {
      if(!this.fieldV[k]) {
        if(this.dispName) {
          errors.push(Messages.getValidationMessageWithParam(k, this.dispName));
        }
        else if(this.value) {
          errors.push(Messages.getValidationMessageWithValue(k, this.value));
        }
        else {
          errors.push(Messages.getValidationMessage(k));
        }
      }
    }
    return errors;
  }
}
