



































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { AchievePieChart, SkboxPlugins } from '@/components/chart'
import { ChecktestReportUserTypeDist } from '@/types'

@Component({
  components: {
    AchievePieChart,
  }
})
export default class TestReportTypeDist extends Vue{

  @Prop()
  report: ChecktestReportUserTypeDist;

  @Prop({'default': 'female'})
  gender: string;

  dispData: ChecktestReportUserTypeDist = {
    balance: 0,
    strategist: 0,
    leader: 0,
    consultant: 0,
    challenger: 0,
    analyst: 0,
    supporter: 0,
    allCount: 0,
    updateFlag: 0,
  }

  updateReport(report: ChecktestReportUserTypeDist) {
    this.$set(this.dispData, 'balance', this.getPercent(report.balance, report.allCount));
    this.$set(this.dispData, 'strategist', this.getPercent(report.strategist, report.allCount));
    this.$set(this.dispData, 'leader', this.getPercent(report.leader, report.allCount));
    this.$set(this.dispData, 'consultant', this.getPercent(report.consultant, report.allCount));
    this.$set(this.dispData, 'challenger', this.getPercent(report.challenger, report.allCount));
    this.$set(this.dispData, 'analyst', this.getPercent(report.analyst, report.allCount));
    this.$set(this.dispData, 'supporter', this.getPercent(report.supporter, report.allCount));
    Vue.$log.debug(this.dispData);
  }

  mounted() {
    this.updateReport(this.report);
  }

  pieChartPlugins = [new SkboxPlugins().drawDoughnutPercenteageText,];

  getPercent(value: number, allCount: number) {
    Vue.$log.debug(value);
    if(value) {
      return Math.round(value / allCount * 100);
    }
    return 0;
  }

  imagePath(userType: string) {
    return `/images/illustration/${userType}_${this.gender}.png`;
  }
}

