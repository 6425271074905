


































































































































































import { Watch } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";
import { ColumnOption } from "@/types/good_table_types";
import { ServiceCallHelper } from "@/mixins";
import { sortBy } from "lodash";
import Filters from "@/utils/filters";
import moment from "moment";
import CommentText from "@/components/action_report_search/CommentText.vue";
import { mapGetters } from "vuex";

import { ActionTimelineData } from "@/types";

import "vue-good-table/dist/vue-good-table.css";

import ActionReportSearchService from "@/services/action_report_search";

import GoodTableHeader from "@/mixins/good_table_mixin";

interface MixinInterface
  extends ServiceCallHelper,
    GoodTableHeader<ActionTimelineData> {}

@Component({
  components: {
    CommentText,
    "vue-good-table": require("vue-good-table").VueGoodTable,
  },
  filters: Filters,
  computed: mapGetters(["actionReportSearchResult"]),
})
export default class ActionReportSearchContent extends Mixins<MixinInterface>(
  ServiceCallHelper,
  GoodTableHeader
) {
  /** vue-good-table用カラム設定 */
  columns = [new ColumnOption("answerDate", "answerDate", { sortable: false })];

  /** vue-good-table用row data */
  rows = [];

  totalRow = 0;

  isLoading = false;

  selectedTabStatus: "memo" | "comment" = "memo";

  orderSortType = "2";

  dateSortType = "all";

  mounted() {
    this.rows = this.$store.getters.actionReportSearchResult.report;
    this.totalRow = this.$store.getters.actionReportSearchResult.report.length;
  }

  orderSort(reports: [], order: "asc" | "desc") {
    if (order === "desc") {
      return sortBy(reports, "answerDate").reverse();
    } else {
      return sortBy(reports, "answerDate");
    }
  }

  /**
   * 並び順変更
   */
  changeOrderSortType(e: Event) {
    if (!(e.target instanceof HTMLSelectElement)) {
      return;
    }
    if (e.target.value === "1") {
      this.rows = this.orderSort(
        this.$store.getters.actionReportSearchResult.report,
        "desc"
      );
    } else {
      this.rows = this.orderSort(
        this.$store.getters.actionReportSearchResult.report,
        "asc"
      );
    }
  }

  /**
   * 期間変更
   */
  changeDateSortType(e: Event) {
    if (!(e.target instanceof HTMLSelectElement)) {
      return;
    }
    let termFrom = "";
    if (e.target.value !== "all") {
      const termArray = e.target.value.split(" ");
      const unit: moment.unitOfTime.DurationConstructor = termArray[1] as moment.unitOfTime.DurationConstructor;
      termFrom = moment()
        .subtract(termArray[0], unit)
        .format("YYYY-MM-DD");
    }
    new ActionReportSearchService()
      .getActionReportSearch({
        termFrom: termFrom,
        termTo: e.target.value !== "all" ? moment().format("YYYY-MM-DD") : "",
        text: this.$store.getters.actionReportSearchConditions.text,
        st: this.selectedTabStatus,
        page: 1,
        perPage: 10,
        filters: {},
      })
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          this.$store.commit("setActionReportSearchConditions", {
            termFrom: termFrom,
            termTo: moment().format("YYYY-MM-DD"),
            text: this.$store.getters.actionReportSearchConditions.text,
            st: this.selectedTabStatus,
          });
          this.$store.commit(
            "setActionReportSearchResult",
            response.data.result
          );
        }
      });
  }

  /**
   * 検索タイプ変更
   */
  changeSearchType(searchType: "memo" | "comment") {
    this.orderSortType = "2";
    this.dateSortType = "all";
    new ActionReportSearchService()
      .getActionReportSearch({
        termFrom: "",
        termTo: "",
        text: this.$store.getters.actionReportSearchConditions.text,
        st: searchType,
        page: 1,
        perPage: 10,
        filters: {},
      })
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          this.$store.commit("setActionReportSearchConditions", {
            termFrom: "",
            termTo: "",
            text: this.$store.getters.actionReportSearchConditions.text,
            st: searchType,
          });
          this.$store.commit(
            "setActionReportSearchResult",
            response.data.result
          );
          this.selectedTabStatus = searchType;
        }
      });
  }

  /**
   * ページ番号 / 表示数の変更
   */
  loadItems() {
    new ActionReportSearchService()
      .getActionReportSearch({
        termFrom: "",
        termTo: "",
        text: this.$store.getters.actionReportSearchConditions.text,
        st: this.$store.getters.actionReportSearchConditions.st,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        filters: {},
      })
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          this.$store.commit("setActionReportSearchConditions", {
            termFrom: "",
            termTo: moment().format("YYYY-MM-DD"),
            text: this.$store.getters.actionReportSearchConditions.text,
            st: this.$store.getters.actionReportSearchConditions.st,
          });
          this.$store.commit(
            "setActionReportSearchResult",
            response.data.result
          );
        }
      });
  }

  /**
   * テーブルの情報を更新
   */
  @Watch("actionReportSearchResult")
  onSearchConditionChange() {
    this.rows = this.$store.getters.actionReportSearchResult.report;
    this.totalRow = this.$store.getters.actionReportSearchResult.recordCount;
  }
}
