







import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { Messages, Code } from '@/const';

import DepartmentService from '@/services/department_service'

import DepartmentEditForm from "@/components/settings/department/DepartmentEditForm.vue"

import { validationMixin } from 'vuelidate';
import { DepartmentSelect } from '@/mixins';
import { DepartmentEditValidator } from '@/validators';

import router from '@/router'

@Component( {
  mixins: [validationMixin],
  validations: {
    fields: new DepartmentEditValidator()
  },
} )

export default class DepartmentEditFormSubmit extends DepartmentSelect {
  saveData() {
    const parent = this.$parent as DepartmentEditForm;
    parent.showLoading();
    new DepartmentService().putDepartment(parent.departmentId, parent.fields).then((response) => { if(parent.commonApiErrorHandler(response)) {
      const data = response.data;
      Vue.$log.debug(data);
      // SUCCESS時は成功通知
      if(data.code == Code.SUCCESS) {
        this.$swal(Messages.UPDATE_SUCCESS, '', 'success').then(() => {
          router.push('/department');
        });
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      parent.hideLoading();
    });
  }

  /** 設定保存ボタン **/
  clickSaveButton() {
    const parent = this.$parent as DepartmentEditForm;
    // validation
    parent.$v.$touch();
    Vue.$log.debug(parent.fields);
    Vue.$log.debug(parent.currentShowMemberActionReportList);
    if (parent.$v.$invalid) {
      this.$swal(Messages.INVALID_INPUT, '', 'warning');
      return;
    }

    if (parent.currentShowMemberActionReportList == '1' && parent.fields.showMemberActionReportList == '0') {
      this.saveData();
      // this.$swal({
      //   title: "",
      //   text: "「メンバーへのアクションレポート一覧」を非表示にすると全社のタイムライン機能が使用できなくなりますが、本当によろしいですか？",
      //   confirmButtonColor: "#5EB0DE",
      //   cancelButtonColor: "#FC7067",
      //   showCancelButton: true,
      // }).then((isConfirm) => {
      //   if(isConfirm.isConfirmed) {
      //     this.saveData();
      //   }
      // });
    }
    else {
      this.saveData();
    }
  }
}

