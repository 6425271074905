














































































































import { Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { Messages, Code } from '@/const';
import { ChecktestReportData } from '@/types';
import { UserTypeDef } from '@/const/user_types';
import { OverlayLoader, StrongPoint, WeakPoint } from '@/components/parts'
import { ChecktestRadar } from '@/components/chart'
import TestReportUserType from '@/components/parts/TestReportUserType.vue'
import TestReportScoreDist from '@/components/test_report/TestReportScoreDist.vue'

import ServiceCallHelper from '@/mixins/service_call_helper'

import { default as ChecktestReportService, ChecktestReportResponse } from '@/services/checktest_report_service'

@Component({
  components: {
    OverlayLoader,
    ChecktestRadar,
    StrongPoint,
    WeakPoint,
    TestReportUserType,
    TestReportScoreDist,
  },
})
export default class MyTestReportContent extends ServiceCallHelper {

  /** ユーザーID */
  userId: string;

  // チェックテストなしフラグ
  checktestNotFound = false;

  // 選択日
  selectedDate = '';

  // 選択可能日一覧
  checktestDates: string[] = [];

  // 表示情報 
  report: ChecktestReportData;

  // レーダーチャートのCSS
  radarCss = "mx-auto w-100 checktestRadarChartBox checktestRadarChartBoxImage";

  /**
   * アクション設定への誘導を行うか
   */
  get dispActionNotify() {
    return !this.userId && this.$store.getters.userInfo.ctActionNotify == 1;
  }

  /**
   * mounted時、データの取得とCSSの設定
   */
  mounted() {
    this.userId = this.$route.params.userId;
    this.loadStats();

    // mobileの場合は背景を省略
    if(this.$device.mobile) {
      this.radarCss = "mx-auto w-100 checktestRadarChartBox";
    }
  }

  /**
   * 選択日付変更時、レポートデータ更新
   */
  changeTestDate() {
    this.loadStats();
  }

  /**
   * レポートデータの取得
   */
  loadStats() {
    this.showLoading();

    // service戻り値処理
    const thenFunc = (response: ChecktestReportResponse) => {
      if(this.commonApiErrorHandler(response)) {
        const data = response.data;
        Vue.$log.debug(data);
        // SUCCESSの場合は値を設定
        if(data.code == Code.SUCCESS) {
          this.checktestDates = data.result.checktestDates;
          this.selectedDate = data.result.report.checktestDate;
          this.report = data.result.report;
          this.pageLoaded = true;
          if(this.$refs.checktestRadar) {
            (this.$refs.checktestRadar as ChecktestRadar).updateChart(data.result.report);
          }
          if(this.$refs.testReportUserType) {
            (this.$refs.testReportUserType as TestReportUserType).updateUserType(data.result.report.userType as UserTypeDef);
          }
        }
        // チェックテスト未受験時
        else if(data.code == Code.CHECKTEST_NOT_FOUND) {
          // this.$swal(Messages.CHECKTEST_NOT_FOUND, 'チェックテストを受験してください。', 'warning');
          this.checktestNotFound = true;
        }
        // その他のケースは接続エラーに
        else {
          this.$swal(Messages.NETWORK_ERROR, '', 'error');
        }
      }
    }

    // userIdが設定されている場合はsearchActionStats呼び出し
    if(this.userId) {
      new ChecktestReportService().getReport(this.userId, this.selectedDate)
        .then(thenFunc)
        .finally(() => {
          this.hideLoading();
        });
    }
    // userIdが設定されていない場合はsearchMyActionStats呼び出し
    else {
      new ChecktestReportService().getMyReport(this.selectedDate)
        .then(thenFunc)
        .finally(() => {
          this.hideLoading();
        });
    }
  }

  clickActionSettings() {
    location.href='/action_list/settings';
  }

}

