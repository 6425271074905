import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types'
import { ChecktestReportRowData, ChecktestReportData, ChecktestReportUserTypeDist, UserSearchData } from '@/types'
import { SearchParams } from '@/types/good_table_types'

import RequestMixin from '@/mixins/request_mixin'

/** チェックテストレポート一覧 */
interface ChecktestReportList {
  recordCount: number;
  report: Array<ChecktestReportRowData>;
}
export class ChecktestReportListSkillboxResponse extends SkillBoxResponse<ChecktestReportList>{}
export class ChecktestReportListResponse extends AxiosResponse<ChecktestReportListSkillboxResponse>{}

/** チェックテストレポート詳細 */
interface ChecktestReportResult {
  report:  ChecktestReportData;
  checktestDates: Array<string>;
}
export class ChecktestReportSkillboxResponse extends SkillBoxResponse<ChecktestReportResult>{}
export class ChecktestReportResponse extends AxiosResponse<ChecktestReportSkillboxResponse>{}

/** 組織レポート詳細 */
export interface GroupChecktestReportResult {
  report: ChecktestReportData;
  userTypeCounts: ChecktestReportUserTypeDist;
  gender: string;
}
export class GroupChecktestReportSkillboxResponse extends SkillBoxResponse<GroupChecktestReportResult>{}
export class GroupChecktestReportResponse extends AxiosResponse<GroupChecktestReportSkillboxResponse>{}


/**
 * チェックテストservice
 */
export default class ChecktestReportService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * テストレポート一覧取得
   */
  public getReportList(param: SearchParams): Promise<ChecktestReportListResponse> {
    return this.getReq(`/api/v1/checktest/list`, param) as Promise<ChecktestReportListResponse>;
  }

  /**
   * レポート取得
   */
  public getReport(userId: string, date: string): Promise<ChecktestReportResponse> {
    const param = {'userId': userId, 'date': date}
    return this.getReq(`/api/v1/checktest/report/${userId}`, param) as Promise<ChecktestReportResponse>;
  }

  /**
   * MYレポート取得
   */
  public getMyReport(date: string): Promise<ChecktestReportResponse> {
    const param = {'date': date};
    return this.getReq(`/api/v1/checktest/my_report`, param) as Promise<ChecktestReportResponse>;
  }

  /**
   * 組織レポート取得 
   */
  public getGroupReport(params: UserSearchData): Promise<GroupChecktestReportResponse> {
    return this.getReq(`/api/v1/checktest/group_report`, {'filters': params}) as Promise<GroupChecktestReportResponse>;
  }
}

