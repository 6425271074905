












































































































































































































































import Vue from 'vue';
import { Component, Mixins } from 'vue-mixin-decorator';
import { OverlayLoader, ErrorMessage, UploadModal } from '@/components/parts';
import { Messages, Code } from '@/const';

import { CompanyActionService } from '@/services'
import { CompanyActionRecommendData, CompanyActionRecommendFlg } from '@/types';

import { validationMixin } from 'vuelidate'
import EditFormSubmit from './EditFormSubmit.vue'

import { PositionOccupationSelect, DepartmentSelect, UserSearchOptionsHelper } from '@/mixins'
import ServiceCallHelper from '@/mixins/service_call_helper'
import ActionListItems from '@/components/action_list/ActionListItems.vue'

import { CompanyActionRecommendValidator } from '@/validators'

/** 役職、職種、組織のセレクトボックス値設定 */
interface MixinInterface extends PositionOccupationSelect, DepartmentSelect, ServiceCallHelper, UserSearchOptionsHelper {}

@Component({
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
    UploadModal,
    PositionOccupationSelect,
    DepartmentSelect,
    ActionListItems,
    EditFormSubmit,
  },
  validations: {
    fields: new CompanyActionRecommendValidator()
  },
})

export default class CompanyEditForm extends Mixins<MixinInterface>(PositionOccupationSelect, DepartmentSelect, ServiceCallHelper) {

  /** form fields */
  fields: CompanyActionRecommendData = new CompanyActionRecommendData();

  /** 有効/無効フラグ */
  flags: CompanyActionRecommendFlg = new CompanyActionRecommendFlg();

  /** アクションチェック */
  checkedActions: any = {}

  /** 必須アクションチェック */
  checkedRequireActions: any = {}

  /** レスポンスデータ */
  data: any = {};

  /** 編集対象レコメンドID（） */
  recommendId = "";

  /** departmentモーダルでroot組織を表示するか */
  showRootDepartment = false;

  /** ability accordionの開閉 */
  checkedAbilities: Array<string> = [];

  /** ページ遷移時の設定 */
  settingActions: Array<number | string> = [];

  /**
   * mount時、レコメンド情報を取得して表示
   */
  mounted() {
    this.settingActions = [];
    this.loadItems();
  }

  loadItems() {
    if (this.$route.params.id) {
      this.recommendId = this.$route.params.id;
    } else {
      this.recommendId = "";
    }
    this.showLoading();
    let orgId = null;
    if(this.$route.query.o && Array.isArray(this.$route.query.o)) {
      orgId = this.$route.query.o[0];
    }
    else {
      orgId = this.$route.query.o;
    }

    new CompanyActionService().getComapnyActionRecommend(this.recommendId, orgId).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESSの場合は値を設定
      if(data.code == Code.SUCCESS) {
        Vue.$log.debug(data.result);
        this.data = data.result;
        this.fields = data.result.recommend;
        this.flags = data.result.flags;
        if(!this.recommendId && !this.fields.recommendName) {
          this.fields.recommendName = "新規";
        }
        // 空欄の設定
        if(!this.fields.departmentId) {
          this.fields.departmentId = "";
        }
        if(!this.fields.position) {
          this.fields.position = "";
        }
        if(!this.fields.occupation) {
          this.fields.occupation = "";
        }
        if(!this.fields.childOccupation) {
          this.fields.childOccupation = "";
        }
        if(!this.fields.hireYearBelow) {
          this.fields.hireYearBelow = "";
        }
        // チェックボックスの値反映
        const checkedIds = [];
        this.checkedRequireActions = {};
        for(const act of this.data.recommendRequireActions) {
          this.checkedRequireActions[act] = true;
          checkedIds.push(act);
        }
        this.checkedActions = {};
        for (const act of this.data.recommendActions) {
          this.checkedActions[act] = true;
          checkedIds.push(act);
        }
        this.settingActions = checkedIds;
        // アコーディオンの開閉
        for (const action of data.result.actions) {
          for (const act of action.actions) {
            if(checkedIds.indexOf(act.id) !== -1) {
              this.checkedAbilities.push(action.abilityName);
              break;
            }
          }
        }
      }
      // その他のケースは接続エラーに
      else {
        this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /**
   * accordionの開閉(true/false)を返す
   */
  acordionVisibility(ability: string) {
    if(this.checkedAbilities.indexOf(ability) !== -1) {
      return true;
    }
    return false;
  }

  /**
   * 必須チェックボックスクリック時の値入れ替え
   */
  changeRequireCheck(actionId: number) {
    delete this.checkedActions[actionId];
  }

  /**
   * 任意チェックボックスクリック時の値入れ替え
   */
  changeNoRequireCheck(actionId: number) {
    delete this.checkedRequireActions[actionId];
  }

  /**
   * 各セレクトボックスのdisabled確認
   */ 
  isDisabled(name: string) {
    Vue.$log.debug(name);
    Vue.$log.debug(this.flags);
    if(name == "department") {
      return this.flags.department == 0 || this.flags.department === false;
    }
    else if(name == "position") {
      return this.flags.position == 0 || this.flags.position === false;
    }
    else if(name == "occupation") {
      return this.flags.occupation == 0 || this.flags.occupation === false;
    }
    else if(name == "childOccupation") {
      return this.flags.childOccupation == 0 || this.flags.childOccupation === false;
    }
    else if(name == "hireYear") {
      return this.flags.hireYear == 0 || this.flags.hireYear === false;
    }
    return false;
  }

  /** レコメンド保存成功通知 */
  editSuccess(actions: number[], requireActions: number[]) {
    this.settingActions = [];
    this.settingActions.push(...actions, ...requireActions);
  }

  /** アクション削除 */
  clickActionDelete(actionId: number) {
    // API呼び出し
    this.$swal({
      title: "削除した場合、他のグループの選択肢からも完全削除されます。この登録アクションを削除しますか。",
      confirmButtonText: "削除する",
      cancelButtonText: "キャンセル",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        this.showLoading();
        new CompanyActionService().deleteAction(actionId).then((response) => {
          if(this.commonApiErrorHandler(response)) {
            const data = response.data;
            // SUCCESSの場合はメッセージを設定
            if(data.code == Code.SUCCESS) {
              this.$swal(Messages.DELETE_SUCCESS, '', 'success').then(() => {
                this.settingActions = [];
                this.loadItems();
              });
            }
            // 他のレコメンドで設定済みの場合はポップアップを表示
            else if(data.code == Code.RECOMMEND_ALREADY_SET) {
              this.$swal(data.message, '', 'warning');
            }
            // アクションが利用中の場合はポップアップを表示
            else if(data.code == Code.ACTION_IN_USE) {
              this.$swal(Messages.ACTION_IN_USE, '', 'warning');
            }
            // その他のケースはエラー通知
            else {
              this.$swal(Messages.NETWORK_ERROR, "", "error");
            }
          }
        }).finally(() => {
          this.hideLoading();
        });
      }
    });
    
  }
}
