export { LoginData, PwdResetData } from '@/types/auth/login_types';
export { UploadableService } from '@/types/service_types';
export { AxiosResponse, SkillBoxResponse } from '@/types/axios_types';
export { OccupationData } from '@/types/settings/position_occupation_types';
export { 
  CompanyEditData,
  CompanyActionRecommendData,
  CompanyActionRecommendFlg,
  CompanyLineWorksAuthData,
} from '@/types/settings/company_types';
export { ContactUsData } from '@/types/contact_us';

export {
    DepartmentSearchData, DepartmentAddData,
    DepartmentEditData, DepartmentSelectBoxData } from '@/types/settings/department_types';

export { UserSearchData, UserAddData, UserEditData, UserEditReadOnly } from '@/types/settings/user_types';
export type { UserProfileImageData } from '@/types/settings/user_types';

export { ChecktestInputData, ChecktestQuestionData } from '@/types/checktest_types';
export type {
    ChecktestAnswerObject, ChecktestReportRowData, ChecktestReportData, ChecktestReportScoreData,
    ChecktestReportUserTypeDist, UserTypeKeys } from '@/types/checktest_types';

export { ActionTeamboardCount } from '@/types/action_types';
export type {
    ActionListObject, ActionListData, ActionListResponseData,
    ActionReportListData, ActionListRowData, ActionListAbilityDispData,
    ActionAnswerResponseData, ActionAnswerDetailResponseRowData,
    ActionAnswerStatsData, ActionEncourageData, ActionFollowData, ActionTimelineData,
    ActionNoAnswer, WaitApproveUsers, NoAnswerUser, AlertInfo, ApproveAlertInfo,
    ActionshowHiddenCommentUsersData,
    CommentData, StampData, PostCommentData, DeleteCommentData, PostStampData, PostCommentStampData, UsedConsultantUserData } from '@/types/action_types';

export type {
    DashboardReport, DashboardDailyRates, DashboardScatterUser, DepartmentRate } from '@/types/dashboard_types';

export {
    SearchPageInfo } from '@/types/search_page_info';

export { ActionReportSearchConditionsData, ActionReportSearchResultData } from '@/types/action_report_search_types';

/*
 * フィールドエラー格納用
 */
export interface FieldErrors {
  [key: string]: string;
}

/** 
 * 日付 - 数値データ
 */
export interface DateValueData {
  date: string;
  value: number; 
}

/** 
 * アクション達成率平均データ
 */
export interface AvgDateValueData {
  actionId: number;
  actionLabel: string;
  actionText: string;
  value: number[]; 
}

/**
 * 日付検索データ
 */
export class DateSearchData {
  dateFrom = '';
  dateTo = '';

  constructor(dateFrom?: string, dateTo?: string) {
    if(dateFrom) {
      this.dateFrom = dateFrom;
    }
    if(dateTo) {
      this.dateTo = dateTo;
    }
  }
}

/**
 * 日付選択データ
 */
export interface VueCalendarDate {
  id: string;
  date: Date;
}


/**
 * 曜日データ
 */
export class DayOfWeek {
  sun = '';
  mon = '';
  tue = '';
  wed = '';
  thu = '';
  fri = '';
  sat = '';

  /**
   * 月水金のような文字列をobjectにセットする
   */
  constructor(str?: string) {
    if(!str)
      return;
    if(str.indexOf('日') > -1)
      this.sun = '1';
    if(str.indexOf('月') > -1)
      this.mon = '1';
    if(str.indexOf('火') > -1)
      this.tue = '1';
    if(str.indexOf('水') > -1)
      this.wed = '1';
    if(str.indexOf('木') > -1)
      this.thu = '1';
    if(str.indexOf('金') > -1)
      this.fri = '1';
    if(str.indexOf('土') > -1)
      this.sat = '1';
  }

  toString() {
    let str = '';
    if(this.sun)
      str += '日';
    if(this.mon)
      str += '月';
    if(this.tue)
      str += '火';
    if(this.wed)
      str += '水';
    if(this.thu)
      str += '木';
    if(this.fri)
      str += '金';
    if(this.sat)
      str += '土';
    return str;
  }
}

