











































import { Prop, Vue } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { DateSearchData } from '@/types';
import { ErrorMessage } from '@/components/parts'
import { TeamBoardSearchDateValidator } from '@/validators'

@Component({
  components: {
    ErrorMessage,
  },
  validations: {
    fields: new TeamBoardSearchDateValidator()
  }
})
export default class DateSearchModal extends Vue {

  @Prop({})
  dateSearchData: DateSearchData;

  @Prop({default: false})
  isTeamBoard: boolean;

  /** 入力フィールド */
  fields = new DateSearchData()

  created() {
    this.fields.dateFrom = this.dateSearchData.dateFrom;
    this.fields.dateTo = this.dateSearchData.dateTo;
  }

  /** 検索テキスト */
  searchText() {
    return `${this.fields.dateFrom}〜${this.fields.dateTo}`;
  }

  /** 検索ボタン */
  searchButton() {
    if(this.isTeamBoard) {
      // validation
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit('search', this.$data.fields);
    }
    this.$emit('search', this.$data.fields);
  }

  /** 閉じるイベント */
  closeModal() {
    Vue.$log.debug('closeModal');
  }

  /**
   * 検索条件のクリア
   */
  clearFields(): void {
    Vue.$log.debug('clear fields');
    this.fields = new DateSearchData();
    Vue.$log.debug(this.fields);
  }
}

