import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, SkillBoxResponse } from '@/types/axios_types'
import RequestMixin from '@/mixins/request_mixin'
import { UserInfo } from '@/types/user_info'

/** ログインAPI実行結果型指定 */
export interface LoginSkillBoxData {
  accessToken: string;
  userInfo: UserInfo;
}
export class LoginResponseData extends SkillBoxResponse<LoginSkillBoxData>{}
export class LoginResponse extends AxiosResponse<LoginResponseData>{}

export default class LoginService extends Mixins<RequestMixin>(RequestMixin) {
  /**
   * ログイン処理
   * @param loginId
   * @param loginPassword
   */
  public async login(loginId: string, loginPassword: string): Promise<LoginResponse> {
    const params = {
      'login': {
        'loginId': loginId,
        'loginPassword': loginPassword,
      }
    }
    return this.postReq('/api/auth/login', params) as Promise<LoginResponse>;
  }

  /**
   * パスワードリセット
   * @param loginPassword
   */  
  public async pwdReset(loginPassword: string, token: string): Promise<AxiosResponse> {
    const params = {
      'login': {
        'loginPassword': loginPassword,
        'token': token,
      }
    }
    return this.postReq('/api/auth/pwd_reset', params) as Promise<AxiosResponse>;
  }

  /**
   * パスワードリセットURL送信
   * @param loginId
   */  
  public async pwdResetSend(loginId: string): Promise<AxiosResponse> {
    const params = {
      'login': {
        'loginId': loginId,
      }
    }
    return this.postReq('/api/auth/pwd_reset_send', params) as Promise<AxiosResponse>;
  }
}