import ActionListSettings from '@/views/action_list/ActionListSettings.vue'
import ActionListSettingsComplete from '@/views/action_list/ActionListSettingsComplete.vue'
import ActionListApprove from '@/views/action_list/ActionListApprove.vue'
import ActionListApproveComplete from '@/views/action_list/ActionListApproveComplete.vue'
import ActionListManagerSettings from '@/views/action_list/ActionListManagerSettings.vue'
import ActionAnswer from '@/views/action_list/ActionAnswer.vue'
import ActionAnswerComplete from '@/views/action_list/ActionAnswerComplete.vue'
import ActionReportList from '@/views/action_report/ActionReportList.vue'
import MyActionReport from '@/views/action_report/MyActionReport.vue'
import ActionReport from '@/views/action_report/ActionReport.vue'
import ActionLink from '@/views/action_report/ActionLink.vue'
import ActionTimeline from '@/views/action_report/ActionTimeline.vue'
import ActionTeamBoard from '@/views/action_report/ActionTeamBoard.vue'
import ActionCommentConfirm from '@/views/action_report/ActionCommentConfirm.vue'

export default [
  {
    path: '/action_list/settings',
    name: 'ActionListSettings',
    component: ActionListSettings,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/action_list/settings/complete',
    name: 'ActionListSettingsComplete',
    component: ActionListSettingsComplete,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/action_list/approve/complete',
    name: 'ActionListApproveComplete',
    component: ActionListApproveComplete,
    meta: {
      requireLogin: true,
      isAdmin: true,
    },
  },
  {
    path: '/action_list/approve/:userId',
    name: 'ActionListApprove',
    component: ActionListApprove,
    meta: {
      requireLogin: true,
      isAdmin: true,
    },
  },
  {
    path: '/action_list/manager_settings/:userId',
    name: 'ActionListManagerSettings',
    component: ActionListManagerSettings,
    meta: {
      requireLogin: true,
    },
  },
  {
  path: '/action_report/:userId/:actionId',
    name: 'ActionLink',
    component: ActionLink,
    meta: {
      requireLogin: true,
    },
  },
  {
  path: '/action_report/:userId',
    name: 'ActionReport',
    component: ActionReport,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/action_report',
    name: 'ActionReportList',
    component: ActionReportList,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/my_action_report',
    name: 'MyActionReport',
    component: MyActionReport,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/action_timeline',
    name: 'ActionTimeline',
    component: ActionTimeline,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/action_team_board',
    name: 'ActionTeamBoard',
    component: ActionTeamBoard,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/action_answer/complete',
    name: 'ActionAnswerComplete',
    component: ActionAnswerComplete,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/action_answer',
    name: 'ActionAnswer',
    component: ActionAnswer,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/action_comment_confirm',
    name: 'ActionCommentConfirm',
    component: ActionCommentConfirm,
    meta: {
      requireLogin: true,
    },
  }
]
