export type UserTypeDef = 'analyst' | 'balance' | 'challenger' | 'consultant' | 'leader' | 'strategist' | 'supporter';

export class UserTypeJa {
  analyst = 'アナリストタイプ';
  balance = 'バランスタイプ';
  challenger = 'チャレンジャータイプ';
  consultant = 'コンサルタントタイプ';
  leader = 'リーダータイプ';
  strategist = 'ストラテジストタイプ';
  supporter = 'サポータータイプ';
}

export class UserTrend {
  analyst = 'アナリストタイプは、「考え抜く力」が他と比べて高いのが特徴です。具体的には課題発見力や計画力といった能力が高いため、組織において改善点を発見し、実現に向けた筋道を立てることが得意です。';

  balance = 'バランスタイプは、「前に踏み出す力」「考え抜く力」「チームで働く力」のすべてがバランスよく高いのが特徴です。大失敗はしないけれど大成功もしない、手堅い人が多いタイプと言うこともできます。';

  challenger = 'チャレンジャータイプは、「前に踏み出す力」が他と比べて高いのが特徴です。主体性が高い他、働きかけ力により周囲の人たちを巻き込んでいくため、組織としての行動でも起点となり活躍していきます。';

  consultant = 'コンサルタントタイプは、「考え抜く力」と「チームで働く力」の両方高いのが特徴です。課題を発見し計画を立てることに加え、その実行において組織のメンバーを納得させ一致団結させます。';

  leader = 'リーダータイプは、「前に踏み出す力」と「チームで働く力」の両方高いのが特徴です。チームの結束力を高めつつ、率先した行動でチーム全体を引っ張っていくことが得意なタイプと言えます。';

  strategist = 'ストラテジストタイプは、「前に踏み出す力」と「考え抜く力」の両方高いのが特徴です。自ら課題を発見し、計画を立て、その実現に向けて行動していく姿勢は、会社として非常に頼れる存在です。';

  supporter = 'サポータータイプは、「チームで働く力」が他と比べて高いのが特徴です。コミュニケーション能力や規律性などで組織として結束力を高める重要な存在であり、チームプレーにおいて大きな力を発揮します';
}

export class UserConscious {
  analyst = '会社として何かしらのアクションを行うためには、単にあなたが計画を立てて実現に向けた筋道を立てるだけでなく、周囲の人間へ働き掛けて協力を仰ぎ、組織として実行するところまでを自分の能力で促すことが大切です。そのためには働きかけ力や発信力などが有効ですので、あわせて鍛えていきましょう。';

  balance = '能力としてバランスが取れているのは良いことですが、自分自身が担当している業務により、重視される能力のバランスが変わってくることもあります。まずは自分が現在担当している業務において、どの能力要素が重視されているのかを考え、その能力を高めようと意識するようにしましょう。';

  challenger = 'やろうと声を上げてから、組織として実際のアクションへとつなげていくためには、しっかりとした計画力など前に踏み出す力以外の基礎力も徐々に求められてきます。そのため計画力と情況把握力には特に意識をして、「組織として前に踏み出す力」へとさらに磨きをかけていきましょう。';

  consultant = 'コンサルタントとは、立ち位置として相談役です。もちろん自分が主体となってチームを引っ張りアクションを実行していってもいいのですが、そちらは前に踏み出す力が高い人の方が得意であるため、まずは頼れる右腕として自分の能力を十分に伸ばしていくことが活躍する際の近道と言えます。';

  leader = 'すべての能力が満点な人はいない中で、お互いの弱みを補い合えるのがチームとして仕事をする利点であり、そのために一番の影響力を発揮するのがリーダーです。自分自身の弱みを他者の強みで補うことを意識すれば、個人としてもチームとしても、大きな活躍と成長を期待できます。';

  strategist = 'このタイプは、「我が道を行く」といった気質を持つ人が多いです。順調な時はそれでまったく問題ないのですが、時には視野が狭くなってしまうことも。傾聴力や情況把握力をあわせて高めていこうと意識することで、幅広い視野を持ち続け、より確実に活躍していくことができると言えます。';

  supporter = 'サポータータイプは、文字通り誰かをサポートする時に一番の力を発揮します。そのため、意識としては誰をサポートしたいのか、対象を明らかにすることが大切です。社内の人だけでなく、お客さんなど不特定多数の人が対象でも大丈夫です。その上で、サポートのために何ができるかをしっかり考えましょう。';
}

