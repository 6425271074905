





















































import { Vue, Prop } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";
import { Messages, Code } from "@/const";

import {
  default as UserService
} from "@/services/user_service";
import ActionListService from "@/services/action_list_service";
import ServiceCallHelper from "@/mixins/service_call_helper";
import { UserEditData } from "@/types";

import router from "@/router";

import { validationMixin } from "vuelidate";
import { UserEditValidator } from "@/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    fields: new UserEditValidator(),
  },
})
export default class UserDeleteButton extends  ServiceCallHelper {
  @Prop()
  userId: string;

  @Prop()
  myPage: boolean;

  @Prop()
  fileLabel: string;

  @Prop()
  fields: UserEditData;

  /**
   * ユーザーを削除するボタン
   */
  clickDeleteUser() {
    // myPageでは実行しない
    if (this.myPage) {
      return;
    }

    this.$swal({
      title: "ユーザーを削除します",
      html: '削除したユーザーデータはシステムから完全に削除されるため、<span style="color: red">二度と復元することができません。</span>本当によろしいですか？',
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        this.showLoading();
        new UserService()
          .deleteUser(this.userId)
          .then((response) => {
            if (this.commonApiErrorHandler(response)) {
              const data = response.data;
              Vue.$log.debug(data);
              // SUCCESS時は成功通知
              if (data.code == Code.SUCCESS) {
                this.$swal(Messages.DELETE_SUCCESS, "", "success").then(() => {
                  router.push("/user");
                });
              }
              // その他のケースは接続エラーに
              else {
                this.$swal(Messages.NETWORK_ERROR, "", "error");
              }
            }
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    });
  }

  /**
   * ユーザーを休止するボタン
   */
  clickSuspendUser(cancel: boolean) {
    // myPageでは実行しない
    if (this.myPage) {
      return;
    }

    let title = "ユーザーを休止します";
    if (cancel) {
      title = "ユーザー休止を解除します";
    }

    this.$swal({
      title: title,
      text: "よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        this.showLoading();
        new UserService()
          .suspendUser(this.userId, cancel)
          .then((response) => {
            if (this.commonApiErrorHandler(response)) {
              const data = response.data;
              Vue.$log.debug(data);
              // SUCCESS時は成功通知
              if (data.code == Code.SUCCESS) {
                this.$swal("処理に成功しました", "", "success").then(() => {
                  router.push("/user");
                });
              }
              // その他のケースは接続エラーに
              else {
                this.$swal(Messages.NETWORK_ERROR, "", "error");
              }
            }
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    });
  }

  /**
   * アクションリストのリセット
   */
  clickActionListReset() {
    if(!this.userId) {
      // userIdが設定されていない場合は表示対象外
      return;
    }
    this.$swal({
      title: "アクションリストをリセットします。",
      text: "よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        this.showLoading();
        new ActionListService().deleteActionList(this.userId).then((response) => {
          if (this.commonApiErrorHandler(response)) {
            const data = response.data;
            if (data.code == Code.SUCCESS) {
              this.fileLabel = "";
              this.fields.hasAction = 0;
              this.$swal(Messages.DELETE_SUCCESS, "", "success");
            } else if (data.code == Code.VALIDATION_ERROR) {
              this.$swal(data.message, "", "warning");
            } else {
              this.$swal(Messages.NETWORK_ERROR, "", "warning");
            }
          }
        })
        .finally(() => {
          this.hideLoading();
        });
      }
    });
  }
}
