



















































































































































































































import { Prop, Watch, Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import 'vue-good-table/dist/vue-good-table.css'
import { default as ActionAnswerService } from '@/services/action_answer_service'
import StampService from '@/services/stamp_service'
import { ColumnOption  } from '@/types/good_table_types'
import { ActionAnswerDetailResponseRowData, DateSearchData, CommentData, StampData, PostStampData } from '@/types'
import { Messages, Code } from '@/const';
import moment from 'moment';

import GoodTableHeader from '@/mixins/good_table_mixin'
import ActionReportServiceHelper from '@/mixins/action_report_service_helper'

import { OverlayLoader } from '@/components/parts'
import CommentUpdateModal from '@/components/action_report/CommentUpdateModal.vue'
import CommentListModal from '@/components/action_report/CommentListModal.vue'
import CommentText from '@/components/action_report/CommentText.vue'
import StampModal from '@/components/action_report/StampModal.vue'
import StampButton from '@/components/action_report/StampButton.vue'

interface MixinInterface extends ActionReportServiceHelper, GoodTableHeader<ActionAnswerDetailResponseRowData> {}

import '@/styles/tooltips.scss'

@Component({
  components: {
    OverlayLoader,
    CommentUpdateModal,
    CommentListModal,
    StampModal,
    StampButton,
    CommentText,
    'vue-good-table': require('vue-good-table').VueGoodTable,
  }
})

export default class ActionReportDetails extends Mixins<MixinInterface>(ActionReportServiceHelper, GoodTableHeader) { // GoodTableHeader<ActionAnswerDetailResponseRowData> {

  /** ユーザーID */
  @Prop({default: ''})
  userId: string;

  /** マイか否か */
  @Prop({default: true})
  isMyAction: boolean;

  /** ユーザー名 */
  @Prop()
  userName: string;

  /** aid query param */
  @Prop({default: null})
  aid: string | null;

  /** 日付検索データ */
  @Prop({default: null})
  dateSearchData: DateSearchData | null;

  /** アクションリストの直近の更新日 */
  @Prop()
  latestListChangeDate: string;

  /** アクションリスト変更通知メッセージ表示日 */
  @Prop()
  listChangeAnnotationDate: string;

  showHiddenCommentUsers = "hiddenComment";

  /** vue-good-table用カラム設定 */
  columns = [
    new ColumnOption('answerDate', 'answerDate', {sortable: false}),
  ]

  /** vue-good-table用row data */
  rows: ActionAnswerDetailResponseRowData[] = [];

  /** コメントモーダルのモード */
  commentMode = 'add';

  /** コメントモーダルの対象日付 */
  commentDate = '';

  /** コメントモーダルの初期表示テキスト */
  commentText = '';

  /** 回答スタンプ情報 */
  answerStamps: StampData[] = [];

  /** 選択Row index */
  selectedRowIdx = -1;

  /** 選択回答日 */
  selectAnswerDate = '';

  /** コメント一覧表示用データ */
  listComments: Array<CommentData> = [];

  /** 閲覧ユーザーのコメントが存在するかの判定 */
  inMyComment = false;

  /** ナイショ話を閲覧できるかの判定 */
  hasHiddenComment = false;

  memoComment: {memo: string; date: string} = {memo: '', date: ''};

  /** アクション回答日時 */
  answerTime = "";

  dailyAnswerId = "";

  /** 日付検索条件変更時にデータ更新 */
  @Watch('dateSearchData')
  onDateChanged() {
    Vue.$log.debug('change dateSearchData');
    this.updateParams({filters: this.dateSearchData, page: 1});
    this.loadItems();
  }

  @Watch("userName")
  onUserNameChange() {
    Vue.$log.debug("change userName");
    this.$emit("loaded", this.userName);
  }

  // getrows(totalRows: number, rows: ActionAnswerDetailResponseRowData[]) {
  //   this.totalRows = totalRows;
  //   this.rows = rows;
  //   this.pageLoaded = true;
  // }

  loadItems() {
    if(this.dateSearchData) {
      this.updateParams({filters: {
        'termFrom': this.dateSearchData.dateFrom,
        'termTo': this.dateSearchData.dateTo
      }});
    }
    this.$emit("loadItems", this.serverParams);
  }

  /** stampデータのclass出し分け */
  stampClass(stamp: StampData) {
    if(stamp.myStamp) {
      return 'btn  actionreport--stamp actionreport--stamp-active';
    }
    return 'btn  actionreport--stamp';
  }

  /**
   * スタンプ選択モーダル表示
   */
  clickShowStampModal(dailyAnswerId: string, date: string, rowIdx: number) {
    this.dailyAnswerId = dailyAnswerId;
    this.commentDate = date;
    this.selectedRowIdx = rowIdx;
    this.$modal.show('stamp-modal');
  }

  /**
   * モーダルのスタンプクリック
   */
  clickModalStamp(stamp: StampData) {
    Vue.$log.debug(stamp.stampId);
    // stamp登録
    const stampData = {
      'dailyAnswerId' : this.dailyAnswerId,
      'answerDate': this.commentDate,
      'stampId': stamp.stampId,
      'userId': this.userId,
    };
    Vue.$log.debug('stampData')
    Vue.$log.debug(stampData)
    this.$emit('close');
    new StampService().postStamp(stampData).then((response) => {
      if(this.commonApiErrorHandler(response)) {
        Vue.$log.debug(response);
        this.refreshStampDisp(this.selectedRowIdx, stampData);
      }
    }).finally(() => {
      this.hideLoading();
    });
  }

  /**
   * 表示スタンプクリック
   */
  clickStampImage(stamp: StampData, dailyAnswerId: string, answerDate: string, rowIdx: number) {
    if(!this.userId) {
      return
    }
    Vue.$log.debug(stamp);
    // API用パラメータ
    const postData = {
      dailyAnswerId: dailyAnswerId,
      answerDate: answerDate,
      stampId: stamp.stampId,
      userId: this.userId,
    };
    Vue.$log.debug(rowIdx);

    // 自スタンプがある場合は削除
    if(stamp.myStamp) {
      new StampService().deleteStamp(postData).then((response) => { if(this.commonApiErrorHandler(response)) {
        Vue.$log.debug('deleteStamp');
        this.refreshStampDisp(rowIdx, postData);
      } });
    }
    // 自スタンプがない場合は追加
    else {
      new StampService().postStamp(postData).then((response) => { if(this.commonApiErrorHandler(response)) {
        this.refreshStampDisp(rowIdx, postData);
      } });
    }
  }

  /**
   * コメントする / コメントを編集するボタン
   */
  clickCommentUpdateButton(date: string, comments: Array<CommentData>) {
    Vue.$log.debug(date);
    this.commentMode = 'add';
    this.commentText = '';
    this.commentDate = date;
    for(const comment of comments) {
      if(comment.myComment == 1) {
        this.commentMode = 'update';
        this.commentText = comment.text;
        break;
      }
    }
    this.$modal.show('comment-update-modal');
  }

  /** コメント投稿成功通知 */
  commentUpdateSuccess() {
    this.$modal.hide('comment-update-modal');
    this.loadItems();
  }

  /** コメント投稿成功通知 */
  commentSendSuccess(data: {date: string; userId: number; replyUserId: number}) {
    // アイテム更新
    this.$emit("loadItems", this.serverParams, true, data.date, data.userId);
    if(!data.replyUserId) {
      this.inMyComment = true;
    }
    // チームボードカウント更新
    this.updateTeamboardCount();
  }

   /** コメント削除成功通知 */
  commentDeleteSuccess(data: {date: string; userId: number; replyUserId: number}) {
    this.$emit("loadItems", this.serverParams, true, data.date, data.userId);
    if(!data.replyUserId) {
      this.inMyComment = false;
    }
    // チームボードカウント更新
    this.updateTeamboardCount();
  }

  /**
   * コメントボタン（コメント一覧表示）
   */
  clickCommentList(
    dailyAnswerId: string,
    userId: string,
    answerDate: string, 
    answerTime: string,
    comments: Array<CommentData>, 
    memo: string, 
    hiddenComment: string,
    stamps: StampData[],
    rowIdx: number
  ) {
    const commentListModal = this.$refs.commentListModal as CommentListModal;
    commentListModal.clearReplyUser();
    if(commentListModal.dailyAnswerId != dailyAnswerId) {
      commentListModal.clearInputComment();
      commentListModal.clearReplyInputComment();
    }
    if(comments && comments.length >= 0) {
      this.$modal.show('comment-list-modal');
      this.listComments = comments;
      this.selectAnswerDate = answerDate;
      this.memoComment = { memo: memo, date: answerDate };
      this.answerTime = answerTime;
      this.answerStamps = stamps;
      this.selectedRowIdx = rowIdx;
      if(hiddenComment) {
        this.hasHiddenComment = true;
      } else {
        this.hasHiddenComment = false;
      }
      const myComment = comments.filter(e => e.myComment == 1);
      if(myComment.length > 0) {
        this.inMyComment = true;
      } else {
        this.inMyComment = false;
      }
      commentListModal.refreshStampDisp(
        dailyAnswerId, 
        this.userId ? this.userId : userId, 
        answerDate);
    }
  }

  /** コメント一覧close通知 */
  closeCommentList() {
    this.$modal.hide('comment-list-modal');
  }

  /** スタンプモーダル処理成功通知 */
  successStampModal(rowIdx: number, stampData: PostStampData) {
    Vue.$log.debug('successStampModal');
    this.refreshStampDisp(rowIdx, stampData);
  }

  /** スタンプ表示情報の更新 */
  refreshStampDisp(rowIdx: number, stampData: PostStampData) {
    new StampService().getDailyStamp(stampData.userId, stampData.answerDate).then((response) => { if(this.commonApiErrorHandler(response)) {
      Vue.$log.debug('refreshStampDisp')
      Vue.$log.debug(response.data.result.stamp)
      this.rows[rowIdx].stamps = response.data.result.stamp;
      this.answerStamps = response.data.result.stamp;
    } } );
  }

  /** 回答削除ボタン表示/非表示 */
  showAnswerDelete(answerDate: string) {
    if(this.userId) {
      return false;
    }
    const dateDiff = moment().diff(moment(answerDate), 'days')
    Vue.$log.debug(`dateDiff=${dateDiff}`)
    if(dateDiff > 7) {
      return false;
    }
    return true;
  }

  /** 回答削除クリック */
  clickAnswerDelete(answerDate: string, answerId: number) {
    if(this.userId) {
      return;
    }
    this.$swal({
      title: "アクション回答を削除します",
      text: "削除された回答は復元することができません。よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        this.showLoading();
        new ActionAnswerService()
          .deleteAnswer(answerDate, answerId)
          .then((response) => {
            if (this.commonApiErrorHandler(response)) {
              const data = response.data;
              Vue.$log.debug(data);
              // SUCCESS時は成功通知
              if (data.code == Code.SUCCESS) {
                this.$swal(Messages.DELETE_SUCCESS, "", "success").then(() => {
                  this.loadItems();
                });
              }
              // アクションリスト回答期限切れ
              else if(data.code == Code.ACTION_ANSWER_EXPIRED) {
                this.$swal(Messages.ACTION_ANSWER_DELETE_EXPIRED, '', 'warning');
              }
              // その他のケースは接続エラーに
              else {
                this.$swal(Messages.NETWORK_ERROR, "", "error");
              }
            }
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    });
  }

  /**
   * コメントの編集するテキストとidを代入
   */
  commentUpdate(text: string, commentId: number, replyUserId = 0) {
    this.loadItems();
    commentData: for (const comment of this.listComments) {
      if(replyUserId && comment.replyComment) {
        for (const replyComment of comment.replyComment) {
          if (replyComment.commentId == commentId) {
            replyComment.text = text;
            replyComment.commentId = commentId;
            break commentData;
          }
        }
      } else if(comment.commentId == commentId) {
        comment.text = text;
        comment.commentId = commentId;
        break;
      }
    }
  }

  /** リンクをクリップボードにコピー */
  copyActionLink(aid: number, userId: number) {
    const text = `https://${window.location.host}/action_report/${userId}?aid=${aid}`;
    navigator.clipboard.writeText(text);
  }
}
