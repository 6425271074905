import Vue from 'vue'

export { SideMenuItems } from '@/const/menu'
import { String } from 'typescript-string-operations';

export class Messages {
  static readonly NETWORK_ERROR = '通信に失敗しました。時間を置いて再操作してください。';
  static readonly INVALID_AUTH = 'ログイン状態に異常があります。再度ログインしてください。';
  static readonly INVALID_USER_ROLE = '不正な操作が行われました。';
  static readonly INVALID_PARAM = '不正なパラメータが指定されました。';
  static readonly INPUT_VALIDATION_ERROR = '入力項目に誤りがあります。';
  static readonly DB_UPDATE_ERROR = 'DB更新に失敗しました。';
  static readonly DUPLICATE_DATA_ERROR = 'データが重複しています。';
  static readonly SERVER_ERROR = 'サーバーに障害が発生しています。時間を置いて再操作してください。';

  static readonly FILE_REQUIRE_ERROR = 'ファイルを選択してください';

  static readonly INVALID_INPUT = '入力項目に誤りがあります。';
  static readonly INVALID_DATE_TYPE = '日付を入力してください。';
  static readonly INVALID_SELECTION = 'いずれかを選択してください。';

  static readonly INSERT_SUCCESS = '登録処理が完了しました。';
  static readonly UPDATE_SUCCESS = '更新処理が完了しました。';
  static readonly DELETE_SUCCESS = '削除処理が完了しました。';
  static readonly NOTIFY_SUCCESS = '通知処理を受付けました。';

  static readonly CHECKTEST_NOT_FOUND = '表示するテストレポートはありません。';
  static readonly ACTION_LIST_NOT_FOUND = 'アクションリストが設定されていません。';
  static readonly ACTION_LIST_NOT_APPROVED = 'アクションリストが承認されていません。';

  static readonly USER_SUSPENSION = 'ログイン中のユーザーは休止しています。';

  static readonly LOGIN_FAILURE = 'IDかパスワードが間違っています。';

  static readonly ACTION_ANSWER_EXPIRED = '7日以上前のアクション回答は変更できません。';
  static readonly ACTION_ANSWER_DELETE_EXPIRED = '7日以上前のアクション回答は削除できません。';
  static readonly ACTION_ANSWER_EDIT_EXPIRED = '2日以上前のアクション回答は編集できません。';

  static readonly ACTION_IN_USE = "このアクションを利用中のユーザーがいます。現在利用中のアクションは削除できません。"

  static readonly PHRASE_GENERATION_LIMIT = "AI機能は週一回の利用を想定しており、生成上限を超えています。"

  /**
   * メッセージ取得
   */
  public static getValidationMessage(key: string): string { 
    // TODO 型定義作成
    // eslint-disable-next-line
    const messages: any = {
      'required': `必須項目です。`,
      'email': `メールアドレスを入力してください。`,
      'zeroOrOne': `いずれかを選択してください。`,
      'oneOrTwo': `いずれかを選択してください。`,
      'isDate': `日付を入力してください。`,
      'integer': `数値を入力してください。`,
      'sameAsPassword': `パスワードが一致しません。`,
      'minLength': `文字数が不足しています。`,
      'alLimitMaxValue': '1〜10の数値を入力してください。',
      'alLimitMinValue': '1〜10の数値を入力してください。',
      'isPhoneNumber': '電話番号は0000-0000-0000の形式で入力してください。',
      'setNotify': '連絡先を1つ以上入力してください。',
      "overOneMonth": "1ヶ月間以上の期間は表示できません。",
    };
    return messages[key];
  }

  /**
   * parameter付きメッセージ取得
   */
  public static getValidationMessageWithParam(key: string, value: string): string { 
    // TODO 型定義作成
    // eslint-disable-next-line
    const messages: any = {
      'required': `{0}を入力してください。`,
      'email': `メールアドレスを入力してください。`,
      'sameAsPassword': `パスワードが一致しません。`,
      'minLength': `文字数が不足しています。`,
      'maxLength': `上限文字数を越えています。`,
      'maxValue': `上限を越えています。`,
    };
    Vue.$log.debug('key');
    Vue.$log.debug(key);
    Vue.$log.debug(value);
    Vue.$log.debug(messages[key]);
    if(value) {
      return String.Format(messages[key], value);
    }
    else {
      return messages[key];
    }
  }

  /**
   * parameter付きメッセージ取得
   */
  public static getValidationMessageWithValue(key: string, value: string): string { 
    // TODO 型定義作成
    // eslint-disable-next-line
    const messages: any = {
      'required': `必須項目です。`,
      'email': `メールアドレスを入力してください。`,
      'sameAsPassword': `パスワードが一致しません。`,
      'minLength': `下限{0}文字を越えています。。`,
      'maxLength': `上限{0}文字を越えています。`,
      'maxValue': `{0}以下の値を入力してください。`,
    };
    if(value) {
      return String.Format(messages[key], value);
    }
    else {
      return messages[key];
    }
  }
}

export class Code {

  /** ユーザー権限設定 */
  static readonly USER_ROLE_SUPER = 1;
  static readonly USER_ROLE_MENTOR = 2;
  static readonly USER_ROLE_MEMBER = 3;

  /** API成功ステータス */
  static readonly SUCCESS = 'SUCCESS';

  /** 5xxエラー */
  static readonly SERVER_ERROR = 'SERVER_ERROR';

  /** バリデーションエラー */
  static readonly VALIDATION_ERROR = 'VALIDATION_ERROR';

  /** レコードが存在しない */
  static readonly RECORD_NOT_FOUND = 'RECORD_NOT_FOUND';

  /** ログインエラー */
  static readonly LOGIN_FAILURE = 'LOGIN_FAILURE';

  /** チェックテストが存在しない（アクション等の利用可否判定） */
  static readonly CHECKTEST_NOT_FOUND = 'CHECKTEST_NOT_FOUND';

  /** 当日チェックテスト回答済み */
  static readonly CHECKTEST_ALREADY_EXIST = 'CHECKTEST_ALREADY_EXIST';
  
  /** アクションリストが申請中でない */
  static readonly ACTION_LIST_NOT_REQUESTED = 'ACTION_LIST_NOT_REQUESTED';

  /** アクションリストが設定されていない */
  static readonly ACTION_LIST_NOT_FOUND = 'ACTION_LIST_NOT_FOUND';

  /** アクションリストが承認されていない */
  static readonly ACTION_LIST_NOT_APPROVED = 'ACTION_LIST_NOT_APPROVED';

  /** ログイン状態不正 */
  static readonly INVALID_AUTH = 'INVALID_AUTH';

  /** 指定アカウントが存在しない */
  static readonly ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND';

  /** 期限切れのトークン */
  static readonly SIGNATURE_EXPIRED = 'SIGNATURE_EXPIRED';

  /** トークン不正 */
  static readonly BAD_SIGNATURE = 'BAD_SIGNATURE';

  /** 権限不足 */
  static readonly INVALID_USER_ROLE = 'INVALID_USER_ROLE';

  /** ユーザー休止中 */
  static readonly USER_SUSPENSION = 'USER_SUSPENSION';

  /** アクション回答期限切れ */
  static readonly ACTION_ANSWER_EXPIRED = 'ACTION_ANSWER_EXPIRED';
  static readonly ACTION_ANSWER_EDIT_EXPIRED = 'ACTION_ANSWER_EDIT_EXPIRED';

  /** アクションレコメンドで設定済み */
  static readonly RECOMMEND_ALREADY_SET = 'RECOMMEND_ALREADY_SET';

  /** アクション利用中 */
  static readonly ACTION_IN_USE = 'ACTION_IN_USE';

  /** 褒めるAIコンサルタントコメント生成上限 */
  static readonly PHRASE_GENERATION_LIMIT = 'PHRASE_GENERATION_LIMIT';
}