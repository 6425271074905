






import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LogoImage extends Vue {
  imagePath() {
    const domain = process.env.VUE_APP_IMAGE_BASE_URL;
    return `${domain}/images/logo/logo.png`;
  }
}
