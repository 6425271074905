

















































































































































































































import { Prop, Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import 'vue-good-table/dist/vue-good-table.css'
import { default as ActionAnswerService } from '@/services/action_answer_service'
import StampService from '@/services/stamp_service'
import { ColumnOption  } from '@/types/good_table_types'
import { ActionAnswerDetailResponseRowData, CommentData, StampData, PostStampData } from '@/types'

import Filters from '@/utils/filters'

import GoodTableHeader from '@/mixins/good_table_mixin'
import ActionReportServiceHelper from '@/mixins/action_report_service_helper'

import { OverlayLoader } from '@/components/parts'
import StampModal from '@/components/action_report/StampModal.vue'
import StampButton from '@/components/action_report/StampButton.vue'

interface MixinInterface extends ActionReportServiceHelper, GoodTableHeader<ActionAnswerDetailResponseRowData> {}

import '@/styles/tooltips.scss'
import CommentText from '@/components/action_report/CommentText.vue';
import CommentConfirmCommentListModal from '@/components/action_report/CommentConfirmCommentListModal.vue';

@Component({
  components: {
    OverlayLoader,
    CommentText,
    CommentConfirmCommentListModal,
    ActionAnswerService,
    StampButton,
    StampModal,
    'vue-good-table': require('vue-good-table').VueGoodTable,
  },
  filters: Filters,
})
export default class CommentConfirmDetails extends Mixins<MixinInterface>(ActionReportServiceHelper, GoodTableHeader) { // GoodTableHeader<ActionAnswerDetailResponseRowData> {
  /** マイか否か */
  @Prop({default: true})
  isMyAction: boolean;

  /** aid query param */
  @Prop({default: null})
  aid: string | null;

  @Prop({default: false})
  isCommentConfirm: boolean;

  /** userIdに紐づくユーザーの各種情報 */
  @Prop({default: {}})
  userInfo: any;

  /** vue-good-table用カラム設定 */
  columns = [
    new ColumnOption('answerDate', 'answerDate', {sortable: false}),
  ]

  /** ユーザーID */
  userId = "";

  /** vue-good-table用row data */
  rows: ActionAnswerDetailResponseRowData[] = [];

  /** アクションリストの直近の更新日 */
  latestListChangeDate = '';

  /** アクションリスト変更通知メッセージ表示日 */
  listChangeAnnotationDate = '';

  /** コメントモーダルのモード */
  commentMode = 'add';

  /** コメントモーダルの対象日付 */
  commentDate = '';

  /** コメントモーダルの初期表示テキスト */
  commentText = '';

  /** 選択Row index */
  selectedRowIdx = -1;

  /** 選択回答日 */
  selectAnswerDate = '';

  /** コメント一覧表示用データ */
  listComments: Array<CommentData> = [];

  memoComment: {memo: string; date: string} = {memo: '', date: ''};

  dailyAnswerId = "";

  mounted(){
    this.loadItems();
  }

  /**
   * 一覧の取得
   */
  loadItems() {
    this.isLoading = true;
    this.$emit("loadItems");
  }

  getRow(totalRows: number, rows: ActionAnswerDetailResponseRowData[]) {
    this.totalRows = totalRows;
    if(this.totalRows > 0) {
      this.rows = rows;
    } else {
      this.rows = [];
    }
    this.pageLoaded = true;
  }

  /** stampデータのclass出し分け */
  stampClass(stamp: StampData) {
    if(stamp.myStamp) {
      return 'btn  actionreport--stamp actionreport--stamp-active';
    }
    return 'btn  actionreport--stamp';
  }

  /**
   * スタンプ選択モーダル表示
   */
  clickShowStampModal(dailyAnswerId: string, userId: string, date: string, rowIdx: number) {
    this.dailyAnswerId = dailyAnswerId;
    this.commentDate = date;
    this.userId = userId;
    this.selectedRowIdx = rowIdx;
    this.$modal.show('stamp-modal');
  }

  /**
   * モーダルのスタンプクリック
   */
  clickModalStamp(stamp: StampData) {
    Vue.$log.debug(stamp.stampId);
    // stamp登録
    const stampData = {
      'dailyAnswerId' : this.dailyAnswerId,
      'answerDate': this.commentDate,
      'stampId': stamp.stampId,
      'userId': this.userId,
    };
    Vue.$log.debug('stampData')
    Vue.$log.debug(stampData)
    this.$emit('close');
    new StampService().postStamp(stampData).then((response) => {
      if(this.commonApiErrorHandler(response)) {
        Vue.$log.debug(response);
        this.refreshStampDisp(this.selectedRowIdx, stampData);
      }
    }).finally(() => {
      this.hideLoading();
    });
  }

  /**
   * 表示スタンプクリック
   */
  clickStampImage(stamp: StampData, userId: string, dailyAnswerId: string, answerDate: string, rowIdx: number) {
    if(!userId) {
      return
    }
    Vue.$log.debug(stamp);
    // API用パラメータ
    const postData = {
      dailyAnswerId: dailyAnswerId,
      answerDate: answerDate,
      stampId: stamp.stampId,
      userId: userId,
    };
    Vue.$log.debug(rowIdx);

    // 自スタンプがある場合は削除
    if(stamp.myStamp) {
      new StampService().deleteStamp(postData).then((response) => { if(this.commonApiErrorHandler(response)) {
        Vue.$log.debug('deleteStamp');
        this.refreshStampDisp(rowIdx, postData);
      } });
    }
    // 自スタンプがない場合は追加
    else {
      new StampService().postStamp(postData).then((response) => { if(this.commonApiErrorHandler(response)) {
        this.refreshStampDisp(rowIdx, postData);
      } });
    }
  }

  /** スタンプモーダル処理成功通知 */
  successStampModal(rowIdx: number, stampData: PostStampData) {
    Vue.$log.debug('successStampModal');
    this.refreshStampDisp(rowIdx, stampData);
  }

  /** スタンプ表示情報の更新 */
  refreshStampDisp(rowIdx: number, stampData: PostStampData) {
    new StampService().getDailyStamp(stampData.userId, stampData.answerDate).then((response) => { if(this.commonApiErrorHandler(response)) {
      Vue.$log.debug('refreshStampDisp')
      Vue.$log.debug(response.data.result.stamp)
      this.rows[rowIdx].stamps = response.data.result.stamp;
    } } );
  }

  /**
   * コメントする / コメントを編集するボタン
   */
  clickCommentUpdateButton(date: string, comments: Array<CommentData>) {
    Vue.$log.debug(date);
    this.commentMode = 'add';
    this.commentText = '';
    this.commentDate = date;
    for(const comment of comments) {
      if(comment.myComment == 1) {
        this.commentMode = 'update';
        this.commentText = comment.text;
        break;
      }
    }
    this.$modal.show('comment-update-modal');
  }

  /** コメント削除成功通知 */
  // commentDeleteSuccess(data: {date: string; userId: number}) {
  //   this.loadItems();
  // }

  /** コメント投稿成功通知 */
  // commentUpdateSuccess() {
  //   this.$modal.hide('comment-update-modal');
  //   this.loadItems();
  // }

  /** コメント投稿成功通知 */
  // commentSendSuccess(data: {date: string; userId: number}) {
  //   // アイテム更新
  //   this.loadItems();
  // }

  /**
   * コメントボタン（コメント一覧表示）
   */
  clickCommentList(dailyAnswerId: string, answerDate: string, comments: Array<CommentData>, memo: string) {
    if(comments && comments.length >= 0) {
      this.$modal.show('comment-list-modal');
      this.listComments = comments;
      this.selectAnswerDate = answerDate;
      this.memoComment = { memo: memo, date: answerDate };
      const commentConfirmCommentList = this.$refs.commentConfirmCommentListModal as CommentConfirmCommentListModal;
      commentConfirmCommentList.refreshStampDisp(dailyAnswerId, this.userId, answerDate);
    }
  }

  /** コメント一覧close通知 */
  closeCommentList() {
    this.$modal.hide('comment-list-modal');
  }

  /**
   * コメントの編集するテキストとidを代入
   */
  commentUpdate(text: string, commentId: number) {
    this.loadItems();
    for(const comment of this.listComments) {
      if(comment.myComment == 1) {
        comment.text = text;
        comment.commentId = commentId;
        break;
      }
    }
  }

  commentConfirmSuccess() {
    this.loadItems();
    this.closeCommentList();
  }

  /** リンクをクリップボードにコピー */
  copyActionLink(aid: number, userId: number) {
    const text = `https://${window.location.host}/action_report/${userId}?aid=${aid}`;
    navigator.clipboard.writeText(text);
  }
}
