import { frequency } from '@/validators/utils'
import { required, integer, maxValue, minValue } from 'vuelidate/lib/validators'

/**
 * 組織登録Validator
 */
export class DepartmentAddValidator {
  // 必須設定
  departmentName = { required };
  parentDepartmentId = { required, integer };
  alLimitBelow = { required, integer, alLimitMaxValue: maxValue(10), alLimitMinValue: minValue(1) };
  alLimitAbove = { required, integer, alLimitMaxValue: maxValue(10), alLimitMinValue: minValue(1) };
  actionAnswerNotify = { required, integer };

  // 配信設定
  alCycleFreq = { frequency };
  alDayOfWeeks = {};
  alMonthday = {};
  alSendTime = {};
  arCycleFreq = { frequency };
  arDayOfWeeks = {};
  arMonthday = {};
  arSendTime = {};
  checkCycleFreq = { frequency };
  checkDayOfWeeks = {};
  checkMonthday = {};
  checkSendTime = {};
}

/**
 * 組織更新Validator
 */
export class DepartmentEditValidator {
  // 必須設定
  departmentName = { required };
  parentDepartmentId = { required, integer };
  alLimitBelow = { required, integer, alLimitMaxValue: maxValue(10), alLimitMinValue: minValue(1) };
  alLimitAbove = { required, integer, alLimitMaxValue: maxValue(10), alLimitMinValue: minValue(1) };
  actionAnswerNotify = { required, integer };
  memoExample = { required };
  memoDescription = { required };
  memoInitialText = { };
  showMemberActionReportList = { required };

  alSettingEndDate = {};

  // 配信設定
  alCycleFreq = { frequency };
  alDayOfWeeks = {};
  alMonthday = {};
  alSendTime = {};
  arCycleFreq = { frequency };
  arDayOfWeeks = {};
  arMonthday = {};
  arSendTime = {};
  checkCycleFreq = { frequency };
  checkDayOfWeeks = {};
  checkMonthday = {};
  checkSendTime = {};
}

