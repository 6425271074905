



















import { Prop, Vue } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { StampData } from '@/types';

@Component
export default class StampButton extends Vue {
  // SP用のユーザ名ツールチップ表示
  isShowUserName = false;

  @Prop()
  stamp: StampData;

  @Prop()
  date: string;

  @Prop()
  rowIdx: number;

  @Prop({default: -1})
  stampCount: number;

  @Prop({default: 'default'})
  size: string;

  stampClass = 'stamp-image';

  /**
   * authorsの文字列生成
   */
  authorText() {
    if(this.stamp && this.stamp.authors) {
      return this.stamp.authors.join(' / ')
    }
    else {
      return '';
    }
  }

  mounted() {
    // ミニサイズ
    if(this.size == 'mini') {
      this.stampClass = 'stamp-image mini'
    }
  }

  clickStamp() {
    this.$emit('click', this.stamp, this.date, this.rowIdx);
  }

  touchHoldHandler() {
    if(this.$device.mobile) {
      this.isShowUserName = !this.isShowUserName;
    }
  }

  stampUrl(path: string) {
    return `${process.env.VUE_APP_IMAGE_BASE_URL}/stamps/${path}`
  }
}

