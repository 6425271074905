

























































import { Prop, Watch, Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Code, Messages } from '@/const';

import ServiceCallHelper from '@/mixins/service_call_helper'
import ActionCommentService from '@/services/action_comment_service'
import { OverlayLoader, ErrorMessage } from '@/components/parts'

import { ActionCommentValidator } from '@/validators'
import { PostCommentData } from '@/types'

@Component( {
  components: {
    OverlayLoader,
    ErrorMessage,
  },
  validations: {
    fields: new ActionCommentValidator()
  },
} )
export default class CommentUpdateModal extends ServiceCallHelper {

  /** コメント投稿対象者のユーザーID */
  @Prop()
  userId: string;

  /** add or update */
  @Prop()
  commentMode: string;

  /** 投稿対象のアクション日付 */
  @Prop()
  date: string;

  /** 編集時のテキスト */
  @Prop()
  orgText: string;

  /** コメント投稿対象者のユーザーID */
  @Prop()
  commentId: number;

  @Prop()
  dailyAnswerId: string;

  @Prop()
  replyUserId: number;

  /** 投稿データ */
  fields: PostCommentData = {
    dailyAnswerId: "",
    text: '',
  };

  /** テキストエリアのデフォルトの高さ */
  defaultTextareaHeight = 180;

  /** テキストエリアの高さ */
  textareaHeight: number = this.defaultTextareaHeight;

  /** userIdの更新検知 */
  @Watch('userId')
  onUserIdChanged() {
    Vue.$log.debug('change user id');
    this.fields.text = "";
  }

  /** 日付の更新検知 */
  @Watch('date')
  onDateChanged() {
    Vue.$log.debug('change date');
    this.fields.text = "";
  }

  /** 元データテキストの更新検知 */
  @Watch('orgText')
  onOrgTextChanged() {
    Vue.$log.debug('change orgText');
    this.fields.text = this.orgText;
  }
  
  /** 入力時、テキストエリアの高さを動的に変更 */
  inputTextarea(e: Event) {
    this.textareaHeight = (e.target as HTMLInputElement).scrollHeight
  }

  /** モーダルを開いたタイミングでテキストエリアの高さを動的に変更 */
  modalOpened() {
    const target: any = this.$refs.textarea;
    this.textareaHeight = target.scrollHeight;
  }

  /** モーダルを閉じたらテキストエリアの高さをデフォルトに */
  modalClosed() {
    this.textareaHeight = this.defaultTextareaHeight;
  }

  /** コメント投稿 */
  clickCommentButton() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      Vue.$log.debug(this.$v);
      // this.$swal(Messages.INVALID_INPUT, '', 'warning');
      return;
    }

    // 
    // this.fields.userId = this.userId;
    // this.fields.answerDate = this.date;
    this.fields.dailyAnswerId = this.dailyAnswerId;
    this.fields.commentId = this.commentId;
    if(this.replyUserId) {
      this.fields.replyUserId = this.replyUserId;
    }

    this.showLoading();
    new ActionCommentService().postComment(this.fields).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESSの場合は成功通知
      if(data.code == Code.SUCCESS) {
        Vue.$log.debug(data.result.action);
        if(this.$v.fields.text) {
          this.$v.fields.text.$reset();
        }
        this.$emit('success', this.fields.text, this.fields.commentId, this.fields.replyUserId);
      }
      // その他のケースはエラー通知
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.textareaHeight = this.defaultTextareaHeight;
      delete this.fields.replyUserId;
      this.hideLoading();
    });
  }

}

