import { NotifyService } from '@/services/notify/notify_service'
import { AxiosResponse } from '@/types';
import { SearchParams } from '@/types/good_table_types'

export default class TestNotifyService extends NotifyService {

  /**
   * テストの受験依頼（全ユーザー）
   *
   * @aparam params
   */
  public notifyAll(param: SearchParams): Promise<AxiosResponse> {
    return this.getReq('/api/v1/checktest_notify_all', param) as Promise<AxiosResponse>;
  }

  /**
   * テストの受験依頼（指定ユーザー）
   */
  public notifyTarget(userIds: number[]): Promise<AxiosResponse> {
    return this.getReq('/api/v1/checktest_notify', {'userIds': userIds}) as Promise<AxiosResponse>;
  }

}

