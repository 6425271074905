














import moment from "moment";
import { Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator";
import { OverlayLoader } from '@/components/parts';
import { Messages, Code } from '@/const';
import ActionAnswerForm from '@/components/action_list/ActionAnswerForm.vue';
import router from '@/router'

import ServiceCallHelper from '@/mixins/service_call_helper'

import { ActionAnswerService } from '@/services'

@Component ({
  components: {
    OverlayLoader,
  },
})
export default class ActionListApproveForm extends ServiceCallHelper {

  /** 回答ボタン **/
  clickSaveButton() {
    Vue.$log.debug('clickSaveButton');
    const parent = this.$parent as ActionAnswerForm;
    // 日付チェック
    if(!parent.date) {
      this.$swal('日付が選択されていません。', '', 'warning');
      return;
    }
    // 未来日チェック
    if(moment(parent.date).isAfter()) {
      this.$swal('未来日は指定できません。', '', 'warning');
      return;
    }

    // ナイショ話表示ユーザーID取得
    const hiddenCommentUserIds = parent.hiddenCommentUsers().map((user) => user.userId);
    Vue.$log.debug(hiddenCommentUserIds);

    // 全件選択されているか確認
    Vue.$log.debug(parent.fields);
    let answerCount = 0;
    for(const key in parent.fields) {
      if(parent.fields[key]) {
        answerCount += 1;
      }
    }
    Vue.$log.debug(parent.actionCount);
    Vue.$log.debug(answerCount);
    if(answerCount < parent.actionCount) {
      this.$swal('未チェックの項目があります。', '', 'warning');
      return;
    }

    // API呼び出し
    this.showLoading();
    new ActionAnswerService().postAnswer(
      parent.date, parent.fields, hiddenCommentUserIds,
    ).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      Vue.$log.debug(data);
      // SUCCESSの場合はcomplete画面へ
      if(data.code == Code.SUCCESS) {
        if(data.result.expireDateDiff) {
          router.push(`/action_answer/complete?r=${data.result.expireDateDiff}`);
        }
        else {
          router.push("/action_answer/complete");
        }
        Vue.$log.debug(data.result.action);
      }
      // アクションリスト回答期限切れ
      else if(data.code == Code.ACTION_ANSWER_EXPIRED) {
        this.$swal(Messages.ACTION_ANSWER_EXPIRED, '', 'warning');
      }
      // アクションリスト回答期限切れ
      else if(data.code == Code.ACTION_ANSWER_EDIT_EXPIRED) {
        this.$swal(Messages.ACTION_ANSWER_EDIT_EXPIRED, '', 'warning');
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }
}
