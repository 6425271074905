










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
import UserTable from '@/components/settings/user/UserTable.vue';

@Component({
  components: {
    Header,
    UserTable,
    Footer,
  },
})
export default class UserList extends Vue {}
