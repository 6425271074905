











import { Vue, Prop } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Messages, Code } from '@/const';

import DepartmentService from '@/services/department_service';
import ServiceCallHelper from '@/mixins/service_call_helper';

import { validationMixin } from 'vuelidate';
import { DepartmentSelect } from '@/mixins';

import router from '@/router';

interface MixinInterface extends ServiceCallHelper, DepartmentSelect {}

@Component( {
  mixins: [validationMixin],
} )

export default class DepartmentEditForm extends Mixins<MixinInterface>(ServiceCallHelper, DepartmentSelect) {
  @Prop()
  departmentId: number

  /** 組織削除ボタン **/
  clickDeleteButton() {
    Vue.$log.debug(this.departmentId);
    this.$swal({
      title: '組織を削除します',
      text: '削除された組織は復元することができません。よろしいですか？',
      icon: 'warning',
      confirmButtonText: ' 送信 ',
      cancelButtonText: 'キャンセル',
      showCancelButton: true,
      focusConfirm: false,
    }).then( isConfirm => {
      if(isConfirm.isConfirmed) {
        this.showLoading();
        new DepartmentService().deleteDepartment(this.departmentId).then((response) => { if(this.commonApiErrorHandler(response, false)) {
          const data = response.data;
          Vue.$log.debug(data);
          // SUCCESS時は成功通知
          if(data.code == Code.SUCCESS) {
            this.$swal(Messages.DELETE_SUCCESS, '', 'success').then(() => {
              router.push('/department')
            });
          }
          // validation error時
          else if(data.code == Code.VALIDATION_ERROR) {
            this.$swal(data.message, '', 'error');
          }
          // その他のケースは接続エラーに
          else {
            this.$swal(Messages.NETWORK_ERROR, '', 'error');
          }
        } }).finally(() => {
          this.hideLoading();
        });
      }
    }); 
  }
}

