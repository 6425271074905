








import { Component, Prop, Vue } from 'vue-property-decorator';
import DepartmentService from '@/services/department_service'
import { DepartmentSelectBoxData } from '@/types/settings/department_types';

@Component
export default class DepartmentSelectBox extends Vue {

  /** 上位組織セレクトボックス */
  departments = [new DepartmentSelectBoxData()];

  departmentId = '';

  @Prop()
  showRoot: boolean;

  mounted() {
    new DepartmentService().getDepartmentIdNames(this.showRoot).then((response) => {
      const data = response.data;
      if(data && data.result) {
        this.departments = data.result.departments;
      }
    });
  }

}
