









import { Component, Vue } from 'vue-property-decorator';

@Component
export default class OverlayLoader extends Vue {
  isLoading = false;
}
