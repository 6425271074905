export default {

  /**
   * 0.0〜1.0の値をパーセンテージ表記に
   */
  toPercent(rate: number | string) {
    if(typeof rate === 'string') {
      return rate;
    }
    else if(rate === undefined || isNaN(rate)) {
      return '';
    }
    return `${Math.round((rate as number) * 100)}%`
  },

  /**
   * 0.0〜1.0の値をパーセンテージ表記に（末尾の%はなし）
   */
  toPercentNoP(rate: number) {
    if(rate === undefined || isNaN(rate)) {
      return '';
    }
    return `${Math.round(rate * 100)}`
  },

  /** undefinedの場合は0を返す */
  nullToZero(v: number | string) {
    if(v === undefined) {
      return 0;
    }
    return v;
  },

  /**
   * toFixed(1)
   */
  toFixed1(n: number) {
    if(n === undefined) {
      return '';
    }
    if(Number.isInteger(n)) {
      return n;
    }
    return n.toFixed(1);
  },

  /**
   * profile imageのURL取得
   */
  profileImageUrl(imagePath: string) {
    const domain = process.env.VUE_APP_IMAGE_BASE_URL;
    if(!imagePath) {
      return `${domain}/profile/profile_400c.png`;
    }
    else {
      return `${domain}/profile/${imagePath}`;
    }
  }
}
