/**
 * お問い合わせフォーム
 */
export class ContactUsData {
  userName = '';
  loginId = '';
  departmentId = '';
  phoneNumber = '';
  text = '';
}
