










import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import PwdResetSendForm from '@/components/auth/PwdResetSendForm.vue';

@Component({
  components: {
    PwdResetSendForm,
    Footer,
  },
})
export default class PwdResetSend extends Vue {}

