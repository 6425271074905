

























































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Messages, Code } from '@/const';
import { OverlayLoader, UploadModal } from '@/components/parts'

import 'vue-good-table/dist/vue-good-table.css'

import DepartmentService from '@/services/department_service'
import { SortOption, ColumnOption  } from '@/types/good_table_types'

import GoodTableHeader from '@/mixins/good_table_mixin'
import ServiceCallHelper from '@/mixins/service_call_helper'

import DepartmentSearchModal from '@/components/settings/department/DepartmentSearchModal.vue';
import DepartmentAddModal from '@/components/settings/department/DepartmentAddModal.vue';
import { DepartmentSearchData } from '@/types/settings/department_types';

interface MixinInterface extends ServiceCallHelper, GoodTableHeader<DepartmentSearchData> {}

@Component({
  components: {
    DepartmentAddModal,
    DepartmentSearchModal,
    UploadModal,
    OverlayLoader,
    'vue-good-table': require('vue-good-table').VueGoodTable,
  }
})
export default class DepartmentTable extends Mixins<MixinInterface>(ServiceCallHelper, GoodTableHeader) {

  columns = [
    new ColumnOption('id', 'id', {hidden: true}),
    new ColumnOption('組織名', 'departmentName'),
    new ColumnOption('上位組織', 'parentName'),
    new ColumnOption('リスト項目数', 'alLimit'),
    new ColumnOption('リスト配信頻度', 'alFrequency', {sortable: false}),
    new ColumnOption('レポート配信頻度', 'arFrequency', {sortable: false}),
    new ColumnOption('回答時の通知', 'actionAnswerNotify'),
  ]

  rows = [];

  sortOptions = new SortOption(true, []);

  /** 検索条件テキスト */
  filterTexts: string[] = [];

  /** 成功メッセージ */
  successMessage = '';

  /** アップロードservice */
  departmentService = new DepartmentService();

  /**
   * 組織一覧の取得
   */
  loadItems() {
    Vue.$log.debug('loadItems');
    new DepartmentService().getDepartments(this.serverParams).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESS時はデータ設定
      if(data.code == Code.SUCCESS) {
        this.totalRows = data.result.recordCount;
        this.rows = data.result.departments;
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.isLoading = false;
    });
  }

  /** ユーザーを追加ボタン */
  clickDepartmentAddButton() {
    this.$modal.show('department-add-modal');
  }

  /** CSVから追加ボタン */
  clickCsvUploadButton() {
    this.$modal.show('department-upload-modal');
  }

  /** CSVをダウンロードボタン */
  clickCsvDownloadButton() {
    Vue.$log.debug('csv download');
    new DepartmentService().downloadCsv(this.serverParams);
  }

  /** 条件を絞り込み検索ボタン */
  clickFilterButton() {
    this.$modal.show('department-search-modal');
  }

  /** 検索ボタンでdepartmentSearchModalがclose */
  closeDepartmentSearchModal(searchParams: DepartmentSearchData) {
    const departmentTable = this.$refs.departmentTable as any;
    Vue.$log.debug('closeDepartmentSearchModal');
    Vue.$log.debug(searchParams);
    this.$modal.hide('department-search-modal');
    this.updateParams({filters: searchParams, page:1 });
    departmentTable.initializePagination();
    this.updateFilterText();
    this.loadItems();
  }

  /** 検索条件のクリア */
  clearSearchFilter() {
    Vue.$log.debug('clearSearchFilter start');
    const departmentSearchModal = this.$refs.departmentSearchModal as DepartmentSearchModal;
    const departmentTable = this.$refs.departmentTable as any;
    this.clearParams(Object.keys(departmentSearchModal.fields));
    this.filterTexts = [];
    departmentSearchModal.clearFields();
    this.updateParams({ page:1 });
    departmentTable.initializePagination();
    this.loadItems();
  }

  /** 絞り込み検索テキストの更新 */
  updateFilterText() {
    const departmentSearchModal = this.$refs.departmentSearchModal as DepartmentSearchModal;
    this.filterTexts = departmentSearchModal.searchTexts();
  }

  /** モーダルからの追加成功通 */
  addSuccess() {
    Vue.$log.debug('add success')
    this.loadItems();
    (this.$refs.departmentAddModal as DepartmentAddModal).updateDepartments();
    (this.$refs.departmentSearchModal as DepartmentSearchModal).updateDepartments();
  }

  /** CSV追加モーダルからの更新成功通知 */
  uploadSuccess() {
    this.$modal.hide('department-upload-modal');
    this.$swal(Messages.INSERT_SUCCESS, '', 'success');
    this.loadItems();
    (this.$refs.departmentAddModal as DepartmentAddModal).updateDepartments();
    (this.$refs.departmentSearchModal as DepartmentSearchModal).updateDepartments();
  }
}

