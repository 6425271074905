























import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import router from '@/router'

@Component({
  components: {
    Footer,
  },
})
export default class NotFound extends Vue{
  clickLoginButton() {
    router.push('/login');
  }
}

