

























































































































































































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { DepartmentEditData } from '@/types';
import { Messages, Code } from '@/const'

import DepartmentEditFormSubmit from "./DepartmentEditFormSubmit.vue"
import DepartmentDeleteButton from "./DepartmentDeleteButton.vue"
import DepartmentService from '@/services/department_service'
import ServiceCallHelper from '@/mixins/service_call_helper'

import { validationMixin } from 'vuelidate'
import { DepartmentSelect } from '@/mixins'

import { ErrorMessage, OverlayLoader, DayOfWeekCheckbox, CycleFreqSelectBox, HourMinuteSelectBox } from '@/components/parts'
import { DepartmentEditValidator } from '@/validators'

/** 組織のセレクトボックス値設定 */
interface MixinInterface extends ServiceCallHelper, DepartmentSelect {}

@Component( {
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
    DayOfWeekCheckbox,
    CycleFreqSelectBox,
    HourMinuteSelectBox,
    DepartmentEditFormSubmit,
    DepartmentDeleteButton,
  },
  validations: {
    fields: new DepartmentEditValidator()
  },
} )
export default class DepartmentEditForm extends Mixins<MixinInterface>(ServiceCallHelper, DepartmentSelect) {

  fields = new DepartmentEditData();

  // メンバーへのアクションリストの表示の値記憶
  currentShowMemberActionReportList: string | number = 0

  departmentId = -1;

  mounted() {
    // departmentId取得
    this.departmentId = parseInt(this.$route.params.id, 10);

    // 表示データ取得
    this.showLoading();
    new DepartmentService().getDepartment(this.departmentId).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESS時は表示データ設定
      if(data.code == Code.SUCCESS) {
        this.fields = data.result.department;
        this.pageLoaded = true;
        this.currentShowMemberActionReportList = this.fields.showMemberActionReportList
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

}

