import Vue from 'vue';
import { Mixin } from "vue-mixin-decorator"
import { UserSearchModal } from '@/components/parts'

/**
 * ユーザー検索オプション補助
 */
@Mixin
export default class UserSearchOptionsHelper extends Vue {
  
  /** 検索条件テキスト */
  filterOptionsTexts: string[] = [];

  selectedList: { [key: string]: string[] } = {
    departments: [],
    childOccupations: [],
    positions: [],
    superUsers: [],
  };

  /** 絞り込み検索テキストの更新 */
  updateFilterOptionsText() {
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    this.filterOptionsTexts = userSearchModal.searchOptionsTexts();
  }
}
