












import { Vue } from "vue-property-decorator";
import { Component } from "vue-mixin-decorator";
import { Messages, Code } from "@/const";

import {
  default as UserService
} from "@/services/user_service";

import router from "@/router";

import { AxiosResponse } from "@/types";
import { validationMixin } from "vuelidate";
import { UserEditValidator } from "@/validators";

import UserEditForm from "@/components/settings/user/UserEditForm.vue";

import Calendar from 'v-calendar/lib/components/calendar.umd'

import { sameAs, minLength } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  components: {
    Calendar,
  },
  validations: {
    fields: new UserEditValidator(),
    loginPassword: {
      sameAsPassword: sameAs("loginPassword2"),
      minLength: minLength(8),
    },
    loginPassword2: {},
  },
})
export default class UserEditFormSubmit extends Vue {

  /**
   * 設定を保存ボタン押下時、データ更新
   */
  clickSaveButton() {
    const parent = this.$parent as UserEditForm;
    // validation
    parent.$v.$touch();
    Vue.$log.debug(parent.$v);
    Vue.$log.debug(parent.fields);
    if (parent.$v.$invalid) {
      this.$swal(Messages.INVALID_INPUT, "", "warning");
      return;
    }

    parent.showLoading();

    // パスワードの設定
    if (parent.loginPassword && parent.loginPassword == parent.loginPassword2) {
      parent.fields.loginPassword = parent.loginPassword;
      parent.fields.loginPassword2 = parent.loginPassword2;
    }
    const thenFunc = (response: AxiosResponse) => {
      if (parent.commonApiErrorHandler(response)) {
        const data = response.data;
        Vue.$log.debug(data);
        // SUCCESS時は成功通知
        if (data.code == Code.SUCCESS) {
          this.$swal(Messages.UPDATE_SUCCESS, "", "success").then(() => {
            // Myユーザー設定でない場合は一覧に遷移
            if(parent.userId) {
              router.push('/user');
            }
            else {
              window.scrollTo(0,0);
            }
          });
        }
        // その他のケースは接続エラーに
        else {
          this.$swal(Messages.NETWORK_ERROR, "", "error");
        }
      }
    };
    // userIdが設定されている場合はsearchActionStats呼び出し
    Vue.$log.debug("call user service");
    if (parent.userId) {
      new UserService()
        .putUser(parent.userId, parent.fields, parent.dateIds)
        .then(thenFunc)
        .finally(() => {
          parent.hideLoading();
        });
    }
    // userIdが設定されていない場合はsearchMyActionStats呼び出し
    else {
      new UserService()
        .putMyUser(parent.fields, parent.dateIds)
        .then(thenFunc)
        .finally(() => {
          parent.hideLoading();
        });
    }
  }
}
