


































































import { Vue, Prop } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import { OverlayLoader, CollapseHintMessage } from '@/components/parts'
import { ActionListResponseData, ActionListObject } from '@/types';

import ServiceCallHelper from '@/mixins/service_call_helper'

@Component({
  components: {
    OverlayLoader,
    CollapseHintMessage,
  },
})
export default class ActionListItems extends ServiceCallHelper {

  // form data
  @Prop()
  data: ActionListResponseData;

  // 確認画面: true, 入力画面: false
  @Prop({default: false})
  isConfirm: boolean;

  // 強み/弱み
  // eslint-disable-next-line
  strongWeakPoint: any;

  // フォーム情報
  fields: ActionListObject = {};

  // アクション追加ID用カウンター
  actionAddCounter = 0;

  created() {
    const fields: ActionListObject = {};
    const allActions = [];
    for(const ability of this.data.action) {
      for(const action of ability.actions) {
        if(action.required == 1) {
          fields[`act_${action.id}`] =  'y';
        }
        allActions.push(action.id);
      }
    }
    Vue.$log.debug(this.data.userAction);
    Vue.$log.debug(allActions);
    for(const action of this.data.userAction) {
      Vue.$log.debug(allActions.includes(action));
      if(allActions.includes(action)) {
        fields[`act_${action}`] =  'y';
      }
    }
    this.fields = fields;
    Vue.$log.debug(this.fields);
    this.strongWeakPoint = this.data.strongWeakPoints;
    this.updateCheckedCount();
  }

  // 追加アクション
  // eslint-disable-next-line
  newActions: any = {}; 

  // チェックされた要素数
  // eslint-disable-next-line
  checkedCount: any = {};

  // 強み/弱みごとのcard設定
  // eslint-disable-next-line
  cardClass(ability: any) {
    if(this.$store.getters.userInfo.enableCustomAction == 1) {
      return 'card card-custom card-common';
    }
    else if(this.strongWeakPoint) {
      if(ability == 'other') {
        return 'card card-custom card-common';
      }
      else if('strong' == this.strongWeakPoint[ability]) {
        return 'card card-strengths card-common';
      }
      else if('weak' == this.strongWeakPoint[ability]) {
        return 'card card-weak card-common';
      }
    }
    return 'card card-default card-common';
  }

  /**
   * abilityの表示/非表示判定
   */
  // eslint-disable-next-line
  showAbility(ability: any) {
    // 確認画面の場合は0件以上のabilityを表示
    if(this.isConfirm) {
      return this.checkedCount[ability.abilityName] > 0;
    }
    return ability.actions.length > 0;
  }

  /**
   * チェック内容変更確認
   */
  isActionChanged() {
    const act1 = this.data.userAction.slice().sort();
    const act2 = this.checkedActions()
    act2.sort();
    // 長さチェック
    if(act1.length != act2.length) {
      return true;
    }
    // 要素チェック
    for(let i = 0; i < act1.length; i++) {
      if(act1[i] != act2[i]) {
        return true;
      }
    }
    return false;
  }

  /**
   * チェックされたアクションのID一覧を取得する
   */
  checkedActions() {
    const acts: Array<number> = [];
    for(const key in this.fields) {
      if(key.startsWith('act_') && this.fields[key]) {
        if(this.fields[key].toString().trim()) {
          acts.push(parseInt(key.slice(4)));
        }
      }
    }
    return acts;
  }

  /**
   * チェック数の更新
   */
  updateCheckedCount() {
    Vue.$log.debug(this.data);
    for(const ability of this.data.action) {
      let checkedCount = 0;
      for(const action of ability.actions) {
        if(action && 'act_' + action.id in this.fields) {
          checkedCount += 1;
        }
      }
      // 新規登録アクションの取得
      if(this.newActions[ability.abilityName]) {
        for(const action of this.newActions[ability.abilityName]) {
          if(this.fields[action.id]) {
            checkedCount += 1;
          }
        }
      }
      this.checkedCount[ability.abilityName] = checkedCount;
    }
  }

  /**
   * アクションを追加するボタン
   */
  ckickAddAction(ability: string) {
    Vue.$log.debug('ckickAddAction');
    const lst = this.newActions[ability] || [];
    this.actionAddCounter += 1;
    lst.push({'id': `act_${ability}_${this.actionAddCounter}`, 'text': '', alpha: ''});
    this.$set(this.newActions, ability, lst);
    Vue.$log.debug(this.newActions);
  }

  /**
   * 追加アクションを削除するボタン
   */
  clickDelNewAction(actionId: number, ability: string) {
    Vue.$log.debug('clickDelNewAction');
    this.fields[actionId] = '';
    Vue.$log.debug(actionId);
    const lst = (this.newActions[ability] || []);
    for(let i = 0; i <= this.actionAddCounter; i++){ 
      Vue.$log.debug(lst[i])
      if (i in lst && lst[i].id == actionId) { 
        Vue.$log.debug(`splice ${i}`)
        lst.splice(i, 1); 
        Vue.$log.debug(lst)
      }
    }
    this.$set(this.newActions, ability, lst);
    Vue.$log.debug(this.newActions);
  }

}

