import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'

/**
 * ログインフォームValidator
 */
export class LoginValidator {
  loginId = { required, email };
  loginPassword = { required };
}

/**
 * パスワードリセット通知送信Validator
 */
export class PwdResetSendValidator {
  loginId = { required, email };
}

/**
 * パスワードリセットValidator
 */
export class PwdResetValidator {
  loginPassword = { required, minLength: minLength(8) };
  // eslint-disable-next-line
  loginPassword2 = { required, sameAsPassword: sameAs(function(this: any) { return this.fields.loginPassword }) };
}

