







































































































































































import moment from 'moment';
import Vue from 'vue';
import { Component } from "vue-mixin-decorator"
import { OverlayLoader, ErrorMessage, UploadModal } from '@/components/parts'
import { Messages, Code } from '@/const';
import router from '@/router'

import { CompanyService, PositionService, OccupationService } from '@/services'
import { CompanyEditData, VueCalendarDate } from '@/types';

import { validationMixin } from 'vuelidate'
import { CompanyValidator } from '@/validators'

import ServiceCallHelper from '@/mixins/service_call_helper'

import Calendar from 'v-calendar/lib/components/calendar.umd'

import '@/styles/holidays.scss'

@Component({
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
    UploadModal,
    Calendar,
  },
  validations: {
    fields: new CompanyValidator()
  },
})
export default class CompanyEditForm extends ServiceCallHelper {

  /** form fields */
  fields = new CompanyEditData();

  /** カレンダー日付 */
  days: Array<VueCalendarDate> = [];
  get dates() {
    return this.days.map(day => day.date);
  }
  get dateIds() {
    return this.days.map(day => day.id);
  }
  get calendarAttributes() {
    return this.dates.map(date => ({
      key: moment(date, 'YYYY-mm-dd').toString(),
      highlight: true,
      dates: date,
    }));    
  }

  /** 役職アップロードService */
  positionService = new PositionService();

  /** 職種アップロードService */
  occupationService = new OccupationService();

  /**
   * mount時、会社情報を取得して表示
   */
  mounted() {
    this.showLoading();
    new CompanyService().getCompany().then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESSの場合は値を設定
      if(data.code == Code.SUCCESS) {
        Vue.$log.debug(data.result);
        this.fields = data.result.company;
        for(const day of data.result.businessDays) {
          this.days.push({id: day, date: moment(day).toDate()});
        }
      }
      // レコードが存在しない場合は404へ
      else if(data.code == Code.RECORD_NOT_FOUND) {
        router.push('/404');
      }
      // その他のケースは接続エラーに
      else {
        this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /** 設定保存ボタン **/
  clickSaveButton() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$swal(Messages.INPUT_VALIDATION_ERROR, '', 'warning');
      return;
    }
    this.saveData()
  }
  
  /**
   * データ更新
   */
  saveData() {
    this.showLoading();
    new CompanyService().putCompany(this.fields, this.dateIds).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // 成功時はメッセージ表示
      if(data.code == Code.SUCCESS){
        this.$swal(Messages.UPDATE_SUCCESS, '', 'success');
      }
      // その他のケースは接続エラーに
      else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /** カレンダー日付クリック */
  onDayClick(day: VueCalendarDate) {
    // 選択された日付を追加
    const idx = this.days.findIndex(d => d.id === day.id);
    if (idx >= 0) {
      this.days.splice(idx, 1);
    } else {
      this.days.push({
        id: day.id,
        date: day.date,
      });
    }
    Vue.$log.debug(this.days);
  }


  /** 役職CSVインポートクリック */
  clickPositionCsvExport() {
    new PositionService().downloadCsv();
  }

  /** 職種CSVエクスポートクリック */
  clickOccupationCsvExport() {
    new OccupationService().downloadCsv();
  }

  /** 役職CSVエクスポートクリック */
  clickPositionCsvUpload() {
    this.$modal.show('position-upload-modal');
  }

  /** 職種CSVエクスポートクリック */
  clickOccupationCsvUpload() {
    this.$modal.show('occupation-upload-modal');
  }

  /** モーダルからの更新成功通知 */
  positionUploadSuccess() {
    this.$modal.hide('position-upload-modal');
    this.$swal(Messages.INSERT_SUCCESS, '', 'success');
  }

  /** モーダルからの更新成功通知 */
  occupationUploadSuccess() {
    this.$modal.hide('occupation-upload-modal');
    this.$swal(Messages.UPDATE_SUCCESS, '', 'success');
  }

  clickSetActionRecommend() {
    router.push("/company_action_recommends");
  }
}
