



























import { Component, Prop, Vue } from 'vue-property-decorator';

import { UserTypeDef, UserTypeJa, UserTrend, UserConscious } from '@/const/user_types';

@Component
export default class TestReportUserType extends Vue {

  /** 画像パス */
  imagePath = '';

  /** ユーザータイプ日本語表記 */
  userTypeJa = '';

  /** 傾向 */
  userTrendText = '';

  /** 意識すること */
  userConsciousText = '';

  @Prop()
  userType: UserTypeDef;

  @Prop()
  gender: string;

  /**
   * created時、表示メッセージ生成
   */
  created() {
    this.updateUserType(this.userType);
  }

  /**
   * userType更新時処理
   */
  updateUserType(userType: UserTypeDef) {
    this.userType = userType;
    this.imagePath = this.createImagePath();
    this.userTypeJa = new UserTypeJa()[this.userType];
    this.userTrendText = new UserTrend()[this.userType];
    this.userConsciousText = new UserConscious()[this.userType];
  }

  /**
   * 画像パスの生成
   */
  createImagePath() {
    return `/images/illustration/${this.userType}_${this.gender}.png`;
  }

}
