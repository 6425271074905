













import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { Messages, Code } from '@/const';
import { OverlayLoader, UploadModal } from '@/components/parts';
import CompanyActionRecommendTable from './Table.vue';
import CompanyActionService from '@/services/company_action_service';


@Component({
  components: {
    OverlayLoader,
  }
})
export default class CompanyActionTableSaveSettingsButton extends Vue {

  /**
   * 設定を保存ボタンを押下
   */
  clickSaveSettings() {
    const parent = this.$parent as CompanyActionRecommendTable;
    // parent.fields.

    parent.showLoading();
    new CompanyActionService().putRecommendSettings(parent.fields).then((response) => {
      if(parent.commonApiErrorHandler(response)) {
        const data = response.data;
        // 成功時はメッセージ表示
        if(data.code == Code.SUCCESS){
          parent.$swal(Messages.UPDATE_SUCCESS, '', 'success');
        }
        // その他のケースは接続エラーに
        else {
          parent.$swal(Messages.NETWORK_ERROR, '', 'error');
        }
      }
    }).finally(() => {
      parent.hideLoading();
    });
    this.$emit("success");
  }

}

