import User from '@/views/settings/User.vue'
import MyUser from '@/views/settings/MyUser.vue'
import UserList from '@/views/settings/UserList.vue'

import Department from '@/views/settings/Department.vue'
import DepartmentList from '@/views/settings/DepartmentList.vue'

import Company from '@/views/settings/Company.vue'
import CompanyLineWorksPage from '@/views/settings/CompanyLineWorks.vue'
import CompanyActionRecommendPage from '@/views/settings/CompanyActionRecommendPage.vue'
import CompanyActionRecommendEditPage from '@/views/settings/CompanyActionRecommendEditPage.vue'

export default [
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: {
      requireLogin: true,
      isSuper: true,
    },
  },
  {
    path: '/company_action_recommends',
    name: 'CompanyActionRecommendPage',
    component: CompanyActionRecommendPage,
    meta: {
      requireLogin: true,
      isSuper: true,
    },
  },
  {
    path: '/company_line_works',
    name: 'CompanyLineWorksPage',
    component: CompanyLineWorksPage,
    meta: {
      requireLogin: true,
      isSuper: true,
    },
  },
  {
    path: '/company_action_recommend',
    name: 'CompanyActionRecommendEditPage',
    component: CompanyActionRecommendEditPage,
    meta: {
      requireLogin: true,
      isSuper: true,
    },
  },
  {
    path: '/company_action_recommend/:id',
    name: 'CompanyActionRecommendEditPage',
    component: CompanyActionRecommendEditPage,
    meta: {
      requireLogin: true,
      isSuper: true,
    },
  },
  {
    path: '/user',
    name: 'UserList',
    component: UserList,
    meta: {
      requireLogin: true,
      isAdmin: true,
    },
  },
  {
    path: '/user/:id',
    name: 'User',
    component: User,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/my_user',
    name: 'MyUser',
    component: MyUser,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/department',
    name: 'DepartmentList',
    component: DepartmentList,
    meta: {
      requireLogin: true,
      isAdmin: true,
    },
  },
  {
    path: '/department/:id',
    name: 'Department',
    component: Department,
    meta: {
      requireLogin: true,
    },
  },
]
