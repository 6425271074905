/**
 * ヘルプページ設定
 */

export interface MenuItem {
  title: string;
  anchor: string;
  page?: string;
  subMenus?: MenuItem[];
}


export class HelpMenu {

  static readonly MenuItems = [
    {
      title: 'SkillBoxの概要（最初にお読みください）',
      anchor: 'desc_l',
      subMenus: [
        {
          title: 'SkillBoxの概要',
          page: 'desc',
          anchor: 'desc_m',
          subMenus: [
            { title: 'SkillBoxの概要', anchor: 'desc' },
            { title: 'ログイン', anchor: 'login' },
            { title: 'ログアウト', anchor: 'logout' },
          ],
        }
      ],
    },

    {
      title: 'システム利用のための各種設定',
      anchor: 'settings_l',
      subMenus: [
        {
          title: '全社共通設定',
          anchor: 'company',
          page: 'company',
          subMenus: [
            { title: 'データ表示用の営業日登録', anchor: 'company_busiday' },
            { title: '自動リマインド用の設定期日登録', anchor: 'company_remind' },
            { title: 'チェックテスト完了後の挙動設定（オプション）', anchor: 'company_ctopt' },
            { title: '権限ごとの各種表示/非表示設定', anchor: 'company_disp' },
            { title: '属性情報の選択肢やレコメンドのカスタマイズ', anchor: 'company_recom' },
          ],
        },
        {
          title: '組織設定',
          anchor: 'depart',
          page: 'depart',
          subMenus: [
            { title: '組織一覧画面の見方', anchor: 'depart_list' },
            { title: '組織の新規追加・変更・削除', anchor: 'depart_edit' },
            { title: 'アクション項目数の設定', anchor: 'depart_limit' },
            { title: '配信頻度の設定', anchor: 'depart_freq' },
          ],
        },
        {
          title: 'ユーザー設定',
          anchor: 'user',
          page: 'user',
          subMenus: [
            { title: 'ユーザー一覧画面の見方', anchor: 'user_list' },
            { title: 'ユーザーの新規追加・変更・削除', anchor: 'user_edit' },
            { title: 'アクションリスト変更時の承認不要設定', anchor: 'user_approve' },
            { title: '配信頻度の設定（個別）', anchor: 'user_freq' },
          ],
        },
        {
          title: 'Myユーザー設定',
          anchor: 'myuser',
          page: 'myuser',
          subMenus: [
            { title: 'ユーザー情報の設定', anchor: 'myuser_se' },
            { title: '配信頻度の設定', anchor: 'myuser_freq' },
          ],
        },
      ],
    },
    
    {
      title: '社会人基礎力チェックテスト（オプション）',
      anchor: 'ct_l',
      page: 'ct',
      subMenus: [
        {
          title: '受験依頼通知',
          anchor: 'exam_notify',
          page: 'exam_notify',
          subMenus: [
            { title: '全ユーザーへの一括連絡', anchor: 'ct_as' },
            { title: '各ユーザーへの個別連絡', anchor: 'ct_ts' },
            { title: 'テスト結果の閲覧依頼通知', anchor: 'ct_ds' },
          ],
        },
        {
          title: '受験方法',
          anchor: 'exam',
          page: 'exam',
          subMenus: [
            { title: '受験画面への遷移方法', anchor: 'examp_link' },
            { title: '受験者情報入力', anchor: 'exam_input' },
            { title: 'テスト問題の回答', anchor: 'exam_answer' },
          ],
        },
        {
          title: '受験結果の確認',
          anchor: 'report',
          page: 'report',
          subMenus: [
            { title: '社会人基礎力とは', anchor: 'report_ba' },
            { title: '7タイプ分類とは', anchor: 'report_7t' },
            { title: '組織レポートの見方', anchor: 'report_group' },
            { title: 'テストレポート一覧画面の見方', anchor: 'report_list' },
          ],
        },
        {
          title: 'Myテストレポート',
          anchor: 'myreport',
          page: 'myreport',
          subMenus: [
            { title: 'テストレポートの見方', anchor: 'myreport_v' },
            { title: '強み弱みとは', anchor: 'myreport_sw' },
          ],
        },
      ],
    },

    {
      title: 'アクションリスト設定・回答',
      anchor: 'act_l',
      page: 'act',
      subMenus: [
        {
          title: '設定依頼通知',
          anchor: 'act_notify',
          page: 'act_notify',
          subMenus: [
            { title: '全ユーザーへの一括連絡', anchor: 'act_as' },
            { title: '各ユーザーへの個別連絡', anchor: 'act_ts' },
            { title: 'アクションリストの回答依頼通知', anchor: 'act_ds' },
          ],
        },
        {
          title: 'アクションリストの設定',
          anchor: 'act_list',
          page: 'act_list',
          subMenus: [
            { title: '初回の設定方法', anchor: 'act_fst' },
            { title: '新規アクションの追加', anchor: 'act_add' },
            { title: '管理者への連絡事項', anchor: 'act_adminf' },
            { title: '二回目以降の設定方法', anchor: 'act_scnd' },
          ],
        },
        {
          title: 'アクションリストの承認',
          anchor: 'act_aprv',
          page: 'act_aprv',
          subMenus: [
            { title: '申請内容の確認', anchor: 'act_chk' },
            { title: '申請内容の修正', anchor: 'act_edit' },
            { title: '差戻し', anchor: 'act_rmd' },
          ],
        },
        {
          title: 'アクションリストの回答',
          anchor: 'act_ans',
          page: 'act_ans',
          subMenus: [
            { title: 'アクションリストの回答方法', anchor: 'act_ans_q' },
            { title: '「機会なし」とは', anchor: 'act_noc' },
          ],
        },
      ],
    },

    {
      title: 'アクション状況の確認・リアクション',
      anchor: 'actc_l',
      page: 'actc',
      subMenus: [
        {
          title: 'ダッシュボード',
          anchor: 'actc_dash',
          page: 'actc_dash',
          subMenus: [
            { title: 'アクションに関する状況の各数値の意味', anchor: 'actc_as' },
            { title: '条件絞り込み検索', anchor: 'actc_search' },
            { title: '達成率の時系列推移', anchor: 'actc_lcht' },
            { title: '組織ごとのアクション状況一覧', anchor: 'actc_dept' },
          ],
        },
        {
          title: 'アクション状況の確認',
          anchor: 'actc_lck',
          page: 'actc_lck',
          subMenus: [
            { title: 'レポート一覧画面の見方', anchor: 'act_report' },
            { title: '個人レポートの見方', anchor: 'actc_preport' },
            { title: 'リアクション方法（スタンプ・コメント）', anchor: 'actc_react' },
            { title: 'カスタムスタンプの追加', anchor: 'actc_stamp' },
          ],
        },
        {
          title: 'Myアクションレポート',
          anchor: 'actc_my',
          page: 'actc_my',
          subMenus: [
            { title: 'アクションレポートの見方', anchor: 'actc_myr' },
            { title: 'コメントの見方', anchor: 'actc_myc' },
          ],
        },
        {
          title: 'タイムライン',
          anchor: 'actc_tline',
          page: 'actc_tline',
          subMenus: [
            { title: 'タイムラインに表示される内容', anchor: 'actc_tlinec' },
            { title: 'メンバーリスト', anchor: 'actc_tlinel' },
          ],
        },
        {
          title: 'チームボード',
          anchor: 'actc_tbd',
          page: 'actc_tbd',
          subMenus: [
            { title: 'チームボードに表示される内容', anchor: 'actc_tbdc' },
            { title: 'チームボードの表示から消える回答について', anchor: 'actc_tbda' },
          ],
        },

      ],
    },
    {
      title: 'その他のシステム情報',
      anchor: 'system_info',
      page: 'sysi',
      subMenus: [
        {
          title: '推奨環境',
          anchor: 'sysi_recom',
          page: 'sysi_recom',
          subMenus: [
          ],
        },
        {
          title: 'お問い合わせ',
          anchor: 'contact',
          page: 'contact',
          subMenus: [
          ],
        },
      ],
    },
  ];
}

export class HelpPageText {

  /** SkillBoxの概要 */
  static readonly DESC = `
「SkillBox」は、能力向上のための具体的アクションを設定し、その記録と管理を行うシステムです。これは主に、以下４つの機能（学習ステップ）で成り立っています。

<img src="https://image.skillbox.site/help/desc01.jpg" class="w-100" />

<h6>Step１．コンピテンシーの設定</h6>

個別具体的なスキルではなく、「主体性」や「傾聴力」といった行動特性（コンピテンシー）を会社ごとに設定します。既存のコンピテンシーが無い場合、経済産業省が定める<a href="https://skillbox.site/help/report#report_ba">「社会人基礎力」</a>を用いた簡単設定も可能です。

<h6>Step２．アクションリストの設定</h6>

コンピテンシーを高めるための具体的アクションを、AIによるレコメンドを参考に一人一人が設定します。（一人最大10個まで。例：相談する時は自分なりに一案用意した上で相談する）

<h6>Step３．アクションの記録と確認</h6>

設定したアクションリストへ定期的に行動状況を回答していきます。回答した内容やその分析結果は、ダッシュボード上で簡単に確認可能です。（設定頻度は毎日や毎週など自由に指定が可能です）

<h6>Step４．記録に対するリアクション</h6>

管理者や同じチームのメンバーはアクション記録に対してスタンプやコメントで手軽にリアクションを行え、アクションをした本人は自分へのリアクションを確認します。

以上のように、能力向上と結びつく具体的アクションをくり返し記録していくことで、意識として本人に行動が定着する上、管理者側も達成率の推移などによる定量評価が可能なシステムが「SkillBox」です。

システムの操作方法に関しては、それぞれのヘルプページをご確認ください。またヘルプページに載っていない項目に関しては、自社の担当部署もしくは<a href="/contactus" target="_blank">問い合わせページ</a>よりご連絡ください。
`;

  /** ログイン */
  static readonly LOGIN = `
<img src="https://image.skillbox.site/help/login01.jpg" class="w-100" />

<h6>①ID</h6>

ID は連絡先となるメールアドレスなどが該当します。パスワード設定の通知と共に ID の連絡が届いているかと思いますが、もしも確認できない場合は自分の ID が何になるのか管理者の方へ問い合わせをお願いいたします。

<h6>②パスワード</h6>

パスワードは、アカウントの発行時に登録されている連絡先へ設定依頼をお送りします。その際にご自身で設定されたものがご自身のアカウントにおけるパスワードとなります。もしも設定したパスワードを忘れてしまった場合は、ログインボタンの下にある③<b>「パスワードを忘れた方はこちら」</b>から、ご自身の ID を入力し再度パスワードの設定を行ってください。
`

  /** ログアウト */
  static readonly LOGOUT = `
<img src="https://image.skillbox.site/help/logout01.jpg" class="w-100" />

①<b>「ユーザー名」</b>を押して表示される、②<b>「ログアウトボタン」</b>を押してください。
`

  /** データ表示用の営業日登録 */
  static readonly COMPANY_BUSIDAY = `
<img src="https://image.skillbox.site/help/zensya01.jpg" class="w-100" />
①<b>「営業日登録」</b>で、営業日を登録することができます。営業日登録は、主にダッシュボード上などで「前営業日の結果」を確認する場合と、アクション記録のリマインドメールなど自動通知を行う場合に使用します。
営業日の登録の仕方は２つあり、「毎週平日」を選択した場合は土日祝日を除くすべての日が営業日としてカウントされます。（年末は12月28日まで、年始は1月4日からです）
「カスタム」を選択した場合は、営業日を自由に選択いただけます。
`

  /**  自動リマインド用の設定期日登録  */
  static readonly COMPANY_REMIND = `
②<b>「期日設定」</b>では、自動的に配信されるリマインドメールの期日設定ができます。

チェックテストの受験期日を設定している場合は、その日までにチェックテストの受験が完了されていないアカウント全員に対して自動でリマインドメールを送ります。送られるタイミングは、期日の3日前と当日の合計２回です。また、期日を過ぎても受験がなされていないアカウントには、毎日自動でリマインドメールを送ります。 

アクションリスト受験期日を設定している場合は、その日までにアクションリストの設定が完了されていないアカウント全員に対して自動でリマインドメールを送ります。送られるタイミングは、期日の3日前と当日の合計２回です。また、期日を過ぎても受験がなされていないアカウントには、毎日自動でリマインドメールを送ります。 

それぞれのリマインドメールは、期日を未設定にすることで送信停止にできます。期日を未設定にする場合は、日付入力欄を選択しdeleteキーで削除してください。
`

  /**  チェックテスト完了後の挙動設定（オプション）  */
  static readonly COMPANY_CTOPT = `
③<b>「テスト完了後の設定」</b>では社会人基礎力チェックテスト（オプション）が完了した後に自動でなされる挙動について設定を行うことができます。

テストレポート画面へのリダイレクトは、受験者の方がチェックテストの受験を完了した際に、自分自身の得点が確認できるテストレポート画面へ自動で遷移する設定です。基本的には「設定する」にしておくことをお勧めしますが、全員の受験が完了してから全員へ一斉に結果を伝えたい場合などは、「設定しない」にしておき全員の受験が完了した後で一括通知機能により受験結果の確認を促すという流れも可能です。

アクションリスト設定依頼の自動通知は、受験者の方がチェックテストの受験を完了した際に、自動で本人へアクションリスト設定の依頼メールを送る設定です。こちらも基本的には「設定する」にしておくことをお勧めしますが、チェックテストの結果を見て会社側でアクションを指定したい場合や、アクション設定のタイミングを全員同じに揃えたい場合などは、「設定しない」にしておき、任意のタイミングで一括通知機能によりアクションリストの設定を依頼するという流れも可能です。
`

  /**  権限ごとの各種表示/非表示設定  */
  static readonly COMPANY_DISP = `
④<b>「表示設定」</b>では、権限ごとでの各種表示/非表示を切り替える設定ができます。

<h6>個人への社内平均点数</h6>
チェックテストの受験結果に関して、メンバー権限の受験者へ社内平均点を表示するかしないかの設定ができます。

<h6>管理者によるコメント機能</h6>
管理者がメンバーのアクション状況を確認する際、コメントボタンを表示するかしないかの設定ができます。メンバーと管理者間でのコミュニケーションはとても重要ですので推奨としてはコメントボタンを表示することですが、管理者側の負荷が強いなどの理由からコメントボタンを非表示に設定することも可能となっています。

<h6>アクション設定時の「追加」ボタン</h6>
メンバーがアクションリストを設定する際に、メンバー自身が自分で新規アクションを追加するボタンを表示するかしないかの設定ができます。メンバー自ら意識をしたいアクションを追加できますので自主的な成長習慣を植え付けるために推奨としては新規アクションボタンを表示することですが、会社が指定するもの以外で本人に簡単なアクションを設定させたくないといった場合には非表示にすることも可能となっています。

<h6>メンバーへのアクションレポート一覧</h6>
メンバー権限者が、他のメンバーのアクションレポートを見れる・見れないを設定できます。「表示する」に設定することで、メンバー権限者のサイドバーにもアクションレポートページが表示され、他のメンバーのアクションレポートが見れるようになります。アクションレポートからは他のメンバーへコメントやスタンプといったリアクションができるため、社内コミュニケーションを活発にするためにも表示することを推奨します。

<h6>メンバーへのテストレポート一覧</h6>
メンバー権限者が、他のメンバーのテストレポートを見れる・見れないを設定できます。「表示する」に設定することで、メンバー権限者のサイドバーにもテストレポートページが表示され、他のメンバーのテストレポートが見れるようになります。他のメンバーの状況を円滑に理解するために「表示する」設定を推奨しています。
`

  /**  属性情報の選択肢やレコメンドのカスタマイズ  */
  static readonly COMPANY_RECOM = `
⑤<b>「カスタマイズ」</b>では、役職・職種の選択肢を自社に合った形へとカスタマイズすることができます。一度「CSVでエクスポート」した後、そのファイルに上書きする形で選択肢を編集し、「CSVでインポートして登録」することでカスタマイズを反映できます。
なお、年次や役職などのグループ別にアクションのレコメンド設定をしたい場合は、お客様側の管理画面上からはできない仕様となっております。設定を希望される場合は、<a href="/contactus" target="_blank">お問い合わせページ</a>から、弊社担当まで直接ご連絡ください。
`

  /** 組織一覧画面の見方 */
  static readonly DEPART_LIST = `
<img src="https://image.skillbox.site/help/depart01.jpg" class="w-100" />

<h6>①組織名</h6>
任意に設定した組織名が表示されます。

<h6>②上位組織</h6>
該当組織の一つ上位に位置する組織名が表示されます。なお、最上位に位置する上位組織は一つだけの設定です。
組織階層は、その組織に所属する人のデータ閲覧権限と関係があります。管理者権限を持っている人は自分が所属する組織のデータを全て見ることができますが、自分が所属する組織より上位の組織や、隣の組織のデータを見ることはできません。また、自分が所属する組織の下位に位置する組織のデータはすべて見ることができますが、隣の下位に位置するデータは見ることができません。

<img src="https://image.skillbox.site/help/depart02.jpg" class="w-75" />

<h6>③リスト項目数</h6>
アクションリストに入れられるアクションの最小数と最大数を表示しています。

<h6>④リスト配信頻度</h6>
その組織に所属するメンバーへ、アクションリストの回答依頼をいつ配信するかが表示されています。この項目は、個人ごとに個別設定をすることができます。

<h6>⑤レポート配信頻度</h6>
その組織に所属するメンバーへ、アクションレポートの確認依頼をいつ配信するかが表示されています。この項目は、個人ごとに個別設定をすることができます。

<h6>⑥管理者の確認頻度</h6>
その組織に所属する管理者へ、メンバーのアクション回答に対するリアクション依頼をいつ配信するかが表示されています。

<h6>⑦回答時の通知</h6> 
メンバーがアクションリストに回答した際、その組織の管理者へ都度回答完了の通知を送るかどうかが表示されています。「通知あり」の場合は、メンバーが回答を完了するたびに管理者へ通知が届き、「通知なし」の場合はメンバーが回答しても管理者に通知は届きません。

<h6>⑧条件絞り込み検索</h6>
条件に該当する人のデータだけを対象とした表示が可能です。絞り込み条件の詳細については、<a href="/help/actc_dash#actc_search">こちら</a>をご確認ください。
`

  /** 組織の新規追加・変更・削除 */
  static readonly DEPART_EDIT = `
<img src="https://image.skillbox.site/help/depart03.jpg" class="w-100" />

組織を新規追加する場合には、まず①<b>「組織を追加」</b>を押してください。
※CSVで一括追加をする場合は、②<b>「CSVから追加」</b>を押して該当するCSVファイルをアップロードしてください。CSVファイルは、③<b>「CSVをダウンロード」</b>を押すことでダウンロードすることができます。

<img src="https://image.skillbox.site/help/depart04.jpg" class="w-100" />

各項目の意味は、<a href="#depart_list">組織一覧画面の見方</a>の①～⑦をご参照ください。

組織の設定を変更する場合には、④<b>「組織名」</b>に表示されている組織名を押してください。

<img src="https://image.skillbox.site/help/depart05.jpg" class="w-100" />

各項目の意味は、<a href="#depart_list">組織一覧画面の見方</a>の①～⑦をご参照ください。

組織を削除する場合には、⑤<b>「組織を削除する」</b>を押してください。削除しようとしている組織に所属しているメンバーが一人でもいる場合、組織を削除することはできません。なお、誤ってボタンを押してしまった場合でも一度だけ確認画面が表示されキャンセルすることができますが、その上で削除をしてしまった場合は復旧することができませんのでご注意ください。
`

  /** アクション項目数の設定 */
  static readonly DEPART_LIMIT = `
上記<a href="#depart_edit">「組織の新規追加・変更・削除」</a>の⑥<b>「アクションリストの項目数」</b>で設定をしてください。
`

  /** 配信頻度の設定 */
  static readonly DEPART_FREQ = `
上記<a href="#depart_edit">「組織の新規追加・変更・削除」</a>の⑦<b>「配信頻度設定」</b>で設定をしてください。
`

  /** ユーザー一覧画面の見方 */
  static readonly USER_LIST = `
<img src="https://image.skillbox.site/help/user01.jpg" class="w-100" />

<h6>①名前</h6>
アカウントの名前が表示されます。

<h6>②通知先</h6>
通知などの連絡を送る先が表示されます。一つのアカウントに対して、複数の連絡先を設定することはできません。

<h6>③所属組織</h6>
アカウントが所属している組織が表示されます。

<h6>④権限</h6>
アカウントの権限が表示されます。権限は、「メンバー権限」「メンター権限」「スーパーユーザー権限」の3種類があります。メンバーは主に学習者を意味し、基本となる設定では自分以外のアクション結果を確認できません。メンターはメンバーの管理者を意味し、所属する組織とその下位組織のアクション結果を確認できます。スーパーユーザーは人事部門などのシステム管理者を意味し、すべての組織のアクション結果を確認できる他、全社共通設定などの変更が可能です

<h6>⑤承認機能</h6>
アクションリスト設定時に、管理者が承認をする必要があるかどうかを表示しています。基本的なアクションリスト設定の流れは、メンバーが自身のアクションリストを検討した上で管理者へ申請を出し管理者が承認するというものであり、その場合に承認機能の欄は「―」と表示されます。「不要」と表示されている場合、管理者が確認することなくメンバーが自身でアクションリストを決定できる状態です。

<h6>⑥リスト配信頻度</h6>
アクションリストの回答依頼を、いつ配信するかが表示されています。基本は所属する組織の設定に従いますが、個別設定をすることで一人一人異なる配信頻度を指定することも可能です。

<h6>⑦レポート配信頻度</h6>
アクションレポートの確認依頼を、いつ配信するかが表示されています。基本は所属する組織の設定に従いますが、個別設定をすることで一人一人異なる配信頻度を指定することも可能です。

<h6>⑧条件絞り込み検索</h6>
条件に該当する人のデータだけを対象とした表示が可能です。絞り込み条件の詳細は<a href="/help/actc_dash#actc_search">こちら</a>をご確認ください。
`

  /** ユーザーの新規追加・変更・削除 */
  static readonly USER_EDIT = `
<img src="https://image.skillbox.site/help/user02.jpg" class="w-100" />

ユーザーを新規追加する場合には、まず①<b>「ユーザーを追加」</b>を押してください。
※CSVで一括追加をする場合は、②<b>「CSVから追加」</b>を押して該当するCSVファイルをアップロードしてください。CSVファイルは、③<b>「CSVをダウンロード」</b>を押すことでダウンロードすることができます。

<img src="https://image.skillbox.site/help/user03.jpg" class="w-100" />

主な各項目の意味は、<a href="#user_list">ユーザー一覧画面の見方</a>の①～⑦をご参照ください。

<h6>⑤詳細設定</h6>
入力は任意です。各項目を設定しておくと、条件絞り込み検索時に役立ちます。なお、社会人基礎力チェックテストをお申込みいただいている場合は、受験画面において各受験者が自身で詳細設定の各項目を入力するため、自動的に設定がされます。※部署移動などありましたら、ユーザー設定画面から手動でご変更ください。

<h6>⑥配信頻度設定</h6>
「組織設定を適用」を選択した場合、所属する組織の配信頻度がこのユーザーにも適用されます。「個人設定を適用」を選択した場合、アカウントごとに異なる配信頻度を個別で設定することができます。

ユーザーの設定を変更する場合には、④<b>「名前」</b>に表示されているユーザー名を押してください。

<img src="https://image.skillbox.site/help/user04.jpg" class="w-100" />

各項目の意味は、<a href="#user_list">ユーザー一覧画面の見方</a>の①～⑦を参照ください。

ユーザーアカウントを削除する場合には、⑦<b>「ユーザーを削除する」</b>を押してください。なお、誤ってボタンを押してしまった場合でも一度だけ確認画面が表示されキャンセルすることができますが、その上で削除をしてしまった場合は復旧することができませんのでご注意ください。

`

  /** アクションリスト変更時の承認不要設定 */
  static readonly USER_APPROVE = `
上記<a href="#user_edit">「ユーザーの新規追加・変更・削除」</a>の⑧<b>「承認申請」</b>で設定をしてください。
`

  /** 配信頻度の設定（個別） */
  static readonly USER_FREQ = `
アカウントごとに配信頻度の設定を行う場合は、上記<a href="#user_edit">「ユーザーの新規追加・変更・削除」</a>の⑨<b>「配信頻度設定」</b>で設定をしてください。
※毎週〇曜日や毎月〇日といった特定のルールしか設定ができないため、不定期シフトなどの場合における適切なタイミングのみの配信は都度設定する必要があります。
`

  /** ユーザー情報の設定 */
  static readonly MYUSER_SE = `
<img src="https://image.skillbox.site/help/user05.jpg" class="w-100" />

<h6>①ユーザー名</h6>
アカウントの名前が表示されます。

<h6>②連絡先</h6>
通知などの連絡を送る先が表示されます。一つのアカウントに対して複数の連絡先を設定することはできません。

<h6>③パスワード</h6>
パスワードの変更ができます。

<h6>④所属組織</h6>
アカウントが所属している組織が表示されます。

<h6>⑤権限</h6>
アカウントの権限が表示されます。権限は、「メンバー権限」「メンター権限」「スーパーユーザー権限」の3種類があります。メンバーは主に学習者を意味し、基本となる設定では自分以外のアクション結果を確認できません。メンターはメンバーの管理者を意味し、所属する組織とその下位組織のアクション結果を確認できます。スーパーユーザーは人事部門などのシステム管理者を意味し、すべての組織のアクション結果を確認できる他、全社共通設定などの変更が可能です。

<h6>⑥詳細設定</h6>
入力は任意です。各項目を設定しておくと、条件絞り込み検索時に役立ちます。なお、社会人基礎力チェックテストをお申込みいただいている場合は、受験画面において各受験者が自身で詳細設定の各項目を入力するため、自動的に設定がされます。※部署移動などありましたら、手動でご変更ください。

<h6>⑦配信頻度設定</h6>
「組織設定を適用」を選択した場合、所属する組織の配信頻度がこのユーザーにも適用されます。「個人設定を適用」を選択した場合、アカウントごとに異なる配信頻度を個別で設定することができます。
`

  /** 配信頻度の設定 */
  static readonly MYUSER_FREQ = `
⑦<b>「配信頻度」</b>では、自身の配信頻度が表示されます。管理者以上の権限があれば自身の配信頻度を変更できますが、メンバー権限では自身での変更ができないため、管理者にご連絡をお願いいたします。
※毎週〇曜日や毎月〇日といった特定のルールしか設定ができないため、不定期シフトなどの場合における適切なタイミングのみの配信は都度管理者が設定する必要があります。
`

  /** 全ユーザーへの一括連絡 */
  static readonly CT_AS = `
<img src="https://image.skillbox.site/help/check01.jpg" class="w-100" />
①<b>「全ユーザーへ連絡」</b>を押して表示される項目の中から、「テストの受験依頼」を選択してください。
`

  /** 各ユーザーへの個別連絡 */
  static readonly CT_TS = `
②<b>「各ユーザーへ連絡」</b>を押して表示される項目の中から、「テストの受験依頼」を選択ください。
※各ユーザーへの個別連絡は、③<b>「名前」</b>の列にあるチェックボックスへチェックをつけた人にだけ限定して通知を送ります。ページをまたいでチェックをつけることはできませんので、一度に多くの方に送りたい場合は画面右下から表示件数を増やすか、条件絞り込み検索で対象を絞り込んでからチェックをつけるようにしてください。
`

  /** テスト結果の閲覧依頼通知 */
  static readonly CT_DS = `
①<b>「全ユーザーへ連絡」</b>、②<b>「各ユーザーへ連絡」</b>を押して表示される項目の中から、「テスト結果の閲覧」を選択することで通知を送れます。
※テストレポートを印刷して手渡しされたい場合は、④<b>「テストレポートをダウンロード」</b>を押して表示される項目の中から「ダウンロード（PDF）」を選択しダウンロードしてから印刷すると便利です。チェックボックスにチェックをつけたアカウントのテスト結果は、一人一人別のPDFファイルとしてまとめてダウンロードできます。なお、その他の項目は以下のとおりです。
「全件ダウンロード（CSV）」…CSV 形式で全員のテスト結果を一括ダウンロードします。
「ダウンロード（CSV）」… CSV 形式でチェックをつけたアカウントのテスト結果をまとめてダウンロードします。
`

  /** 受験画面への遷移方法 */
  static readonly EXAMP_LINK = `
<img src="https://image.skillbox.site/help/check02.jpg" class="w-100" />
サイドバーにある「受験」ボタンから受験画面へ遷移できます。ただし、受験済みの場合はボタン自体が非表示となりますので、誤って回答完了をしてしまった場合などは管理者の方へご連絡ください。
`

  /** 受験者情報入力 */
  static readonly EXAM_INPUT = `
<img src="https://image.skillbox.site/help/check03.jpg" class="w-100" />
<h6>①受験者氏名</h6>
アカウント作成時に登録されている名前が自動で入力されます。こちらの画面からは修正できません。

<h6>②生年月日</h6>
受験者が自身の生年月日を入力する欄です。

<h6>③性別</h6>
受験者が自身の性別を入力する欄です。

<h6>④新卒/中途</h6>
受験者が新卒入社か中途入社かを選択する欄です。

<h6>⑤入社年月</h6>
受験者が自身の入社年月を入力する欄です。

<h6>⑥職種</h6>
受験者が自身の職種を選択肢から入力する欄です。職種１を選択した後は職種２が選択できるようになります。なお、職種は初期設定による選択肢の他、全社共通設定から会社ごとに選択肢をカスタマイズすることも可能です。

<h6>⑦役職</h6>
受験者が自身の役職を選択肢から入力する欄です。なお、役職も初期設定による選択肢の他、全社共通設定から会社ごとに選択肢をカスタマイズすることも可能です。
`

  /** テスト問題の回答 */
  static readonly EXAM_ANSWER = `
<img src="https://image.skillbox.site/help/check04.jpg" class="w-100" />  
全60問、制限時間は30分となっています。質問に対して当てはまる度合いを、0～10の中で選択していきます。制限時間が過ぎた後も回答はできます。
`

  /** 社会人基礎力とは */
  static readonly REPORT_BA = `
<img src="https://image.skillbox.site/help/check05.jpg" class="w-100" />   
社会人基礎力とは、経済産業省により定義された「職場や地域社会で多様な人々と仕事をしていくために必要な基礎的な力」です。諏訪康雄（法政大学名誉教授）をプロジェクトリーダーとした検討会で決定され、「前に踏み出す力」、「考え抜く力」、「チームで働く力」の3つの能力（12の能力要素）から構成されています。
<img src="https://image.skillbox.site/help/check06.jpg" class="w-100" />
`

 /** 7タイプ分類とは */
 static readonly REPORT_7T = `
7タイプ分類とは、社会人基礎力における３つの能力割合に関する傾向により、一人一人をタイプ分けしたものです（監修者：諏訪康雄）。どのタイプが優れているといったことはありませんが、個性や性格的な傾向を見極める上での参考にすることができます。
<img src="https://image.skillbox.site/help/check07.jpg" class="w-100" /> 
`

 /** 組織レポートの見方 */
 static readonly REPORT_GROUP = `
<img src="https://image.skillbox.site/help/check08.jpg" class="w-100" /> 
<h6>①各能力要素の得点分布</h6>
社会人基礎力の12の能力要素それぞれに関して、自身が所属する組織の得点と、全国平均点を表示します。

<h6>②平均点</h6>
①<b>「各能力要素の得点分布」</b>の得点を数値として表示したものになります。太字で大きく表示されている得点が自身の所属する組織の平均点、括弧内に表示されている得点が全国の平均点です。

<h6>③7タイプの人数分布</h6>
自身が所属する組織における、7タイプ分類での各タイプ割合を表示しています。

<h6>④条件絞り込み検索</h6>
条件に該当する人のデータだけを対象とした表示が可能です。絞り込み条件の詳細は<a href="/help/actc_dash#actc_search">こちら</a>をご確認ください。
`

 /** テストレポート一覧画面の見方 */
 static readonly REPORT_LIST = `
<img src="https://image.skillbox.site/help/check09.jpg" class="w-100" />
<h6>①名前</h6>
アカウントの名前が表示されます。青字でリンクになっている場合、押すことで一人一人のテストレポートを確認できます。（チェックテストの受験が完了するとリンクになります）

<h6>②受験日</h6>
チェックテストを受験した日が表示されます。同一アカウントが複数回チェックテストを受けている場合は、最新のものが表示されます。

<h6>③タイプ</h6>
チェックテストの結果における7タイプ分類のタイプが表示されます。同一アカウントが複数回チェックテストを受けている場合は、最新のものが表示されます。

<h6>④得点</h6>
チェックテスト結果における各能力の得点が表示されます。同一アカウントが複数回チェックテストを受けている場合は、最新のものが表示されます。

<h6>⑤結果を見る</h6>
ボタンを押すことで、一人一人のテストレポートを確認できます。具体的な見方については、<a href="https://skillbox.site/help/myreport#myreport_v">テストレポートの見方</a>をご参照ください。
`

  /** テストレポートの見方 */
  static readonly MYREPORT_V = `
<img src="https://image.skillbox.site/help/check10.jpg" class="w-100" />

<h6>①タイプ診断</h6>
7タイプ分類において、自身がどのタイプに該当するのか表示されます。

<h6>②各能力要素の得点分布</h6>
社会人基礎力の12の能力要素それぞれに関して、自身の得点と全国平均点が表示されます。

<h6>③平均点</h6>
②<b>「各能力要素の得点分布」</b>の得点を数値として表示したものになります。太字で大きく表示されている得点が自身の得点、括弧内に表示されている得点が全国の平均点です。
`

  /** 強み弱みとは */
  static readonly MYREPORT_SW = `
12の能力要素の得点において、上位三つを強み、下位三つを弱みとして表示しています。（④<b>「自分の強みと弱み」</b>に表示）強み弱みはアクションリストでアクションを設定する際にも参考情報として表示されるほか、各能力要素の得点によってレコメンド内容が変化したりします。
※同点が複数ある場合は、社会人基礎力においてより重要とされる能力要素から表示しています。
`

  /** 全ユーザーへの一括連絡 */
  static readonly ACT_AS = `
<img src="https://image.skillbox.site/help/actionlist01.jpg" class="w-100" />

①<b>「全ユーザーへ連絡」</b>を押して表示される項目の中から、「アクションの設定依頼」を選択ください。
`

  /** 各ユーザーへの個別連絡 */
  static readonly ACT_TS = `
②<b>「各ユーザーへ連絡」</b>を押して表示される項目の中から、「アクションの設定依頼」を選択ください。
※各ユーザーへの個別連絡は、③<b>「名前」</b>の列にあるチェックボックスへチェックをつけた人にだけ限定して通知を送ります。ページをまたいでチェックをつけることはできませんので、一度に多くの方へ送りたい場合は画面右下から表示件数を増やすか、条件絞り込み検索で対象を絞り込んでからチェックをつけるようにしてください。
`

  /** アクションリストの回答依頼通知 */
  static readonly ACT_DS = `
①<b>「全ユーザーへ連絡」</b>、②<b>「各ユーザーへ連絡」</b>を押して表示される項目の中から、「アクションの回答依頼」を選択することで通知を送れます。
`

  /** 初回の設定方法 */
  static readonly ACT_FST = `
<img src="https://image.skillbox.site/help/actionlist02.jpg" class="w-100" />

<h6>①能力要素</h6>
能力の分類に社会人基礎力を用いている場合は、社会人基礎力に定義される12の能力要素がそれぞれ表示されます。また、チェックテストを受けている場合にはそれぞれの点数に応じて「強み」や「弱み」といった表示がなされます。
社会人基礎力を用いない、自社独自のアクションリスト（コンピテンシーなど）を当てはめたカスタマイズ版の場合は、能力要素の欄に自社独自のアクションリスト（コンピテンシーなど）名が表示されます。この場合強みや弱みといった表示はありません。

<h6>②必須アクション</h6>
会社側が必ずアクションリストに追加することを希望するアクションは、事前に設定がなされることで必須と表示され、自動で選択されます。もしアクションの内容を変更したい場合は、管理者にご連絡をお願いいたします。
※管理者は人事担当者が一括で担当している場合もあれば、各組織の管理職が個別に担当している場合もあります。

<h6>③レコメンドアクション</h6>
アクションリストに加える日々意識するアクション内容に関して、システムによるレコメンド（お勧め）が表示されます。チェックボックスにチェックを付けることで、好きなものを選択してアクションリストに加えることができます。
※設定されているアクションの最大数（基本は10個）を超えることはできません。
`

  /** 新規アクションの追加 */
  static readonly ACT_ADD = `
④<b>「＋アクションを追加する」</b>を押すことで、新しい入力欄が追加されます。レコメンドにない、追加したいアクション内容を入力欄へと記入することで、任意のアクション内容をアクションリストに追加することができます。
`

  /** 管理者への連絡事項 */
  static readonly ACT_ADMINF = `
⑤<b>「管理者への連絡事項」</b>では、アクションリストの内容を申請した後で管理者が承認のための確認をする際、もし何か管理者へ留意して欲しいことなどがあればそれを伝えることができます。
※基本となる設定では管理者による承認が必須となっていますが、承認不要に設定することも可能です。設定方法については、<a href="/help/user#user_approve">アクションリスト変更時の承認不要設定</a>をご確認ください。
`

  /** 二回目以降の設定方法 */
  static readonly ACT_SCND = `
<img src="https://image.skillbox.site/help/actionlist03.jpg" class="w-100" />
<h6>①現在アクションリストにある項目</h6>
設定・変更ページを開いた時、最初からチェックがついているアクションは現在アクションリストにある項目です。変更後もアクションリストに残したい項目はチェックをつけたままにしておき、アクションリストから外したい項目はチェックを外すようにしてください。

<h6>②新しくアクションリストに入れる候補</h6>
最初からチェックがついていないアクションは、システムによるレコメンド（おすすめ）です。チェックボックスにチェックを付けることで、好きなものを選択してアクションリストに加えることができます。
なお、初回の設定と同様に新規アクションの追加も可能です。選択が完了しましたら、管理者への連絡事項を任意で記入した後、画面最下部にある⑥<b>「確認画面へ」</b>のボタンを押して確認し、問題なければ申請完了です。
`

  /** 申請内容の確認 */
  static readonly ACT_CHK = `
<img src="https://image.skillbox.site/help/actionlist04.jpg" class="w-100" />
アクションレポート画面の①<b>「リスト申請」</b>では、未承認のアクションリストがある場合に「未承認」の文字が表示されますので、その文字を押してください。
`

  /** 申請内容の修正 */
  static readonly ACT_EDIT = `
<img src="https://image.skillbox.site/help/actionlist05.jpg" class="w-100" />
<h6>②申請されているアクション</h6>
申請されているアクションリストのアクション項目が表示されます。アクションリストから削除したい項目は、該当アクションのチェックボックスについているチェックを外してください。

<h6>③アクションを追加する</h6>
新しくアクションを追加したい場合は、③<b>「＋アクションを追加する」</b>を押すことで新しい入力欄が追加されます。申請された内容にない、追加したいアクション内容を入力欄へと記入することで、任意のアクション内容をアクションリストに追加することができます。
※設定されているアクションの最大数（基本は10個）を超えることはできません。
※申請されているアクションを一部修正したい場合でも、修正したいアクションのチェックボックスについているチェックを外した後で新しくアクションを追加してください。

<h6>④対象者からの連絡事項</h6>
承認依頼者から管理者に対する連絡事項が何か記入されていれば、その内容が表示されます。

<h6>⑤対象者への連絡事項</h6>
承認依頼者へ承認や差戻しの通知をする際に、励ましのコメントや注意点などの連絡事項を伝えることができる入力欄です。承認をする場合の入力は任意ですが、差戻しをする場合は入力が必須となります。
`

  /** 差戻し */
  static readonly ACT_RMD = `
申請されたアクションリストの内容を管理者自身が修正して確定させるのではなく、再度本人に検討を促したい場合には⑥<b>「差戻し」</b>を押すことで差戻しをすることができます。この場合は⑤<b>「対象者への連絡事項」</b>の入力が必須となりますので、具体的にどのような点を検討してもらいたいのか記入することが推奨となります。

内容に問題がなければ、⑦<b>「確認画面へ」</b>を押して最終確認し、「承認」ボタンを押してアクションリストの設定は完了です。※承認後に修正する場合、再度本人の申請から設定する必要がありますのでご注意ください。
`

  //** アクションリストの回答方法 */
  static readonly ACT_ANS_Q = `
<img src="https://image.skillbox.site/help/actionlist06.jpg" class="w-100" />

<h6>①対象日</h6>
回答するアクションの日付を選択する欄です。
※「回答をした日」ではなく「アクションをした日」であることにご注意ください。例えば昨日のアクション結果について今日回答する場合、対象日として選択するのは昨日の日付となります。

<h6>②アクションリストへの回答</h6>
「できた」「できなかった」「機会なし」の三択から、それぞれのアクションに対してアクション状況を選択します。

<h6>③メモ</h6>
回答者本人が自身の行動記録を見返す際の備忘録として使ったり、管理者への連絡事項を記載するなどの使い方ができます。推奨する使い方としては、「できなかった」を選択したアクションに関してその詳細や反省点・改善点などを記録しておくと、両方に役立ちます。
`

  /** 「機会なし」とは */
  static readonly ACT_NOC = `
機会なしとは、そのアクションを行う機会がなかった場合の選択肢です。例えば「会議中に発言する」というアクションに対して、その日会議がなければ「機会なし」という回答になります。なお、機会なしの選択率はシステムで自動的に集計されており、管理者がダッシュボードや一覧画面から確認できるようになっています。
`

  /** アクションに関する状況の各数値の意味 */
  static readonly ACTC_AS = `
<img src="https://image.skillbox.site/help/action01.jpg" class="w-100" />
<h6>①回答率</h6>
前営業日において、アクションリストに回答すべき人数のうちどれだけの人が実際に回答したかを示しています。100%でなければ未回答の人がいることを意味しますので、アクションレポート一覧画面から未回答の人を確認した上で、回答の依頼を通知することを推奨しています。システムを用いた通知方法については、<a href="https://skillbox.site/help/act_notify#act_ds">こちら</a>をご参照ください。

<h6>②達成率</h6>
前営業日において、アクション回答における「できた」の回答割合を示しています。なお「機会なし」の回答は集計対象から省かれます。具体的な集計結果の例としては、以下のケースを参考にしてください。
例：アクションリストが全体で10項目あり、回答として「できた」が4つ、「できなかった」が4つ、「機会なし」が2つの場合
&nbsp;&nbsp;→達成率は50％（「機会なし」を除く8つのうち、半分の4つが「できた」のため）

<h6>③リアクション率</h6>
前営業日のアクションに対する回答に関して、何かしらのリアクション（スタンプorコメント）がされている人の割合を示しています。100%でなければリアクションがされていない人がいることを意味しますので、アクションレポート一覧画面からリアクションされていない人を確認した上で、スタンプを押すなどのリアクション実施を推奨しています。

<h6>④達成率分布（分布図）</h6>
前営業日における、回答者ごとの達成率を表示しています。上位・中位・下位でそれぞれ色分けをしており、色分けの条件は以下のとおりです。
上位（オレンジ）…達成率が90％以上
中位（グリーン）…達成率が40％以上90％未満
下位（ブルー）…達成率40％未満

<h6>⑤達成率分布（棒グラフ）</h6>
前営業日における、上位・中位・下位の割合を表示しています。

※上記①～⑤で表示される数値の対象人数は、閲覧者がデータを見れる組織にいる合計人数となっています。閲覧者が見れる組織に関しては、以下の図をご参照ください。
<img src="https://image.skillbox.site/help/depart02.jpg" class="w-100" />
`

  /** 条件絞り込み検索 */
  static readonly ACTC_SEARCH = `
上図の⑥<b>「条件絞り込み検索」</b>を押すことで、条件に該当する人のデータだけを対象とした表示ができます。

<img src="https://image.skillbox.site/help/action02.jpg" class="w-100" />
<h6>①新卒/中途</h6>
新卒入社か、中途入社かで条件絞り込みをします。

<h6>②社歴</h6>
入社してから何年目かで条件絞り込みをします。

<h6>③性別</h6>
性別で条件絞り込みをします。

<h6>④組織</h6>
所属組織で条件絞り込みをします。

<h6>⑤職種</h6>
職種で条件絞り込みをします。職種１を選択した後は職種２も選択できるようになります。なお、職種は初期設定による選択肢の他、全社共通設定から会社ごとに選択肢をカスタマイズすることも可能です。

<h6>⑥役職</h6>
役職で条件絞り込みをします。役職も、初期設定による選択肢の他、全社共通設定から会社ごとに選択肢をカスタマイズすることが可能です。

<h6>⑦集計期間</h6>
以下にある「達成率の時系列推移」の表示期間を指定することができます。
`

  /** 達成率の時系列推移 */
  static readonly ACTC_LCHT = `
<img src="https://image.skillbox.site/help/action03.jpg" class="w-100" />
<h6>①達成率の時系列推移</h6>
平均達成率の推移が表示されます。右上にある平均達成率の数値は、集計期間を通した平均値となっています。
※表示される数値の対象人数は、閲覧者がデータを見れる組織にいる合計人数となっています。閲覧者が見れる組織に関しては、上にある図をご参照ください。
`

  /** 組織ごとのアクション状況一覧 */
  static readonly ACTC_DEPT = `
②<b>「組織ごとのアクション状況一覧」</b>では、前営業日における各組織のアクション状況が一覧形式で表示されます。各種指標の意味については、上記<a href="/help/actc_dash#actc_as">「アクションに関する状況の各数値の意味」</a>をご確認ください。
`

  /** レポート一覧画面の見方 */
  static readonly ACT_REPORT = `
<img src="https://image.skillbox.site/help/action04.jpg" class="w-100" />
<h6>①回答率</h6>
集計期間（初期表示は過去30日間）において、アクションリストに回答すべき日数のうち実際に回答した日数の割合を示しています。100%でなければ未回答の日があることを意味しますので、回答の依頼を通知することを推奨しています。システムを用いた通知方法については、<a href="https://skillbox.site/help/act_notify#act_ds">こちら</a>をご参照ください。

<h6>②リアクション獲得率</h6>
集計期間（初期表示は過去30日間）になされたアクションリストへの回答に対して、何かしらのリアクション（スタンプorコメント）がされている日の割合を表示しています。100%でなければリアクションがされていない回答日があることを意味しますので、スタンプを押すなどのリアクション実施を推奨しています。

<h6>③前営業日達成率</h6>
前営業日のアクション回答における「できた」の回答割合を示しています。なお「機会なし」の回答は集計対象から省かれます。具体的な集計結果の例としては、以下のケースを参考にしてください。
例：アクションリストが全体で10項目あり、回答として「できた」が4つ、「できなかった」が4つ、「機会なし」が2つの場合
&nbsp;&nbsp;→達成率は50％（「機会なし」を除く8つのうち、半分の4つが「できた」のため）

<h6>④平均達成率</h6>
集計期間（初期表示は過去30日間）における達成率の平均値を表示しています。

<a name="ar_avg_vansrate"><h6>⑤平均有効回答率</h6></a>
集計期間（初期表示は過去30日間）における有効回答率の平均値を表示しています。有効回答率とは、全体の回答における「機会なし」以外の回答割合です。具体的な集計結果の例としては、以下のケースを参考にしてください。
例：アクションリストが全体で10項目あり、回答として「できた」が4つ、「できなかった」が4つ、「機会なし」が2つの場合
&nbsp;&nbsp;→有効回答率は80％（全10項目のうち、8つが「機会なし」以外の回答だったため）

<h6>⑥アクション設定日</h6>
回答の対象となる現在のアクションリストが、いつ設定されたものかを表示しています。

<h6>⑦アクション数</h6>
回答の対象となる現在のアクションリストの、アクション数を表示しています。

<h6>⑧リスト申請</h6>
アクションリストの設定における、承認依頼の有無を表示しています。

<h6>⑨結果を見る</h6>
ボタンを押すことで、一人一人のアクションレポートを確認できます。なお、名前部分のリンクを押すことでも同じアクションレポートページを表示することができます。（アクションリストの設定が完了していると名前部分がリンクになります）

<h6>⑩条件絞り込み検索</h6>
条件に該当する人のデータだけを対象とした表示が可能です。絞り込み条件の詳細は<a href="/help/actc_dash#actc_search">こちら</a>をご確認ください。
`

  /** 個人レポートの見方 */
  static readonly ACTC_PREPORT = `
<img src="https://image.skillbox.site/help/action05.jpg" class="w-100" />
<h6>①アクションの達成率</h6>
平均達成率の推移が表示されます。

<h6>②表示期間</h6>
アクションレポートに表示する回答データの期間が表示されます。初期表示では過去30日間となっています。

<h6>③アクション内容</h6>
④<b>「アクション結果」</b>に表示されているAからJまでのアクション内容と、表示期間におけるアクションごとの平均達成率が表示されます。

<h6>④アクション結果</h6>
「できた」が〇、「できなかった」が×、「機会なし」が「ー」という形で該当する日の回答結果が表示されます。

<h6>⑤メモ</h6>
アクションリストの回答時に本人が入力したメモの内容が表示されます。
`

  /** リアクション方法（スタンプ・コメント） */
  static readonly ACTC_REACT = `
⑥のスタンプアイコンを押すことで、スタンプを選択し押すことができます。自分が押したスタンプは背景が青くなり、再度そのスタンプを押すことで取り消すこともできます。
⑦のコメントボタンを押すことで、コメントをつけることができます。コメントの修正をする場合は、同じボタンを再度押すことで入力した内容を編集することができます。
`

  /** カスタムスタンプの追加 */
  static readonly ACTC_STAMP = `
<img src="https://image.skillbox.site/help/action06.jpg" class="w-100" />
⑥のスタンプアイコンを押して表示されるスタンプ一覧画面から、⑧<b>「＋絵文字を追加」</b>を押すことでカスタムスタンプを追加できます。カスタムスタンプは追加画面にあるリンク先のサイトから簡単に作ることができる他、インターネット上で様々な既存スタンプがフリー素材として配布されていたりもしますので、ぜひお気に入りのものを作成もしくは探してみてください。
`

  /** アクションレポートの見方 */
  static readonly ACTC_MYR = `
<img src="https://image.skillbox.site/help/action07.jpg" class="w-100" />

<h6>①アクションの達成率</h6>
平均達成率の推移が表示されます。

<h6>②表示期間</h6>
アクションレポートに表示する回答データの期間が表示されます。初期表示では過去30日間となっています。

<h6>③アクション内容</h6>
④<b>「アクション結果」</b>に表示されているAからJまでのアクション内容と、表示期間におけるアクションごとの平均達成率が表示されます。

<h6>④アクション結果</h6>
「できた」が〇、「できなかった」が×、「機会なし」が「ー」という形で該当する日の回答結果が表示されます。

<h6>⑤メモ</h6>
アクションリストの回答時に本人が入力したメモの内容が表示されます。
`

  /** コメントの見方 */
  static readonly ACTC_MYC = `
⑥<b>「コメント」</b>を押すことで、該当する日の回答結果に対して他の人からなされたコメントを確認することができます。
`

  /** タイムラインに表示される内容 */
  static readonly ACTC_TLINEC = `
タイムラインとは、自分がフォローした人の回答だけが時系列に沿って表示される画面です。同じ組織のメンバーや同期同士など、自分がリアクションしたい方や行動を把握しておきたい方をフォローすることで、自分が確認したい回答だけを一覧で表示することができます。
<img src="https://image.skillbox.site/help/timeline01.jpg" class="w-100" />

<h6>①アラート表示（アクションリスト運用者のみ）</h6>
閲覧者自身に未回答の回答日があった場合、アラートが表示されます。「アクションリスト回答ページへ」ボタンを押せば、未回答の日付がすでに記入された状態で回答画面が開きます。また、有給取得などにより回答が必要なかった場合には、「回答日に該当しないため無視する」ボタンを押すことでアラート表示を消すとともに、回答率の集計カウントから外すことができ未回答の状態でも回答率100%へと修正することが可能です。

<h6>②メンバーアイコン</h6>
回答者のアイコンが表示され、クリックすることで対象メンバーのアクションレポートページへ遷移します。アクションレポートページでは、その他の日付の回答や達成率の時系列推移が表示される他、フォローボタンを押してフォローすることができます。
なおアイコン写真は、各自がMyユーザー設定から登録が可能です。
また回答内容の見方やリアクションの方法については、<a href="https://skillbox.site/help/actc_lck">「アクション状況の確認」</a>をご参照ください。

<h6>③関連メンバー</h6>
閲覧者がまだフォローしていないメンバーが表示されます。クリックすることで対象メンバーのアクションレポートページへ遷移し、フォローすることができます。

<h6>④メンバーリスト</h6>
アイコンをクリックすることで、メンバーリストがポップアップ表示されます。

`
  /** メンバーリスト */
  static readonly ACTC_TLINEL = `
<img src="https://image.skillbox.site/help/timeline02.jpg" class="w-100" />

<h6>⑤リストタブ</h6>
表示されるメンバーリストの種類を選択できます
「全て」…登録されている全てのメンバーが表示されます。（アクションリスト運用をしていないメンターやスーパーユーザーアカウントは除外されます。）
「近しいメンバー」…閲覧者と同期入社や同じ組織、近い組織に所属するメンバーが表示されます。
「フォロー中」…閲覧者が現在フォローしている人が表示されます。

<h6>⑥メンバーアイコン</h6>
メンバーのアイコンが表示され、クリックすることで対象メンバーのアクションレポートページへ遷移し、アクションリストの内容や過去の回答状況を確認することができます。

<h6>⑦フォローボタン</h6>
ボタンを押すことで、対象メンバーをフォローすることができます。また、既にフォローしているメンバーの場合はボタンを再度押すことでフォロー解除できます。
`

  /** チームボードに表示される内容 */
  static readonly ACTC_TBDC = `
チームボードとは主にメンター向けの画面で、同じ組織でなされた回答が一覧で表示され一画面で全てのリアクションができる画面です。（表示される回答は、前日の回答と閲覧者が未リアクションの回答だけです。）

<img src="https://image.skillbox.site/help/teamboard01.jpg" class="w-100" />

<h6>①サイドバー上の数値表示</h6>
チームボード画面内に表示されるアラートの数であり、数字が表示されていない状態が適切な状態です。アラートに表示される対応を行うか、アラートを非表示にすることで数字をゼロにすることができます。

<h6>②アラート表示</h6>
未回答のユーザーがいる場合や、達成率が低いなどの理由からコメントによるフォローを推奨する場合などに表示されます。フォローの推奨は×ボタンを押すことで非表示にできますが、未回答のユーザーがいた場合については対応を完了させなければ消すことができません。対応としては、該当するメンバーに連絡し回答を完了させるか、もしくは「回答日に該当しないため無視する」ボタンを押す必要があります。
※「回答日に該当しないため無視する」ボタンは、対象の日が有給取得などにより回答が必要なかった場合に押すボタンです。押すことにより回答率の集計カウントからも外れるため、アラートを消すためだけなど該当しない状況で押さないようご注意ください。

<h6>③メンバーアイコン</h6>
回答者のアイコンが表示され、クリックすることで対象メンバーのアクションレポートページへ遷移します。アクションレポートページでは、その他の日付の回答や達成率の時系列推移が表示されます。
なおアイコン写真は、各自がMyユーザー設定から登録が可能です。
また回答内容の見方やリアクションの方法については、<a href="https://skillbox.site/help/actc_lck">「アクション状況の確認」</a>をご参照ください。

<h6>④達成率顔アイコン</h6>
達成率の数値によって3パターンに顔アイコンが変化し、パッと見ておおよその状況を把握することができます。それぞれの顔アイコンの意味は以下の通りです。
笑顔（オレンジ）…達成率が90％以上
無表情（グリーン）…達成率が40％以上90％未満
泣き顔（ブルー）…達成率40％未満

`

  /** チームボードの表示から消える回答について */
  static readonly ACTC_TBDA = `
チームボードに表示される回答は、前日の回答と閲覧者が未リアクションの回答だけです。そのため、前日より前の回答にリアクションを行った場合、画面を再読み込みすることで意図せず非表示になってしまうことがあります。
自分がすでにリアクションをした前日より前の回答をあらめて確認したい場合は、アクションリスト一覧画面から該当する人のアクションレポートを見に行くようにしてください。

`


  /** 推奨環境 */
  static readonly SYSI_RECOM = `
<h6>【管理者向け画面】</h6>
<b>OS</b>
Windows
・Windows 10
・Windows 8.1
Mac
・macOS 10.13以上
iPhone・iPadOS
・iOS12以上、iPadOS13以上
Android
・Android OS 9.0以上

<b>PCブラウザ</b>
以下ブラウザの最新版かつ提供元のサポートが切れていないものをご利用ください。
・Google
・Chrome
・Safari
・Microsoft Edge
※Internet Explorerは非対応です
※管理画面は、スマートフォン非対応です

<h6>【メンバー向け画面】</h6>
<b>OS</b>
Windows
・Windows 10
・Windows 8.1
Mac
・macOS 10.13以上
iPhone・iPadOS
・iOS12以上、iPadOS13以上
Android
・Android OS 9.0以上

<b>PCブラウザ</b>
以下ブラウザの最新版かつ提供元のサポートが切れていないものをご利用ください。
・Google
・Chrome
・Safari
・Microsoft Edge
※Internet Explorerは非対応です

<b>スマートフォンブラウザ</b>
・iOS12以上のSafari
・Android OS 9.0以上のGoogle Chrome
`

  /** お問い合わせ */
  static readonly CONTACT = `
オンラインヘルプをご参照いただいても解決しない場合は、専用フォームからお問い合わせください。
お問い合わせの際は以下の内容をお伺いします。事前にご確認ください。
・会社名
・部署
・お名前
・ユーザーID
・電話番号
・お問い合わせ内容
なお、使い方のお問い合わせではなく、システムのエラーに関するお問い合わせの場合は、お手数をおかけしてしまい大変恐縮ですが原因確認のため以下の内容もあわせてご連絡ください。
・お使いのOS
・お使いのブラウザ
・エラーが発生した日時
下記リンクをクリックすると、フォームが開きます。
https://skillbox.site/contactus
`

}
