import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types'
import { ActionListObject, ActionListResponseData } from '@/types'

import RequestMixin from '@/mixins/request_mixin'

/** アクションリスト表示一覧 */
export class ActionListEditSkillboxResponse extends SkillBoxResponse<ActionListResponseData>{}
export class ActionListEditResponse extends AxiosResponse<ActionListEditSkillboxResponse>{}

/** アクションリスト表示一覧 */
export interface ActionListCompleteData {
  alRequireApproval: number;
}
export class ActionListPostSkillboxResponse extends SkillBoxResponse<ActionListCompleteData>{}
export class ActionListPostResponse extends AxiosResponse<ActionListPostSkillboxResponse>{}


/**
 * アクションリストservice
 */
export default class ActionListService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * アクションリスト検索
   */
  public getActions(): Promise<ActionListEditResponse> {
    return this.getReq(`/api/v1/action_list`, {}) as Promise<ActionListEditResponse>;
  }

  /**
   * アクションリスト申請
   */
  public postUserActions(params: ActionListObject): Promise<ActionListPostResponse> {
    return this.postReq(`/api/v1/action_list`, {'action': params}) as Promise<ActionListPostResponse>;
  }

  /**
   * ユーザーアクションリスト検索（承認画面）
   */
  public getUserActions(userId: number): Promise<ActionListEditResponse> {
    return this.getReq(`/api/v1/action_list/${userId}`, {}) as Promise<ActionListEditResponse>;
  }

  /**
   * ユーザーアクションリスト承認
   */
  public approveUserActions(userId: number, params: ActionListObject): Promise<AxiosResponse> {
    return this.putReq(`/api/v1/action_list/${userId}`, {'action': params}) as Promise<AxiosResponse>;
  }

  /**
   * 差戻し
   */
  public postRemand(userId: number, message: string): Promise<AxiosResponse> {
    const param = {'action': {'remandMessage': message, 'userId': userId} }; 
    return this.postReq(`/api/v1/action_list_remand`, param) as Promise<AxiosResponse>;
  }

  /**
   * リセット
   */
  public deleteActionList(userId: number | string): Promise<AxiosResponse> {
    return this.deleteReq(`/api/v1/action_list/${userId}`, {}) as Promise<AxiosResponse>;
  }
}

