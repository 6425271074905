import { Mixins } from "vue-mixin-decorator"

import { AxiosResponse } from '@/types'
import RequestMixin from '@/mixins/request_mixin'

export default class TestReportService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * テストの受験依頼（全ユーザー）
   *
   * @aparam params
   */
  // eslint-disable-next-line
  public requestTestAll(params: any): Promise<any> {
    // eslint-disable-next-line
    return this.getReq('/api/v1/checktest_request_all', params) as Promise<any>;
  }

  /**
   * テストの受験依頼（指定ユーザー）
   *
   * @aparam params
   */
  public requestTest(userIds: number[]): Promise<AxiosResponse> {
    return this.getReq('/api/v1/checktest_request', {'userIds': userIds}) as Promise<AxiosResponse>;
  }

  /**
   * テストのレポート閲覧依頼（全ユーザー）
   *
   * @aparam params
   */
  // eslint-disable-next-line
  public requestReportAll(params: any): Promise<AxiosResponse> {
    return this.getReq('/api/v1/checktest_report_request_all', params) as Promise<AxiosResponse>;
  }

  /**
   * テストの受験依頼（指定ユーザー）
   *
   * @aparam params
   */
  public requestReport(userIds: number[]): Promise<AxiosResponse> {
    return this.getReq('/api/v1/checktest_report_request', {'userIds': userIds}) as Promise<AxiosResponse>;
  }

  /*
   * CSVダウンロード
   *
   * @param userIds
   */
  // eslint-disable-next-line
  public csvDownload(userIds: number[]): Promise<any> { 
    // eslint-disable-next-line
    return this.downloadReq('/api/v1/checktest_csv', 'test_report.csv', {'userIds': userIds}) as Promise<any>;
  }

  /*
   * CSVダウンロード（全件）
   *
   * @param params
   */
  // eslint-disable-next-line
  public csvDownloadAll(params: any): Promise<any> { 
    // eslint-disable-next-line
    return this.downloadReq('/api/v1/checktest_csv_all', 'test_report.csv', params) as Promise<any>;
  }

  /*
   * PDFダウンロード
   *
   * @param userIds
   */
  // eslint-disable-next-line
  public pdfDownload(userIds: number[]): Promise<any> { 
    // eslint-disable-next-line
    return this.downloadReq('/api/pdf/v1/test_report', 'test_report.pdf', {'userIds': userIds}) as Promise<any>;
  }

}

