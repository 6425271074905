



































































import { Messages, Code } from '@/const';
import { Prop, Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator";
import { AxiosResponse } from '@/types/axios_types';
import { UploadableService } from '@/types';
import ServiceCallHelper from '@/mixins/service_call_helper';

import { OverlayLoader } from '@/components/parts';

@Component({
  components: {
    OverlayLoader,
  },
})
export default class UploadModal extends ServiceCallHelper {

  uploadFile: File;

  fileErrorMessage = '';

  uploadErrorMessage = '';

  fileLabel = '';

  @Prop({ default: '' })
  modalName: string;

  @Prop({ default: '' })
  modalId: string;

  @Prop({ default: null })
  uploadService: UploadableService;

  /**
   * キャンセル
   */
  cancelButton() {
    this.$modal.hide(this.modalName);
  }

  /*
   * ファイル変更時
   */
  changeUploadFile(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (!files) {
      Vue.$log.debug('not files');
    } else if(!files.length) {
      this.fileErrorMessage = Messages.FILE_REQUIRE_ERROR;
    } else {
      Vue.$log.debug(files[0]);
      this.fileLabel = files[0].name;
      this.fileErrorMessage = '';
    }
  }

  /**
   * 追加ボタン
   */
  addButton() {
    Vue.$log.debug('addButton');
    const form = document.getElementById("form") as HTMLFormElement;
    if(form.uploadFile.files.length == 0) {
      this.fileErrorMessage = Messages.FILE_REQUIRE_ERROR;
      return;
    }

    this.showLoading();
    this.uploadErrorMessage = '';

    const formData = new FormData(form);
    this.uploadService.uploadFile(formData).then((response) => { if(this.commonApiErrorHandler(response, false)) {
      Vue.$log.debug(response);
      const data = response.data;
      if(data.code == Code.SUCCESS) {
        Vue.$log.debug(data.code);
        this.fileLabel = '';
        this.$emit('success');
      }
      else if(data.code == Code.VALIDATION_ERROR) {
        Vue.$log.debug(data.code);
        this.uploadErrorMessage = data.message;
      }
      else {
        Vue.$log.debug(data.code);
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }
}

