










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import Footer from '@/components/common/Footer.vue';
import CompanyEditForm from '@/components/settings/company/CompanyEditForm.vue';

@Component({
  components: {
    Header,
    CompanyEditForm,
    Footer,
  },
})
export default class Company extends Vue {}
