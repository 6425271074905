import { Mixins } from "vue-mixin-decorator"

import { AxiosResponse } from '@/types/axios_types';
import { ContactUsData } from '@/types'

import RequestMixin from '@/mixins/request_mixin'

/**
 * contact us service
 */
export default class ContactUsService extends Mixins<RequestMixin>(RequestMixin) {

  /*
   * メール送信
   *
   * @param param
   */
  public send(param: ContactUsData): Promise<AxiosResponse> { 
    return this.postReq('/api/v1/contact_us', {'contact': param}) as Promise<AxiosResponse>;
  }
}

