import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types'
import { SearchParams } from '@/types/good_table_types'
import { UserAddData, UserEditData, UserSearchData, UserProfileImageData } from '@/types/settings/user_types'

import RequestMixin from '@/mixins/request_mixin'

/** 検索結果用type指定 */
export interface UserSearchSkillBoxData {
  users: UserSearchData[];
  recordCount: number;
}
export class UserSearchResponseData extends SkillBoxResponse<UserSearchSkillBoxData>{}
export class UserSearchResponse extends AxiosResponse<UserSearchResponseData>{}

/** ユーザーID検索用type指定 */
export interface UserIdSearchData {
  user: UserEditData;
  businessDays: Array<string>;
}
export class UserIdSearchResponseData extends SkillBoxResponse<UserIdSearchData>{}
export class UserIdSearchResponse extends AxiosResponse<UserIdSearchResponseData>{}

/** プロファイル画像更新用type指定 */
export class UserProfileImageResponseData extends SkillBoxResponse<UserProfileImageData>{}
export class UserProfileImageResponse extends AxiosResponse<UserProfileImageResponseData>{}

/** challenge取得 */
export interface UserWebAuthnChallengeData {
  challengeID: string;
}
export class UserWebAuthnChallengeResponseData extends SkillBoxResponse<UserWebAuthnChallengeData>{}
export class UserWebAuthnChallengeResponse extends AxiosResponse<UserWebAuthnChallengeResponseData>{}

/**
 * user service
 */
export default class UserService extends Mixins<RequestMixin>(RequestMixin) {

  /*
   * ユーザー検索
   *
   * @param params
   */
  public getUsers(params: SearchParams): Promise<UserSearchResponse> { 
    return this.getReq('/api/v1/user', params) as Promise<UserSearchResponse>;
  }

  /**
   * ユーザーID検索
   */
  public getUser(userId: string): Promise<UserIdSearchResponse> {
    return this.getReq(`/api/v1/user/${userId}`, {}) as Promise<UserIdSearchResponse>;
  }

  /**
   * ユーザーID検索
   */
  public getMyUser(): Promise<UserIdSearchResponse> {
    return this.getReq(`/api/v1/my_user`, {}) as Promise<UserIdSearchResponse>;
  }

  /*
   * ユーザー登録
   *
   * @param param
   */
  public postUser(param: UserAddData): Promise<AxiosResponse> { 
    return this.postReq('/api/v1/user', {'user': param}) as Promise<AxiosResponse>;
  }

  /*
   * ユーザー更新
   *
   * @param param
   */
  public putUser(userId: string, param: UserEditData, days: string[]): Promise<AxiosResponse> { 
    return this.putReq(`/api/v1/user/${userId}`, {'user': param, 'businessDays': days}) as Promise<AxiosResponse>;
  }

  /*
   * Myユーザー更新
   *
   * @param param
   */
  public putMyUser(param: UserEditData, days: string[]): Promise<AxiosResponse> { 
    return this.putReq(`/api/v1/my_user`, {'user': param, 'businessDays': days}) as Promise<AxiosResponse>;
  }

  /**
   * プロファイル画像更新
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public uploadProfileImage(userId: string, formData: FormData): Promise<UserProfileImageResponse> {
    return this.uploadReq(`/api/v1/user_profile/${userId}`, formData);
  }

  /**
   * プロファイル画像更新
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public uploadMyProfileImage(formData: FormData): Promise<UserProfileImageResponse> {
    return this.uploadReq('/api/v1/my_user_profile', formData);
  }

  /**
   * プロファイル画像削除
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public deleteProfileImage(userId: string): Promise<UserProfileImageResponse> {
    return this.deleteReq(`/api/v1/user_profile/${userId}`, {});
  }

  /**
   * プロファイル画像削除
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public deleteMyProfileImage(): Promise<UserProfileImageResponse> {
    return this.deleteReq('/api/v1/my_user_profile', {});
  }

  /**
   * CSVダウンロード
   *
   * @param params
   */
  public downloadUsers(params: SearchParams): Promise<AxiosResponse> {
    return this.downloadReq('/api/v1/user_csv', 'users.csv', params);
  }

  /**
   * CSVアップロード
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public uploadFile(formData: FormData): Promise<AxiosResponse> {
    return this.uploadReq('/api/v1/user_csv', formData);
  }

  /*
   * ユーザー削除
   *
   * @param param
   */
  public deleteUser(userId: string): Promise<AxiosResponse> { 
    return this.deleteReq(`/api/v1/user/${userId}`, {}) as Promise<AxiosResponse>;
  }

  /*
   * ユーザー休止
   *
   * @param param
   * @param cancel: True=休止取消, False=休止
   */
  public suspendUser(userId: string, cancel: boolean): Promise<AxiosResponse> { 
    if(cancel) {
      return this.putReq(`/api/v1/user_resume/${userId}`, {}) as Promise<AxiosResponse>;
    }
    else {
      return this.putReq(`/api/v1/user_suspend/${userId}`, {}) as Promise<AxiosResponse>;
    }
  }

  /**
   * Webauthn challenge取得
   *
   * @param password
   */
  public postWebAuthnChallenge(loginId: string, password: string): Promise<UserWebAuthnChallengeResponse> {
    const params = {
      "login": {
        "loginId": loginId,
        "password": password,
      }
    }
    return this.postReq('/api/webauthn/challenge', params) as Promise<UserWebAuthnChallengeResponse>;
  }

  /**
   * Webauthn register
   *
   * @param password
   */
  public postWebAuthnRegister(credential: any): Promise<UserWebAuthnChallengeResponse> {
    console.log(credential);
    const params = {
      "response": JSON.stringify(credential)
    }
    return this.postReq('/api/webauthn/register', params) as Promise<UserWebAuthnChallengeResponse>;
  }
}
