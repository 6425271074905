import { Mixins } from "vue-mixin-decorator"
import { SearchParams } from '@/types/good_table_types'
import {
  AxiosResponse,
  SkillBoxResponse,
  CompanyActionRecommendData,
  CompanyActionRecommendFlg
} from '@/types'

import RequestMixin from '@/mixins/request_mixin'

/** 検索結果用type指定 */
export interface CompanyActionRecommendListSkillBoxData {
  recommend: CompanyActionRecommendData[];
  setting: CompanyActionRecommendFlg;
  totalRows: number;
}
export class CompanyActionRecommendListResponseData extends SkillBoxResponse<CompanyActionRecommendListSkillBoxData>{}
export class CompanyActionRecommendListResponse extends AxiosResponse<CompanyActionRecommendListResponseData>{}

/** 編集用type指定 */
export interface CompanyActionRecommendSkillBoxData {
  recommend: CompanyActionRecommendData;
  flags: CompanyActionRecommendFlg;
  actions: any;
  recommendActions: Array<number>;
  recommendRequireActions: Array<number>; 
}
export class CompanyActionRecommendResponseData extends SkillBoxResponse<CompanyActionRecommendSkillBoxData>{}
export class CompanyActionRecommendResponse extends AxiosResponse<CompanyActionRecommendResponseData>{}

/** 検索結果用type指定 */
export interface CompanyActionRecommendFlgSkillBoxData {
  company: CompanyActionRecommendFlg;
}
export class CompanyActionRecommendFlgResponseData extends SkillBoxResponse<CompanyActionRecommendFlgSkillBoxData>{}
export class CompanyActionRecommendFlgResponse extends AxiosResponse<CompanyActionRecommendFlgResponseData>{}

export default class CompanyActionService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * レコメンド一覧検索
   */
  public getCompanyActionRecommends(params: SearchParams): Promise<CompanyActionRecommendListResponse> {
    return this.getReq('/api/v1/action_recommends', params) as Promise<CompanyActionRecommendListResponse>;
  }

  /**
   * レコメンドデータ取得
   */
  public getComapnyActionRecommend(recommendId: string | null, orgId: string | null): Promise<CompanyActionRecommendResponse> {
    if(recommendId) {
      return this.getReq(`/api/v1/action_recommend/${recommendId}`, {}) as Promise<CompanyActionRecommendResponse>;
    }
    else if(orgId) {
      return this.getReq(`/api/v1/action_recommend?o=${orgId}`, {}) as Promise<CompanyActionRecommendResponse>;
    }
    else {
      return this.getReq(`/api/v1/action_recommend`, {}) as Promise<CompanyActionRecommendResponse>;
    }
  }

  /**
   * レコメンドデータ更新
   */
  public putCompanyActionRecommend(recommendId: string, fields: any, actionIds: Array<number | string>, requireActionIds: Array<number | string>): Promise<AxiosResponse> {
    const params = {
      recommend: {
        fields: fields,
        checkedActions: actionIds,
        checkedRequireActions: requireActionIds,
      }
    }
    return this.putReq(`/api/v1/action_recommend/${recommendId}`, params) as Promise<AxiosResponse>;
  }
 
  /**
   * レコメンドデータ削除
   */
  public deleteCompanyActionRecommend(recommendId: string): Promise<AxiosResponse> {
    return this.deleteReq(`/api/v1/action_recommend/${recommendId}`, {}) as Promise<AxiosResponse>;
  }
 
  /**
   * レコメンドデータ新規登録
   */
  public postCompanyActionRecommend(fields: any, actionIds: Array<number | string>, requireActionIds: Array<number | string>): Promise<AxiosResponse> {
    const params = {
      recommend: {
        fields: fields,
        checkedActions: actionIds,
        checkedRequireActions: requireActionIds,
      }
    }
    return this.postReq(`/api/v1/action_recommend`, params) as Promise<AxiosResponse>;
  }

  /**
   * レコメンド設定取得
   */
  public getRecommendSettings(fields: any): Promise<CompanyActionRecommendFlgResponse> {
    const params = {company: fields}
    return this.getReq(`/api/v1/company_recommend_setting`, params) as Promise<CompanyActionRecommendFlgResponse>;
  }

  /**
   * レコメンド設定更新
   */
  public putRecommendSettings(fields: any): Promise<AxiosResponse> {
    const params = {company: fields}
    return this.putReq(`/api/v1/company_recommend_setting`, params) as Promise<AxiosResponse>;
  }

  /**
   * CSVアップロード
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public uploadFile(formData: FormData): Promise<AxiosResponse> {
    return this.uploadReq('/api/v1/action_csv', formData);
  }

  /** 
   * アクション削除
   */
  public deleteAction(actionId: number): Promise<AxiosResponse> {
    return this.deleteReq(`/api/v1/action_delete/${actionId}`, {}) as Promise<AxiosResponse>
  }
}

