




































import { Component, Vue } from 'vue-property-decorator';
import { HelpMenu, MenuItem } from '@/const/help';
import HelpNav from '@/components/help/HelpNav.vue'

@Component({
  components: {
    HelpNav,
  },
})
export default class HelpMenuPage extends Vue {

  /** メニュー設定 */
  menus = HelpMenu.MenuItems;

  /**
   * サブメニュークラス生成
   */
  menuClass(idx: number) {
    if(idx == 0) {
      return 'mt-0';
    }
    return '';
  }

  /**
   * リンク生成
   */
  getLink(page: string, item: MenuItem) {
    return `/help/${page}#${item.anchor}`;
  }

}
