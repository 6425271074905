




































import { Component } from "vue-mixin-decorator";
import { OverlayLoader } from "@/components/parts";
import ActionReportDetails from "@/components/action_report/ActionReportDetails.vue";

import ServiceCallHelper from "@/mixins/service_call_helper";

import Filters from '@/utils/filters'

@Component({
  components: {
    OverlayLoader,
    ActionReportDetails,
  },
  filters: Filters
})
export default class ActionReportContent extends ServiceCallHelper {
  /** ユーザーID */
  userId = this.$route.params.userId;

  /** action id */
  aid = this.$route.params.actionId;

  /** ユーザー名 */
  userName = "";

  departmentName = "TODO 組織名";

  mounted() {
    if (this.$refs.actionReportDetails) {
      (this.$refs.actionReportDetails as ActionReportDetails).loadItems();
    }
  }

  /** ActionReportDetailからユーザー名の通知 */
  reportDetailLoaded(userName: string) {
    this.userName = userName;
  }
}
