import { render, staticRenderFns } from "./UserEditForm.vue?vue&type=template&id=522dfbe0&scoped=true&"
import script from "./UserEditForm.vue?vue&type=script&lang=ts&"
export * from "./UserEditForm.vue?vue&type=script&lang=ts&"
import style0 from "./UserEditForm.vue?vue&type=style&index=0&id=522dfbe0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522dfbe0",
  null
  
)

export default component.exports