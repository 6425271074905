


































































































































































import moment from 'moment';

import { Prop, Watch, Vue } from "vue-property-decorator";
import { Component, Mixins } from 'vue-mixin-decorator';
import { UserSearchData } from '@/types';
import { PositionOccupationSelect, DepartmentSelect, UserSearchOptionsHelper } from '@/mixins'
import ServiceCallHelper from '@/mixins/service_call_helper'
import UserSearchOptionsModal from '@/components/parts/UserSearchOptionsModal.vue'

/** 役職、職種、組織のセレクトボックス値設定 */
interface MixinInterface extends PositionOccupationSelect, DepartmentSelect, ServiceCallHelper, UserSearchOptionsHelper {}

@Component({
  components: {
    UserSearchOptionsModal,
  },
})
export default class UserSearchModal extends Mixins<MixinInterface>(PositionOccupationSelect, DepartmentSelect, ServiceCallHelper, UserSearchOptionsHelper) {

  /** ユーザー名フィールドを表示する */
  @Prop({default:false})
  useUserName: boolean;

  /** 集計期間入力フィールドを表示する */
  @Prop({default:false})
  useTerm: boolean;

  @Prop({default:3})
  defaultMonthTerm: number;

  @Prop()
  pageName: string;

  /** root組織は非表示 */
  showRootDepartment = false

  /** 入力フィールド */
  fields: UserSearchData = new UserSearchData();

  // 入社年のselect box年一覧
  hireYearOptions = Array.from({length: new Date().getFullYear() - 1969}, (v, k) => k + 1970);

  optionsModalType: "departments" | "childOccupations" | "positions" | "" = "";

  optionList: any[] = [];

  /**
   * mounted時の初期化処理
   * 職種/役職の取得
   */
  mounted() {
    // 集計期間が未指定の場合は3ヶ月前〜前日を指定
    this.fields.termFrom = '';
    this.fields.termTo = '';
    this.fields.userName = '';
    Vue.$log.debug('UserSearchModal mounted');
    Vue.$log.debug(this.pageName);
    const searchParams = this.$store.getters.searchParams || {};
    if(searchParams && searchParams[this.pageName] && searchParams[this.pageName].searchData) {
      const searchParam = searchParams[this.pageName].searchData;
      Vue.$log.debug('searchParam');
      Vue.$log.debug(this.pageName);
      Vue.$log.debug(searchParam);
      this.fields.userName = searchParam.userName;
      this.fields.newRecruit = searchParam.newRecruit;
      this.fields.hireYearFrom = searchParam.hireYearFrom;
      this.fields.hireYearTo = searchParam.hireYearTo;
      this.fields.gender = searchParam.gender;
      this.fields.departmentId = searchParam.departmentId;
      this.fields.occupation = searchParam.occupation;
      this.fields.childOccupation = searchParam.childOccupation;
      this.fields.position = searchParam.position;
      this.fields.termFrom = searchParam.termFrom;
      this.fields.termTo = searchParam.termTo;
    }
    if(this.useTerm && !this.fields.termFrom && !this.fields.termTo) {
      this.fields.termTo = moment().subtract(1, "days").format('YYYY-MM-DD');
      this.fields.termFrom = moment(this.fields.termTo).subtract(this.defaultMonthTerm, "months").format('YYYY-MM-DD');
    }
    // ヘッダー検索からの遷移
    if (this.$route.query.username) {
      this.fields.userName = this.$store.getters.actionReportSearchConditions.text;
      this.searchButton();
    }
    this.$emit('mounted');
  }

  /** ヘッダー検索からキーワードを変更した時 */
  @Watch("$route.query.username")
  onUsernameChange() {
    this.fields.userName = this.$store.getters.actionReportSearchConditions.text;
    this.searchButton();
  }

  /** 検索ボタン */
  searchButton() {
    const searchParam = this.$store.getters.searchParams || {};
    if(!searchParam[this.pageName]) {
      searchParam[this.pageName] = {searchData: this.$data.fields}
    }
    else {
      searchParam[this.pageName]['searchData'] = this.$data.fields;
    }
    
    // 検索条件のフォーマット
    const departmentIds: number[] = this.selectedList.departments?.reduce((accumulator: number[], currentValue: string) => {
      const id = Number(this.departments.filter(v => v.name === currentValue)[0]?.id);
      accumulator.push(id);
      return accumulator;
    }, []);
    searchParam[this.pageName]['searchData']['departmentId'] = "";
    searchParam[this.pageName]['searchData']['childOccupation'] = "";
    searchParam[this.pageName]['searchData']['position'] = "";
    searchParam[this.pageName]['searchData']['departmentIds'] = departmentIds;
    searchParam[this.pageName]['searchData']['childOccupations'] = this.selectedList.childOccupations;
    searchParam[this.pageName]['searchData']['positions'] = this.selectedList.positions;
    this.$store.commit('setSearchParams', searchParam);
    this.$emit('search', this.$data.fields);
  }

  /** 検索ボタン */
  cancelButton() {
    this.$modal.hide('user-search-modal');
  }

  /**
   * 絞り込み検索テキストの生成
   */
  searchTexts(): string[] {
    Vue.$log.debug('searchText');
    const conditions: string[] = [];
    // 新卒 | 中途
    Vue.$log.debug(this.fields.newRecruit);
    if(this.fields.newRecruit == '1') {
      conditions.push('新卒');
    } else if(this.fields.newRecruit == '2') {
      conditions.push('既卒');
    } else if(this.fields.newRecruit == '0') {
      conditions.push('中途');
    }
    // 社歴
    if(this.fields.hireYearFrom || this.fields.hireYearTo) {
      conditions.push(`${this.fields.hireYearFrom}〜${this.fields.hireYearTo}`);
    }
    // 性別
    if(this.fields.gender == '1') {
      conditions.push('男性');
    } else if(this.fields.gender == '2') {
      conditions.push('女性');
    }
    // 組織
    if(this.fields.departmentId) {
      conditions.push(this.selectedDepartmentText);
    }
    // 職種1
    if(this.fields.occupation) {
      conditions.push(this.fields.occupation);
    }
    // 職種2
    if(this.fields.childOccupation) {
      conditions.push(this.fields.childOccupation);
    }
    // 役職
    if(this.fields.position) {
      conditions.push(this.fields.position);
    }
    // 集計期間
    if(this.useTerm && this.fields.termFrom && this.fields.termTo) {
      conditions.push(`${this.fields.termFrom}〜${this.fields.termTo}`);
    }
    return conditions;
  }

  /**
   * 絞り込み検索集計期間テキストの生成
   */
  termText() {
    if(this.useTerm && this.fields.termFrom && this.fields.termTo) {
      return `${this.fields.termFrom}〜${this.fields.termTo}`;
    }
    const dayBefore = moment().subtract(1, "days").format('YYYY-MM-DD');
    const monthAgo = moment(dayBefore).subtract(this.defaultMonthTerm, "months").format('YYYY-MM-DD');
    return `${monthAgo}〜${dayBefore}`;
  }

  /**
   * ラジオボタンのtoggle
   */
  toggleRadio(name: string, event: Event) {
    // eslint-disable-next-line
    if(event.target && (this.fields as any)[name]) {
      // eslint-disable-next-line
      if((this.fields as any)[name] == (event.target as HTMLInputElement).value) {
        // eslint-disable-next-line
        (this.fields as any)[name] = '';
        return false;
      }
    }
  }

  /**
   * 検索条件のクリア
   */
  clearFields(): void {
    Vue.$log.debug('clear fields');
    this.fields = new UserSearchData();
    this.fields.termTo = moment().subtract(1, "days").format('YYYY-MM-DD');
    this.fields.termFrom = moment(this.fields.termTo).subtract(this.defaultMonthTerm, "months").format('YYYY-MM-DD');
    // 検索条件クリア時にはstore側のデータも初期化
    this.$store.commit('setSearchParams', {});
    Vue.$log.debug(this.fields);
  }

  /**
   * 組織、職種2、役職のモーダルを開く、リストの更新
   */
  clickOptions(type: "departments" | "childOccupations" | "positions") {
    this.optionsModalType = type;
    switch(type) {
      case 'departments':
        this.optionList = this.departments.map(v => v.name);
        break;
      case 'childOccupations':
        this.optionList = this.childOccupations;
        break;
      case 'positions':
        this.optionList = this.positions;
        break;
      default:
        this.optionList = [];
        break;
    }
    this.$modal.show('user-search-options-modal');
  }

  /**
   * 組織、職種2、役職モーダル閉じる
   */
  closeUserSearchOptionsModal() {
    this.$modal.hide('user-search-options-modal');
  }

  /**
   * 組織、職種2、役職の変更
   */
  changeOptions(data: string) {
    if (this.selectedList[this.optionsModalType].includes(data)) {
      this.selectedList[this.optionsModalType] = this.selectedList[this.optionsModalType].filter(v => v !== data);
    } else {
      this.selectedList[this.optionsModalType].push(data);
    }
  }

  /**
   * 条件クリア
   */
  clearSearchOptionsFilter() {
    this.filterOptionsTexts = [];
    this.fields.userName = '';
    this.selectedList.departments = [];
    this.selectedList.childOccupations = [];
    this.selectedList.positions = [];
  }
  
  /**
   * 職種1を変更した時の挙動
   */
  userSearchChangeOccupation() {
    this.changeOccupation();
    this.selectedList.childOccupations = [];
  }

  /**
   * 絞り込み条件テキストの取得
   */
  searchOptionsTexts() {
    return [this.fields.userName, ...this.selectedList.departments, ...this.selectedList.childOccupations, ...this.selectedList.positions];
  }
}

