









































import { Component, Vue } from 'vue-property-decorator';
import { HelpPageText, HelpMenu, MenuItem } from '@/const/help';
import HelpNav from '@/components/help/HelpNav.vue'

@Component({
  components: {
    HelpNav,
  },
})
export default class HelpContent extends Vue {

  pageId = '';

  trimSplit(text: string) {
    if(!text) {
      return [];
    }
    return text.trim().split('\n');
  }

  /** メニュー設定 */
  menu: MenuItem;

  /** コンテンツ */
  helpText = HelpPageText

  created() {
    this.pageId = this.$route.params.pageId;
    HelpMenu.MenuItems.forEach((menu) => {
      if(menu.subMenus) {
        menu.subMenus.forEach((subMenu) => {
          if(subMenu.page == this.pageId) {
            this.menu = subMenu;
          }
        });
      }
    });
  }

}
