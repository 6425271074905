





























































import { Prop, Vue } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { Code } from '@/const';
import { StampData } from '@/types';

import ServiceCallHelper from '@/mixins/service_call_helper'
import StampService from '@/services/stamp_service'
import StampButton from '@/components/action_report/StampButton.vue'
import StampUploadModal from '@/components/action_report/StampUploadModal.vue'

@Component({
  components: {
    StampButton,
    StampUploadModal,
  }
})
export default class StampModal extends ServiceCallHelper {

  @Prop()
  modalName: string;

  /** ノーマルスタンプ */
  stamps: StampData[] = [];
 
  /** カスタムスタンプ */
  customStamps: StampData[] = [];

  /** よく使うスタンプ */
  favoriteStamps: StampData[] = [];

  stampService = new StampService();

  /** アクションユーザーのuserId */
  @Prop({default: ''})
  userId: string;

  /** 投稿日 */
  @Prop()
  date: string;

  /** コメントID */
  @Prop({default: ''})
  actionCommentId: string;

  @Prop({default: -1})
  rowIdx: number;

  @Prop()
  dailyAnswerId: string;

  mounted() {
    this.refresh();
  }

  refresh() {
    this.stampService.getStampList().then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESS
      if(data.code == Code.SUCCESS) {
        this.stamps = data.result.stamps;
        this.customStamps = data.result.customStamps;
        this.favoriteStamps = this.$store.getters.favoriteStamps;
        if(!this.favoriteStamps) {
          this.favoriteStamps = new Array<StampData>();
        }
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /**
   * スタンプクリック
   */
  clickStamp(stamp: StampData) {
    // よく使うスタンプに追加
    const stamps = this.stamps.filter((s) => s.stampId == stamp.stampId);
    stamps.forEach((stamp) => {
      let favoriteStamps = this.$store.getters.favoriteStamps;
      if(!favoriteStamps) {
        favoriteStamps = new Array<StampData>();
      }
      favoriteStamps = this.favoriteStamps.filter((s) => s.stampId != stamp.stampId);
      favoriteStamps = [stamp, ...favoriteStamps].slice(0, 7);
      this.$store.commit('setFavoriteStamps', favoriteStamps);
      this.favoriteStamps = favoriteStamps;
    });

    this.$emit('clickStamp', stamp);
    this.$modal.hide(this.modalName);
  }

  /**
   * スタンプを追加する
   */
  clickAddStamp() {
    this.$modal.show('stamp-upload-modal');
  }

  /**
   * スタンプ追加成功
   */
  uploadSuccess() {
    this.$modal.hide('stamp-upload-modal');
    this.refresh();
  }
}

