import Vue from 'vue';
import { Mixin } from "vue-mixin-decorator";
import { AxiosResponse  } from '@/types/axios_types';
import { Messages } from '@/const';
import fileDownload from 'js-file-download';
import store from '@/store';
import router from '@/router';

import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

@Mixin
export default class Requests extends Vue {
  /**
   * get reuqest
   */
  // eslint-disable-next-line
  getReq(url: string, params: any): Promise<AxiosResponse> {
    Vue.$log.debug('Reqests mixin getReq');
    Vue.$log.debug(params);
    return axios.get(url, {
      timeout: 10000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + Vue.cookies.get('accessToken'),
      },
      params: params,
    }).catch( (error) => {
      if(error.code == 404) {
        router.push('/404');
      }
      else if (error.code === 408 || error.code === 'ECONNABORTED') {
        return {data: {code: 'TIMEOUT_ERROR'}} as AxiosResponse;
      }
      else {
        store.commit('setErrorMessage', Messages.NETWORK_ERROR);
        return error.response;
      }
    });
  }

  /**
   * post request
   */
  // eslint-disable-next-line
  postReq(url: string, param: any): Promise<AxiosResponse> {
    Vue.$log.debug('Reqests mixin postReq');
    Vue.$log.debug(param);
    return axios.post(url,  param, {
      timeout: 10000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + Vue.cookies.get('accessToken'),
      },
    }).catch( (error) => {
      Vue.$log.debug(error);
      store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      return error.response;
    });
  }

  /**
   * put request
   */
  // eslint-disable-next-line
  putReq(url: string, param: any): Promise<AxiosResponse> {
    Vue.$log.debug('Reqests mixin putReq');
    Vue.$log.debug(param);
    return axios.put(url,  param, {
      timeout: 10000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + Vue.cookies.get('accessToken'),
      },
    }).catch( (error) => {
      Vue.$log.debug(error);
      store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      return error.response;
    });
  }

  /**
   * delete request
   */
  // eslint-disable-next-line
  deleteReq(url: string, params: any): Promise<AxiosResponse> {
    Vue.$log.debug('Reqests mixin deleteReq');
    Vue.$log.debug(params);
    return axios.delete(url, {
      timeout: 10000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + Vue.cookies.get('accessToken'),
      },
      params: params
    }).catch( (error) => {
      Vue.$log.debug(error);
      store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      return error.response;
    });
  }

  /**
   * download(csv or pdf) reuqest
   */
  // eslint-disable-next-line
  downloadReq(url: string, fileName: string, params: any): Promise<AxiosResponse> {
    Vue.$log.debug('Reqests mixin downloadReq');
    Vue.$log.debug(params);
    return axios.get(url, {
      timeout: 90000,
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer ' + Vue.cookies.get('accessToken'),
      },
      params: params,
    }).then((response) => {
      fileDownload(response.data, fileName);
    }).catch( (error) => {
      Vue.$log.debug(error);
      alert(error);
      store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      return error.response;
    });
  }

  /**
   * upload(csv or pdf) reuqest
   */
  uploadReq(url: string, formData: FormData): Promise<AxiosResponse> {
    Vue.$log.debug('Reqests mixin uploadReq');
    return axios.post(url, formData, {
      timeout: 20000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + Vue.cookies.get('accessToken'),
      },
    }).catch( (error) => {
      Vue.$log.debug('uploadReq error');
      Vue.$log.debug(error);
      if(error.response) {
        store.commit('setErrorMessage', Messages.NETWORK_ERROR);
        return error.response;
      }
      return {
        status: 413,
        isAxiosError: true
      };
    });
  }
}
