import ChecktestInput from '@/views/checktest/ChecktestInput.vue'
import ChecktestQuestion from '@/views/checktest/ChecktestQuestion.vue'
import ChecktestComplete from '@/views/checktest/ChecktestComplete.vue'

export default [
  {
    path: '/checktest',
    name: 'ChecktestInput',
    component: ChecktestInput,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/checktest/question',
    name: 'ChecktestQuestion',
    component: ChecktestQuestion,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/checktest/complete',
    name: 'ChecktestComplete',
    component: ChecktestComplete,
    meta: {
      requireLogin: true,
    },
  },
]

