














































import Vue from 'vue'
import { Prop } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';

import Filters from '@/utils/filters'

import VTooltip from 'v-tooltip'
Vue.use(VTooltip);

@Component({
  filters: Filters,
})
export default class AchieveStackBar extends Vue {

  filters = Filters;

  @Prop()
  percents: Array<number>;

}
