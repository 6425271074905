import ActionReportSearch from '@/views/action_report_search/ActionReportSearch.vue'

export default [
  { 
    path: '/action_report_search',
    name: 'ActionReportSearch',
    component: ActionReportSearch,
    meta: {
      requireLogin: true,
    },
  },
]
