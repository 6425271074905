

import Chart from "chart.js";
import { Prop, Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Radar } from "vue-chartjs";
import { ChecktestReportData, ChecktestReportScoreData } from "@/types";
import ServiceCallHelper from '@/mixins/service_call_helper'

interface MixinInterface extends ServiceCallHelper, Radar {}

interface RadarChartOptions extends Chart.ChartOptions {
  startAngle: number;
}

const LABELS = ["課題発見力", "計画力", "想像力", ["ストレス", "コント", "ロール力"], "規律性", "状況把握力", "柔軟性","傾聴力","配信力","実行力","働きかけ力","主体性"];

@Component
export default class CheckTestRadar extends Mixins<MixinInterface>(ServiceCallHelper, Radar) {

  @Prop()
  reportData: ChecktestReportData;

  mounted() {
    this.updateChart(this.reportData);
  }

  /** グラフ描画用データ検索 */
  updateChart(reportData: ChecktestReportData) {
    const colorSet = {
      yellow: 'rgb(255, 205, 86)',
      pink: 'rgb(253, 183, 182)',
      darkgrey: 'rgb(169,169,169)',
      blue: 'rgb(94, 176, 222)',
      black: 'rgb(0,0,0)',
    };
    const color = Chart.helpers.color;

    const chartOptions: RadarChartOptions = {
      startAngle: 15,
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
        labels: {
          fontColor: colorSet.darkgrey,
        }
      },
      title: {
        display: false
      },
      scale: {
        display: true,
        pointLabels: {
          fontSize: 10.5,
          fontColor: colorSet.black
        },
        ticks: {
          display: false,
          fontSize: 14,
          fontColor: colorSet.black,
          min: 0,
          max: 10,
          stepSize: 10,
          beginAtZero: true
        },
        gridLines: {
          display: true,
          color: colorSet.darkgrey
        }
      },
      layout: {
        padding: {
          top: 70,
        }
      },
      plugins: {
        datalabels: {
          display: false
        }
      },
      tooltips:{
        callbacks:{
          title: function(tooltipItems: Chart.ChartTooltipItem[]) {
            return LABELS[(tooltipItems[0].index as number)];
          },
        },
      },
    }

    const datasets: Chart.ChartDataSets[] = [];

    // 個人データ
    let scoreData = reportData.scoreData;
    if(scoreData && scoreData.totalScore > 0) {
      datasets.push({
        backgroundColor: color(colorSet.blue).alpha(0.3).rgbString(),
        pointBackgroundColor: colorSet.blue,
        data: this.toScoreDataArray(scoreData),
      })
    }

    // 社内平均データ
    scoreData = reportData.scoreDataAvg;
    if(scoreData && scoreData.totalScore > 0) {
      datasets.push({
        backgroundColor: color(colorSet.yellow).alpha(0.3).rgbString(),
        pointBackgroundColor: colorSet.yellow,
        data: this.toScoreDataArray(scoreData),
      })
    }

    // 全国平均データ
    scoreData = reportData.scoreDataNationalAvg;
    if(scoreData && scoreData.totalScore > 0) {
      datasets.push({
        backgroundColor: color(colorSet.pink).alpha(0.3).rgbString(),
        pointBackgroundColor: colorSet.pink,
        data: this.toScoreDataArray(scoreData),
      })
    }

    const chartData: Chart.ChartData = {
      labels: LABELS,
      datasets: datasets
    }


    Vue.$log.debug(chartData);

    this.renderChart(chartData, chartOptions);
  }

  /**
   * チャートに渡すスコア配列
   */
  toScoreDataArray(scoreData: ChecktestReportScoreData) {
    const fmt = function(n: number){return parseFloat(n.toFixed(1))};
    return [
      fmt(scoreData.problemDiscoveryScore),
      fmt(scoreData.planningScore),
      fmt(scoreData.creativityScore),
      fmt(scoreData.stressControlScore),
      fmt(scoreData.disciplineScore),
      fmt(scoreData.graspSituationScore),
      fmt(scoreData.flexibilityScore),
      fmt(scoreData.listeningScore),
      fmt(scoreData.transmissionScore),
      fmt(scoreData.executionScore),
      fmt(scoreData.approachScore),
      fmt(scoreData.subjectivityScore),
    ];
  }

}
