








import { Component, Vue } from 'vue-property-decorator';
import ChecktestHeader from '@/components/checktest/ChecktestHeader.vue';
import Footer from '@/components/common/Footer.vue';
import ChecktestQuestionForm from '@/components/checktest/ChecktestQuestionForm.vue';

@Component({
  components: {
    ChecktestHeader,
    ChecktestQuestionForm,
    Footer,
  },
})
export default class ChecktestQuestion extends Vue {}
