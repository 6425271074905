
import Chart from "chart.js"
import { Component, Mixins } from "vue-mixin-decorator"
import { HorizontalBar } from "vue-chartjs"
import {
  DailyAbilityAchieveRatesPerActionResponseData
} from "@/services/dashboard_service"
import ServiceCallHelper from "@/mixins/service_call_helper"

interface MixinInterface extends ServiceCallHelper, HorizontalBar {}

@Component
export default class AchieveHorizontalBarChart extends Mixins<MixinInterface>(
  ServiceCallHelper,
  HorizontalBar
) {
  chartData: Chart.ChartData = {}

  chartOptions: Chart.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
          },
          ticks: {
            min: 0,
            max: 100,
            fontSize: 18,
            callback: function() {
              return ""
            },
             padding: -100,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
              display: false
          }
        }
      ]
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  }

  /** mount済み通知 */
  mounted() {
    this.$emit('onMounted');
  }

  /** グラフ描画用データ検索 */
  search(data: DailyAbilityAchieveRatesPerActionResponseData) {
    this.chartData = {
      labels: data.labels,
      datasets: [
        {
          label: "",
          data: data.data.map(v => Math.floor(v * 100)),
          backgroundColor: "#F08CB0",
          barPercentage: 0.4,
          categoryPercentage: 0.4,
        },
      ],
    }
    this.renderChart(this.chartData, this.chartOptions);
  }
}
