import TestReportList from '@/views/test_report/TestReportList.vue';
import TestReport from '@/views/test_report/TestReport.vue';
import MyTestReport from '@/views/test_report/MyTestReport.vue';
import GroupTestReport from '@/views/test_report/GroupTestReport.vue';

export default [
  {
    path: '/test_report/:userId',
    name: 'TestReport',
    component: TestReport,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/test_report',
    name: 'TestReportList',
    component: TestReportList,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/my_test_report',
    name: 'MyTestReport',
    component: MyTestReport,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/group_test_report',
    name: 'GroupTestReport',
    component: GroupTestReport,
    meta: {
      requireLogin: true,
    },
  },
]


