



















































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { DepartmentSearchData } from '@/types/settings/department_types';

import ServiceCallHelper from '@/mixins/service_call_helper'
import { DepartmentSelect } from '@/mixins'

/** 組織のセレクトボックス値設定 */
interface MixinInterface extends ServiceCallHelper, DepartmentSelect {}

@Component
export default class DepartmentSearchModal extends Mixins<MixinInterface>(ServiceCallHelper, DepartmentSelect) {

  fields = new DepartmentSearchData();

  /**
   * 検索
   */
  clickSearchButton() {
    this.$modal.hide('department-search-modal');
    this.$emit('search', this.$data.fields);
  }

  /**
   * キャンセル
   */
  clickCancelButton() {
    this.$modal.hide('department-search-modal');
  }

  /**
   * 絞り込み検索テキストの生成
   */
  searchTexts(): string[] {
    Vue.$log.debug('searchText');
    const conditions: string[] = [];
    // 組織名
    if(this.fields.departmentName) {
      conditions.push(this.fields.departmentName);
    }
    // 上位組織
    if(this.fields.parentDepartmentId) {
      conditions.push(this.selectedDepartmentText);
    }
    return conditions;
  }

  /**
   * 検索条件のクリア
   */
  clearFields(): void {
    Vue.$log.debug('clear fields');
    this.fields = new DepartmentSearchData();
    Vue.$log.debug(this.fields);
  }
}

