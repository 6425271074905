












































import { Prop, Vue } from 'vue-property-decorator';
import { Component } from "vue-mixin-decorator"
import AutoUrlLink from './AutoUrlLink.vue';

@Component({
  components: {
    AutoUrlLink,
  }
})
export default class CommentText extends Vue {

  @Prop({default: ''})
  text: string;

  @Prop({default: 0})
  index: number;

  @Prop({default: 140})
  hideTextLength: number;

  @Prop({ default: "" })
  boxTitle: string;

  @Prop({ default: '' })
  hiddenCommentInfo: string;

  @Prop({default: ""})
  answerTime: string;

  lineCount = 0

  visible = false

  mounted() {
    this.lineCount = this.text.trim().split('\n').length
  }

  /** コメントの初期表示部分 */
  userCommentHeader() {
    const lines = this.text.trim().slice(0, this.hideTextLength).split('\n')
    if(this.lineCount > 3 || this.text.length > this.hideTextLength) {
      lines[lines.length - 1] += ' ...'
    }
    return lines.slice(0, 3).join("\n");
  }
}

