











































































































































































































































































































































































































import { Vue } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";
import "vue-good-table/dist/vue-good-table.css";
import { default as ActionAnswerService } from "@/services/action_answer_service";
import StampService from "@/services/stamp_service";
import { ColumnOption } from "@/types/good_table_types";
import {
  ActionTimelineData,
  CommentData,
  StampData,
  PostStampData,
} from "@/types";
import { Messages, Code } from '@/const';

import Filters from '@/utils/filters'

import GoodTableHeader from "@/mixins/good_table_mixin";
import ActionReportServiceHelper from "@/mixins/action_report_service_helper";

import { OverlayLoader } from "@/components/parts";
import CommentUpdateModal from "@/components/action_report/CommentUpdateModal.vue";
import CommentListModal from "@/components/action_report/CommentListModal.vue";
import CommentText from "@/components/action_report/CommentText.vue";
import StampModal from "@/components/action_report/StampModal.vue";
import StampButton from "@/components/action_report/StampButton.vue";
import MemberListModal from "@/components/action_report/MemberListModal.vue";
import { AchievePieChart, SkboxPlugins } from "@/components/chart";
import router from "@/router";

interface MixinInterface
  extends ActionReportServiceHelper,
    GoodTableHeader<ActionTimelineData> {}

import "@/styles/tooltips.scss";

@Component({
  components: {
    OverlayLoader,
    CommentUpdateModal,
    CommentListModal,
    StampModal,
    StampButton,
    CommentText,
    "vue-good-table": require("vue-good-table").VueGoodTable,
    AchievePieChart,
    MemberListModal,
  },
  filters: Filters,
})
export default class TimelineDetails extends Mixins<MixinInterface>(
  ActionReportServiceHelper,
  GoodTableHeader
) {
  /** 各ライムラインのユーザーID */
  userId = "";

  /** vue-good-table用カラム設定 */
  columns = [new ColumnOption("answerDate", "answerDate", { sortable: false })];

  /** vue-good-table用row data */
  rows: ActionTimelineData[] = [];

  /** Action IDに対して紐づく説明テキスト */
  actionTexts: any = {};

  /** userIdに紐づくユーザーの各種情報 */
  userInfo: any = {};

  /** コメントモーダルのモード */
  commentMode = "add";

  /** コメントモーダルの対象日付 */
  commentDate = "";

  /** コメントモーダルの初期表示テキスト */
  commentText = "";

  /** 選択Row index */
  selectedRowIdx = -1;

  /** 選択回答日 */
  selectAnswerDate = "";

  dailyAnswerId = "";
  
  /** コメント一覧表示用データ */
  listComments: Array<CommentData> = [];

  /** 閲覧ユーザーのコメントが存在するかの判定 */
  inMyComment = false;

  memoComment: {memo: string; date: string} = {memo: '', date: ''};

  /** 回答スタンプ情報 */
  answerStamps: StampData[] = [];

  /** アクション回答日時 */
  answerTime = "";

  /** ナイショ話を閲覧できるかの判定 */
  hasHiddenComment = false;

  pieChartPlugins = [new SkboxPlugins().drawDoughnutPercenteageText];

  /** stampデータのclass出し分け */
  stampClass(stamp: StampData) {
    if (stamp.myStamp) {
      return "btn  actionreport--stamp actionreport--stamp-active";
    }
    return "btn  actionreport--stamp";
  }

  created() {
    this.updateParams({perPage: 50});
  }

  mounted() {
    this.loadItems();
  }

  /**
   * 一覧の取得
   */
  loadItems(isCommentList = false, targetDate = "", targetUserId = 0) {
    this.isLoading = true;

    Vue.$log.debug(this.serverParams);

    // 指定userIdのレポート取得
    new ActionAnswerService()
      .searchActionTimeline(this.serverParams)
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          const data = response.data;
          Vue.$log.debug(this.userId);
          if (this.commonApiErrorHandler(response)) {
            // SUCCESSの場合は値を設定
            if (data.code == Code.SUCCESS) {
              this.totalRows = response.data.result.totalRows;
              if (this.totalRows > 0) {
                this.rows = data.result.action;

                // コメントリストからの投稿の場合は更新
                if (isCommentList) {
                  const currentAction = data.result.action.filter(v => v.answerDate === targetDate && v.userId === targetUserId);
                  this.listComments = currentAction[0].comments
                }
                this.actionTexts = data.result.actionTexts;
                this.userInfo = data.result.users;
                Vue.$log.debug(this.userInfo);
              } else {
                this.rows = [];
              }
              this.pageLoaded = true;
            }
          }
        }
      });
  }

  /**
   * スタンプ選択モーダル表示
   */
  clickShowStampModal(date: string, rowIdx: number, userId: string, dailyAnswerId: string) {
    this.commentDate = date;
    this.selectedRowIdx = rowIdx;
    this.userId = userId;
    this.dailyAnswerId = dailyAnswerId;
    this.$modal.show("stamp-modal");
  }

  /**
   * モーダルのスタンプクリック
   */
  clickModalStamp(stamp: StampData) {
    Vue.$log.debug(stamp.stampId);
    // stamp登録
    const stampData = {
      dailyAnswerId: this.dailyAnswerId,
      answerDate: this.commentDate,
      stampId: stamp.stampId,
      userId: this.userId,
    };
    Vue.$log.debug("stampData");
    Vue.$log.debug(stampData);
    this.$emit("close");
    new StampService()
      .postStamp(stampData)
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug(response);
          this.refreshStampDisp(this.selectedRowIdx, stampData);
        }
      })
      .finally(() => {
        this.hideLoading();
      });
  }

  /**
   * 表示スタンプクリック
   */
  clickStampImage(
    stamp: StampData,
    answerDate: string,
    rowIdx: number,
    userId: string,
    dailyAnswerId: string,
  ) {
    if (!userId) {
      return;
    }
    Vue.$log.debug(stamp);
    // API用パラメータ
    const postData = {
      answerDate: answerDate,
      stampId: stamp.stampId,
      userId: userId,
      dailyAnswerId: dailyAnswerId,
    } as PostStampData;
    Vue.$log.debug(rowIdx);

    // 自スタンプがある場合は削除
    if (stamp.myStamp) {
      new StampService().deleteStamp(postData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug("deleteStamp");
          this.refreshStampDisp(rowIdx, postData);
        }
      });
    }
    // 自スタンプがない場合は追加
    else {
      new StampService().postStamp(postData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          this.refreshStampDisp(rowIdx, postData);
        }
      });
    }
  }

  /**
   * コメントする / コメントを編集するボタン
   */
  clickCommentUpdateButton(
    date: string,
    comments: Array<CommentData>,
    userId: string
  ) {
    Vue.$log.debug(date);
    this.commentMode = "add";
    this.commentText = "";
    this.commentDate = date;
    this.userId = userId;
    for (const comment of comments) {
      if (comment.myComment == 1) {
        this.commentMode = "update";
        this.commentText = comment.text;
        break;
      }
    }
    this.$modal.show("comment-update-modal");
  }

  /** コメント投稿成功通知 */
  commentUpdateSuccess() {
    this.$modal.hide("comment-update-modal");
    this.loadItems();
  }

  /** コメント投稿成功通知 */
  commentSendSuccess(data: {date: string; userId: number; replyUserId: number}) {
    // アイテム更新
    this.loadItems(true, data.date, data.userId);
    if(!data.replyUserId) {
      this.inMyComment = true;
    }
    // チームボードカウント更新
    this.updateTeamboardCount();
  }

   /** コメント削除成功通知 */
  commentDeleteSuccess(data: {date: string; userId: number; replyUserId: number}) {
    this.loadItems(true, data.date, data.userId);
    if(!data.replyUserId) {
      this.inMyComment = false;
    }
    // チームボードカウント更新
    this.updateTeamboardCount();
  }

  /**
   * コメントボタン（コメント一覧表示）
   */
  clickCommentList(
    dailyAnswerId: string,
    userId: string,
    answerDate: string,
    answerTime: string,
    comments: Array<CommentData>,
    memo: string,
    hiddenComment: string,
    stamps: StampData[],
    rowIdx: number
  ) {
    const commentListModal = this.$refs.commentListModal as CommentListModal;
    commentListModal.clearReplyUser();
    if(commentListModal.dailyAnswerId != dailyAnswerId) {
      commentListModal.clearInputComment();
      commentListModal.clearReplyInputComment();
    }
    if (comments && comments.length >= 0) {
      this.$modal.show("comment-list-modal");
      this.userId = userId;
      this.listComments = comments;
      this.selectAnswerDate = answerDate;
      this.memoComment = { memo: memo, date: answerDate };
      this.answerTime = answerTime;
      this.answerStamps = stamps;
      this.selectedRowIdx = rowIdx;
      if (hiddenComment) {
        this.hasHiddenComment = true;
      } else {
        this.hasHiddenComment = false;
      }
      const myComment = comments.filter(e => e.myComment == 1);
      if(myComment.length > 0) {
        this.inMyComment = true;
      } else {
        this.inMyComment = false;
      }
      commentListModal.refreshStampDisp(dailyAnswerId, this.userId, answerDate);
    }
  }

  /** コメント一覧close通知 */
  closeCommentList() {
    this.$modal.hide("comment-list-modal");
  }

  /** スタンプモーダル処理成功通知 */
  successStampModal(rowIdx: number, stampData: PostStampData) {
    Vue.$log.debug("successStampModal");
    this.refreshStampDisp(rowIdx, stampData);
  }

  /** スタンプ表示情報の更新 */
  refreshStampDisp(rowIdx: number, stampData: PostStampData) {
    new StampService()
      .getDailyStamp(stampData.userId, stampData.answerDate)
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug("refreshStampDisp");
          Vue.$log.debug(response.data.result.stamp);
          this.rows[rowIdx].stamps = response.data.result.stamp;
          this.answerStamps = response.data.result.stamp;
        }
      });
  }

  /**
   * 前日からの流動率を取得
   */
  getRatio(current: number, prev: number) {
    let symbol = "";
    if (current > prev) {
      symbol = "+";
    } else if (current < prev) {
      symbol = "-";
    }

    const num = Math.floor(Math.abs(current * 100 - prev * 100));

    return `${symbol}${num}%`;
  }

  /**
   * テーブルセル上部にボーダー付きか
   */
  getHasBorder(index: number, answerDate: string) {
    return (
      index === 0 ||
      (this.rows[index - 1] && this.rows[index - 1].answerDate !== answerDate)
    );
  }

  /** 回答削除クリック */
  clickAnswerDelete(answerDate: string, answerId: number) {
    if(this.userId) {
      return;
    }
    this.$swal({
      title: "アクション回答を削除します",
      text: "削除された回答は復元することができません。よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        this.showLoading();
        new ActionAnswerService()
          .deleteAnswer(answerDate, answerId)
          .then((response) => {
            if (this.commonApiErrorHandler(response)) {
              const data = response.data;
              Vue.$log.debug(data);
              // SUCCESS時は成功通知
              if (data.code == Code.SUCCESS) {
                this.$swal(Messages.DELETE_SUCCESS, "", "success").then(() => {
                  this.loadItems()
                });
              }
              // その他のケースは接続エラーに
              else {
                this.$swal(Messages.NETWORK_ERROR, "", "error");
              }
            }
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    });
  }

  /**
   * コメントの編集するテキストとidを代入
   */
  commentUpdate(text: string, commentId: number, replyUserId = 0) {
    this.loadItems();
    commentData: for (const comment of this.listComments) {
      if(replyUserId && comment.replyComment) {
        for (const replyComment of comment.replyComment) {
          if (replyComment.commentId == commentId) {
            replyComment.text = text;
            replyComment.commentId = commentId;
            break commentData;
          }
        }
      } else if(comment.commentId == commentId) {
        comment.text = text;
        comment.commentId = commentId;
        break;
      }
    }
  }

  /** リンクをクリップボードにコピー */
  copyActionLink(aid: number, userId: number) {
    const text = `https://${window.location.host}/action_report/${userId}?aid=${aid}`;
    navigator.clipboard.writeText(text);
  }
}
