

























































































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import Footer from '@/components/common/Footer.vue';

import { ContactUsData } from '@/types';
import { Messages, Code } from '@/const';
import { ErrorMessage, OverlayLoader } from '@/components/parts';

import { validationMixin } from 'vuelidate'
import { ContactUsValidator } from '@/validators'

import UserService from '@/services/user_service';
import ContactUsService from '@/services/contact_us_service';
import ServiceCallHelper from '@/mixins/service_call_helper';
import { DepartmentSelect } from '@/mixins';

/** 組織のセレクトボックス値設定 */
interface MixinInterface extends ServiceCallHelper, DepartmentSelect {}

@Component( {
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
    Footer,
  },
  validations: {
    fields: new ContactUsValidator()
  },
} )
export default class ContactUs extends Mixins<MixinInterface>(ServiceCallHelper, DepartmentSelect) {
  fields = new ContactUsData();

  /**
   * mounted時、ログインユーザーの情報でフォームfill
   */
  mounted() {
    this.showLoading();
    new UserService().getMyUser().then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      this.fields.userName = data.result.user.userName;
      this.fields.departmentId = data.result.user.departmentId;
      this.fields.loginId = data.result.user.loginId;
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /**
   * 送信ボタンクリック
   */
  clickSend() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      Vue.$log.debug(this.$v);
      this.$swal(Messages.INVALID_INPUT, '', 'warning');
      return;
    }

    this.$swal({
      title: '送信します',
      text: 'よろしいですか？',
      confirmButtonColor: '#5EB0DE',
      cancelButtonColor: '#FC7067',
      showCancelButton: true,
    }).then( isConfirm => {
      if(isConfirm.isConfirmed) {
        this.showLoading();
        // メール送信API呼び出し
        new ContactUsService().send(this.fields).then((response) => { if(this.commonApiErrorHandler(response)) {
          const data = response.data;
          // SUCCESS時は成功通知
          if(data.code == Code.SUCCESS) {
            this.$swal('お問い合わせありがとうございます。', '', 'success');
          }
          // その他のケースは接続エラーに
          else {
            this.$swal(Messages.NETWORK_ERROR, '', 'error');
          }
        } }).finally(() => {
          this.hideLoading();
        });
      }
    }); 

  }
}
