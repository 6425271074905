/**
 * Cookieの保存管理用
 */

import Vue from 'vue';

export default class Cookies {

  /**
   * ログイン情報保存の可否取得
   */
  public static getNoSavingLoginInfo() {
    return Vue.cookies.get('noSavingLoginInfo')
  }

  /**
   * ログイン情報保存の可否設定
   */
  public static setNoSavingLoginInfo(value: string) {
    Vue.cookies.set('noSavingLoginInfo', value, {'expires': '730d'});
  }

  /**
   * アクセストークン取得
   */
  public static getAccessToken() {
    return Vue.cookies.get('accessToken')
  }

  /**
   * アクセストークン設定
   */
  public static setAccessToken(value: string) {
    Vue.cookies.set('accessToken', value, {'expires': '30d'});
  }

  /**
   * アクセストークン設定
   */
  public static setAccessTokenNoCache(value: string) {
    Vue.cookies.set('accessToken', value);
  }

}