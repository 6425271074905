






















































































import moment from "moment"
import router from '@/router'

import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-mixin-decorator';
import { OverlayLoader, ErrorMessage } from '@/components/parts'
import { Messages, Code } from '@/const';
import ServiceCallHelper from '@/mixins/service_call_helper'

import { ChecktestService } from '@/services'
import { ChecktestQuestionData, ChecktestAnswerObject } from '@/types';
import ChecktestQuestionMessage from '@/components/checktest/ChecktestQuestionMessage.vue'

@Component({
  components: {
    OverlayLoader,
    ErrorMessage,
    ChecktestQuestionMessage,
  },
})
export default class ChecktestQuestionForm extends ServiceCallHelper {

  // form fields
  fields: ChecktestAnswerObject = {};

  // 現在の表示ページ
  pageNumber = 1;

  // 設問情報
  questions: Array<ChecktestQuestionData> = [];

  /** 表示設問 */
  get dispQuestions() {
    return this.questions.slice((this.pageNumber - 1) * 5, this.pageNumber * 5)
  }

  /** 最終ページ */
  get lastPage() {
    return this.pageNumber >= (this.questions.length / 5)
  }

  /** 開始時 */
  startTime = new Date();

  /** 表示時刻 */
  dispTime = '';

  // 回答時間オーバー
  timeUp = false;

  /** タイマー */
  clockInterval: number; // = setInterval(() => { this.updateTimer() }, 1000);

  /**
   * タイマー更新
   */
  updateTimer() {
    const diff = Math.round((this.startTime.getTime() - new Date().getTime()) / 1000);
    const mom = moment("1970-01-01T01:30:00").seconds(diff);
    if(mom.hour() < 1) {
      this.dispTime = '00:00';
      this.timeUp = true;
      clearInterval(this.clockInterval);
    }
    else {
      this.dispTime = mom.format('mm:ss');
    }
  }

  /**
   * mounted時、設問レコード取得
   */
  mounted() {
    this.showLoading();
    new ChecktestService().getQuestions().then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESSの場合は値を設定
      if(data.code == Code.SUCCESS) {
        Vue.$log.debug(data.result);
        this.questions = data.result.questions;
        this.pageLoaded = true;
        Vue.$log.debug(this.questions);
      }
      // 回答済みの場合はエラー表示
      else if(data.code == Code.CHECKTEST_ALREADY_EXIST) {
        this.$swal('チェックテストは終了しています', 'テストレポートページへ移動します。', 'error');
        router.push('/my_test_report');
      // その他のケースは接続エラーに
      } else {
        this.$swal(Messages.NETWORK_ERROR, '', 'error');
      }
    } }).finally(() => {
      this.hideLoading();
      this.clockInterval = setInterval(() => { this.updateTimer() }, 1000);
      this.startTime = new Date();
    });
  }

  /**
   * 次へ
   */
  clickNext() {
    // 全件回答済み
    if(this.dispQuestions.every((q) => this.fields[`q${q.id}`])) {
      this.pageNumber += 1;
      window.scrollTo(0,0);
      // TODO テスト用にデフォルトで値が入るように
      for(const q of this.dispQuestions) {
        this.fields[`q${q.id}`] = Math.floor(Math.random() * 10).toString();
      }
    }
    else {
      this.$swal('未回答の問いがあります。', '', 'warning');
    }
  }

  /*
   * 戻る
   */
  clickPrev() {
    if(this.pageNumber <= 1) {
      router.push('/checktest')
    }
    else {
      this.pageNumber -= 1;
      window.scrollTo(0,0);
    }
  }

  /** 回答ボタン **/
  clickAnswer() {
    if(! this.dispQuestions.every((q) => this.fields[`q${q.id}`])) {
      this.$swal('未回答の問いがあります。', '', 'warning');
    }
    // 設問の並び順を取得
    this.showLoading();
    new ChecktestService().postAnswers(this.fields, this.questions).then((response) => { if(this.commonApiErrorHandler(response, false)) {
      const data = response.data;
      console.log(data);
      if(data.code == Code.VALIDATION_ERROR) {
        this.$swal(data.message, '', 'warning');
      }
      else if(data.code == Code.SUCCESS) {
        this.$store.commit('setEnableUserChecktest', 0);
        router.push('/checktest/complete');
      }
    } }).finally(() => {
      this.hideLoading();
    });
  }

  /**
   * destroy時, intervalの始末
   */
  beforeDestroy() {
    clearInterval( this.clockInterval )
  }

}

