/**
 * 全体検索 / 検索条件
 */
export class ActionReportSearchConditionsData {
  text  = '';
  st = '';
  termFrom = '';
  termTo = '';
}

/**
 * 全体検索 / 検索結果
 */
export class ActionReportSearchResultData {
  recordCount = 0;
  report: {
    id: number;
    memo: string;
  }[] = []
}
