























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ActionListWaitApproveMessage extends Vue {
  @Prop()
  users: Array<any>;

  closeAlert(requestDate: string, userId: number) {
    this.$emit("closeApproveAlert", requestDate, userId);
  }
}
