











































































































































import router from '@/router'
import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Messages, Code } from '@/const';
import { OverlayLoader, UploadModal } from '@/components/parts'
import TableSaveSettingsButton from '@/components/settings/company_action_recommend/TableSaveSettingsButton.vue'

import 'vue-good-table/dist/vue-good-table.css'

import CompanyActionService from '@/services/company_action_service'
import { SortOption, ColumnOption  } from '@/types/good_table_types'

import { CompanyActionRecommendData, CompanyActionRecommendFlg } from '@/types'
import GoodTableHeader from '@/mixins/good_table_mixin'
import ServiceCallHelper from '@/mixins/service_call_helper'


interface MixinInterface extends ServiceCallHelper, GoodTableHeader<CompanyActionRecommendData> {}

@Component({
  components: {
    OverlayLoader,
    UploadModal,
    TableSaveSettingsButton,
    'vue-good-table': require('vue-good-table').VueGoodTable,
  }
})
export default class CompanyActionRecommendTable extends Mixins<MixinInterface>(ServiceCallHelper, GoodTableHeader) {

  service = new CompanyActionService();

  columns = [
    new ColumnOption('id', 'id', {hidden: true}),
    new ColumnOption('名前', 'name', {sortable: false}),
    new ColumnOption('組織', 'departmentName', {sortable: false}),
    new ColumnOption('役職', 'position', {sortable: false}),
    new ColumnOption('職種1', 'occupation', {sortable: false}),
    new ColumnOption('職種2', 'childOccupation', {sortable: false}),
    new ColumnOption('入社歴', 'hireYearBelow', {sortable: false}),
    new ColumnOption('', 'duplicate', {sortable: false}),
  ]

  rows: Array<CompanyActionRecommendData> = [];

  sortOptions = new SortOption(true, []);

  /** 検索条件テキスト */
  filterTexts: string[] = [];

  /** 成功メッセージ */
  successMessage = '';

  fields = new CompanyActionRecommendFlg();

  /**
   * 組織一覧の取得
   */
  loadItems() {
    Vue.$log.debug('loadItems');
    // 一覧取得
    new CompanyActionService().getCompanyActionRecommends(this.serverParams).then((response) => {
      if(this.commonApiErrorHandler(response)) {
        const data = response.data;
        // SUCCESS時はデータ設定
        if(data.code == Code.SUCCESS) {
          this.totalRows = data.result.totalRows;
          this.rows = data.result.recommend;
          this.fields = data.result.setting;
          this.setColumns();
        }
        // その他のケースは接続エラーに
        else {
          this.$swal(Messages.NETWORK_ERROR, '', 'error');
        }
      }
    }).finally(() => {
      this.isLoading = false;
    });
  }

  /**
   * 条件を追加ボタン押下
   */
  clickAddCondition() {
    router.push('/company_action_recommend');
  }

  /**
   * アクションをCSVから追加ボタン押下
   */
  clickActionUploadButton() {
    Vue.$log.debug("clickActionUploadButton");
    this.$modal.show('action-upload-modal');
  }

  /** upload success時 */
  uploadSuccess() {
    Vue.$log.debug("upload success");
    this.$swal(Messages.INSERT_SUCCESS, '', 'success').then(() => {
      this.$modal.hide('action-upload-modal');
    })
  }

  setColumns() {
    const columns: Array<ColumnOption> = [];
    columns.push(new ColumnOption('id', 'id', {hidden: true}));
    columns.push(new ColumnOption('名前', 'name', {sortable: false}));
    if(this.fields.department == 1) {
      columns.push(new ColumnOption('組織', 'departmentName', {sortable: false}));
    }
    if(this.fields.position == 1) {
      columns.push(new ColumnOption('役職', 'position', {sortable: false}));
    }
    if(this.fields.occupation == 1) {
      columns.push(new ColumnOption('職種1', 'occupation', {sortable: false}));
    }
    if(this.fields.childOccupation == 1) {
      columns.push(new ColumnOption('職種2', 'childOccupation', {sortable: false}));
    }
    if(this.fields.hireYear == 1) {
      columns.push(new ColumnOption('入社歴', 'hireYearBelow', {sortable: false}));
    }
    columns.push(new ColumnOption('', 'duplicate', {sortable: false}));
    this.columns = columns;
  }

}

